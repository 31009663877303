import { IAntecipadoraTransacoesService } from '../@types/services';
import { createApi } from '../providers/factories';
import contaEscrowService from './contaEscrowService';

const createContaEscrowService = (): IAntecipadoraTransacoesService => {
  const apiProvider = createApi();
  return contaEscrowService(apiProvider);
};

export default createContaEscrowService;
