/* eslint-disable no-debugger */
import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import useCurrentUser from '../../../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';
import { Header } from '../styles';
import { IHeaderMenuProps } from '../types';
import createBusinessLinked from '../../../services/companyLikend';
import { ISelectValue } from '../../Forms/AsyncFormDropdown/types';
import FormFilter from '../../Forms/SelectEmpresas';
import { saveCompanySelected } from '../../../store/Company';
import { ISelectedBusiness } from '../../../global/dataTransferObjects/business';

const HeaderMenu: React.FC<IHeaderMenuProps> = (props: IHeaderMenuProps) => {
  const {
    onSignOutButtonClick,
    collapsed,
    toggle,
    onModalStatus,
    onSaveCompanySelected,
  } = props;
  // const [setUserBusinessList] = useState<any>();
  const { name } = useCurrentUser();

  const businessListService = createBusinessLinked()
  // const [state, dispatch] = useReducer(Reducer, InitialState);

  // const business = useGetCompany();
  const handleLoadBusinessOptions = async (): Promise<Array<any>> => {
    const allBusiness: any = await businessListService.getCompanyLinkedList();
    const options = allBusiness.response.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
    return options;
  }

  // useEffect(() => {
  //   const businessList = async () => {
  //     const allBusiness = await businessListService.getCompanyLinkedList();
  //     setUserBusinessList(allBusiness);
  //   }
  //   businessList();
  // }, []);

  const handleCompanySelected = (item: ISelectValue) => {
    if (item !== undefined) {
      //@ts-ignore
      const selectedCompany: ISelectedBusiness = {
        id: item.value,
        name: item.label,
      }
      onSaveCompanySelected(selectedCompany);
    }
  }

  return (
    <Header>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <UserAvatar
        userName={name}
        onSignOutButtonClick={onSignOutButtonClick}
        onModalStatus={onModalStatus}
      />
    </Header>
  );
};

const mapStateToProps = (props: IHeaderMenuProps) => ({
  props,
});

const mapDispatchToProps = {
  onSaveCompanySelected: saveCompanySelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
