import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loadingStatus: false,
  isModalOpen: false,
  unidadeRecebivel: {
    dataLiquidacao: '',
    codigoArranjoPagamento: '',
    instituicaoCredenciadora: '',
    documentoFinalRecebedor: '',
    constituicao: '',
    valorConstituidoTotal: 0,
    valorConstituidoAntecipacaoPre: 0,
    valorBloqueado: 0,
    valorLivre: 0,
    valorTotalUr: 0,
  },
  unidadesRecebiveis: {
    count: 0,
    offset: 0,
    pageSize: 20,
    records: [],
  },
}

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.payload?.loadingStatus ?? InitialState.loadingStatus,
      };
    case ActionTypes.UPDATE_UNIDADE_RECEBIVEL:
      return {
        ...state,
        unidadeRecebivel: action.payload?.unidadeRecebivel ?? InitialState.unidadeRecebivel,
      }
    case ActionTypes.UPDATE_MODAL_STATUS:
      return {
        ...state,
        isModalOpen: action.payload?.modalStatus ?? InitialState.isModalOpen,
      }
    case ActionTypes.UPDATE_UNIDADES_RECEBIVEIS:
      return {
        ...state,
        unidadesRecebiveis: action.payload?.unidadesRecebiveis ?? { ...InitialState.unidadesRecebiveis },
      }
    default:
      return { ...state }
  }
}
