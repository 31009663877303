import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  type: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('Este campo é obrigatório')
    .nullable()
    .required('Este campo é obrigatório'),
  nickname: Yup.string().required('Este campo é obrigatório'),
  contact: Yup.string().required('Este campo é obrigatório'),
  cnpj: Yup.string().when('type', {
    is: { label: 'Pessoa Juridica', value: '1' },
    then: Yup.string().required('Este campo é obrigatório'),
  }),
  cpf: Yup.string().when('type', {
    is: { label: 'Pessoa Fisica', value: '2' },
    then: Yup.string().required('Este campo é obrigatório'),
  }),
  ie: Yup.string().required('Este campo é obrigatório'),
  telephone1: Yup.string().required('Este campo é obrigatório'),
  telephone2: Yup.string().required('Este campo é obrigatório'),
  telephone3: Yup.string().required('Este campo é obrigatório'),
  telephone4: Yup.string().required('Este campo é obrigatório'),
  addressZipCode: Yup.string().required('Este campo é obrigatório'),
  addressDescription: Yup.string().required('Este campo é obrigatório'),
  addressNumber: Yup.string().required('Este campo é obrigatório'),
  addressCity: Yup.string().required('Este campo é obrigatório'),
  addressState: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string().email().required('Este campo é obrigatório'),
  website: Yup.string().required('Este campo é obrigatório'),
  anticipationPolicyId: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('Este campo é obrigatório')
    .nullable()
    .required('Este campo é obrigatório'),
  businessActivity: Yup.string().required('Este campo é obrigatório'),
  mcc: Yup.string().required('Este campo é obrigatório'),
  categoriaCode: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('Este campo é obrigatório')
    .nullable()
    .required('Este campo é obrigatório'),
  dataCadastro: Yup.string().required('Este campo é obrigatório'),
  foundationBirthDate: Yup.string().required('Este campo é obrigatório'),
  comments: Yup.string().required('Este campo é obrigatório'),
  slip: Yup.string().required('Este campo é obrigatório'),
  accountableName: Yup.string().required('Este campo é obrigatório'),
  accountableEmail: Yup.string().email().required('Este campo é obrigatório'),
  accountableTelephone: Yup.string().required('Este campo é obrigatório'),
  nameOnBankStatementLine: Yup.string().required('Este campo é obrigatório'),
  cieloMerchantId: Yup.string().required('Este campo é obrigatório'),
  cieloMerchantKey: Yup.string().required('Este campo é obrigatório'),
  fluxo005: Yup.string().required('Este campo é obrigatório'),
});

export default validationSchema;
