import { ActionTypes, IAction, IPageState } from './types';
import { ILicenciamentoDto } from '../../../../global/dataTransferObjects/debitosVeiculares';

export const InitialState: IPageState = {
  loading: false,
  vehicleId: '',
  isPaymentModalOpen: false,
  valorTotal: 0,
  valorLiquido: 0,
  selectedDebitos: {
    licenciamento: {} as ILicenciamentoDto,
    dpvaTs: [],
    ipvas: [],
    multas: [],
  },
  veiculos: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  debitos: {
    licenciamento: {
      key: '',
      taxaLicenciamento: 0,
      taxaTransferencia: 0,
      codigoOcorrencia: '',
      codigoReceptora: 0,
      identificador: '',
      loteOnline: false,
      numeroProdesp: 0,
      numeroTransacao: 0,
      ocorrencia: '',
      servico: '',
      trocaCheque: false,
      valorTotal: 0,
    },
    dpvaTs: [],
    ipvas: [],
    multas: [],
  },
}

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_MULTAS_SELECTION:
      return {
        ...state,
        selectedDebitos: {
          ...state.selectedDebitos,
          multas: [...action.payload.multas],
        },
      };
    case ActionTypes.OPEN_PAYMENT_MODAL:
      return {
        ...state,
        valorLiquido: action.payload.valorLiquido ?? 0,
        valorTotal: action.payload.valorTotal ?? 0,
        isPaymentModalOpen: true,
        loading: false,
      };
    case ActionTypes.UPDATE_PAYMENT_MODAL_STATUS:
      return {
        ...state,
        isPaymentModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        valorLiquido: InitialState.valorLiquido,
        valorTotal: InitialState.valorTotal,
        isPaymentModalOpen: InitialState.isPaymentModalOpen,
      };
    case ActionTypes.UPDATE_VEICULOS:
      return {
        ...state,
        loading: false,
        veiculos: action.payload?.veiculos ?? { ...InitialState.veiculos },
      };
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_DEBITS_VEHICLES:
      return {
        ...state,
        loading: false,
        vehicleId: action.payload.veiculoId,
        debitos: { ...action.payload?.debitos },
      };
    case ActionTypes.UPDATE_TOTAL:
      return {
        ...state,
        loading: false,
        valorTotal: action.payload.valorTotal ?? 0,
      };
    default:
      return { ...state };
  }
};
