import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput';

export const Form = styled.form``;

export const DatePickerStyled = styled(DayPickerInput)`
    display: flex;
`;

export const Test = styled.div`
  flex: 1;
  background-color: #fff;
  border-color: ${(props) => props.theme.colors.cyanDark};
`;

export const ErrorMessage = styled.div`
  margin-top: 3px;
  color: ${(props) => props.theme.colors.redDanger};
`;
