import { IAdjustmentFilterProps, IAllAdjustments } from '../../global/dataTransferObjects/adjustments';
import { IPagedResult } from '../../global/generics';
import { IAdjustmentService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';

const adjustmentService = (apiProvider: ITecpayApi): IAdjustmentService => {
  const getAllAdjustments = async (
    offset: number,
    pageSize: number,
    filter: IAdjustmentFilterProps,
  ): Promise<IApiResponse<IPagedResult<IAllAdjustments>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllAdjustments>>({ url: 'transacoes/adiq-ajustes' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
      })
      .sendAsync();

    return response;
  };

  return ({
    getAllAdjustments,
  });
};

export default adjustmentService;
