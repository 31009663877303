import { createApi } from '../providers/factories';
import { IContaDigitalService } from '../@types/services';
import contaDigitalService from './contaDigitalService';

const createContaDigitalService = (): IContaDigitalService => {
  const apiProvider = createApi();
  return contaDigitalService(apiProvider);
};

export default createContaDigitalService;
