import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  cvc: Yup.string()
    .required('Este campo é obrigatório'),
  cardholderName: Yup.string()
    .required('Este campo é obrigatório'),
  cardNumber: Yup.string()
    .required('Este campo é obrigatório'),
  expiry: Yup.string()
    .required('Este campo é obrigatório'),
  installments: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  })
    .typeError('O número de parcelas é obrigatório')
    .nullable()
    .required('O número de parcelas é obrigatório'),
  documentNumber: Yup.string()
    .required('Este campo é obrigatório'),
});

export default validationSchema;
