import { IPagedResult } from '../../generics'

export interface IContaDigitalTransacoesDTO {
  id: string
  originName: string
  destinyName?: string
  destinyAccountNumber?: string
  description: string
  fee: number
  feeAmount: number
  amount: number
  paymentAmount: number
  createdAt: string
  originAccountNumber?: string
  tipoServico?: string;
  pagamentoId?: string;
}

export interface IFilterTransacoesValues {
  start?: string;
  finish?: string;
  pagamentoId?: string;
  tipoServico?: string;
}

export enum ContaDigitalTipoServicoEnum {
  pix = 1,
  cargaCartao = 2,
  contaDigital = 3,
  boleto = 4,
}

export interface IPagedContaDigitalResponseDto<T> extends IPagedResult<T> {
  totalPaymentAmount: number;
}
