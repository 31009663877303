import { ITransferenciaRepassesDto, ITotalTransferenciasRepassesDto } from '../../../../global/dataTransferObjects/repasses';
import { IPagedTransferenciaRepasseResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  transferenciaRepasses: IPagedTransferenciaRepasseResult<ITransferenciaRepassesDto>;
  totalRepasses: ITotalTransferenciasRepassesDto;
  zeroState: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_TRANSFERENCIA_REPASSES = 'GET_TRANSFERENCIA_REPASSES',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
