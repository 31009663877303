import React, { useReducer, useMemo } from 'react';

import { T2, T3 } from '../../../../styles/titles';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ConsultaDebitosVeiculoForm from '../../../../components/Forms/Pages/ConsultaDebitosVeiculoForm';
import { Container, TableContainer } from './styles';
import {
  InitialState,
  Reducer,
  getSolicitacoes,
} from './actions';
import createVeiculoService from '../../../../services/veiculo';
import { ISelectValue } from '../../../../components/Forms/AsyncFormDropdown/types';
import Table, { TableColumn } from '../../../../components/Table';
import { createFormatProvider } from '../../../../services/providers/factories';
import { IVeiculoDto } from '../../../../global/dataTransferObjects/veiculo';

const ListSolicitacoes: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const onFormSubmit = (veiculoId: string) => getSolicitacoes(veiculoId)(dispatch);

  const onLoadVeiculosOptions = async (): Promise<Array<ISelectValue>> => {
    const veiculoService = createVeiculoService();
    const result = await veiculoService.loadVeiculosDropdown(0, 20);
    return result;
  };

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Meus Pagamentos</T2>
      <ConsultaDebitosVeiculoForm
        onLoadVehiclesOptions={onLoadVeiculosOptions}
        onFormSubmit={onFormSubmit}
      />
      <TableContainer>
        <T3>Solicitações de Pagamento</T3>
        <Table dataSource={state.solicitacoes}>
          <TableColumn
            title="Placa"
            dataIndex="veiculo"
            render={(veiculo: IVeiculoDto) => veiculo.placa}
          />
          <TableColumn
            title="Valor Total"
            dataIndex="valorTotal"
            render={formatProvider.convertFromCents}
          />
          <TableColumn
            title="Valor Liquido"
            dataIndex="valorLiquido"
            render={formatProvider.convertFromCents}
          />
          <TableColumn
            title="Taxas"
            dataIndex="valorTaxas"
            render={formatProvider.convertFromCents}
          />
          <TableColumn
            title="Parcelas"
            dataIndex="parcelas"
          />
          <TableColumn
            title="Status do Pagamento"
            dataIndex="statusPagamento"
            render={(value: number) => (value === 1 ? 'Pendente' : 'Pago')}
          />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListSolicitacoes;
