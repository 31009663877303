import React, {
  useEffect,
  useCallback,
  useReducer,
  useState,
  useMemo,
} from 'react';
import Table, { TableColumn } from '../../components/Table';
import { Reducer, InitialState } from './actions/reducer'
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../styles/titles';
import FilterForm from './components/FilterForm';
import { ISalesFilterProps } from './types';
import { getAllVendas } from './actions';
import { createFormatProvider } from '../../services/providers/factories';
import { NossaCidadeAntecipacaoStatus } from '../../global/enum';

const Vendas: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const fetchAllSales = useCallback(() => getAllVendas(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllSales();
  }, [fetchAllSales]);

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const salesPagination = (initialPage: number, finalPage :number) => {
    getAllVendas(initialPage, finalPage, filterOrder)(dispatch);
  }

  const handleSalesFilter = async (filter: ISalesFilterProps) => {
    setFilterOrder(filter);
    getAllVendas(offSet, pageSize, filter)(dispatch);
  };

  useEffect(() => {
    if (currentPage > 1) {
      salesPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      salesPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const sales = state?.sales?.records.map((salesParam) => (
    {
      ...salesParam,
    }
  ));

  const formatStatus = (status: number) => {
    let notaStatus = '';
    if (status === NossaCidadeAntecipacaoStatus.Aprovado) {
      notaStatus = 'Aprovado';
    }
    if (status === NossaCidadeAntecipacaoStatus.NaoSolicitado) {
      notaStatus = 'Não Solicitado';
    }
    if (status === NossaCidadeAntecipacaoStatus.Solicitado) {
      notaStatus = 'Solicitado';
    }
    if (status === NossaCidadeAntecipacaoStatus.Reprovado) {
      notaStatus = 'Reprovado';
    }
    return notaStatus;
  }

  const formattedDocumentNumber = (numeroDocumento: string) => (
    numeroDocumento?.length === 14
      ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
      : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
  );

  return (
    <Container>
      <T2>Vendas Nossa Cidade</T2>
      <FilterForm onFormSubmit={handleSalesFilter} />
      <TableContainer>
        <Table
          dataSource={sales}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.sales.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Data Transacao" dataIndex="dataTransacao" key="dataTransacao" width={100} render={formattedDate} />
          <TableColumn title="Nome Estabelecimento" dataIndex="nomeEstabelecimento" key="nomeRecebedor" width={250} />
          <TableColumn title="Nsu" dataIndex="nsu" key="nsu" width={100} />
          <TableColumn title="Nome Cliente" dataIndex="nomeCliente" key="nomePagador" width={250} />
          <TableColumn title="Número Parcela" dataIndex="numeroParcela" key="numeroParcela" />
          <TableColumn title="Número Total Parcela" dataIndex="numeroTotalParcela" key="numeroTotalParcela" />
          <TableColumn title="Valor Taxa" dataIndex="taxa" key="taxa" render={format} />
          <TableColumn title="Valor Bruto" dataIndex="valorBrutoParcela" key="valorBrutoParcela" render={format} />
          <TableColumn title="Valor Líquido" dataIndex="valorLiquidoParcela" key="valorLiquidoParcela" render={format} />
          <TableColumn title="Status Antecipação" dataIndex="statusAntecipacao" key="status" render={formatStatus} />
          <TableColumn title="Taxa Antecipação" dataIndex="taxaAntecipacao" key="taxaAntecipacao" />
          <TableColumn title="Dias Antecipação" dataIndex="diasAntecipacao" key="diasAntecipacao" />
          <TableColumn title="CNPJ" dataIndex="cnpj" key="cnpj" render={formattedDocumentNumber} />
        </Table>
      </TableContainer>
    </Container>
  )
}

export default Vendas
