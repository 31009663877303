/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IAllTransactions, IFilterTransactionsProps } from '../../../global/dataTransferObjects/transactions';
import { IPagedResult } from '../../../global/generics';
import createTransactionsService from '../../../services/transactions';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllTransactions = (transactions: IPagedResult<IAllTransactions>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_TRANSACTIONS,
  payload: {
    transactions: { ...transactions },
  },
});

export const onOpenUserModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
});

export const onUpdateUserModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const getAllTransactions = (
  offSet: number,
  pageSize: number,
  filter?: IFilterTransactionsProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createTransactionsService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.getAllTransactions(offSet, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllTransactions(result.response)(dispatch);
};
