import React, { useMemo } from 'react';
import Modal from '../../../../components/Modal';
import { createFormatProvider } from '../../../../services/providers/factories';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from '../styles';
import { IChargebackDetailsModalProps } from '../types';

const ChargebackDetailsModal: React.FC<IChargebackDetailsModalProps> = (props: IChargebackDetailsModalProps) => {
  const { chargeback, isVisible, onClose } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  const convertedMoney = useMemo(() => (cents: number) => (cents !== undefined
    ? formatProvider.convertFromCents(cents)
    : formatProvider.convertFromCents(0)), []);

  const formattedDocumentNumber = useMemo(() => (documentNumber: string) => (documentNumber !== '' || undefined
    ? formatProvider.formatCnpj(documentNumber)
    : 'N/A'), []);

  return (
    <Modal
      title="Detalhes do chargeback"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Identificador chargeback:</Description>
          <Item>{chargeback.numeroIdentificadorChargeback || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data do chargeback:</Description>
          <Item>{formattedDate(chargeback.dataChargeback)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data da planilha:</Description>
          <Item>{formattedDate(chargeback.dataPlanilha)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data limite de retorno:</Description>
          <Item>{formattedDate(chargeback.dataLimiteRetorno)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Número do cartão:</Description>
          <Item>{chargeback.numeroCartao}</Item>
        </TextGroup>
        <TextGroup>
          <Description>CNPJ:</Description>
          <Item>{formattedDocumentNumber(chargeback.numeroDocumento)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Transação:</Description>
          <Item>{formattedDate(chargeback.dataHoraTransacao)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>NSU/Parcela:</Description>
          <Item>{chargeback.nsuParcela || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Código de autorização:</Description>
          <Item>{chargeback.codigoAutorizacao || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor do chargeback:</Description>
          <Item>{convertedMoney(chargeback.valorChargeback)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>ARN:</Description>
          <Item>{chargeback.arn || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Parcela:</Description>
          <Item>{chargeback.parcela || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Estabelecimento:</Description>
          <Item>{chargeback.estabelecimento || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Filial:</Description>
          <Item>{chargeback.filial || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Razão chargeback:</Description>
          <Item>{chargeback.razaoChargeback || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Observação:</Description>
          <Item>{chargeback.observacao || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Adquirente:</Description>
          <Item>{chargeback.adquirente || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Bandeira cartão:</Description>
          <Item>{chargeback.bandeiraCartao || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tipo transação:</Description>
          <Item>{chargeback.tipoTransacao || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor venda:</Description>
          <Item>{convertedMoney(chargeback.valorVenda)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Status pagamento:</Description>
          <Item>{chargeback.statusPagamento ?? 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tipo chargeback:</Description>
          <Item>{chargeback.tipoChargeback || 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Status chargeback:</Description>
          <Item>{chargeback.statusChargeback ?? 'N/A'}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data aviso:</Description>
          <Item>{formattedDate(chargeback.dataAviso)}</Item>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default ChargebackDetailsModal;
