import styled from 'styled-components';
import { Button as StyledButton, ResetButton as StyledResetButton } from '../../../../../styles/buttons';

export const DateGroup = styled.div`
    flex-direction: row;
    height: 150px;
    align-items: center;
    justify-content: space-around;
    background-color: red;
    margin-top: 15px;
    margin-bottom: 30px;
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: none;
    margin-top: 2px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

export const DateView = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 0.8rem;

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }

    &:focus-visible {

    }
  }
  div.DayPickerInput, input {
   width: 100%;
  }`;

export const Button = styled(StyledButton)`
  margin-top: 20px;
`;

export const ResetButton = styled(StyledResetButton)`
  margin-top: 20px;
`;

export const Form = styled.form``;
