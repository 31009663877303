import React from 'react';
import { ModalWrapper } from './styles';
import { ModalProps } from './types';

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    title,
    centered,
    visible,
    onCancel,
    width,
    children,
  } = props;

  return (
    <ModalWrapper
      title={title}
      centered={centered}
      visible={visible}
      onCancel={onCancel}
      width={width}
      footer={null}
    >
      {children}
    </ModalWrapper>
  )
};

export default Modal;
