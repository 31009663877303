import React, {useEffect, useState} from 'react';
import PieChart from "../../../../../components/Charts/PieChart";

const TipoPagamentoChart: React.FC<any> = ({vendasData}) => {
  const CHART_COLORS = {
    petrolGreen: 'rgb(14, 43, 39)',
    lightGreen: 'rgb(38, 119, 108)',
    whiteGreen: 'rgb(62, 195, 177)',
  };
  const [chartDataTipoPagamento, setChartData] = useState({
    labels: vendasData.map((data: any) => data.productName),
    datasets: [
      {
        label: "Valor total",
        data: vendasData.map((data: any) => data.value),
        borderColor: "lightgray",
        borderWidth: 1,
        backgroundColor: Object.values(CHART_COLORS),
      },
    ],
  });
  const [chartOptionsTipoPagamento, setChartOptionsTipoPagamento] = useState({
    plugins: {
      title: {
        display: true,
        text: "Distribuição do Valor Total de Vendas por Tipo de Pagamento",
      },
      responsive: true,
      datalabels: {
        formatter: (value: number, context: any) => {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data as number[];
          dataArr.forEach(data => {
            sum += data;
          });
          const percentualValor = ((value * 100) / sum).toFixed(2);
          return (percentualValor.concat('%'));
        },
        color: 'white',
      },
    },
  });

  useEffect(() => {
    const tiposPagamento: string[] = vendasData.map((data: any) => data.productName).filter((element: any, index: any, self: any) => {
      return index === self.indexOf(element) && element !== "Cancelamento";
    });

    const totalTiposPagamento: number[] = tiposPagamento.map((dataLabel) => {
      let total = 0;
      vendasData.forEach((data: any) => {
        if (data.productName === dataLabel) total += data.value;
      })
      return total;
    });
    const formatColor: string[] = vendasData.map((data: any) => {
      return data.productName;
    }).filter((element: any, index: any, self: any) => {
      return index === self.indexOf(element);
    });

    const backgroundcolor: string[] = [];

    for (let i = 0; i < formatColor.length; i++) {
      if (formatColor[i] === 'Débito') {
        backgroundcolor.push('rgb(14, 43, 39)');
      } else if (formatColor[i] === 'Crédito à Vista') {
        backgroundcolor.push('rgb(38, 119, 108)');
      } else if (formatColor[i] === 'Parcelamento sem Juros') {
        backgroundcolor.push('rgb(62, 195, 177)');
      } else if (formatColor[i] === 'Parcelamento com Juros') {
        backgroundcolor.push('rgb(65,118,111)');
      } else {
        backgroundcolor.push('#e0a458');
      }
    }

    const tempChartData = {
      ...chartDataTipoPagamento,
      labels: tiposPagamento,
      datasets: [
        {
          ...chartDataTipoPagamento.datasets[0],
          data: totalTiposPagamento,
          backgroundColor: backgroundcolor,
        },
      ],
    };

    setChartData(tempChartData);
  }, [vendasData]);

  return (
    <div>
      {vendasData.length > 0 ? <PieChart chartData={chartDataTipoPagamento} chartName="Vendas por Tipo de Pagamento" chartOptions={chartOptionsTipoPagamento} /> : <p>{'Sem resultados para essa pesquisa. \nTente alterar os dados do filtro.'} </p>}
    </div>
  )
}

export default TipoPagamentoChart;
