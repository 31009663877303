import { ICancellationDto } from '../../../../global/dataTransferObjects/creditCard/cancellation';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  cancellations: IPagedResult<ICancellationDto>;
  cancellation: ICancellationDto;
  isModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CANCELLATIONS = 'UPDATE_CANCELLATIONS',
  UPDATE_CANCELLATION = 'UPDATE_CANCELLATION',
  UPDATE_MODAL_STATUS = 'UPDATE_MODAL_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
