import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import Table, { TableColumn } from '../../../components/Table';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getAllTransfers } from './actions';

const Details: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  // const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formatValorMqnValue = (valueParam: string) => {
    const valor = valueParam.replace(',', '.');
    const valorFormatted = parseFloat(valor);
    const final = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return final.format(valorFormatted)
  }

  const fetchAllTransferPagination = (initialPage: number, finalPage :number) => {
    getAllTransfers(initialPage, finalPage)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllTransferPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllTransferPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const transfer = state?.transfers?.records.map((params) => (
    {
      ...params,
    }
  ));

  // if (_.isEmpty(transfer) && !state.loading) {
  //   return (
  //     <ZeroState />
  //   )
  // }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Transações e Repasses</T2>
      <TableContainer>
        <Table
          dataSource={transfer}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.transfers.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Adquirente" dataIndex="adquirente" key="adquirente" />
          <TableColumn title="NSU/Parcela" dataIndex="nsu" key="nsu" />
          <TableColumn title="Parcela" dataIndex="parcela" key="parcela" />
          <TableColumn title="Total Parcelas" dataIndex="totalParcelas" key="totalParcelas" />
          <TableColumn title="Data/Hora Captura" dataIndex="dataHoraCaptura" key="dataHoraCaptura" render={formattedDate} />
          <TableColumn title="Data Captura" dataIndex="dataCaptura" key="dataCaptura" render={formattedDate} />
          <TableColumn title="Serial Number" dataIndex="serialNumber" key="serialNumber" />
          <TableColumn title="Post ID" dataIndex="posTid" key="posTid" />
          <TableColumn title="Tipo" dataIndex="tipo" key="tipo" />
          <TableColumn title="Post ID" dataIndex="salePlanType" key="salePlanType" />
          <TableColumn title="Post ID" dataIndex="bandeira" key="bandeira" />
          <TableColumn title="No. Cartao" dataIndex="cardNumber" key="cardNumber" />
          <TableColumn title="Cod. Cliente" dataIndex="codigoCliente" key="codigoCliente" />
          <TableColumn title="Nome Cliente" dataIndex="nomeCliente" key="nomeCliente" />
          <TableColumn
            title="Valor Passado Maquina"
            dataIndex="valorPassadoMaquina"
            key="valorPassadoMaquina"
            render={formatValorMqnValue}
          />
          <TableColumn
            title="Valor Pago Adquirente"
            dataIndex="valorPagoAdquirente"
            key="valorPagoAdquirente"
            render={formatValorMqnValue}
          />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Details;
