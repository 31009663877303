import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  transacoes: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
    totalPaymentAmount: 0,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_TRANSACOES:
      return {
        ...state,
        loading: false,
        transacoes: action.payload?.transacoes ?? { ...InitialState.transacoes },
      };
    default:
      return { ...state };
  }
};
