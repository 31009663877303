import TerminaisPhoebusService from "./service/terminaisPhoebusService";
import { useState } from "react";
import { Container } from "./styles";
import { T2 } from "../../styles/titles";
import LiveTable from "./components/liveTable";
import { FormFilter } from "./components/filter";

const TerminaisPhoebus: React.FC = () => {
  const [filterData, setFilterData] = useState<any>(null);

  return (
    <Container>
      <T2>Terminais Phoebus</T2>
      <FormFilter getFilters={(data) => setFilterData(data)} />
      <LiveTable dataFilter={filterData} />
    </Container>
  );
};

export default TerminaisPhoebus;
