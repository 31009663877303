import React from 'react';
import AvatarOverlay from './AvatarOverlay';
import { Dropdown, Avatar } from '../styles';
import { IUserAvatarProps } from '../types';

const UserAvatar: React.FC<IUserAvatarProps> = (props: IUserAvatarProps) => {
  const { userName, onSignOutButtonClick, onModalStatus } = props;

  return (
    <Dropdown
      overlay={(
        <AvatarOverlay
          onSignOutButtonClick={onSignOutButtonClick}
          onModalStatus={onModalStatus}
        />
      )}
    >
      <Avatar>
        {userName[0]}
      </Avatar>
    </Dropdown>
  )
};

export default UserAvatar;
