import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isPaymentModalOpen: false,
  isBusinessModalOpen: false,
  businessInfo: {
    id: '',
    tipoPessoa: '',
    type: 0,
    name: '',
    nickName: '',
    contact: '',
    foundationBirthDate: new Date(),
    Comments: '',
    sellerCommission: 0,
    anticipationIncluded: false,
    slip: '',
    monthlySalesForecast: 0,
    cnpj: '',
    cpf: '',
    ie: '',
    rg: '',
    telephone1: '',
    telephone2: '',
    telephone3: '',
    telephone4: '',
    addressZipCode: '',
    addressDescription: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    email: '',
    website: '',
    accountableName: '',
    accountableEmail: '',
    accountableTelephone: '',
    parentId: 0,
    isAnticipationBusiness: false,
    lastCnab400Number: 0,
    nameOnBankStatementLine: '',
    commercialHierId: '',
    businessActivity: '',
    created: '',
    anticipationPolicyId: 0,
    paymentIncluded: '',
    mcc: '',
    categoriaCode: '',
    dataCadastro: new Date(),
    dataUltimaTransacao: new Date(),
    cieloMerchantId: '',
    cieloMerchantKey: '',
    cercA001T005Fluxo: '',
    cercA001T005ArquivoId: false,
  },
  business: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_BUSINESS:
      return {
        ...state,
        loading: false,
        business: action.payload?.business ?? { ...InitialState.business },
      };
    case ActionTypes.OPEN_CREDENTIAL_MODAL:
      return {
        ...state,
        isPaymentModalOpen: true,
      };
    case ActionTypes.OPEN_BUSINESS_MODAL:
      return {
        ...state,
        isBusinessModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.UPDATE_CREDENTIAL_MODAL_STATUS:
      return {
        ...state,
        isPaymentModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.UPDATE_BUSINESS_MODAL:
      return {
        ...state,
        businessInfo: action.payload?.businessInfo ?? { ...InitialState.businessInfo },
      };
    case ActionTypes.CREATE_NEW_CREDENTIAL:
      return {
        ...state,
        loading: false,
        isPaymentModalOpen: false,
      }
    default:
      return { ...state };
  }
};
