import { IPixCopiaEColaResponseDto } from '../../../../global/dataTransferObjects/pix/copiaECola';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  isCreateModalOpen: boolean;
  isCopiaEColaInfoModalOpen: boolean;
  pixCopiaECola: IPixCopiaEColaResponseDto;
  pagedPixCopiaECola: IPagedResult<IPixCopiaEColaResponseDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CREATE_MODAL_STATUS = 'UPDATE_CREATE_MODAL_STATUS',
  UPDATE_PIX_COPIA_E_COLA = 'UPDATE_PIX_COPIA_E_COLA',
  UPDATE_COPIA_E_COLA_INFO_MODAL_STATUS = 'UPDATE_COPIA_E_COLA_INFO_MODAL_STATUS',
  UPDATE_PAGED_PIX_COPIA_E_COLA = 'UPDATE_PAGED_PIX_COPIA_E_COLA'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
