import { BusinessDto } from '../../global/dataTransferObjects/business';
import {
  ICreatePoliticaCobrancaDTO,
  IPoliticaCobrancaDTO,
  IPoliticaCobrancaFilterProps,
  IUpdatePoliticaCobrancaDTO,
} from '../../global/dataTransferObjects/politicaCobranca';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';
import { IPoliticaCobrancaService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const politicaCobrancaService = (apiProvider: ITecpayApi): IPoliticaCobrancaService => {
  const createPoliticaCobranca = async (createPoliticaCobrancaDTO: ICreatePoliticaCobrancaDTO) => {
    const payload = { ...createPoliticaCobrancaDTO };

    return apiProvider
      .createRequest<ICreatePoliticaCobrancaDTO, IPoliticaCobrancaDTO>({ url: '/billing-policy' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const getPoliticasCobrancas = async (
    offset: number,
    pageSize: number,
    filter: IPoliticaCobrancaFilterProps,
  ): Promise<IApiPagedResponse<IPoliticaCobrancaDTO>> => {
    const name = filter?.name || null;
    const description = filter?.description || null;
    const dateStartFrom = filter?.dateStartFrom || null;
    const dateFinishFrom = filter?.dateFinishFrom || null;
    const dateStartTo = filter?.dateStartTo || null;
    const dateFinishTo = filter?.dateFinishTo || null;

    return apiProvider
      .createRequest<IPagedResult<IPoliticaCobrancaDTO>>({ url: '/billing-policy' })
      .withSearch({
        name,
        description,
        dateStartFrom,
        dateFinishFrom,
        dateStartTo,
        dateFinishTo,
        offset,
        pageSize,
      })
      .sendAsync();
  }

  const deletePoliticaCobranca = async (politicaCobrancaId: number): Promise<IApiResponse<string>> => {
    const url = `/billing-policy/${politicaCobrancaId}`;

    return apiProvider
      .createRequest<string>({ url })
      .withMethod(HttpMethod.DELETE)
      .sendAsync();
  }

  const updatePoliticaCobranca = async (
    politicaCobrancaId: number,
    updatePoliticaCobrancaDTO: IUpdatePoliticaCobrancaDTO,
  ): Promise<IApiResponse<IPoliticaCobrancaDTO>> => {
    const url = `/billing-policy/${politicaCobrancaId}`;
    const paylod = { ...updatePoliticaCobrancaDTO }

    return apiProvider
      .createRequest<IUpdatePoliticaCobrancaDTO, IPoliticaCobrancaDTO>({ url })
      .withMethod(HttpMethod.PUT)
      .withData({ ...paylod })
      .sendAsync();
  }

  const getPoliticaCobranca = async (politicaCobrancaId: number): Promise<IApiResponse<IPoliticaCobrancaDTO>> => {
    const url = `/billing-policy/${politicaCobrancaId}`;

    return apiProvider
      .createRequest<IPoliticaCobrancaDTO>({ url })
      .sendAsync();
  }

  const getBusinessByBillingPolicy = async (
    offset: number,
    pageSize: number,
    billingPolicyId: number,
  ): Promise<IApiResponse<IPagedResult<BusinessDto>>> => apiProvider
    .createRequest<IPagedResult<BusinessDto>>({ url: '/billing-policy/business' })
    .withSearch({
      offset,
      pageSize,
      billingPolicyId,
    })
    .sendAsync();

  return ({
    createPoliticaCobranca,
    getPoliticasCobrancas,
    deletePoliticaCobranca,
    updatePoliticaCobranca,
    getPoliticaCobranca,
    getBusinessByBillingPolicy,
  })
}

export default politicaCobrancaService;
