import React, { useMemo } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import {
  Modal,
  Row,
  Col,
  Button,
} from './styles';
import { ICreateModalProps } from './types';
import { createFormatProvider } from '../../../../../services/providers/factories';

const CreateModal: React.FC<ICreateModalProps> = (props: ICreateModalProps) => {
  const {
    isVisible,
    onModalClose,
    loading,
    createPagamento,
    onCreatePagamento,
  } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  return (
    <Modal
      title="Enviar pagamento via PIX"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <LoadingOverlay
        show={loading}
        relative
        tip="Enviando pagamento via PIX, por favor aguarde..."
      />
      <Row>
        <Col>
          <span style={{ fontSize: '30px' }}><QrcodeOutlined /></span>
          <strong>
            Chave PIX:
            {' '}
            {createPagamento.creditorKey}
          </strong>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <strong>Nome</strong>
              <span>{createPagamento.creditorName}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <strong>Tipo da chave</strong>
              <span>{createPagamento.keyType}</span>
            </div>
            {createPagamento.pixPaymentType === 2 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Identificador</strong>
                <span>{createPagamento.txId}</span>
              </div>
            ) : null}
          </div>
          <span>
            Valor:
            {' '}
            <h1>{formattedValue(createPagamento.amount)}</h1>
          </span>
          <Button onClick={onCreatePagamento}>Enviar pagamento</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateModal;
