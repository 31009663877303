import React, { useMemo, useRef, useCallback } from 'react';
import { toPng } from 'html-to-image';
import moment from 'moment';
import { Image } from '../../../../../styles/_layouts/Default';
import logo from '../../../../../assets/logo.png';
import {
  Modal,
  ModalBody,
  TextGroup,
  Description,
  VerticalLine,
  Item,
  Button,
  Container,
} from './styles';
import { IComprovanteModalProps } from './types';
import { formatterMaskDocumentNumber } from '../../../../../components/Masks';

const ComprovantePixModal: React.FC<IComprovanteModalProps> = (props: IComprovanteModalProps) => {
  const {
    transaction,
    isVisible,
    onModalClose,
  } = props;

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const ref = useRef<HTMLDivElement>(null);

  const handleSaveTransaction = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'comprovante.png'
        link.href = dataUrl
        link.click()
      })
  }, [ref])

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? moment(date).utcOffset('-06:00').format('DD/MM/YYYY HH:mm')
    : 'N/A'), []);

  return (
    <Modal
      title="Comprovante de pagemento PIX"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <ModalBody ref={ref}>
        <Image src={logo} alt="Logotipo Tecpay" />
        <TextGroup>
          <Description>Data:</Description>
          <Item>{formattedDate(transaction.createdAt)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor:</Description>
          <Item>{format(transaction.amount)}</Item>
        </TextGroup>
        <VerticalLine />
        <Description>Origem</Description>
        <VerticalLine />
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{transaction.originName}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Documento:</Description>
          <Item>{formatterMaskDocumentNumber(transaction.originDocumentNumber)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Instituição:</Description>
          <Item>Tecpay</Item>
        </TextGroup>
        <VerticalLine />
        <Description>Destino</Description>
        <VerticalLine />
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{transaction.destinyName}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Documento:</Description>
          <Item>{formatterMaskDocumentNumber(transaction.destinyDocumentNumber)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Chave pix:</Description>
          <Item>{transaction.destinyAccountNumber}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Descrição:</Description>
          <Item>{transaction.description}</Item>
        </TextGroup>
        <TextGroup>
          <Description>EndToEndId:</Description>
          <Item>{transaction.endToEndId}</Item>
        </TextGroup>
      </ModalBody>
      <Container><Button onClick={handleSaveTransaction}>Salvar comprovante</Button></Container>
    </Modal>
  );
};

export default ComprovantePixModal;
