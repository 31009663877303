import { connect } from 'react-redux';
import ForgotPassword from './forgotPassword';
import { forgotPassword } from '../../store/user/actions';
import { IAppState } from '../../store/types';

const mapStateToProps = (state: IAppState) => ({
  isLoading: state.user.loading,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
