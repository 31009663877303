import React from 'react';
import Modal from '../../../../components/Modal';
import { ModalBody, TextGroup } from '../styles';
import { ICancellationModalProps } from '../types';

const CancellationModal: React.FC<ICancellationModalProps> = (props: ICancellationModalProps) => {
  const { isVisible, onClose, cancellation } = props;

  return (
    <Modal
      title="Detalhes do cancelamento:"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <div>
            <h4>CNPJ:</h4>
            <h5>{cancellation.numeroDocumento}</h5>
          </div>
          <div>
            <h4>Identificador externo:</h4>
            <h5>{cancellation.identificadorExterno}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Data de transação:</h4>
            <h5>{cancellation.dataTransacao}</h5>
          </div>
          <div>
            <h4>Data de previsão:</h4>
            <h5>{cancellation.dataPrevisao}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Empresa:</h4>
            <h5>{cancellation.razaoSocial}</h5>
          </div>
          <div>
            <h4>Terminal:</h4>
            <h5>{cancellation.terminal}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Bandeira:</h4>
            <h5>{cancellation.bandeiraCartao}</h5>
          </div>
          <div>
            <h4>Tipo da transação:</h4>
            <h5>{cancellation.tipoTransacao}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>NSU/Parcela:</h4>
            <h5>{cancellation.nsuParcela}</h5>
          </div>
          <div>
            <h4>Autorização:</h4>
            <h5>{cancellation.autorizacao}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Valor de venda:</h4>
            <h5>{cancellation.valorVenda}</h5>
          </div>
          <div>
            <h4>Valor de repasse:</h4>
            <h5>{cancellation.valorRepasse}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Valor pago:</h4>
            <h5>{cancellation.valorPago}</h5>
          </div>
          <div>
            <h4>Status de cancelamento:</h4>
            <h5>{cancellation.statusCancelamento}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Valor cancelado:</h4>
            <h5>{cancellation.valorCancelado}</h5>
          </div>
          <div>
            <h4>Valor para cancelamento:</h4>
            <h5>{cancellation.valorParaCancelamento}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Data do cancelamento:</h4>
            <h5>{cancellation.dataCancelamento}</h5>
          </div>
          <div>
            <h4>Data desconto:</h4>
            <h5>{cancellation.dataDesconto}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Código de retorno:</h4>
            <h5>{cancellation.codigoRetorno}</h5>
          </div>
          <div>
            <h4>Código de autorização do cancelamento:</h4>
            <h5>{cancellation.codigoAutorizacaoCancelamento}</h5>
          </div>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default CancellationModal;
