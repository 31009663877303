import { IBoletoService } from '../@types/services';
import { createApi } from '../providers/factories';
import boletoService from './boletoService';

const createBoletoService = (): IBoletoService => {
  const apiProvider = createApi();
  return boletoService(apiProvider);
}

export default createBoletoService;
