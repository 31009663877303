import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { IUpdatePoliticaCobrancaProps } from './types';
import PoliticaCobrancaForm from './components/updateForm';
import { IFormValues } from './components/updateForm/types';
import { IUpdatePoliticaCobrancaDTO } from '../../../global/dataTransferObjects/politicaCobranca';
import { Box, Container } from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { T2 } from '../../../styles/titles';
import { InitialState, Reducer } from './actions/reducer';
import { getPoliticaCobranca, updatePoliticaCobranca } from './actions';

const toDecimal = (value: string) => parseFloat(value.replace('.', '').replace(',', '.'));
const toInt = (value: string) => parseInt(value, 10);

const UpdatePoliticaCobranca: React.FC<IUpdatePoliticaCobrancaProps> = (props: IUpdatePoliticaCobrancaProps) => {
  const { match } = props;
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();

  const handleGetPoliticaCobranca = useCallback(() => {
    const { politicaCobrancaId } = match.params;
    getPoliticaCobranca(toInt(politicaCobrancaId))(dispatch);
  }, []);

  useEffect(() => handleGetPoliticaCobranca(), []);

  const onFormSubmit = async (values: IFormValues) => {
    const formattedValues: IUpdatePoliticaCobrancaDTO = {
      ...values,
      accessionFee: toDecimal(values.accessionFee),
      debitFee: toDecimal(values.debitFee),
      anticipationFee: toDecimal(values.anticipationFee),
      debitFloat: toInt(values.debitFloat),
      anticipationComissionFee: toDecimal(values.anticipationComissionFee),
      daysGracePeriod: toInt(values.daysGracePeriod),
      anticipationLimit: toDecimal(values.anticipationLimit),
      minimumTransactionValueToChargeFixedFee: toDecimal(values.minimumTransactionValueToChargeFixedFee),
      fixedFeeToChargeOnMinimumTransaction: toDecimal(values.fixedFeeToChargeOnMinimumTransaction),
      dailyProvisionValue: toDecimal(values.dailyProvisionValue),
      minAnticipationAmount: toDecimal(values.minAnticipationAmount),
      valorMinimoRepasse: toDecimal(values.valorMinimoRepasse),
      debitFeeMasterCard: toDecimal(values.debitFeeMasterCard),
      debitFeeAmex: toDecimal(values.debitFeeAmex),
      debitFeeHipercard: toDecimal(values.debitFeeHipercard),
      debitFeeElo: toDecimal(values.debitFeeElo),
      debitFeeVisaAdiq: toDecimal(values.debitFeeVisaAdiq),
      debitFeeMasterCardAdiq: toDecimal(values.debitFeeMasterCardAdiq),
      debitFeeAmexAdiq: toDecimal(values.debitFeeAmexAdiq),
      debitFeeHipercardAdiq: toDecimal(values.debitFeeHipercardAdiq),
      debitFeeEloAdiq: toDecimal(values.debitFeeEloAdiq),
      taxaExtra: toDecimal(values.taxaExtra),
      debitFeeVisaCielo: toDecimal(values.debitFeeVisaCielo),
      debitFeeMasterCardCielo: toDecimal(values.debitFeeMasterCardCielo),
      debitFeeAmexCielo: toDecimal(values.debitFeeAmexCielo),
      debitFeeHipercardCielo: toDecimal(values.debitFeeHipercardCielo),
      debitFeeEloCielo: toDecimal(values.debitFeeEloCielo),
      originacaoArquivoCip: toDecimal(values.originacaoArquivoCip),
      mensalidadeConta: toDecimal(values.mensalidadeConta),
      tarifaGeracaoBoletoLote: toDecimal(values.tarifaGeracaoBoletoLote),
      taxaPixRecebimento: toDecimal(values.taxaPixRecebimento),
      taxaPixPagamento: toDecimal(values.taxaPixPagamento),
      mensalidadeCartao: toDecimal(values.mensalidadeCartao),
    };

    const result = await updatePoliticaCobranca(state.politicaCobranca.id, { ...formattedValues })(dispatch);

    if (!result) {
      return;
    }

    toast.success('Política de cobrança alterada com sucesso!');
    history.push('/tecpay/admin/cadastro-geral/politicas-cobranca');
  };

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Alterar política de cobrança</T2>
      <Box>
        <h3>Dados da política:</h3>
        <PoliticaCobrancaForm onSubmit={onFormSubmit} politicaCobranca={{ ...state.politicaCobranca }} />
      </Box>
    </Container>
  );
}

export default UpdatePoliticaCobranca;
