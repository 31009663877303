import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IAntecipadoraTransacoesDto } from '../../../../global/dataTransferObjects/contaDigital/antecipadoraTransacoes';
import { IPagedResult } from '../../../../global/generics';
import createContaEscrowService from '../../../../services/contaEscrow';
import { IFilterAntecipadoraTransacoes } from '../components/filterForm/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateTransacoes = (transacoes : IPagedResult<IAntecipadoraTransacoesDto>) => (dispatch : Dispatch<any>)
: void => dispatch({
  type: ActionTypes.UPDATE_TRANSACOES,
  payload: {
    transacoes: { ...transacoes },
  },
})

export const getTransacoes = (
  offset: number,
  pageSize : number,
  filter? : IFilterAntecipadoraTransacoes,
) => async (dispatch: Dispatch<any>) : Promise<void> => {
  const contaEscrowService = createContaEscrowService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaEscrowService.getTransacoes(offset, pageSize, filter);

  if (result.status !== 200) {
    toast.error(result.message);
  }

  onUpdateTransacoes(result.response)(dispatch);
}
