import { IPagamentoDebitoVeicularResponseDto } from '../../../../../global/dataTransferObjects/debitosVeiculares';

export interface IPageState {
  loading: boolean,
  solicitacoes: Array<IPagamentoDebitoVeicularResponseDto>,
}

export enum ActionTypes {
  UPDATE_SOLICITACOES = 'UPDATE_SOLICITACOES',
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
}
