import {IPhobeusPagedResult} from '../../global/generics';
import {HttpMethod, IApiResponse, ITecpayApi} from '../providers/types';
import {getAxiosInstance} from '../providers/api';
import {
  IAllLiveTransactions,
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../global/dataTransferObjects/transacoesTempoReal';

const liveTransactionService = (apiProvider: ITecpayApi): any => {
  const getAllLiveTransactions = async (
    page: number,
    pageSize: number,
    filter?: ILiveTransactionsFilterProps,
  ): Promise<IApiResponse<IPhobeusPagedResult<IAllLiveTransactions>>> => {
    const date = filter?.date || null;
    const response = await apiProvider
      .createRequest<IPhobeusPagedResult<IAllLiveTransactions>>({ url: '/transacoes/tempo-real' })
      .withSearch({
        page,
        pageSize,
        date,
      })
      .sendAsync();
    return response;
  };

  const getAllLiveTransactionsFiltros = async (
    page: number,
    pageSize: number,
    filter?: PainelVendasFilterProps,
    sortBy?: string

  ): Promise<IApiResponse<IPhobeusPagedResult<IAllLiveTransactions>>> => {
    const paged = true;
    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const status = filter?.status !== null && filter?.status !== -1 ? filter?.status : null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const merchantId = filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;

    const response = await apiProvider
      .createRequest<IPhobeusPagedResult<IAllLiveTransactions>>({ url: '/transacoes/sales-panel' })
      .withMethod(HttpMethod.POST)
      .withData({
        offSet: page,
        PageSize: pageSize,
        SortBy: sortBy,
        InitDate: dateInicial,
        FinishDate: dateFinal,
        TipoPagamento: tipoPagamento,
        Bandeira: bandeira,
        Status: status,
        BusinessName: businessName,
        AcquirerNsu: acquirerNsu,
        Acquirer: acquirer,
        MerchantId: merchantId,
        OrderNumber: orderNumber,
        Paged: paged
      })
      .sendAsync();
    return response;
  };

  const getAllLiveTransactionsRecebiveisFiltros = async (
    page: number,
    pageSize: number,
    filter?: PainelVendasFilterProps,
    sortBy?: string
  ): Promise<IApiResponse<IPhobeusPagedResult<IAllLiveTransactions>>> => {
    const paged = true;
    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const saleDateInicial = filter?.saleInitialDate || null;
    const saleDateFinal = filter?.saleFinalDate || null;
    const status = filter?.statusPagamento;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName?.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const merchantId = filter?.merchantId !== undefined && filter?.merchantId?.length > 0 ? filter?.merchantId : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;

    const response = await apiProvider
      .createRequest<IPhobeusPagedResult<IAllLiveTransactions>>({ url: '/transacoes/receivables' })
      .withMethod(HttpMethod.POST)
      .withData({
        offSet: page,
        PageSize: pageSize,
        SortBy: sortBy,
        InitDate: dateInicial,
        FinishDate: dateFinal,
        SaleInitDate: saleDateInicial,
        SaleFinishDate: saleDateFinal,
        TipoPagamento: tipoPagamento,
        Bandeira: bandeira,
        StatusPagamento: status,
        BusinessName: businessName,
        AcquirerNsu: acquirerNsu,
        Acquirer: acquirer,
        MerchantId: merchantId,
        OrderNumber: orderNumber,
        Paged: paged
      })
      .sendAsync();
    return response;
  };

  const getFileDownload = async (
    page: number,
    pageSize: number,
    filter?: PainelVendasFilterProps,
    url?: string,
  ): Promise<any> => {
    const userInfo: any = localStorage.getItem('userInfo');
    let parsedUserInfo = null;
    if (userInfo) parsedUserInfo = JSON.parse(userInfo);

    let commercialHierId = null;
    if (parsedUserInfo.commercialHierId) commercialHierId = parsedUserInfo.commercialHierId;

    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const saleDateInicial = filter?.saleInitialDate || null;
    const saleDateFinal = filter?.saleFinalDate || null;
    const status = filter?.status !== null && filter?.status !== -1 ? filter?.status : null;
    const statusPagamento = filter?.statusPagamento;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName?.length > 0 ? filter?.businessName : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const merchantId = filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;

    let response;
    if (url === 'sales-panel-xls' || url === 'sales-panel-csv') {
      response = await getAxiosInstance().post(`/transacoes/${url}`, {
        offSet: page,
        PageSize: pageSize,
        InitDate: dateInicial,
        FinishDate: dateFinal,
        SaleInitDate: saleDateInicial,
        SaleFinishDate: saleDateFinal,
        TipoPagamento: tipoPagamento,
        Bandeira: bandeira,
        Status: status,
        BusinessName: businessName,
        Acquirer: acquirer,
        AcquirerNsu: acquirerNsu,
        MerchantId: merchantId,
        OrderNumber: orderNumber,
        CommercialHierId: commercialHierId,
        Paged: false,
      }, {responseType: 'blob'});
    } else {
      response = await getAxiosInstance().post(`/transacoes/${url}`, {
        offSet: page,
        PageSize: pageSize,
        InitDate: dateInicial,
        FinishDate: dateFinal ? `${dateFinal} 23:59:59` : dateFinal,
        SaleInitDate: saleDateInicial,
        SaleFinishDate: saleDateFinal ? `${saleDateFinal} 23:59:59` : saleDateFinal,
        TipoPagamento: tipoPagamento,
        Bandeira: bandeira,
        StatusPagamento: statusPagamento,
        BusinessName: businessName,
        Acquirer: acquirer,
        AcquirerNsu: acquirerNsu,
        MerchantId: merchantId,
        OrderNumber: orderNumber,
        CommercialHierId: commercialHierId,
        Paged: false}, {responseType: 'blob'});
    }
    return response.data;
  };

  return ({
    getAllLiveTransactions, getAllLiveTransactionsFiltros, getAllLiveTransactionsRecebiveisFiltros, getFileDownload,
  });
};

export default liveTransactionService;
