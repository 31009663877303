export interface IPageState {
  loading: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  CREATE_NEW_POLITICA_COBRANCA = 'CREATE_NEW_POLITICA_COBRANCA'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
