import { IDebitosDto } from '../../../../global/dataTransferObjects/debitosVeiculares';
import { IVeiculoDto } from '../../../../global/dataTransferObjects/veiculo';
import { IPagedResult } from '../../../../global/generics';

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_VEICULOS = 'UPDATE_VEICULOS',
  UPDATE_DEBITS_VEHICLES = 'UPDATE_DEBITS_VEHICLES',
  UPDATE_PAYMENT_MODAL_STATUS = 'UPDATE_PAYMENT_MODAL_STATUS',
  OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL',
  UPDATE_MULTAS_SELECTION = 'UPDATE_MULTAS_SELECTION',
  UPDATE_TOTAL = 'UPDATE_TOTAL',
  CLOSE_PAYMENT_MODAL = 'CLOSE_PAYMENT_MODAL',
}

export interface IPageState {
  loading: boolean;
  isPaymentModalOpen: boolean;
  valorTotal: number;
  valorLiquido: number;
  vehicleId: string;
  veiculos: IPagedResult<IVeiculoDto>,
  debitos: IDebitosDto,
  selectedDebitos: IDebitosDto,
}

export interface IAction {
  type: ActionTypes;
  payload?: any,
}
