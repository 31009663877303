import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../global/generics';
import createEscrowAccountService from '../../../services/contaEscrow';
import { IContratoDto, ICreateContratoDto, IFilterContratoDto } from '../../../global/dataTransferObjects/contratoEscrow';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllContratos = (contratos: IPagedResult<IContratoDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_CONTRATOS,
  payload: {
    contratos: { ...contratos },
  },
});

export const onOpenContratoModal = (status: boolean) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CONTRATO_MODAL,
  payload: { status },
});

export const onCreateNewContrato = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_NEW_CONTRATO,
});

export const createNewContrato = (
  contratoInfo: ICreateContratoDto,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const contratoservice = createEscrowAccountService();

  const result = await contratoservice.createNewContrato(contratoInfo);

  if (result.status !== 201) {
    toast.error(result.message ?? 'Houve um erro ao cadastrar um novo contrato');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onCreateNewContrato()(dispatch);
  return true;
};

export const getAllcontratos = (
  offSet: number,
  pageSize: number,
  filter?: IFilterContratoDto,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const contratoservice = createEscrowAccountService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await contratoservice.getContratoList(offSet, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllContratos({ ...result.response })(dispatch);
};
