import React from 'react';
import { createFormatProvider } from '../../services/providers/factories'
import {
  Container,
  CardTitle,
  Total,
  Button,
} from './styles';
import { ITotalCardProps } from './types';

const TotalCard: React.FC<ITotalCardProps> = (props: ITotalCardProps) => {
  const { debitos, onContinueButtonClick } = props;
  const formatProvider = createFormatProvider();
  const reduceTotal = (list: Array<any>) => list.reduce((acc, cur) => acc + cur.valor, 0);

  const totalLicenciamento = debitos.licenciamento.taxaLicenciamento ?? 0;
  const totalDpvat = reduceTotal(debitos.dpvaTs);
  const totalIpva = reduceTotal(debitos.ipvas);
  const totalMulta = reduceTotal(debitos.multas);

  const totalAmount = totalLicenciamento + totalDpvat + totalIpva + totalMulta;

  return (
    <Container>
      <CardTitle>Total selecionado:</CardTitle>
      <Total>{formatProvider.convertFromCents(totalAmount)}</Total>
      <Button
        htmlType="button"
        onClick={onContinueButtonClick}
      >
        Continuar
      </Button>
    </Container>
  );
};

export default TotalCard;
