import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  PlusOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { toast } from 'react-toastify';
import createIbgeService from '../../services/ibge';
import Table, { TableColumn } from '../../components/Table';
import CadastrarCredencialModal from '../../components/Forms/Pages/CadastrarCredencialModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import { ISelectValue } from '../../components/Forms/AsyncFormDropdown/types';
import { ICredencialFormValues } from '../../components/Forms/Pages/CadastrarCredencialModal/types'
import { Reducer, InitialState } from './actions/reducer';
import { IBusinessInfo, IFilterBusinessProps } from '../../global/dataTransferObjects/credencial';
import FilterForm from './components/FilterForm';
import ViewBusinessModal from './components/viewBusinessModal';
import {
  getAllBusiness,
  onUpdateCredentialModalStatus,
  onOpenCredentialModal,
  createNewCredencial,
  openBusinessViewModal,
  onUpdateBusinessModalStatus,
} from './actions';
import {
  Container,
  TableContainer,
  Button,
} from './styles';
import { T2 } from '../../styles/titles';

const Credenciais: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const businessItem: any = state.business.records.map((x) => ({
    name: x.business.name,
    documentNumber: x.business.cnpj || x.business.cpf,
    clientId: x.clientId,
    clientSecret: x.clientSecret,
    id: x.clientId,
    businessId: x.business.id,
    business: x.business,
  }));

  const fetchAllBusiness = useCallback(() => getAllBusiness(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);

  const fetchAllBusinessPagination = (initialPage: number, finalPage: number) => {
    getAllBusiness(initialPage, finalPage, filterOrder)(dispatch);
  };

  const updateBusinessModalStatus = (status: boolean) => onUpdateCredentialModalStatus(status)(dispatch);

  const handleViewModalStatus = (status: boolean) => onUpdateBusinessModalStatus(status)(dispatch);

  const handleLoadUfOptions = async (): Promise<ISelectValue[]> => {
    const ibgeService = createIbgeService();
    const response = await ibgeService.getUfsAsync().catch(() => []);
    return ibgeService.mapUfsToSelect(response);
  };

  const handleOpenViewUBusinessModal = (business: IBusinessInfo) => {
    openBusinessViewModal({ ...business })(dispatch)
  }

  const handleButtonClick = () => onOpenCredentialModal()(dispatch);

  const handleBusinessFilter = async (filter: IFilterBusinessProps) => {
    setFilterOrder(filter);
    getAllBusiness(offSet, pageSize, filter)(dispatch);
  };

  const onModalFormSubmit = async (values: ICredencialFormValues) => {
    const result = await createNewCredencial(values)(dispatch);
    if (!result) {
      return;
    }

    toast.success('credencial cadastrada com sucesso');
    fetchAllBusiness();
  };

  const formatDocument = (documentNumber: string) => {
    const documentNumberFormatted = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumberFormatted.length === 11) {
      const cpf = documentNumberFormatted.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
      if (cpf != null) {
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        return cpfFormatted;
      }
    }
    if (documentNumberFormatted.length === 14) {
      const cnpj = documentNumberFormatted.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
      if (cnpj != null) {
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        return cnpjFormatted;
      }
    }
    return documentNumberFormatted;
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllBusinessPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllBusinessPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
      />
      <T2>Credenciais</T2>
      <FilterForm
        onFormSubmit={handleBusinessFilter}
      />
      <Button
        icon={<PlusOutlined />}
        onClick={handleButtonClick}
      >
        Cadastrar credencial
      </Button>
      <TableContainer>
        <Table
          dataSource={businessItem}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.business.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="CNPJ/CPF" dataIndex="documentNumber" key="documentNumber" render={formatDocument} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Client ID" dataIndex="clientId" key="clientId" />
          <TableColumn title="Client Secret" dataIndex="clientSecret" key="clientSecret" />
          <TableColumn title="Business ID" dataIndex="businessId" key="businessId" />
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(i: any, e: any) => (state.business.records.length >= 1 ? (
              <EyeOutlined
                onClick={() => handleOpenViewUBusinessModal(e)}
              />
            ) : null)}
          />
        </Table>
      </TableContainer>
      <CadastrarCredencialModal
        loading={state.loading}
        isVisible={state.isPaymentModalOpen}
        onModalClose={() => updateBusinessModalStatus(false)}
        onLoadUfOptions={handleLoadUfOptions}
        onFormSubmit={onModalFormSubmit}
      />
      <ViewBusinessModal
        business={{ ...state.businessInfo }}
        isVisible={state.isBusinessModalOpen}
        onClose={() => handleViewModalStatus(false)}
      />
    </Container>
  );
};

export default Credenciais;
