import chargebackService from './chargebackService';
import { createApi } from '../../providers/factories';
import { IChargebackService } from '../../@types/services';

const createChargebackService = (): IChargebackService => {
  const apiProvider = createApi();
  return chargebackService(apiProvider);
}

export default createChargebackService;
