import { IUserAnticipationService } from '../@types/services';
import { createApi } from '../providers/factories';
import userAnticipationService from './userAnticipationService';

const createUserAnticipationService = (): IUserAnticipationService => {
  const apiProvider = createApi();
  return userAnticipationService(apiProvider);
};

export default createUserAnticipationService;
