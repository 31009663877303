import {
  IAllTransactions,
  IFilterTransactionsProps,
} from '../../global/dataTransferObjects/transactions';
import { IPagedResult } from '../../global/generics';
import { ITransactionsService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { ApiException } from '../../global/exceptions';

const transactionsService = (apiProvider: ITecpayApi): ITransactionsService => {
  const getAllTransactions = async (
    offset: number,
    pageSize: number,
    filter?: IFilterTransactionsProps,
  ): Promise<IApiResponse<IPagedResult<IAllTransactions>>> => {
    const originName = filter?.originName || null;
    const destinyName = filter?.destinyName || null;
    const description = filter?.description || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllTransactions>>({ url: '/nossa-cidade/transactions/all' })
      .withSearch({
        offset,
        pageSize,
        originName,
        destinyName,
        description,
        startDate,
        finishDate,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  return ({
    getAllTransactions,
  });
};

export default transactionsService;
