import React from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../LoadingOverlay';
import VeiculoForm from '../../VeiculoForm';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Warning,
} from './styles';
import { IVeiculoFormValues, FormProps, ICadastroVeiculoModalProps } from './types';
import validationSchema from './validationSchema';

const CadastroVeiculoModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
    onLoadUfOptions,
  } = props;

  return (
    <Modal
      title="Cadastrar Novo Veículo"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro do veículo, por favor aguarde..."
        />
        <Row>
          <Col>
            <VeiculoForm onLoadUfOptions={onLoadUfOptions} />
            <Button htmlType="submit">
              Cadastrar
            </Button>
            <Warning>
              **O cadastro de veículos pode levar algum tempo até ser concluído
            </Warning>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<ICadastroVeiculoModalProps, IVeiculoFormValues>({
  mapPropsToValues: (): IVeiculoFormValues => ({
    apelido: '',
    renavam: '',
    uf: null,
    placa: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const placa = values.placa.replace(/[^a-zA-Z0-9]/g, '');
    const formattedValues = {
      ...values, placa,
    };

    props.onFormSubmit(formattedValues);
  },
})(CadastroVeiculoModal);
