import React, { useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Popconfirm,
  Typography,
  Table,
  Form,
} from 'antd';
import { cnpj } from 'cpf-cnpj-validator';
import { withFormik } from 'formik';
import { TableContainer } from '../styles';
import { createFormatProvider } from '../../../../services/providers/factories';
import {
  IFormProps,
  IFormValues,
  FormProps,
  EditableCellProps,
} from '../types';
import { ICancellationDto } from '../../../../global/dataTransferObjects/creditCard/cancellation';
import SelectField from '../../../../components/Select';
import { ISelectValue } from '../../../../global/types';

const options: ISelectValue[] = [
  { label: 'Solicitada', value: 'Solicitada' },
  { label: 'Em análise', value: 'Em análise' },
  { label: 'Concluída', value: 'Concluída' },
  { label: 'Rejeitada', value: 'Rejeitada' },
]

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  children,
  record,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`,
          },
        ]}
      >
        <SelectField
          name="statusCancelamento"
          options={options}
          initialValue={{ label: record.statusCancelamento, value: record.statusCancelamento }}
          menuPortalTarget={document.querySelector('body')}
        />
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

const CancellationTable = (props: FormProps) => {
  const {
    cancellations,
    handleSubmit,
    setFieldValue,
    pagination,
    onOpenCancellationModal,
  } = props;

  const [editingKey, setEditingKey] = useState('');

  const [form] = Form.useForm<ICancellationDto>();

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formattedPrice = (cents: number) => formatProvider.convertFromCents(cents);

  const mappedCancellations = cancellations.map((cancellation: ICancellationDto) => ({
    ...cancellation,
    numeroDocumento: cancellation.numeroDocumento !== undefined ? cnpj.format(cancellation.numeroDocumento) : 'N/A',
    dataCancelamento: cancellation.dataCancelamento !== undefined ? formattedDate(cancellation.dataCancelamento) : 'N/A',
    reledataDescontoaseAt: cancellation.dataDesconto !== undefined ? formattedDate(cancellation.dataDesconto) : 'N/A',
    dataPrevisao: cancellation.dataPrevisao !== undefined ? formattedDate(cancellation.dataPrevisao) : 'N/A',
    dataTransacao: cancellation.dataTransacao !== undefined ? formattedDate(cancellation.dataTransacao) : 'N/A',
    valorCancelado: cancellation.valorCancelado !== undefined ? formattedPrice(cancellation.valorCancelado) : 'N/A',
    valorPago: cancellation.valorPago !== undefined ? formattedPrice(cancellation.valorPago) : 'N/A',
    valorParaCancelamento: cancellation.valorParaCancelamento !== undefined
      ? formattedPrice(cancellation.valorParaCancelamento)
      : 'N/A',
    valorRepasse: cancellation.valorRepasse !== undefined ? formattedPrice(cancellation.valorRepasse) : 'N/A',
    valorVenda: cancellation.valorVenda !== undefined ? formattedPrice(cancellation.valorVenda) : 'N/A',
    razaoSocial: cancellation.razaoSocial ?? 'N/A',
    bandeiraCartao: cancellation.bandeiraCartao ?? 'N/A',
    tipoTransacao: cancellation.tipoTransacao ?? 'N/A',
    nsuParcela: cancellation.nsuParcela ?? 'N/A',
    statusCancelamento: cancellation.statusCancelamento ?? 'N/A',
    identificadorExterno: cancellation.identificadorExterno ?? 'N/A',
    terminal: cancellation.terminal ?? 'N/A',
    autorizacao: cancellation.autorizacao ?? 'N/A',
    dataDesconto: cancellation.autorizacao ?? 'N/A',
    codigoRetorno: cancellation.codigoRetorno ?? 'N/A',
    codigoAutorizacaoCancelamento: cancellation.codigoAutorizacaoCancelamento ?? 'N/A',
  }));

  const isEditing = (record: ICancellationDto) => record.id === editingKey;

  const edit = (record: Partial<ICancellationDto> & { id: React.Key }) => {
    form.setFieldsValue({
      ...record,
      statusCancelamento: '',
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Data transação', dataIndex: 'dataTransacao', key: 'dataTransacao', editable: false,
    },
    {
      title: 'Empresa', dataIndex: 'razaoSocial', key: 'razaoSocial', editable: false,
    },
    {
      title: 'CNPJ', dataIndex: 'numeroDocumento', key: 'numeroDocumento', editable: false,
    },
    {
      title: 'Bandeira', dataIndex: 'bandeiraCartao', key: 'bandeiraCartao', editable: false,
    },
    {
      title: 'Tipo', dataIndex: 'tipoTransacao', key: 'tipoTransacao', editable: false,
    },
    {
      title: 'NSU/Parcela', dataIndex: 'nsuParcela', key: 'nsuParcela', editable: false,
    },
    {
      title: 'Valor venda', dataIndex: 'valorVenda', key: 'valorVenda', editable: false,
    },
    {
      title: 'Status cancelamento', dataIndex: 'statusCancelamento', key: 'statusCancelamento', editable: true,
    },
    {
      title: 'Valor cancelado', dataIndex: 'valorCancelado', key: 'valorCancelado', editable: false,
    },
    {
      title: 'Data cancelamento', dataIndex: 'dataCancelamento', key: 'dataCancelamento', editable: false,
    },
    {
      title: '',
      dataIndex: 'editar',
      key: 'editar',
      align: 'center',
      editable: false,
      render: (_: any, record: unknown) => {
        const editable = isEditing(record as ICancellationDto);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                const { id } = record as ICancellationDto;
                setFieldValue('id', id);
                handleSubmit();
                cancel();
              }}
              style={{ marginRight: 8, color: '#000' }}
            >
              Salvar
            </Typography.Link>
            <Popconfirm
              title="Cancelar atualização?"
              onConfirm={() => {
                cancel();
              }}
            >
              <Typography.Link style={{ color: '#000' }}>
                Cancelar
              </Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              onClick={() => edit(record as ICancellationDto)}
              style={{ color: '#000', marginRight: '18px' }}
            >
              <EditOutlined />
            </Typography.Link>
            <Typography.Link
              onClick={() => onOpenCancellationModal(record as ICancellationDto)}
              style={{ color: '#000' }}
            >
              <EyeOutlined />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const mergedColumns: any[] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ICancellationDto) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <TableContainer>
      <Form form={form} component={false}>
        <Table
          dataSource={[...mappedCancellations]}
          rowClassName="editable-row"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          columns={[...mergedColumns]}
          pagination={{
            ...pagination,
            onChange: cancel,
          }}
          scroll={{ x: true }}
        />
      </Form>
    </TableContainer>
  );
}

const CancellationForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    id: '',
    statusCancelamento: null,
  }),
  handleSubmit: async (values, { props }): Promise<void> => props.onCancellationUpdateSubmit({ ...values }),
})(CancellationTable);

export default CancellationForm;
