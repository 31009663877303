import { createApi } from '../providers/factories';
import { ICredencialService } from '../@types/services';
import credencialService from './credencialService';

const createCredencialService = (): ICredencialService => {
  const apiProvider = createApi();
  return credencialService(apiProvider);
};

export default createCredencialService;
