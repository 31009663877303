import styled from 'styled-components';
import { Button as StyledButton } from '../../../../styles/buttons';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Form = styled.form``;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding-top: 17px;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  padding-left: 5px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 24px;
`;

export const Button = styled(StyledButton)`
  margin-top: 20px;
`;
