import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormProps, IFormProps, IFormValues } from './types';
import validationSchema from './validationSchema';
import FormInput from '../../../../../components/Forms/Input';
import {
  Form,
  DateView,
  DateField,
  ButtonGroup,
} from './styles';
import { InputGroup } from '../../../../../styles/inputs';
import { FormTextArea } from '../../../../../components/Forms/TextArea';
import { Button, ResetButton } from '../../../../../styles/buttons';
import { T3 } from '../../../../../styles/titles';

const PoliticaCobrancaForm: React.FC<FormProps> = (props: FormProps) => {
  const { handleSubmit, setFieldValue, handleReset } = props;

  const handleChangeProvisionAndRetention = (e: CheckboxChangeEvent): void => {
    setFieldValue('dailyProvisionAndRetention', e.target.checked);
  };

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        type="text"
        name="name"
        label="Nome"
        required
        placeholder="Nome"
      />
      <FormTextArea
        name="description"
        label="Descrição"
        required
        rows={6}
        placeholder="Descrição"
      />
      <DateView>
        <DateField
          name="from"
          label="De"
          placeholder="De"
        />
      </DateView>
      <DateView>
        <DateField
          name="to"
          label="Para"
          placeholder="Para"
        />
      </DateView>
      <FormInput
        type="text"
        name="accessionFee"
        label="Tarifa de adesão"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'accessionFee')}
      />
      <FormInput
        type="text"
        name="debitFee"
        label="Taxa de débito Visa (Stone)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFee')}
      />
      <FormInput
        type="text"
        name="debitFeeMasterCard"
        label="Taxa de débito MasterCard (Stone)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeMasterCard')}
      />
      <FormInput
        type="text"
        name="debitFeeAmex"
        label="Taxa de débito Amex (Stone)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeAmex')}
      />
      <FormInput
        type="text"
        name="debitFeeHipercard"
        label="Taxa de débito Hipercard (Stone)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeHipercard')}
      />
      <FormInput
        type="text"
        name="debitFeeElo"
        label="Taxa de débito Elo (Stone)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeElo')}
      />
      <FormInput
        type="text"
        name="debitFeeVisaAdiq"
        label="Taxa de débito Visa (Adiq)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeVisaAdiq')}
      />
      <FormInput
        type="text"
        name="debitFeeMasterCardAdiq"
        label="Taxa de débito MasterCard (Adiq)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeMasterCardAdiq')}
      />
      <FormInput
        type="text"
        name="debitFeeAmexAdiq"
        label="Taxa de débito Amex (Adiq)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeAmexAdiq')}
      />
      <FormInput
        type="text"
        name="debitFeeHipercardAdiq"
        label="Taxa de débito Hipercard (Adiq)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeHipercardAdiq')}
      />
      <FormInput
        type="text"
        name="debitFeeEloAdiq"
        label="Taxa de débito Elo (Adiq)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'debitFeeEloAdiq')}
      />
      <FormInput
        type="text"
        name="anticipationFee"
        label="Taxa de antecipação padrão"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'anticipationFee')}
      />
      <FormInput
        type="text"
        name="taxaExtra"
        label="Taxa Extra - Primeira Parcela (MATHON)"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaExtra')}
      />
      <FormInput
        type="text"
        name="minAnticipationAmount"
        label="Valor mínimo para poder solicitar antecipação"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'minAnticipationAmount')}
      />
      <FormInput
        type="text"
        name="anticipationComissionFee"
        label="Custo de Originação"
        required
        placeholder="0,00"
        prefix="%"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'anticipationComissionFee')}
      />
      <FormInput
        type="text"
        name="anticipationLimit"
        label="Limite de antecipação"
        required
        placeholder="0,00"
        prefix="%"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'anticipationLimit')}
      />
      <FormInput
        type="text"
        name="debitFloat"
        label="Float de débito (D+1, D+2, etc)"
        required
        placeholder="0"
      />
      <InputGroup>
        <Checkbox
          name="dailyProvisionAndRetention"
          onChange={handleChangeProvisionAndRetention}
        >
          Provisão e retenção diária de valores
        </Checkbox>
        <FormInput
          type="text"
          name="daysGracePeriod"
          label="Dias de carência do aluguel"
          required
          placeholder="0"
        />
      </InputGroup>
      <InputGroup>
        <FormInput
          type="text"
          name="minimumTransactionValueToChargeFixedFee"
          label="Valor mínimo da transação para cobrar valor fixo"
          required
          placeholder="0,00"
          prefix="R$"
          onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'minimumTransactionValueToChargeFixedFee')}
        />
        <FormInput
          type="text"
          name="fixedFeeToChargeOnMinimumTransaction"
          label="Valor fixo a ser cobrando quando transação mínima não for alcançada"
          required
          placeholder="0,00"
          prefix="R$"
          onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'fixedFeeToChargeOnMinimumTransaction')}
        />
      </InputGroup>
      <InputGroup>
        <FormInput
          type="text"
          name="dailyProvisionValue"
          label="Tarifa de reversão contra estorno"
          required
          placeholder="0,00"
          prefix="R$"
          onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'dailyProvisionValue')}
        />
        <FormInput
          type="text"
          name="valorMinimoRepasse"
          label="Valor mínimo para repasse"
          required
          placeholder="0,00"
          prefix="R$"
          onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorMinimoRepasse')}
        />
      </InputGroup>
      <T3 style={{ padding: '20px 0' }}>Tarifas para a nova conta digital:</T3>
      <FormInput
        type="text"
        name="originacaoArquivoCip"
        label="Originação arquivo CIP"
        required
        placeholder="0,00"
        prefix="%"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'originacaoArquivoCip')}
      />
      <FormInput
        type="text"
        name="mensalidadeConta"
        label="Mensalidade da conta"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'mensalidadeConta')}
      />
      <FormInput
        type="text"
        name="tarifaGeracaoBoletoLote"
        label="Tarifa para geração de boleto em lote"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'tarifaGeracaoBoletoLote')}
      />
      <FormInput
        type="text"
        name="taxaPixRecebimento"
        label="Taxa para recebimento do PIX"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPixRecebimento')}
      />
      <FormInput
        type="text"
        name="taxaPixPagamento"
        label="Taxa para pagamento do PIX"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPixPagamento')}
      />
      <FormInput
        type="text"
        name="mensalidadeCartao"
        label="Mensalidade do cartão"
        required
        placeholder="0,00"
        prefix="R$"
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'mensalidadeCartao')}
      />
      <ButtonGroup>
        <ResetButton htmlType="reset" onClick={handleReset}>
          Cancelar
        </ResetButton>
        <Button htmlType="submit">
          Salvar
        </Button>
      </ButtonGroup>
    </Form>
  );
}

export default withFormik<IFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    name: '',
    description: '',
    from: null,
    to: null,
    accessionFee: '',
    debitFee: '',
    anticipationFee: '',
    debitFloat: '',
    anticipationComissionFee: '',
    dailyProvisionAndRetention: false,
    daysGracePeriod: '',
    anticipationLimit: '',
    minimumTransactionValueToChargeFixedFee: '',
    fixedFeeToChargeOnMinimumTransaction: '',
    dailyProvisionValue: '',
    minAnticipationAmount: '',
    valorMinimoRepasse: '',
    debitFeeMasterCard: '',
    debitFeeAmex: '',
    debitFeeHipercard: '',
    debitFeeElo: '',
    debitFeeVisaAdiq: '',
    debitFeeMasterCardAdiq: '',
    debitFeeAmexAdiq: '',
    debitFeeHipercardAdiq: '',
    debitFeeEloAdiq: '',
    taxaExtra: '',
    debitFeeVisaCielo: '',
    debitFeeMasterCardCielo: '',
    debitFeeAmexCielo: '',
    debitFeeHipercardCielo: '',
    debitFeeEloCielo: '',
    originacaoArquivoCip: '',
    mensalidadeConta: '',
    tarifaGeracaoBoletoLote: '',
    taxaPixRecebimento: '',
    taxaPixPagamento: '',
    mensalidadeCartao: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => props.onSubmit({ ...values }),
})(PoliticaCobrancaForm);
