import {FormValues} from "../types";
import axios from "axios";
import {getAxiosInstance} from "../../../../services/providers/api";


export default class DetalhesEdiService {
  GetEdiTransactions = async (
    page:number,
    pageSize: number,
    filter?: FormValues
  )=> {
    const paged = true;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const bandeira = filter?.bandeira || null;
    const tipoCompra = filter?.tipoCompra || null;
    const nsuAdquirente = filter?.nsuAdquirente || null;

    try{
      const response = await getAxiosInstance().post('/adiq-edi/', {
        offSet: page,
        pageSize: pageSize,
        paged: paged,
        startDate: startDate,
        finishDate: finishDate,
        bandeira: bandeira,
        tipoCompra: tipoCompra,
        nsuAdquirente: nsuAdquirente
      });

      return response.data.response;
    } catch(error) {
      return {success: false, error: error};
    }
  }

  GetAdiqEdiFileDownload = async (
    page: number,
    pageSize: number,
    filter?: FormValues
  ) => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const bandeira = filter?.bandeira || null;
    const tipoCompra = filter?.tipoCompra || null;
    const nsuAdquirente = filter?.nsuAdquirente || null;

    try {
      const response = await getAxiosInstance().post(
        '/adiq-edi/download-xls',
        {
          offSet: page,
          pageSize: pageSize,
          startDate: startDate,
          finishDate: finishDate,
          bandeira: bandeira,
          tipoCompra: tipoCompra,
          nsuAdquirente: nsuAdquirente
        },
        { responseType: 'blob' } // Importante garantir que o axios trate a resposta como blob
      );
      return response.data;
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
      return null;
    }
  }
}
