import { INotaPromissoriaService } from '../@types/services';
import { createApi } from '../providers/factories';
import notaService from './notaService';

const createNotaService = (): INotaPromissoriaService => {
  const apiProvider = createApi();
  return notaService(apiProvider);
};

export default createNotaService;
