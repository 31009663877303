import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { InitialState, Reducer } from './actions/reducer';
import {
  getUnidadesRecebiveis,
  onUpdateModalStatus,
  openUnidadeRecebivelModal,
} from './actions'
import LoadingOverlay from '../../../components/LoadingOverlay';
import UnidadesRecebiveisTable from './components/table';
import UnidadeRecebivelModal from './components/modal';
import { Container } from './styles';
import { T2 } from '../../../styles/titles';
import { RouteParamsProps } from './types';
import { IUnidadeRecebivelResponseDto } from '../../../global/dataTransferObjects/unidadeRecebivel';

const UnidadesRecebiveis: React.FC<RouteParamsProps> = (props: RouteParamsProps) => {
  const { match } = props;
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const handleGetUnidadesRecebiveis = useCallback(() => {
    const { solcitacaoConsultaId } = match.params;
    getUnidadesRecebiveis(solcitacaoConsultaId)(dispatch);
  }, []);

  useEffect(() => {
    handleGetUnidadesRecebiveis();
  }, [handleGetUnidadesRecebiveis]);

  const handleOpenUnidadeRecebivelModal = (
    unidadeRecebivel: IUnidadeRecebivelResponseDto,
  ) => openUnidadeRecebivelModal({ ...unidadeRecebivel })(dispatch);

  const handleUpdateModalStatus = (status: boolean) => onUpdateModalStatus(status)(dispatch);

  const handlePaginationChange = () => ({});

  return (
    <Container>
      <LoadingOverlay show={state.loadingStatus} relative />
      <T2>Lista de unidades de recebíveis</T2>
      <UnidadesRecebiveisTable
        unidadesRecebiveis={{ ...state.unidadesRecebiveis }}
        onOpenUnidadeRecebivelModal={handleOpenUnidadeRecebivelModal}
        onPaginationChange={handlePaginationChange}
      />
      <UnidadeRecebivelModal
        unidadeRecebivel={{ ...state.unidadeRecebivel }}
        isVisible={state.isModalOpen}
        onClose={() => handleUpdateModalStatus(false)}
      />
    </Container>
  )
};

export default UnidadesRecebiveis;
