/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useField } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { InputActionMeta, OptionsType } from 'react-select';
import { Form, ErrorMessage } from './styles';

export interface OptionType {
  value: string;
  label: string;
}

interface SelectProps {
  name: string;
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  loadOptions?: (value: string | null) => void;
  onChange?: (value: OptionType | OptionsType<OptionType> | null) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption?: (inputValue: any) => void;
}

const SelectField: React.FC<SelectProps> = (props) => {
  const {
    name,
    isMulti,
    isDisabled,
    onChange,
    onInputChange,
  } = props;

  const [field, meta, helper] = useField<OptionType | OptionsType<OptionType> | undefined>(name);

  useEffect(() => {
    if (isDisabled) {
      helper.setValue([{ value: '99T', label: 'Todas' }])
    }
  }, [isDisabled])

  const handleChange = async (value: OptionType | any | null) => {
    if (!value) {
      helper.setValue(value)
    } if (isDisabled) {
      helper.setValue(value)
    } else {
      helper.setValue(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const isInvalid = meta.error && meta.touched;

  return (
    <>
      <Form>
        <CreatableSelect
          className={isInvalid ? 'is-invalid' : undefined}
          {...props}
          value={field.value}
          isMulti={isMulti}
          onChange={handleChange}
          onInputChange={onInputChange}
          isDisabled={isDisabled}
        />
        {meta.error && (
          <ErrorMessage>
            {meta.error}
          </ErrorMessage>
        )}
      </Form>
    </>
  );
};

export default SelectField;
