import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {createFormatProvider} from '../../services/providers/factories';
import {updateContaDigital, updateSaldoCartao} from './actions';
import {InitialState, Reducer} from './actions/reducer';
import {ChartPanel, Container} from './styles';
import DashboardServices from "./services/DashboardServices";
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {FilterData} from './types';
import Filter from './components/filter';
import TipoPagamentoChart from './components/charts/TipoPagamentoChart';
import BandeiraChart from './components/charts/BandeiraChart';
import Card from './components/card';
import LoadingOverlay from '../../components/LoadingOverlay';
import {formatDate} from "../../utils/formatDate";

const Dashboard: React.FC = () => {
  const dashboardServices = new DashboardServices();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [vendasData, setVendasData] = useState<any>([]);
  const [recebiveisData, setRecebiveisData] = useState<any>([]);

  let today: Date = new Date();
  let tomorrow: Date = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  const [filterData, setFilterData] = useState<FilterData>({
    InitDate: formatDate(today),
    FinishDate: formatDate(tomorrow),
    Status: 1,
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const fetchContaDigital = useCallback(() => updateContaDigital()(dispatch), []);
  const fetchSaldoCartao = useCallback(() => updateSaldoCartao()(dispatch), []);

  Chart.register(CategoryScale);
  Chart.register(ChartDataLabels);

  const handleVendas = async (): Promise<void> => {
    setIsLoading(true);
    const vendasData = await dashboardServices.getVendas(filterData);
    try {
      setVendasData(vendasData.data.response.records);
      setIsLoading(false);
    } catch (e:any) {
      console.log(e.stack);
      setIsLoading(false);
    }
  };

  const handleSubmit = (data: FilterData): void => {
    setFilterData(data);
  };

  useEffect((): void => {
    handleVendas();
  }, [filterData]);

  return (
    <Container>
      <LoadingOverlay show={isLoading} />
      <Filter onSubmit={handleSubmit} />
      <Card salesData={vendasData} />
      <ChartPanel>
        <TipoPagamentoChart vendasData={vendasData} />
        <BandeiraChart vendasData={vendasData} />
      </ChartPanel>
    </Container>
  )
};

export default Dashboard;
