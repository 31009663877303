import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ICargaCartaoDto, IPixPagamentoDTO } from '../../../../global/dataTransferObjects/pix/pagamento';
import { IPagedResult } from '../../../../global/generics';
import createContaVirtualService from '../../../../services/contaDigital';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateCreateModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREATE_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePagamentos = (pagamentos: IPagedResult<IPixPagamentoDTO>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAGAMENTOS,
  payload: {
    pagamentos: { ...pagamentos },
  },
});

export const createTransferenciaEntreCartoes = (
  valorTransferencia: ICargaCartaoDto,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const contaDigitalService = createContaVirtualService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaDigitalService.createTransferenciaEntreCartoes({ ...valorTransferencia });
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao efetuar carga do cartão');
    return false;
  }

  onUpdateCreateModalStatus(false)(dispatch);
  onUpdateLoadingStatus(false)(dispatch);
  return true;
};
