import { IPagedResult } from '../../global/generics';
import { IUnidadeRecebivelResponseDto } from '../../global/dataTransferObjects/unidadeRecebivel';
import { ICercService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { RoutesEnum } from '../../routes/types';

const cercService = (apiProvider: ITecpayApi): ICercService => {
  const getUnidadesRecebiveis = (
    solicitacaoConsultaId: string,
    offset = 0,
    pageSize = 20,
  ): Promise<IApiResponse<IPagedResult<IUnidadeRecebivelResponseDto>>> => {
    const url = `${RoutesEnum.UnidadesRecebiveis}/${solicitacaoConsultaId}/unidade-recebivel`;

    return apiProvider
      .createRequest<IPagedResult<IUnidadeRecebivelResponseDto>>({ url })
      .withSearch({ offset, pageSize })
      .sendAsync();
  }

  return ({
    getUnidadesRecebiveis,
  });
};

export default cercService;
