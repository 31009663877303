import React, { useEffect } from 'react';
import { useField } from 'formik';
import Select, { InputActionMeta, OptionsType } from 'react-select';
import {
  Form,
  ErrorMessage,
} from './styles';
import Label from '../Forms/FieldLabel';

export interface OptionType {
  label: string;
  value: string;
}

interface SelectProps {
  name: string;
  placeholder?: string;
  options?: OptionsType<OptionType>;
  isMulti?: boolean;
  loadOptions?: (value: string | null) => void;
  onChange?: (value: OptionType | OptionsType<OptionType> | null) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption?: (inputValue: any) => void;
  initialValue?: OptionType | OptionsType<OptionType>;
  menuPortalTarget?: HTMLElement | null;
  label?: string;
  required?: boolean;
}

const SelectField: React.FC<SelectProps> = (props) => {
  const {
    name,
    isMulti,
    onChange,
    onInputChange,
    loadOptions,
    initialValue,
    menuPortalTarget,
    label,
    required,
  } = props;

  const [field, meta, helper] = useField<OptionType | OptionsType<OptionType> | undefined>(name);

  const handleChange = (value: OptionType | OptionsType<OptionType> | null) => {
    if (!value) {
      helper.setValue(undefined);
    } else {
      helper.setValue(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const isInvalid = meta.error && meta.touched;

  useEffect(() => {
    helper.setValue(initialValue)
  }, [])

  return (
    <>
      <Form className="flex-1">
        {!!label && (
          <Label
            htmlFor={name}
            text={label}
            required={required}
          />
        )}
        <Select
          className={isInvalid ? 'is-invalid' : undefined}
          {...props}
          value={field.value}
          isMulti={isMulti}
          onChange={handleChange}
          onInputChange={onInputChange}
          loadOptions={loadOptions}
          menuPortalTarget={menuPortalTarget}
        />
        {meta.error && (
          <ErrorMessage>
            {meta.error}
          </ErrorMessage>
        )}
      </Form>
    </>
  );
};

export default SelectField;
