import React from 'react';
import { Row, Col } from 'antd';
import { withFormik } from 'formik';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import FormInput from '../../../../components/Forms/Input'
import DatePickerField from '../../../../components/DatePicker';
import schema from './schema';
import { IAdiantamentoFilterProps, IFilterProps } from '../../types';

const FormFilter: React.FC<IFilterProps> = (props: any) => {
  const { handleSubmit, resetForm } = props;

  const resetVendasForm = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="number"
              label="Folha Mês"
              name="folhaMes"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="number"
              label="Folha Ano"
              name="folhaAno"
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                placeholder="Data inicio"
                label="Data Inicio"
                name="startDate"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                placeholder="Data fim"
                label="Data fim"
                name="finishDate"
              />
            </DateView>
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="string"
              label="No. Documento"
              name="documentNumber"
            />
          </Col>
          <Col span={5} />
          <Col span={5} />
          <Col span={4}>
            <ResetButton
              onClick={() => resetVendasForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={4}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default withFormik<IFilterProps, IAdiantamentoFilterProps>({
  mapPropsToValues: (): IAdiantamentoFilterProps => ({
    folhaMes: '',
    folhaAno: '',
    documentNumber: 0,
    startDate: '',
    finishDate: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const formatValues = {
      ...values,
    }
    props.onFormSubmit(formatValues);
  },
})(FormFilter);
