import { createApi } from '../providers/factories';
import { IBusinessService } from '../@types/services';
import businessService from './businessService';

const createBusinessService = (): IBusinessService => {
  const apiProvider = createApi();
  return businessService(apiProvider);
};

export default createBusinessService;
