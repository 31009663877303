import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import {
  ICelcoinAutorizacaoDTO,
  ICelcoinCreateAutorizacaoDTO,
  ICelcoinCreatePagamentoDTO,
  ICelcoinPagamentoDTO,
  ICelcoinReceiptDto,
} from '../../../../global/dataTransferObjects/boletos';
import { IPagedResult } from '../../../../global/generics';
import createBoletoService from '../../../../services/boletos';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateVerifyModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_VERIFY_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateCreateModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREATE_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateReceiptModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_RECEIPT_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePagamentos = (
  pagamentos: IPagedResult<ICelcoinPagamentoDTO>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAGAMENTOS,
  payload: {
    pagamentos: { ...pagamentos },
  },
});

export const onUpdatePagamento = (
  autorizacao: ICelcoinAutorizacaoDTO,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_AUTORIZACAO,
  payload: {
    autorizacao: { ...autorizacao },
  },
});

export const onUpdateReceipt = (receipt: ICelcoinReceiptDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_RECEIPT,
  payload: {
    receipt: { ...receipt },
  },
});

export const updatePagamentos = (
  offset: number,
  pageSize: number,
  // filter: IPixPagamentoFilterDTO,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const boletoService = createBoletoService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await boletoService.getPagamentos(offset, pageSize);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Houve um erro obter a lista de pagamentos');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onUpdatePagamentos({ ...result.response })(dispatch);
};

export const createAutorizacao = (
  autorizacao: ICelcoinCreateAutorizacaoDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const boletoService = createBoletoService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await boletoService.createAutorizacao({ ...autorizacao });
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Linha digitável inválida');
    return false;
  }

  onUpdatePagamento({ ...result.response })(dispatch);
  return true;
};

export const createPagamento = (
  pagamento: ICelcoinCreatePagamentoDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const boletoService = createBoletoService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await boletoService.createPagamento({ ...pagamento });
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao enviar o pagamento do boleto');
    return false;
  }

  onUpdateCreateModalStatus(false)(dispatch);
  onUpdateLoadingStatus(false)(dispatch);
  return true;
};

export const getPaymentReceipt = (transactionId: number) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const boletoService = createBoletoService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await boletoService.getPaymentReceiptByTransactionId(transactionId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Não foi possível obter informações do comprovante de pagamento');
    return false;
  }

  onUpdateReceipt({ ...result.response })(dispatch);
  return true;
};
