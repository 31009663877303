import { IBusinessService } from '../@types/services';
import {
  IFilterBusinessProps,
  BusinessDto,
  CreateBusinessDto,
  BusinessCategoryDto,
  BusinessHierarchieDto,
  AnticipationPolicyDto,
} from '../../global/dataTransferObjects/business';
import { IPagedResult } from '../../global/generics';
import { ApiException } from '../../global/exceptions';
import {
  HttpMethod,
  IApiResponse,
  ITecpayApi,
} from '../providers/types';
import { ISelectValue } from '../../global/types';

const businessService = (apiProvider: ITecpayApi): IBusinessService => {
  const getBusinessList = async (
    offset: number,
    pageSize: number,
    filter: IFilterBusinessProps,
  ): Promise<IApiResponse<IPagedResult<BusinessDto>>> => {
    const cnpj = filter?.cnpj || null;
    const name = filter?.name || null;
    const response = await apiProvider
      .createRequest<IPagedResult<BusinessDto>>({ url: '/payments/business' })
      .withSearch({
        offset,
        pageSize,
        cnpj,
        name,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  const getEmpresasList = async (
    offset: number,
    pageSize: number,
    filter?: IFilterBusinessProps,
  ): Promise<IApiResponse<IPagedResult<BusinessDto>>> => {
    const cnpj = filter?.cnpj || null;
    const name = filter?.name || null;
    const response = await apiProvider
      .createRequest<IPagedResult<BusinessDto>>({ url: '/payments/business' })
      .withSearch({
        offset,
        pageSize,
        cnpj,
        name,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  const newBusiness = async (body : CreateBusinessDto) : Promise<IApiResponse<void>> => {
    const payload = { ...body }
    const response = await apiProvider
      .createRequest<void>({ url: '/business' })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync()

    return response;
  }

  const getCategoriesList = async () : Promise<IApiResponse<BusinessCategoryDto[]>> => {
    const response = await apiProvider
      .createRequest<BusinessCategoryDto[]>({ url: '/business/list-categories' })
      .sendAsync();

    return response;
  }

  const getHierarchiesList = async () : Promise<IApiResponse<BusinessHierarchieDto[]>> => {
    const response = await apiProvider
      .createRequest<BusinessHierarchieDto[]>({ url: '/business/list-hierarchies' })
      .sendAsync();

    return response;
  }

  const getAnticipationPoliciesList = async () : Promise<IApiResponse<AnticipationPolicyDto[]>> => {
    const response = await apiProvider
      .createRequest<AnticipationPolicyDto[]>({ url: '/business/list-anticipation-policies' })
      .sendAsync();

    return response;
  }

  const mapToSelectableBusiness = (business: Array<any>): Array<ISelectValue> => business
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  return ({
    getBusinessList,
    mapToSelectableBusiness,
    getEmpresasList,
    newBusiness,
    getCategoriesList,
    getHierarchiesList,
    getAnticipationPoliciesList,
  });
};

export default businessService;
