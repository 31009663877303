import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Button, Input, LoginBox, LoginContainer, Title} from "./styles";
import {toast} from "react-toastify";

const LoginCancelamento: React.FC = () => {
  const [password, setPassword] = useState('');
  const history = useHistory();

  const predefinedPassword = 'C2904v@';

  const handleLogin = () => {
    if (password === predefinedPassword) {
      history.push('/tecpay/admin/painel-de-cancelamentos');
    } else {
      toast.error('Senha incorreta');
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Title>Login de Cancelamento</Title>
        <Input
          type="password"
          placeholder="Insira a senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin}>Entrar</Button>
      </LoginBox>
    </LoginContainer>
  );
};

export default LoginCancelamento;
