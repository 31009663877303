import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import createUserBusinessService from '../../../services/userBusiness';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onCheckingExistene = (existenceLink : boolean) => (dispatch : Dispatch<any>): void => dispatch({
  type: ActionTypes.CHECK_EXISTENCE,
  payload: {
    existenceLink,
  },
})

export const checkExistence = (
  businessId: number,
) => async (dispatch: Dispatch<any>) : Promise<void> => {
  const userBusinessService = createUserBusinessService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userBusinessService.getByBusinessId(businessId);
  if (result.status !== 200) {
    toast.error(result.message);
  }

  if (result.response !== null) {
    onCheckingExistene(true)(dispatch)
  }

  onUpdateLoadingStatus(false)(dispatch);
}

export const submitNewLink = (
  userId : string,
  businessId: number,
) => async (dispatch : Dispatch<any>) : Promise<void> => {
  const userBusinessService = createUserBusinessService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userBusinessService.submitNewLink(userId, businessId);

  if (result.status !== 200) {
    toast.error(result.message);
    onUpdateLoadingStatus(false)(dispatch);
  }

  toast.success('Empresa vinculada com sucesso!');
  onUpdateLoadingStatus(false)(dispatch);
}
