import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import 'moment/locale/pt-br'
import moment from 'moment';

import App from './App';

moment.locale('pt-br');

ReactDOM.render(
  <ConfigProvider locale={ptBR}>
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
);
