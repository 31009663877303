import styled from 'styled-components';
import { Layout, Card as ACard } from 'antd';
import { Button as StyledButton } from '../../../styles/buttons'

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Card = styled(ACard)`
  width: 100%;
  flex-direction: row;
  & .ant-card-actions > li {
    text-align: right;
    margin: 12px -17px;
  }
  & .ant-card-head-title {
    color: #010101;
    font-size: 200;
  }
`;

export const SubmitBtn = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  align-self: flex-end;
`;

export const Button = styled(StyledButton)`
  margin-top: 20px;
`;

export const BottomText = styled.text`
  color: red;
`;
