import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { createFilter } from 'react-select';
import { IAsyncFieldDropdownProps } from './types';
import FieldLabel from '../FieldLabel';
import { Label } from '../../../styles/inputs';
import { AsyncPaginateField } from './styles';

const AsyncFormDropdown: FC<IAsyncFieldDropdownProps> = (props: IAsyncFieldDropdownProps) => {
  const {
    values,
    noOptionsMessage,
    label,
    placeholder,
    onChange,
    validateOnSelect,
    loadOptions,
    required,
    isMulti,
    debounceTime = 500,
    childrenFieldsToReset,
    cacheUniqs,
    isDisabled,
    isClearable,
    additional,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const onFieldChange = (value: any) => {
    childrenFieldsToReset?.map((fieldName: string) => formikContext.setFieldValue(fieldName, 0));
    if (value && validateOnSelect && !validateOnSelect(value)) return;
    helpers.setValue(value);
  };

  const onValueChange = (value: any) => {
    onFieldChange(value)
    if (onChange) {
      onChange(value)
    }
  }

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
  };

  return (
    <>
      <FieldLabel
        htmlFor={field.name}
        text={label}
        required={required}
      />
      <AsyncPaginateField
        onChange={onValueChange}
        placeholder={placeholder}
        loadOptions={loadOptions}
        isMulti={isMulti}
        isDisabled={isDisabled}
        loadingMessage={() => 'Carregando...'}
        value={values || field.value}
        noOptionsMessage={() => noOptionsMessage || 'Nenhum item encontrado...'}
        id={field.name}
        classNamePrefix="react-select"
        debounceTimeout={debounceTime}
        cacheUniqs={cacheUniqs}
        filterOption={createFilter(filterConfig)}
        isClearable={isClearable}
        additional={additional}
        {...rest}
      />
      {(meta.error) && (
        <Label variant="danger">
          {meta.error}
        </Label>
      )}
    </>
  );
};

AsyncFormDropdown.defaultProps = {
  additional: {
    page: 1,
  },
};

export default AsyncFormDropdown;
