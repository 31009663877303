
import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import Table, { TableColumn } from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import { createFormatProvider } from '../../services/providers/factories';
import FormFilter from './components/FilterForm';
import {
  getVirtualCheckingAccount,
} from './actions';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../styles/titles';
import createBusinessService from '../../services/business';
import { IFilterCarteiraVirtualProps } from '../../global/dataTransferObjects/carteiraVirtual'
import { ISelectValue } from '../../global/types';

const CarteiraVirtual: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [businessPageSizeList] = useState<number>(100);
  const [businessOffSetList] = useState<number>(0);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const businessId: any = query.get('id');

  const fetchAllUsers = useCallback(() => getVirtualCheckingAccount(offSet, pageSize, businessId)(dispatch), []);
  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const businessService = createBusinessService();

  const handleLoadBusinessOptions = async (name: any): Promise<Array<ISelectValue>> => {
    setFilterOrder({ name });
    const businessList = await businessService.getEmpresasList(businessOffSetList, businessPageSizeList, filterOrder);

    return businessService.mapToSelectableBusiness(businessList.response.records);
  };

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const fetchAllCarteiraVirtualPagination = (initialPage: number, finalPage :number) => {
    getVirtualCheckingAccount(initialPage, finalPage, businessId, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllCarteiraVirtualPagination(pageSize * (currentPage - 1), (pageSize))
    }
    if (currentPage === 1) {
      fetchAllCarteiraVirtualPagination(0, (pageSize));
    }
  }, [currentPage, pageSize]);

  const handleCarteiraFilter = async (filter: IFilterCarteiraVirtualProps) => {
    setFilterOrder(filter);
    getVirtualCheckingAccount(offSet, pageSize, businessId, filter)(dispatch);
  }

  const virtualCheckingAccountList = state?.virtualCheckingAccountList?.records.map((virtualAccountList) => (
    {
      ...virtualAccountList,
    }
  ));

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Carteira Virtual</T2>
      <FormFilter onFormSubmit={handleCarteiraFilter} onLoadEmpresas={handleLoadBusinessOptions} />
      <TableContainer>
        <Table
          dataSource={virtualCheckingAccountList}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.virtualCheckingAccountList.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn
            title="Empresa"
            dataIndex="businessName"
            key="businessName"
            width={500}
            render={(item: number, record: any) => ({
              props: {
                style: { color: record.rowColor !== null ? record.rowColor : '#cc6600' },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Data Entrada"
            dataIndex="entryDate"
            key="entryDate"
            render={(item: string, record: any) => ({
              props: {
                style: { color: record.rowColor !== null ? record.rowColor : '#cc6600' },
              },
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="Data Pagamento"
            dataIndex="paymentDate"
            key="paymentDate"
            render={(item: string, record: any) => ({
              props: {
                style: { color: record.rowColor !== null ? record.rowColor : '#cc6600' },
              },
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="Observação"
            dataIndex="notes"
            key="notes"
            width={800}
            render={(item: string, record: any) => ({
              props: {
                style: { color: record.rowColor !== null ? record.rowColor : '#cc6600' },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Valor"
            dataIndex="value"
            key="value"
            render={(item: number, record: any) => ({
              props: {
                style: { color: record.rowColor !== null ? record.rowColor : '#cc6600' },
              },
              children: <div>{format(item)}</div>,
            })}
          />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CarteiraVirtual;
