import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../components/Forms/Input';
import validationSchema from './validationSchema';
import DatePickerField from '../../../../components/DatePicker';
import SelectField from '../../../../components/Select';
import { IFilterUser, IFilterTransactionsProps } from '../../types';
import { ISelectValue } from '../../../../global/types';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const options: ISelectValue[] = [
  { label: 'Pagamento recebido', value: 'Pagamento recebido' },
  { label: 'Pagamento realizado', value: 'Pagamento realizado' },
  { label: 'Bônus de primeiro pagamento', value: 'Bônus de primeiro pagamento' },
]

const FilterForm: React.FC<IFilterUser> = (props: any) => {
  const { handleSubmit, resetForm } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="text"
              name="originName"
              label="Nome pagador"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="destinyName"
              label="Nome recebedor"
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="Data Inicio"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Data fim"
              />
            </DateView>
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6}>
            <SelectField
              name="description"
              placeholder=""
              label="Descrição"
              options={options}
            />
            {/* <FormInput
              type="string"
              name="description"
              label="Descrição"
            /> */}
          </Col>
          <Col span={6} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterUser, IFilterTransactionsProps>({
  mapPropsToValues: (): IFilterTransactionsProps => ({
    originName: '',
    destinyName: '',
    description: null,
    startDate: '',
    finishDate: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const description: any = values.description?.value;
    const formattedValues = {
      ...values,
      description,
    };
    props.onFormSubmit(formattedValues);
  },
})(FilterForm);
