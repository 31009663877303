import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  transferenciaRepasses: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
    totalRepasses: {
      paidAmount: 0,
      provisioningValue: 0,
      transferAmount: 0,
    },
  },
  totalRepasses: {
    paidAmount: 0,
    provisioningValue: 0,
    transferAmount: 0,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_TRANSFERENCIA_REPASSES:
      return {
        ...state,
        loading: false,
        transferenciaRepasses: action.payload?.transferenciaRepasses ?? { ...InitialState.transferenciaRepasses },
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    default:
      return { ...state };
  }
};
