import React, {useEffect, useState} from 'react';
import {formatBandeira} from "../../../utils";
import DoughnutChart from "../../../../../components/Charts/DoughnutChart";
const BandeiraChart: React.FC<any> = ({vendasData}) => {

  const CHART_COLORS = {
    HIPERCARD: 'rgb(255, 99, 132)',
    VISA: 'rgb(64,175,255)',
    MASTERCARD: 'rgb(255, 205, 86)',
  };
  const [chartDataBandeira, setChartData] = useState({
    labels: vendasData.map((data: any) => data.brand),
    datasets: [
      {
        label: "Valor total",
        data: vendasData.map((data: any) => data.value),
        borderColor: "lightgray",
        borderWidth: 1,
        backgroundColor: Object.values(CHART_COLORS),
      },
    ],
  });


  const [chartOptionsBandeira, setChartOptionsBandeira] = useState({
    plugins: {
      title: {
        display: true,
        text: "Distribuição do Valor Total de Vendas por Bandeira",
      },

      datalabels: {
        formatter: (value: number, context: any) => {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data as number[];
          dataArr.forEach(data => {
            sum += data;
          });
          const percentualValor = ((value * 100) / sum).toFixed(2);
          return percentualValor.concat('%');
        },
        color: 'white',
      },
    },
    cutout: '70%',
  });

  useEffect(() => {
    const bandeiras: string[] = vendasData.map((data: any) => {
      return formatBandeira(data.brand);
    }).filter((element: any, index: any, self: any) => {
      return index === self.indexOf(element);
    });

    const totalBandeira: number[] = bandeiras.map((dataLabel) => {
      let total = 0;
      vendasData.forEach((data: any) => {
        if (formatBandeira(data.brand) === dataLabel) {
          total += data.value
        }
      })
      return total;
    });

    const formatColor: string[] = vendasData.map((data: any) => {
      return formatBandeira(data.brand)
    }).filter((element: any, index: any, self: any) => {
      return index === self.indexOf(element);
    });

    const backgroundcolor: string[] = [];

    for (let i = 0; i < formatColor.length; i++) {
      if (formatColor[i] === 'HIPERCARD') {
        backgroundcolor.push('rgb(14, 43, 39)');
      } else if (formatColor[i] === 'VISA') {
        backgroundcolor.push('rgb(38, 119, 108)');
      } else if (formatColor[i] === 'MASTERCARD') {
        backgroundcolor.push('rgb(62, 195, 177)');
      } else if (formatColor[i] === 'ELO') {
        backgroundcolor.push('rgb(65,118,111)');
      } else {
        backgroundcolor.push('#e0a458');
      }
    }

    const tempChartData = {
      ...chartDataBandeira,
      labels: bandeiras,
      datasets: [
        {
          ...chartDataBandeira.datasets[0],
          data: totalBandeira,
          backgroundColor: backgroundcolor,
        },
      ],
    };

    setChartData(tempChartData);
  }, [vendasData]);

  return (
    <div>
      {vendasData.length > 0 ? <DoughnutChart chartData={chartDataBandeira} chartName="Vendas por Bandeiras de Cartão" chartOptions={chartOptionsBandeira} /> : <p>{'Sem resultados para essa pesquisa. \nTente alterar os dados do filtro.'}</p>}
    </div>
  )
}

export default BandeiraChart;
