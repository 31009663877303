import { IPixCreatePagamentoDTO } from '../../../../global/dataTransferObjects/pix/pagamento';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isCreateModalOpen: false,
  isVerifyModalOpen: false,
  isDetailsPaymentModalOpen: false,
  transaction: {
    endToEndId: '',
    amount: 0,
    createdAt: new Date(),
    originName: '',
    destinyName: '',
    originDocumentNumber: '',
    destinyDocumentNumber: '',
    destinyAccountNumber: '',
    description: '',
  },
  pagamentos: {
    count: 0,
    offset: 0,
    pageSize: 20,
    records: [],
  },
  createPagamento: {} as IPixCreatePagamentoDTO,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CREATE_MODAL_STATUS:
      return {
        ...state,
        isCreateModalOpen: action.payload?.status ?? InitialState.isCreateModalOpen,
      }
    case ActionTypes.UPDATE_VERIFY_MODAL_STATUS:
      return {
        ...state,
        isVerifyModalOpen: action.payload?.status ?? InitialState.isVerifyModalOpen,
      }
    case ActionTypes.UPDATE_DETAILS_PAYMENT_MODAL_STATUS:
      return {
        ...state,
        loading: false,
        transaction: action.payload?.transaction ?? { ...InitialState.transaction },
        isDetailsPaymentModalOpen: action.payload?.status ?? false,
      }
    case ActionTypes.UPDATE_PAGAMENTOS:
      return {
        ...state,
        loading: false,
        pagamentos: action.payload?.pagamentos ?? { ...InitialState.pagamentos },
      }
    case ActionTypes.UPDATE_CREATE_PAGAMENTO:
      return {
        ...state,
        loading: false,
        isVerifyModalOpen: false,
        createPagamento: action.payload?.createPagamento ?? { ...InitialState.createPagamento },
      }
    default:
      return { ...state };
  }
};
