import { BusinessDto } from '../../../../../../global/dataTransferObjects/business';
import { IPagedResult } from '../../../../../../global/generics';

export interface IPageState {
  loading: boolean;
  businesses: IPagedResult<BusinessDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_BUSINESS = 'GET_ALL_BUSINESS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
