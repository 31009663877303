import { IPoliticaContaDigitalDTO } from '../../../../global/dataTransferObjects/politicaContaDigital';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  politicaContaDigital: {} as IPoliticaContaDigitalDTO,
  selectedUsers: [],
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_POLITICA_CONTA_DIGITAL:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.GET_POLITICA_CONTA_DIGITAL:
      return {
        ...state,
        loading: false,
        politicaContaDigital: action.payload?.politicaContaDigital ?? { ...InitialState.politicaContaDigital },
      }
    case ActionTypes.GET_POLITICA_CONTA_DIGITAL_USERS:
      return {
        ...state,
        loading: false,
        selectedUsers: action.payload?.selectedUsers ?? { ...InitialState.selectedUsers },
      }
    default:
      return { ...state };
  }
};
