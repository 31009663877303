import { IPoliticaCobrancaService } from '../@types/services';
import { createApi } from '../providers/factories';
import politicaCobrancaService from './politicaCobrancaService';

const createPoliticaCobrancaService = (): IPoliticaCobrancaService => {
  const apiProvider = createApi();
  return politicaCobrancaService(apiProvider);
};

export default createPoliticaCobrancaService;
