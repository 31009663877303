import TerminaisServices from "./services/TerminaisServices";
import Table, {TableColumn} from "../../components/Table";
import React, {useEffect, useState} from "react";
import {T2} from "../../styles/titles";
import Filter from "./components/filterForm";
import {IPoliticaCobrancaFilterProps} from "../../global/dataTransferObjects/politicaCobranca";
import { IFormValues} from './components/filterForm/types'
import {IFilterCancellationValues} from "../creditCard/cancellation/components/FilterForm/types";
import {Container, TableContainer} from "./styles";
import {ITerminalDto} from "../../global/terminal";
import LoadingOverlay from "../../components/LoadingOverlay";

const Terminais: React.FC = () => {
  const terminalsServices = new TerminaisServices();
  const [allTerminals, setAllTerminals] = useState<any>(0);
  const [allTerminalsNumber, setAllTerminalsNumber] = useState(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [terminalFilter, setTerminalFilter] = useState<ITerminalDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTerminals = async () => {
    setIsLoading(true);
    const result = await terminalsServices.getAllTerminais(terminalFilter as ITerminalDto);
    setAllTerminals(result.data.response.records);

    if (result.data.response.count) setAllTerminalsNumber(result.data.response.count);
    setIsLoading(false);
  };

  const handleFilterSubmit = async (values: IFormValues): Promise<void> => {
      const formattedValues: ITerminalDto = { ...values };
      setTerminalFilter(formattedValues);
  };

  useEffect(() => {
    handleTerminals();
  }, [terminalFilter]);

  return (
      <Container>
        <LoadingOverlay
          show={isLoading}
          relative
        />
        <T2>Terminais</T2>
        <Filter
          onSubmit={handleFilterSubmit}
        />
        <TableContainer>
          <Table dataSource={allTerminals}
                 pagination={
                   {
                     showSizeChanger: true,
                     onShowSizeChange: (current: number, size: number) => {
                       setPageSize(size);
                     },
                     defaultPageSize: 20,
                     total: allTerminalsNumber,
                     onChange: (pageNum) => {
                       setCurrentPage(pageNum);
                     },
                   }
                 }>
            <TableColumn title="Id" dataIndex="id" key="id" />
            <TableColumn title="Nome" dataIndex="identifier" key="identifier" />
            <TableColumn title="Núm. de Série" dataIndex="serialNumber" key="serialNumber" />
            <TableColumn title="Tid" dataIndex="tid" key="tid" />
            <TableColumn title="Status" dataIndex="status" key="status" />
          </Table>
        </TableContainer>
      </Container>
  )
}

export default Terminais;
