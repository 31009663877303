import { IAllBusiness, IBusinessInfo } from '../../../global/dataTransferObjects/credencial';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean;
  business: IPagedResult<IAllBusiness>;
  businessInfo: IBusinessInfo;
  isPaymentModalOpen: boolean;
  isBusinessModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_BUSINESS = 'GET_ALL_BUSINESS',
  UPDATE_VEICULOS = 'UPDATE_VEICULOS',
  OPEN_CREDENTIAL_MODAL = 'OPEN_CREDENTIAL_MODAL',
  UPDATE_CREDENTIAL_MODAL_STATUS = 'UPDATE_CREDENTIAL_MODAL_STATUS',
  CREATE_NEW_CREDENTIAL = 'CREATE_NEW_CREDENTIAL',
  OPEN_BUSINESS_MODAL = 'OPEN_BUSINESS_MODAL',
  UPDATE_BUSINESS_MODAL = 'UPDATE_BUSINESS_MODAL',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
