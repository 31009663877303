import { IBoletoCnabDto, ICreateBoletoCnabDto } from '../../../global/dataTransferObjects/boletoCnab';
import { IBoletoCnab } from '../../@types/services';
import {
  HttpMethod,
  IApiResponse,
  ITecpayApi,
} from '../../providers/types';

const boletoCnabService = (apiProvider: ITecpayApi): IBoletoCnab => {
  const createBoletoCnab = async (boletoDto: ICreateBoletoCnabDto): Promise<IApiResponse<IBoletoCnabDto>> => {
    const { dataUri, codigoBanco } = boletoDto;
    const payload = {
      dataUri,
      codigoBanco,
    };

    return apiProvider.createRequest<ICreateBoletoCnabDto, IBoletoCnabDto>({ url: 'boletos/cnabs' })
      .withData({ ...payload })
      .withMethod(HttpMethod.POST)
      .sendAsync();
  };
  return ({ createBoletoCnab })
}

export default boletoCnabService;
