import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { IFormValues, IFormProps, FormProps } from './types';
import FormField from '../../../../components/Forms/Input';
import { formatterDocumentNumber } from '../../../../components/Masks';
import { InputGroup } from '../../../../styles/inputs';
import { Form } from '../../../../styles/forms';

const InnerForm = (props: FormProps) => {
  const [phoneMask] = useState<string>('(99) 9999-99999');
  const {
    handleSubmit,
    setFieldValue,
    values,
    user,
  } = props;

  useEffect(() => {
    setFieldValue('id', user.id);
    setFieldValue('nome', user.name);
    setFieldValue('sobrenome', user.lastName);
    setFieldValue('tipoUsuario', user.userType);
    setFieldValue('email', user.email);
    setFieldValue('numeroDocumento', formatterDocumentNumber(user?.documentNumber));
    setFieldValue('telefone', user.phoneNumber);
  }, [user]);

  useEffect(() => {
    const documentNumber = values.documentNumber?.replace(/[^0-9]/g, '');
    setFieldValue('numeroDocumento', documentNumber);
    const handleDocumentNumber = () => {
      if (documentNumber.length === 11) {
        const cpf = documentNumber.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
        if (cpf !== null) {
          const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
          setFieldValue('numeroDocumento', cpfFormatted);
        }
      }
    }
    handleDocumentNumber();
  }, [values.documentNumber]);

  useEffect(() => {
    const phoneNumber = values.phoneNumber?.replace(/[^0-9]/g, '');
    setFieldValue('telefone', phoneNumber);
    const handlePhoneNumber = () => {
      if (phoneNumber.length === 11) {
        const telNumber: RegExpMatchArray | null = phoneNumber.match(/^([0-9]{2})([0-9]{5})([0-9]{4})/);
        if (telNumber !== null) {
          setFieldValue('telefone', `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`);
        }
      }
      if (phoneNumber.length === 10) {
        const telNumber: RegExpMatchArray | null = phoneNumber.match(/^([0-9]{2})([0-9]{4})([0-9]{4})/);
        if (telNumber !== null) {
          setFieldValue('telefone', `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`);
        }
      }
      return phoneNumber;
    }
    handlePhoneNumber();
  }, [values.phoneNumber]);

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <InputGroup>
        <FormField
          name="nome"
          type="text"
          label="Nome"
          placeholder="Digite seu nome"
          required
          disabled
        />
        <FormField
          name="sobrenome"
          type="text"
          label="Sobrenome"
          placeholder="Digite seu sobrenome"
          required
          disabled
        />
      </InputGroup>
      <FormField
        name="email"
        type="email"
        label="E-mail"
        placeholder="Digite seu e-mail"
        disabled
        required
      />
      <FormField
        name="numeroDocumento"
        type="text"
        label="Número do documento"
        placeholder="Digite número do documento"
        required
        disabled
      />
      <FormField
        name="telefone"
        type="text"
        label="Número do telefone"
        placeholder="Digite o número do telefone"
        mask={phoneMask}
        required
        disabled
      />
    </Form>
  )
}

const SignForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    id: '',
    name: '',
    lastName: '',
    email: '',
    documentNumber: '',
    phoneNumber: '',
    userType: 1,
  }),
  handleSubmit: async (values, { props }): Promise<void> => {
    const telefone = values.phoneNumber.replace(/[^0-9]/g, '');
    const formattedValues = {
      ...values,
      telefone,
    }
    await props.onSignUpFormSubmit(formattedValues);
  },
})(InnerForm);

export default SignForm;
