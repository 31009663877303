import React, {useCallback} from 'react';
import {
  ShoppingCartOutlined,
  CreditCardOutlined,
  BankOutlined,
  UserOutlined,
  LockOutlined,
  IdcardOutlined,
  HomeOutlined
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {Menu, AvatarOverlayItem} from '../styles';
import { VscLiveShare, VscDiscard  } from 'react-icons/vsc';
import {RiOrganizationChart} from "react-icons/ri";
import {CiViewTable} from "react-icons/ci";
import { MdOutlineCancel } from "react-icons/md";

const PageOverlayADM: React.FC = () => {

  const history = useHistory();
  const redirectToSaleSimulatorPage = useCallback(() => history.push('/simulador/venda'),[history]);
  const redirectToIncomeSimulatorPage = useCallback(() => history.push('/simulador/recebimento'),[history]);
  const redirectToSalesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-vendas'),[history]);
  const redirectToRecivablesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-recebiveis'),[history]);
  const redirectToPaymentPanel = useCallback(() => history.push('/tecpay/admin/painel-de-pagamentos'), [history] );
  const redirectToCancelPanel = useCallback( () => history.push( 'tecpay/admin/painel-de-cancelamentos'),[history]);
  const redirectToUserPage = useCallback(()=> history.push('/tecpay/admin/usuarios'), [history]);
  const redirectToDashboardPage = useCallback(() => history.push('/dashboard'), [history]);
  const redirectToBusinessPage = useCallback(() => history.push('/tecpay/admin/empresas'), [history]);
  const redirectToDetailsEdi = useCallback(() => history.push('/tecpay/admin/detalhes-edi'), [history]);
  const redirectToManagerEdi = useCallback(() => history.push('/tecpay/admin/gerenciador-edi'), [history]);


  return (
    <Menu>

      <AvatarOverlayItem
        icon={<HomeOutlined />}
        onClick={redirectToDashboardPage}
      >
        Home
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<UserOutlined/>}
        onClick={redirectToUserPage}
      >
        Usuários
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<IdcardOutlined />}
        onClick={redirectToBusinessPage}
      >
        Empresas
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<BankOutlined />}
        onClick={redirectToPaymentPanel}
      >
        Painel de Pagamento
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<VscDiscard/>}
        onClick={redirectToRecivablesPanel}
      >
        Painel de Recebiveis
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<ShoppingCartOutlined/>}
        onClick={redirectToSalesPanel}
      >
        Painel de Vendas
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CiViewTable />}
        onClick={redirectToDetailsEdi}
      >
        Detalhes EDI
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<RiOrganizationChart />}
        onClick={redirectToManagerEdi}
      >
        Geranciador de EDI
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<MdOutlineCancel />}
        onClick={redirectToCancelPanel}
      >
        Cancelamento de Vendas
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToIncomeSimulatorPage}
      >
        Simulador - Recebimento
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToSaleSimulatorPage}
      >
        Simulador - Venda
      </AvatarOverlayItem>
    </Menu>
  );
};
export default PageOverlayADM;
