import { IChargebackDto } from '../../../../global/dataTransferObjects/creditCard/chargeback';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  chargebacks: IPagedResult<IChargebackDto>;
  chargeback: IChargebackDto;
  isCadastroChargebackModalOpen: boolean;
  isChargebackDetailsModalOpen: boolean;
  isChargebackEditModalOpen: boolean;
  isChargebackStatusModalOpen: boolean;
  selectedChargebacks: Array<IChargebackDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CHARGEBACKS = 'UPDATE_CHARGEBACKS',
  UPDATE_CHARGEBACK = 'UPDATE_CHARGEBACK',
  UPDATE_CADASTRO_CHARGEBACK_MODAL_STATUS = 'UPDATE_CADASTRO_CHARGEBACK_MODAL_STATUS',
  OPEN_CADASTRO_CHARGEBACK_MODAL = 'OPEN_CADASTRO_CHARGEBACK_MODAL',
  UPDATE_CHARGEBACK_DETAILS_MODAL_STATUS = 'UPDATE_CHARGEBACK_DETAILS_MODAL_STATUS',
  OPEN_CHARGEBACK_DETAILS_MODAL = 'OPEN_CHARGEBACK_DETAILS_MODAL',
  UPDATE_CHARGEBACK_EDIT_MODAL_STATUS = 'UPDATE_CHARGEBACK_EDIT_MODAL_STATUS',
  OPEN_CHARGEBACK_EDIT_MODAL = 'OPEN_CHARGEBACK_EDIT_MODAL',
  UPDATE_CHARGEBACKS_SELECTION = 'UPDATE_CHARGEBACKS_SELECTION',
  OPEN_CHARGEBACK_STATUS_MODAL = 'OPEN_CHARGEBACK_STATUS_MODAL',
  UPDATE_CHARGEBACK_STATUS_MODAL_STATUS = 'UPDATE_CHARGEBACK_STATUS_MODAL_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
