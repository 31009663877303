import {FormValues} from "../types";
import {getAxiosInstance} from "../../../services/providers/api";

export default class TerminaisPhoebusService {
  getAllTerminals = async (
    page: number,
    pageSize: number,
    filter?: FormValues
  ) => {
    const params= {
      page: page,
      pageSize: pageSize,
      terminal:  filter?.terminal,
      merchant:  filter?.merchant,
      serial_number:  filter?.serial_number,
      associated_terminal:  filter?.associated_terminal,
      status:  filter?.status,
    }

    try {
      const response = await getAxiosInstance().get("/phoebus/terminal/get-terminal",{
        params: params
      });

      return response
    } catch(error) {
      return {sucess:false, error: error}
    }
  }
}
