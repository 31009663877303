import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay';
import {
  Container,
  Box,
} from './styles';
import { ICreatePoliticaCobrancaDTO } from '../../../global/dataTransferObjects/politicaCobranca';
import { createNewPoliticaCobranca } from './actions';
import { IFormValues } from './components/createForm/types';
import { InitialState, Reducer } from './actions/reducer';
import PoliticaCobrancaForm from './components/createForm';
import { T2 } from '../../../styles/titles';

const toDecimal = (value: string) => parseFloat(value.replace('.', '').replace(',', '.'));
const toInt = (value: string) => parseInt(value, 10);

const CreatePoliticaCobranca: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();

  const onFormSubmit = async (values: IFormValues) => {
    const formattedValues: ICreatePoliticaCobrancaDTO = {
      ...values,
      accessionFee: toDecimal(values.accessionFee),
      debitFee: toDecimal(values.debitFee),
      anticipationFee: toDecimal(values.anticipationFee),
      debitFloat: toInt(values.debitFloat),
      anticipationComissionFee: toDecimal(values.anticipationComissionFee),
      daysGracePeriod: toInt(values.daysGracePeriod),
      anticipationLimit: toDecimal(values.anticipationLimit),
      minimumTransactionValueToChargeFixedFee: toDecimal(values.minimumTransactionValueToChargeFixedFee),
      fixedFeeToChargeOnMinimumTransaction: toDecimal(values.fixedFeeToChargeOnMinimumTransaction),
      dailyProvisionValue: toDecimal(values.dailyProvisionValue),
      minAnticipationAmount: toDecimal(values.minAnticipationAmount),
      valorMinimoRepasse: toDecimal(values.valorMinimoRepasse),
      debitFeeMasterCard: toDecimal(values.debitFeeMasterCard),
      debitFeeAmex: toDecimal(values.debitFeeAmex),
      debitFeeHipercard: toDecimal(values.debitFeeHipercard),
      debitFeeElo: toDecimal(values.debitFeeElo),
      debitFeeVisaAdiq: toDecimal(values.debitFeeVisaAdiq),
      debitFeeMasterCardAdiq: toDecimal(values.debitFeeMasterCardAdiq),
      debitFeeAmexAdiq: toDecimal(values.debitFeeAmexAdiq),
      debitFeeHipercardAdiq: toDecimal(values.debitFeeHipercardAdiq),
      debitFeeEloAdiq: toDecimal(values.debitFeeEloAdiq),
      taxaExtra: toDecimal(values.taxaExtra),
      debitFeeVisaCielo: toDecimal(values.debitFeeVisaCielo),
      debitFeeMasterCardCielo: toDecimal(values.debitFeeMasterCardCielo),
      debitFeeAmexCielo: toDecimal(values.debitFeeAmexCielo),
      debitFeeHipercardCielo: toDecimal(values.debitFeeHipercardCielo),
      debitFeeEloCielo: toDecimal(values.debitFeeEloCielo),
      originacaoArquivoCip: toDecimal(values.originacaoArquivoCip),
      mensalidadeConta: toDecimal(values.mensalidadeConta),
      tarifaGeracaoBoletoLote: toDecimal(values.tarifaGeracaoBoletoLote),
      taxaPixRecebimento: toDecimal(values.taxaPixRecebimento),
      taxaPixPagamento: toDecimal(values.taxaPixPagamento),
      mensalidadeCartao: toDecimal(values.mensalidadeCartao),
    };

    const result = await createNewPoliticaCobranca({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    toast.success('Política de cobrança cadastrada com sucesso!');
    history.push('/tecpay/admin/cadastro-geral/politicas-cobranca');
  };

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
        tip="Realizando o cadastro da política de cobrança, por favor aguarde..."
      />
      <T2>Criar política de cobrança</T2>
      <Box>
        <h3>Dados da política:</h3>
        <PoliticaCobrancaForm onSubmit={onFormSubmit} />
      </Box>
    </Container>
  );
}

export default CreatePoliticaCobranca;
