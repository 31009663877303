import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  allLiveTransactions: {
    offset: 0,
    pagesize: 20,
    records: [],
    count: 0,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_LIVE_TRANSACTIONS:
      return {
        ...state,
        loading: false,
        allLiveTransactions: action.payload?.allLiveTransactions ?? { ...InitialState.allLiveTransactions },
      };
    case ActionTypes.SHOW_ZERO_STATE:
      return {
        ...state,
        loading: false,
        zeroState: action.payload?.zeroState ?? false,
      };
    case ActionTypes.GET_FILE_DOWNLOAD:
      return {
        ...state,
        loading: false,
        blob: action.payload.blob,
      };
    default:
      return { ...state };
  }
};
