import { IPoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  politicasCobrancas: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  isDetailsModalOpen: false,
  politicaCobranca: {} as IPoliticaCobrancaDTO,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_POLITICAS_COBRANCAS:
      return {
        ...state,
        loading: false,
        politicasCobrancas: action.payload?.politicasCobrancas ?? { ...InitialState.politicasCobrancas },
      }
    case ActionTypes.OPEN_POLITICA_COBRANCA_MODAL:
      return {
        ...state,
        isDetailsModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.UPDATE_POLITICA_COBRANCA_MODAL:
      return {
        ...state,
        politicaCobranca: action.payload?.politicaCobranca ?? { ...InitialState.politicaCobranca },
      };
    default:
      return { ...state };
  }
};
