import { IFilter } from "../../types";
import React from "react";
import { Container } from "../../styles";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { Row } from "antd";
import { Field, Formik, Form } from "formik";
import {FilterContainer, FilterRow, Label, Button, Col} from "./styles";
import { DateView } from "../../../arranjos/style";
import Select from "antd/lib/select";

export const FormFilter: React.FC<IFilter> = (props: IFilter) => {
  const { getFilters } = props;
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const filterTerminalsPhoebus = async (submit: any) => {
    try {
      getFilters(submit); // Envia os dados para o componente pai
    } catch (error) {
      console.error("Erro ao enviar os filtros:", error);
    }
    return submit;
  };

  return (
    <Container style={{ padding: "0px" }}>
      <LoadingOverlay show={isLoading} relative />

      <Row>
        <Label>Filtrar por:</Label>
      </Row>

      <Formik
        initialValues={{
          terminal: "",
          merchant: "",
          serial_number: "",
          associated_terminal: "",
          status: "",
        }}
        onSubmit={ async (values: any) => {
          await filterTerminalsPhoebus(values); // Envia os filtros ao clicar no botão "Consultar"
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Row gutter={16}>
              <Col>
                <Field
                  type="text"
                  name="terminal"
                  placeholder="Num. Lógico do Terminal"
                />
              </Col>
              <Col>
                <Field
                  type="text"
                  name="merchant"
                  placeholder="Cód. Lojista"
                />
              </Col>

              <Col>
                <Field
                  type="text"
                  name="serial_number"
                  placeholder="Serial number"
                />
              </Col>

              <Col>
                <Select
                  value={values.associated_terminal}
                  style={{ width: "100%", marginRight: "10px" }}
                  onChange={(value) => setFieldValue("associated_terminal", value)}
                  options={[
                    { value: "", label: "Associação" },
                    { value: true, label: "Associado" },
                    { value: false, label: "Não Associados" },
                  ]}
                />
              </Col>

              <Col>
                <Select
                  value={values.status}
                  style={{ width: "100%", paddingRight: "10px" }}
                  onChange={(value) => setFieldValue("status", value)}
                  options={[
                    { value: "", label: "Situação do Terminal" },
                    { value: 1, label: "Instalado" },
                    { value: 2, label: "Bloqueado" },
                    { value: 3, label: "A ser instalado" },
                    { value: 4, label: "A ser bloqueado" },
                    { value: 5, label: "A ser desbloqueado" },
                    { value: 6, label: "A ser desinstalado" },
                    { value: 7, label: "Descredenciado" },
                  ]}
                />
              </Col>

              <Col>
                <Button
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    width: "150px",
                    backgroundColor: "#fbb651",
                  }}
                  htmlType="submit" // O clique no botão chama o onSubmit do Formik
                >
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
