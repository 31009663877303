import React from 'react';
import { withFormik, Field } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import SelectField from '../../../../../components/Select';
import { ISelectValue } from '../../../../../global/types';
import { IFilterDetalheRepasse, IFilterDetalheRepasseProps } from './types';
import { IAsyncDropdownOptions } from '../../../../../global/dataTransferObjects/dropdown';

import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
  AlignCheckbox,
  CheckboxTitle,
  CheckboxContainer,
} from './styles';
import AsyncFormDropdown from '../../../../../components/Forms/AsyncFormDropdown';

const FormFilter: React.FC<IFilterDetalheRepasse> = (props: any) => {
  const { handleSubmit, resetForm, onLoadEmpresas } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }

  const handleLoadBusinessOptions = async (filter: any, loadOption: any): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadEmpresas(filter, loadOption);
    return ({
      options,
      hasMore: false,
    });
  };

  const optionsProduct: ISelectValue[] = [
    { label: 'Débito', value: '1' },
    { label: 'Crédito', value: '2' },
  ]
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={3}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="De"
              />
            </DateView>
          </Col>
          <Col span={3}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Até"
              />
            </DateView>
          </Col>
          <Col span={11}>
            <AsyncFormDropdown
              name="businessName"
              label="Empresa"
              placeholder="Filtre pelo nome da empresa..."
              loadOptions={handleLoadBusinessOptions}
            />
          </Col>
          <Col span={4}>
            <SelectField
              name="productType"
              label="Tipo"
              placeholder="Tipo de Pagamento"
              options={optionsProduct}
            />
          </Col>
          <CheckboxContainer>
            <AlignCheckbox>
              <Field
                name="onlyPayed"
                type="checkbox"
              />
              <CheckboxTitle>Somente pagos</CheckboxTitle>
            </AlignCheckbox>
            <AlignCheckbox>
              <Field
                name="onlyNotPayed"
                type="checkbox"
              />
              <CheckboxTitle>Somente não pagos</CheckboxTitle>
            </AlignCheckbox>
          </CheckboxContainer>
        </Row>
        <Row>
          <Col span={6}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
          <Col span={2} />
          <Col span={6}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterDetalheRepasse, IFilterDetalheRepasseProps>({
  mapPropsToValues: (): IFilterDetalheRepasseProps => ({
    businessName: null,
    startDate: '',
    finishDate: '',
    productType: null,
    onlyPayed: false,
    onlyNotPayed: false,
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const businessId = values.businessName?.value;
    const productType = values.productType?.value;
    const formattedValues = {
      ...values,
      businessId,
      productType,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
