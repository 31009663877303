import React from 'react';
import { Card, Result } from 'antd';
import * as S from './styles';

const ZeroState: React.FC = () => (
  <S.ZeroWrapper>
    <Card style={{ width: 500 }}>
      <Result
        status="error"
        title="No momento não podemos exibir o conteúdo dessa página, tente novamente mais tarde!"
      />
    </Card>
  </S.ZeroWrapper>
)

export default ZeroState;
