import { UserDto } from '../../../global/dataTransferObjects/user';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loadingModal: boolean;
  loading: boolean;
  users: IPagedResult<UserDto>;
  user: UserDto;
  isViewUpdateUserModalOpen: boolean;
  isViewUserModalOpen: boolean;
  isViewCreateUserModalOpen: boolean,
}

export enum ActionTypes {
  LOADING_STATUS_PAGE = 'LOADING_STATUS_PAGE',
  LOADING_STATUS_MODAL = 'LOADING_STATUS_MODAL',
  UPDATE_USER_MODAL_STATUS = 'UPDATE_USER_MODAL_STATUS',
  UPDATE_USER_MODAL = 'UPDATE_USER_MODAL',
  VIEW_USER_MODAL_STATUS = 'VIEW_USER_MODAL_STATUS',
  OPEN_CREATE_USER_MODAL = 'OPEN_CREATE_USER_MODAL',
  CREATE_NEW_USER = 'CREATE_NEW_USER',
  GET_ALL_USERS = 'GET_ALL_USERS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
