/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { INotaPromissoriaFilterProps, IAllNotas } from '../../../global/dataTransferObjects/notaPromissoria';
import { IPagedResult } from '../../../global/generics';
import createNotaService from '../../../services/notasPromissoria';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllNotas = (notaPromissoria: IPagedResult<IAllNotas>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_NOTAS,
  payload: {
    notaPromissoria: { ...notaPromissoria },
  },
});

export const onOpenNotasModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_NOTA_MODAL,
});

export const onUpdateNotasModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_NOTA_MODAL_STATUS,
  payload: { status },
});

export const getAllNotas = (
  offSet: number,
  pageSize: number,
  filter?: INotaPromissoriaFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createNotaService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.getAllNotasPromissorias(offSet, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllNotas(result.response)(dispatch);
};
