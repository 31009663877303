import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { darken } from 'polished';
import { Span } from './_layouts/Default';

export const Link = styled(RouterLink)`
  color: ${(props) => props.theme.colors.cyanDark};

  &:hover {
    color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
  }
`;

export const Anchor = styled.a`
  color: ${(props) => props.theme.colors.cyanDark};
  word-break: break-word;

  &:hover {
    color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
  }
`;

export const LinkGroup = styled.div`
  margin-top: 2rem;
  text-align: center;
  color: ${(props) => props.theme.colors.grayLight};

  ${Span} {
    margin-right: 0.2rem;
    font-weight: 400;
  }

  ${Link}, ${Anchor} {
    font-weight: 500;
  }
`;
