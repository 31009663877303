import { useSelector } from 'react-redux';
import { IAppState } from '../../store/types';
import { UserDto } from '../../global/dataTransferObjects/user';

const useCurrentUser = (): UserDto => {
  const user = useSelector((state: IAppState) => state.user);
  return { ...user.userInfo };
};

export default useCurrentUser;
