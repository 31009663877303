import * as Yup from 'yup';

export const schema = Yup.object().shape({
  password: Yup.string()
    .required('Este campo é obrigatório')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,30}$/,
      'A senha deve ter no mínimo 6 e no máximo 30 caracteres, pelo menos um número e um caractere especial, exemplo: @$!%*#?&'),
  passwordConfirmation: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
});
