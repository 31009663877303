import { AdiantamentoDto } from '../../../global/dataTransferObjects/adiantamentos';
import { IPagedResult } from '../../../global/generics';

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_ADIANTAMENTOS = 'GET_ALL_ADIANTAMENTOS',
  NOTA_PROMISSORIA_MODAL = 'NOTA_PROMISSORIA_MODAL',
  VIEW_NOTA_PROMISSORIA_MODAL = 'VIEW_NOTA_PROMISSORIA_MODAL',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}

export interface IPageState {
  adiantamentos: IPagedResult<AdiantamentoDto>;
  adiantamento: AdiantamentoDto;
  loading: boolean;
  isNotaPromissoriaModalOpen: boolean,
}
