import { LinkUserAnticipationRequest, UserDto } from '../../global/dataTransferObjects/user';
import { IPagedResult } from '../../global/generics';
import { IFilterListLinkedUsers } from '../../pages/antecipacao/listarUsuarios/components/FormLinkedUsers/types';
import { IUserAnticipationService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const userAnticipationService = (apiProvider : ITecpayApi) : IUserAnticipationService => {
  const submitNewLink = async (body: LinkUserAnticipationRequest): Promise<IApiResponse<any>> => {
    const payload = { ...body }
    const response = apiProvider.createRequest({ url: '/user-anticipation' })
      .withMethod(HttpMethod.POST)
      .withData(payload)
      .sendAsync();
    return response;
  }

  const listLinkedUsers = async (
    offset:number,
    pageSize:number,
    anticipationId:string,
    filter?:IFilterListLinkedUsers,
  ): Promise<IApiResponse<IPagedResult<UserDto>>> => {
    const name = filter?.name || null;
    const document = filter?.document || null;

    const response = apiProvider
      .createRequest<IPagedResult<UserDto>>({ url: '/user-anticipation' })
      .withSearch({
        offset,
        pageSize,
        name,
        document,
        anticipationId,
      })
      .sendAsync();

    return response;
  }

  return ({
    submitNewLink,
    listLinkedUsers,
  });
}

export default userAnticipationService;
