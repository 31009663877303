import {
  IAllRepasses,
  RepasseTotalDTO,
  IDetailsRepasseDto,
  TotalDetalheRepasseDto,
} from '../../../../global/dataTransferObjects/repasses';
import { IPagedRepasseResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  repasses: IPagedRepasseResult<IAllRepasses>;
  totalRepasses: RepasseTotalDTO;
  zeroState: boolean;
}

export interface IPageDetalhesState {
  loading: boolean;
  detalhes: IPagedRepasseResult<IDetailsRepasseDto>;
  totalRepasses: TotalDetalheRepasseDto;
  zeroState: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_REPASSES = 'GET_ALL_REPASSES',
  GET_TOTAL_REPASSES = 'GET_TOTAL_REPASSES',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
  GET_ALL_BUSINESS = 'GET_ALL_BUSINESS',
  GET_DETALHES_REPASSES = 'GET_DETALHES_REPASSES',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
