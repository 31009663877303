/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IAllSales, ISalesFilterProps } from '../../../global/dataTransferObjects/vendas';
import { IPagedResult } from '../../../global/generics';
import createSalesService from '../../../services/vendas';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_SALES,
  payload: {
    status,
  },
});

export const onGetAllSales = (sales: IPagedResult<IAllSales>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_SALES,
  payload: {
    sales: { ...sales },
  },
})

export const getAllVendas = (
  offSet: number,
  pageSize: number,
  filter?: ISalesFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const salesService = createSalesService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await salesService.getAllVendas(offSet, pageSize, filter);

  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllSales(result.response)(dispatch);
}
