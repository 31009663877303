import React from 'react';
import { Field } from 'formik';
import FormInput from '../Input';
import { IConsultaFormProps } from './types';
import { InputGroup } from '../../../styles/inputs'
import DatePickerField from '../../DatePicker';
import Select from '../../Select';
import Creatable from '../../SelectCreatable';
import {
  SelectGroup,
  DateView,
  AlignCheckbox,
  CheckboxTitle,
  CheckboxContainer,
} from './styles';
import { bandeirasCartoes } from './list';

const CnpjForm: React.FC<IConsultaFormProps> = (props) => {
  const { values } = props;

  return (
    <>
      <FormInput
        type="text"
        name="nomeIdentificador"
        label="Nome"
        required
      />

      <InputGroup>
        <FormInput
          type="string"
          name="numeroDocumento"
          label="CNPJ"
          mask="99.999.999/9999-99"
          required
        />
      </InputGroup>
      <CheckboxContainer>
        <CheckboxTitle>Todas Credenciadoras:</CheckboxTitle>
        <AlignCheckbox>
          <Field
            name="todasCredenciadoras"
            type="checkbox"
          />
        </AlignCheckbox>
        <Creatable
          name="credenciadora"
          isMulti
          isDisabled={values.todasCredenciadoras}
          placeholder="Digite o código da credenciadora"
        />
      </CheckboxContainer>
      <InputGroup>
        <DateView>
          <DatePickerField
            name="dataInicio"
            placeholder="Data inicio"
          />
        </DateView>
        <DateView>
          <DatePickerField
            name="dataFim"
            placeholder="Data fim"
          />
        </DateView>
      </InputGroup>
      <SelectGroup>
        <Select
          name="listaCodigoArranjoPagamento"
          placeholder="Selecione as bandeiras"
          isMulti
          options={bandeirasCartoes}
        />
      </SelectGroup>

    </>
  );
}

export default CnpjForm;
