import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { ICancellationDto } from '../../../../global/dataTransferObjects/creditCard/cancellation';
import { IPagedResult } from '../../../../global/generics';
import createCancellationService from '../../../../services/creditCard/cancellation';
import { HttpStatus } from '../../../../services/providers/types';
import { IFormValues } from '../types';
import { IFilterCancellationValues } from '../components/FilterForm/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateCancellations = (
  cancellations: IPagedResult<ICancellationDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CANCELLATIONS,
  payload: {
    cancellations: { ...cancellations },
  },
});

export const onUpdateCancellation = (
  cancellation: ICancellationDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CANCELLATION,
  payload: {
    cancellation: { ...cancellation },
  },
});

export const onUpdateModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const getAllCancellations = (
  offset = 0,
  pageSize = 20,
  filter?: IFilterCancellationValues,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const cancellationService = createCancellationService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await cancellationService.getAllCancellations(offset, pageSize, filter);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message);
    return;
  }

  onUpdateCancellations(result.response)(dispatch);
};

export const updateCancellationStatus = (values: IFormValues) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const cancellationService = createCancellationService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await cancellationService.updateCancellationStatus(values.id, values.statusCancelamento?.value);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Houve um erro na atualização do cancelamento!');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  toast.success('Status de cancelamento atualizado com sucesso!');
  return true;
}

export const openCancellationModal = (
  cancellation: ICancellationDto,
) => (dispatch: Dispatch<any>): void => {
  onUpdateCancellation({ ...cancellation })(dispatch);
  onUpdateModalStatus(true)(dispatch);
}
