import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import useCurrentUser from '../../../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';
import { Header } from '../styles';
import { IHeaderMenuProps } from '../types';
import PageMenuPJ from "../../PageWrapperPessoaJuridica/components/PageMenuPJ"

const HeaderMenu: React.FC<IHeaderMenuProps> = (props: IHeaderMenuProps) => {
  const {
    onSignOutButtonClick,
    collapsed,
    toggle,
    onModalStatus,
  } = props;
  const { name } = useCurrentUser();
  return (
    <Header>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <div className='pages'><PageMenuPJ/></div>
      <UserAvatar
        userName={name}
        onSignOutButtonClick={onSignOutButtonClick}
        onModalStatus={onModalStatus}
      />
    </Header>
  );
};

export default HeaderMenu;
