import React from "react";
import {SimulatedTableProps} from "../../types";
import {formatCurrency} from "../../utils";

const SimulatedTable: React.FC<SimulatedTableProps> = (props) => {
const { valoresSimulados} = props;
  return (
    <table>
      <tr style={{backgroundColor: "lightgray"}}>
        <th>PARCELAS</th>
        <th>VALOR DA PARCELA</th>
        <th>VALOR A RECEBER</th>
      </tr>
      {valoresSimulados.map((parcel) => {
        return (
          <div>
            <tr key={parcel.parcela}>
              <td>{parcel.parcela.replace('e','é')}</td>
              <td style={{textAlign: "center"}}> {parcel.valorParcela === '-' ? parcel.valorParcela : formatCurrency(parcel.valorParcela * 100)}</td>
              <td style={{textAlign: "center"}}>{parcel.valor === '-' ? parcel.valor : formatCurrency(parcel.valor * 100)}</td>
            </tr>
          </div>
        )
      })}
    </table>
  )
}

export default SimulatedTable;
