import styled from 'styled-components'
import {
  Row as AntdRow,
  Col as AntdCol,
  Radio as AntdRadio,
} from 'antd';

const AntdRadioGroup = AntdRadio.Group;

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
})``;

export const Radio = styled(AntdRadio)``;

export const RadioGroup = styled(AntdRadioGroup)`
    margin-bottom: 0.8rem;
    text-align: center;
    width: 100%;
`;
