import { IRepasseService } from '../@types/services';
import { createApi } from '../providers/factories';
import repasseService from './repasses';

const createRepasseService = (): IRepasseService => {
  const apiProvider = createApi();
  return repasseService(apiProvider);
};

export default createRepasseService;
