import React, {useEffect, useState} from "react";
import {Button, Container} from "./styles";
import {T2} from "../../../styles/titles";
import LiveTable from "./components/liveTable";
import {FormFilter} from "./components/filter";
import {FormValues} from "./types";
import DetalhesEdiServices from "./services/detalhesEdiService";
import {Totals} from "./components/totals";

const DetalhesEdi: React.FC = () => {
  const [filterData, setFilterData] = useState<any>(null);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const detalhesEdiService = new DetalhesEdiServices();


  const handleDownload = async (filter?: FormValues) => {
    const response = await detalhesEdiService.GetAdiqEdiFileDownload(page, pageSize, filter);

    if (response) {
      const objectUrl = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = 'Edi_Adiq.xlsx'; // Nome do arquivo
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    }
  };

  return (
    <Container>
      <T2>EDI Adiq</T2>
      <Totals dataFilter={filterData}></Totals>
      <FormFilter getFilters={(data => setFilterData(data))}/>
      <Button onClick={() => { handleDownload(filterData)}} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download</Button>
      <LiveTable dataFilter={filterData}/>
    </Container>
  )
}

export default DetalhesEdi;
