import React from 'react';
import {Dropdown} from '../styles';
import {Space, Avatar} from 'antd';
import PageOverlayParceiro from "./PageOverlayParceiro";
import {DownCircleOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
const PageMenuParceiro: React.FC = () => {
  return (
    <Dropdown
      overlay={(
        <PageOverlayParceiro
        />
      )}
    >
      <Space>
        <MenuUnfoldOutlined style={{color:'black', fontSize:'20px'}}/>
      </Space>
    </Dropdown>
  );
};

export default PageMenuParceiro;
