import React from 'react';
import { Row, Col } from 'antd';
import { withFormik } from 'formik';
import { ISelectValue } from '../../../../global/types';
import SelectField from '../../../../components/Select';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import FormInput from '../../../../components/Forms/Input'
import DatePickerField from '../../../../components/DatePicker';
import { IFilterProps, ISalesFilterProps } from '../../types';
import schema from './schema';

import { NossaCidadeAntecipacaoStatus } from '../../../../global/enum';

const options: ISelectValue[] = [
  { label: 'Nao Solicitado', value: NossaCidadeAntecipacaoStatus.NaoSolicitado },
  { label: 'Aprovado', value: NossaCidadeAntecipacaoStatus.Aprovado },
  { label: 'Solicitado', value: NossaCidadeAntecipacaoStatus.Solicitado },
  { label: 'Reprovado', value: NossaCidadeAntecipacaoStatus.Reprovado },
]

const FilterForm: React.FC<IFilterProps> = (props: any) => {
  const { handleSubmit, resetForm } = props;

  const resetVendasForm = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="text"
              label="Nome do Recebedor"
              name="nomeRecebedor"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              label="Nome do Pagador"
              name="nomePagador"
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                placeholder="Data inicio"
                label="Data Início"
                name="start"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                placeholder="Data fim"
                label="Data fim"
                name="finish"
              />
            </DateView>
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6}>
            <SelectField
              placeholder="selecione um status... "
              label="Status da Antecipação"
              options={options}
              name="status"
            />
          </Col>
          <Col span={5} />
          <Col span={5} />
          <Col span={4}>
            <ResetButton
              onClick={() => resetVendasForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={4}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default withFormik<IFilterProps, ISalesFilterProps>({
  mapPropsToValues: (): ISalesFilterProps => ({
    nomePagador: '',
    nomeRecebedor: '',
    status: null,
    start: '',
    finish: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const status: any = values.status?.value;
    const formattedValues = {
      ...values,
      status,
    };
    props.onFormSubmit(formattedValues);
  },
})(FilterForm);
