import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { CreateBusinessDto } from '../../../../global/dataTransferObjects/business';
import createBusinessService from '../../../../services/business';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onSubmitBusiness = () => (dispatch : Dispatch<any>): void => dispatch({
  type: ActionTypes.SUBMIT_BUSINESS,
  payload: {},
})

export const submitNewBusiness = (
  body: CreateBusinessDto,
) => async (dispatch : Dispatch<any>) : Promise<void> => {
  const businessService = createBusinessService();

  onUpdateLoadingStatus(true)(dispatch)

  const result = await businessService.newBusiness(body);

  if (result.status === 200) {
    toast.success('Empresa cadastrada com sucesso!');
    onUpdateLoadingStatus(false)(dispatch);
  } else {
    toast.error(result.message);
    onUpdateLoadingStatus(false)(dispatch);
  }
}
