import React, { useReducer, useState } from 'react'
import LoadingOverlay from '../../components/LoadingOverlay';
import Modal from '../../components/Modal';
import { T2 } from '../../styles/titles';
import { checkExistence, submitNewLink } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import Form from './components/Form';
import { Button } from './components/Form/style';
import { IFormValues } from './components/Form/types';
import { Container } from './style';

const LinkBusiness: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('')
  const [businessId, setBusinessId] = useState<number>(0)

  const handleFilterForm = async (values: IFormValues) => {
    setUserId(values.userId?.value)
    setBusinessId(parseInt(values.businessId?.value, 10))

    await checkExistence(parseInt(values.businessId?.value, 10))(dispatch);
    if (state.existenceLink) {
      setModalVisible(true);
    } else {
      submitNewLink(values.userId?.value, parseInt(values.businessId?.value, 10))(dispatch);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  }

  const overWriteLink = () => {
    submitNewLink(userId, businessId)(dispatch);
    closeModal();
  }

  return (
    <Container>
      <Modal
        title="Vincular Usuário"
        centered
        visible={modalVisible}
        onCancel={closeModal}
        width={400}
      >
        Está empresa já tem um usuário vinculado, deseja sobreescrever?
        <Button onClick={overWriteLink}> Sim </Button>
        <Button onClick={closeModal}> Cancelar </Button>
      </Modal>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Vincular Usuário a Empresa</T2>
      <Form onSubmit={handleFilterForm} />
    </Container>
  )
}

export default LinkBusiness;
