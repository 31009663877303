import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IArranjoDto } from '../../../global/dataTransferObjects/arranjos';
import { IPagedResult } from '../../../global/generics';
import { IAction } from '../../../global/types';
import createArranjoService from '../../../services/arranjos';
import { IFilterArranjosProps } from '../components/FormFilter/types';
import { ActionTypes, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  arranjos: {
    count: 0,
    offset: 0,
    pageSize: 20,
    records: [],
  },
};

export const onUpdateArranjos = (arranjos : IPagedResult<IArranjoDto>) => (dispatch : Dispatch<any>) : void => dispatch({
  type: ActionTypes.UPDATE_ARRANJOS,
  payload: {
    arranjos: { ...arranjos },
  },
});

export const getArranjos = (
  pageSize : number,
  offset : number,
  filter? : IFilterArranjosProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const arranjoService = createArranjoService();
  const result = await arranjoService.getArranjos(offset, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
  }
  console.log('result api: ', result.response);

  onUpdateArranjos(result.response)(dispatch);
}
export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_ARRANJOS:
      return {
        ...state,
        loading: action.payload.status ?? false,
        arranjos: action.payload?.arranjos ?? { ...InitialState.arranjos },
      };
    default:
      return { ...state };
  }
};
