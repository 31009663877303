import styled from 'styled-components';
import { Button as StyledButton } from '../../../../styles/buttons';

export const Container = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.white};
`;

export const Separator = styled.div``;

export const Button = styled(StyledButton)`
 width: unset;
 margin-top: 0;
`;

export const ButtonGroup = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${ButtonGroup} {
    align-self: flex-end;
  }
  `;

export const Warning = styled.span`
  color: ${(props) => props.theme.colors.redDanger}
`;
