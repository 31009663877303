import { Col, Row } from 'antd'
import { Field, withFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import SelectField, { OptionType } from '../../../../../components/Select'
import { ISelectValue } from '../../../../../global/types'
import FormInput from '../../../../../components/Forms/Input';
import { FormTextArea } from '../../../../../components/Forms/TextArea';
import {
  AlignCheckbox,
  Button,
  CheckboxContainer,
  CheckboxTitle,
  Container,
  DateView,
  BusinessForm,
} from './style'
import { FormProps, IFormProps, IFormValues } from './types'
import DatePickerField from '../../../../../components/DatePicker'
import createBusinessService from '../../../../../services/business'
import createPoliticaCobrancaService from '../../../../../services/politicaCobranca'
import validationSchema from './validationSchema'

const NewBusinessForm = (props : FormProps) => {
  const { setFieldValue, handleSubmit } = props;
  const [phoneMask] = useState<string>('(99) 9999-99999');
  const [matrizList, setMatrizList] = useState<ISelectValue[]>([]);
  const [billingPolicyList, setBillingPolicyList] = useState<ISelectValue[]>([]);
  const [categoriesList, setCategoriesList] = useState<ISelectValue[]>([]);
  const [hierarchieList, setHierarchieList] = useState<ISelectValue[]>([]);
  const [anticipationPoliciesList, setAnticipationPoliciesList] = useState<ISelectValue[]>([]);
  const [userType, setUserType] = useState<OptionType | null>({ label: 'Pessoa Juridica', value: '1' });
  const options: ISelectValue[] = [
    { label: 'Pessoa Juridica', value: '1' },
    { label: 'Pessoa Fisica', value: '2' },
  ];

  useEffect(() => {
    const getBusinessList = async () => {
      const businessService = createBusinessService();
      const list = await businessService.getEmpresasList(0, 100, undefined);
      const newList = list.response.records.map((item) => ({ label: item.name, value: item.id }))

      const categories = await businessService.getCategoriesList();
      const newCategories = categories.response.map((item) => ({ label: item.name, value: item.id }))

      const hierarchie = await businessService.getHierarchiesList();
      const newHierarchies = hierarchie.response.map((item) => ({ label: item.levelNome1, value: item.responsavelId1 }));

      const policies = await businessService.getAnticipationPoliciesList();
      const newPolicies = policies.response.map((item) => ({ label: item.name, value: item.id }));

      setMatrizList(newList);
      setCategoriesList(newCategories);
      setHierarchieList(newHierarchies);
      setAnticipationPoliciesList(newPolicies)
    }

    const getBillingPolicyList = async () => {
      const billingPolicyService = createPoliticaCobrancaService();
      const list = await billingPolicyService.getPoliticasCobrancas(0, 100, {});
      const newList = list.response.records.map((item) => ({ label: item.name, value: item.id }))
      setBillingPolicyList(newList);
    }

    getBusinessList();
    getBillingPolicyList();
  }, [])
  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  return (
    <Container>
      <BusinessForm onSubmit={handleSubmit}>
        <Row gutter={18} align="top" justify="center">
          <Col span={2}>
            <CheckboxContainer>
              <AlignCheckbox>
                <Field
                  name="active"
                  type="checkbox"
                />
                <CheckboxTitle>Ativo</CheckboxTitle>
              </AlignCheckbox>
            </CheckboxContainer>
          </Col>
          <Col span={4}>
            <CheckboxContainer>
              <AlignCheckbox>
                <Field
                  name="isAnticipationBusiness"
                  type="checkbox"
                />
                <CheckboxTitle>É uma antecipadora</CheckboxTitle>
              </AlignCheckbox>
            </CheckboxContainer>
          </Col>
          <Col span={4}>
            <CheckboxContainer>
              <AlignCheckbox>
                <Field
                  name="anticipationIncluded"
                  type="checkbox"
                />
                <CheckboxTitle>Antecipação Automática</CheckboxTitle>
              </AlignCheckbox>
            </CheckboxContainer>
          </Col>
        </Row>
        <Row gutter={18} align="top" justify="center">
          <Col span={12}>
            <SelectField
              name="anticipationPolicyId"
              label="Política de Antecipação (Quando é antecipadora)"
              options={anticipationPoliciesList}
            />
            <SelectField
              name="type"
              placeholder=""
              label="Tipo (PJ/PF)"
              options={options}
              onChange={(value) => value && 'value' in value && setUserType(value)}
            />
            <FormInput
              type="text"
              name="name"
              label="Razão Social/ Nome"
              placeholder="Razão Social (PJ) ou Nome (PF)"
            />
            <FormInput
              type="text"
              name="nickname"
              label="Nome Fantasia/ Apelido"
              placeholder="Nome Fantasia (PJ) ou Apelido (PF)"
            />
            <FormInput
              type="text"
              name="businessActivity"
              label="Atividade da empresa"
              placeholder="Atividade econômica da empresa"
            />
            <FormInput
              type="text"
              name="mcc"
              label="MCC"
              placeholder="MCC"
            />
            <SelectField
              name="categoriaCode"
              label="Categoria"
              options={categoriesList}
              placeholder="Categoria"
            />
            <DateView>
              <DatePickerField
                name="dataCadastro"
                label="Data de Cadastro"
                placeholder="Data de Cadastro"
              />
            </DateView>
            <FormInput
              type="text"
              name="contact"
              label="Contato"
              placeholder="Nome do contato"
            />
            <DateView>
              <DatePickerField
                name="foundationBirthDate"
                placeholder="Data da Fundação/ Nascimento"
                label="Data da Fundação/ Nascimento"
              />
            </DateView>
            <FormTextArea
              name="comments"
              label="Descrição"
              required
              rows={6}
              placeholder="Descrição"
            />
            <SelectField
              name="commercialHierId"
              label="Hierarquia Comercial"
              options={hierarchieList}
            />
            <SelectField
              name="billingPolicyId"
              label="Politica de cobrança"
              options={billingPolicyList}
              placeholder="Politica de cobrança"
            />
            <FormInput
              type="text"
              name="slip"
              label="Slip"
              placeholder="Slip"
            />
            <FormInput
              type="text"
              name="monthlySalesForecast"
              label="Previsão de faturamento mensal"
              required
              placeholder="0,00"
              prefix="R$"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'monthlySalesForecast')}
            />
            <FormInput
              type="text"
              name="email"
              label="Email"
              placeholder="contato@empresa.com.br"
            />
            <FormInput
              type="text"
              name="website"
              label="Website"
              placeholder="Website"
            />
            {userType?.value === '1'
                  && (
                    <FormInput
                      type="text"
                      name="cnpj"
                      label="CNPJ"
                      mask="99.999.999/9999-99"
                      placeholder="CNPJ"
                    />
                  )}
            {userType?.value === '2'
                && (
                  <FormInput
                    type="text"
                    name="cpf"
                    label="CPF"
                    mask="999.999.999-99"
                    placeholder="CPF"
                  />
                )}
            <FormInput
              type="text"
              name="ie"
              label="IE"
              placeholder="IE"
            />
            <SelectField
              name="matriz"
              label="Matriz"
              options={matrizList}
              placeholder="Matriz"
            />
          </Col>
          <Col span={12}>
            <FormInput
              type="text"
              name="telephone1"
              mask={phoneMask}
              label="Telefone Comercial"
              placeholder="(99) 9999-9999"
            />
            <FormInput
              type="text"
              name="telephone2"
              mask={phoneMask}
              label="Telefone Celular"
              placeholder="(99) 9999-9999"
            />
            <FormInput
              type="text"
              name="telephone3"
              mask={phoneMask}
              label="Telefone Residencial"
              placeholder="(99) 9999-9999"
            />
            <FormInput
              type="text"
              name="telephone4"
              mask={phoneMask}
              label="Telefone Adicional"
              placeholder="(99) 9999-9999"
            />
            <FormInput
              type="text"
              name="addressZipCode"
              label="CEP"
              mask="99999-999"
              placeholder="9999-999"
            />
            <FormInput
              type="text"
              name="addressDescription"
              label="Endereço"
              placeholder="Endereço"
            />
            <FormInput
              type="text"
              name="addressNumber"
              label="Número"
              placeholder="9999"
            />
            <FormInput
              type="text"
              name="addressComplement"
              label="Complemento"
              placeholder="Apto 123"
            />
            <FormInput
              type="text"
              name="addressNeighborhood"
              label="Bairro"
              placeholder="Bairro"
            />
            <FormInput
              type="text"
              name="addressCity"
              label="Cidade"
              placeholder="Cidade"
            />
            <FormInput
              type="text"
              name="addressState"
              label="Estado"
              placeholder="Estado"
            />
            <FormInput
              type="text"
              name="accountableName"
              label="Sócio administrador / Responsável"
              placeholder="Sócio administrador (PJ) ou Responsável (PF)"
            />
            <FormInput
              type="text"
              name="accountableEmail"
              label="Email do sócio administrador / Responsável"
              placeholder="responsavel@empresa.com.br"
            />
            <FormInput
              type="text"
              name="accountableTelephone"
              label="Telefone do sócio administrador / Responsável"
              placeholder="(99) 99999-9999"
            />
            <FormInput
              type="text"
              name="nameOnBankStatementLine"
              label="Nome nas linhas do extrato bancário"
              placeholder=""
            />
            <FormInput
              type="text"
              name="cieloMerchantId"
              label="CieloMerchantID"
              placeholder=""
            />
            <FormInput
              type="text"
              name="cieloMerchantKey"
              label="CieloMerchantKey"
              placeholder=""
            />
            <FormInput
              type="text"
              name="fluxo005"
              label="Fluxo005"
              placeholder="Não Registrado"
            />
          </Col>
          <Button htmlType="submit">
            Salvar
          </Button>
        </Row>
      </BusinessForm>
    </Container>
  )
}

export default withFormik<IFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    active: true,
    type: null,
    name: '',
    nickname: '',
    contact: '',
    cnpj: '',
    cpf: '',
    ie: '',
    telephone1: '',
    telephone2: '',
    telephone3: '',
    telephone4: '',
    addressZipCode: '',
    addressDescription: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    email: '',
    website: '',
    isAnticipationBusiness: false,
    billingPolicyId: null,
    anticipationPolicyId: null,
    businessActivity: '',
    mcc: '',
    categoriaCode: null,
    dataCadastro: '',
    foundationBirthDate: '',
    anticipationIncluded: false,
    comments: '',
    commercialHierId: null,
    slip: '',
    monthlySalesForecast: 0,
    accountableName: '',
    accountableEmail: '',
    accountableTelephone: '',
    nameOnBankStatementLine: '',
    cieloMerchantId: '',
    cieloMerchantKey: '',
    fluxo005: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => {
    props.onSubmit({ ...values })
  },
})(NewBusinessForm);
