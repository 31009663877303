import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
// Components
import FormInput from '../../../../components/Forms/Input';
import SelectField from '../../../../components/Select';

import { ISelectValue } from '../../../../global/types';
import schema from './schema';
import { IFilterBoletos, IFilterBoletosProps } from './types';
import {
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const options: ISelectValue[] = [
  { label: 'Em aberto', value: 'Em aberto' },
  { label: 'Em Cartorio', value: 2 },
  { label: 'Em Float', value: 'Em Float' },
  { label: 'Pago', value: 'Pago' },
  { label: 'Baixado', value: 'Baixado' },
  { label: 'Protesto', value: 'Baixado' },
]

const FormFilter: React.FC<IFilterBoletos> = (props: any) => {
  const { handleSubmit, resetForm } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="string"
              name="nossoNumero"
              label="Nosso Número"
              placeholder="Filtrar pelo Nosso Número"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="email"
              label="Email"
              placeholder="Filtrar pelo Email"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="vlrNominal"
              label="Valor Nominal"
              placeholder="Filtrar pelo valor nominal"
            />
          </Col>
          <Col span={6}>
            <SelectField
              name="status"
              placeholder="Selecione a situação do boleto... "
              label="Situação"
              options={options}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="string"
              name="sacado"
              label="Sacado"
              placeholder="Filtrar pelo sacado"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="sacador"
              label="Sacador"
              placeholder="Filtrar pelo sacador"
            />
          </Col>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6} />
          <Col span={6} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterBoletos, IFilterBoletosProps>({
  mapPropsToValues: (): IFilterBoletosProps => ({
    nossoNumero: 0,
    status: null,
    email: '',
    vlrNominal: 0,
    sacado: '',
    sacador: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const status: any = values?.status?.value
    const formattedValues = {
      ...values,
      status,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
