import React from 'react';
import FormInput from '../Input';
import AsyncFormDropdown from '../AsyncFormDropdown';
import { IVeiculoFormProps } from './types';
import { IAsyncDropdownOptions } from '../../../global/dataTransferObjects/dropdown';
import { InputGroup } from '../../../styles/inputs'

const VeiculoForm: React.FC<IVeiculoFormProps> = (props: IVeiculoFormProps) => {
  const { onLoadUfOptions } = props;

  const handleLoadUfOptions = async (): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadUfOptions();
    return ({
      options,
      hasMore: false,
    });
  };

  return (
    <>
      <FormInput
        type="text"
        name="apelido"
        label="Apelido"
        required
      />
      <InputGroup>
        <FormInput
          type="text"
          name="renavam"
          label="Renavam do veículo"
          required
          mask="99999999999"
        />
        <FormInput
          type="text"
          name="placa"
          label="Placa do veículo"
          required
          mask="aaa-9*99"
        />
      </InputGroup>
      <AsyncFormDropdown
        required
        name="uf"
        label="Estado"
        placeholder="Selecione o estado do veículo..."
        loadOptions={handleLoadUfOptions}
      />
    </>
  )
};

export default VeiculoForm;
