import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isNotaPromissoriaModalOpen: false,
  adiantamentos: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  adiantamento: {
    folhaAno: 0,
    folhaMes: 0,
    totalProventos: 0,
    totalDescontos: 0,
    totalLiquido: 0,
    margem: 0,
    numeroControle: '',
    notaPromissoria: {
      numeroControle: '',
      status: 0,
      valorSolicitacao: 0,
    },
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_ADIANTAMENTOS:
      return {
        ...state,
        loading: false,
        adiantamentos: action.payload?.adiantamentos ?? { ...InitialState.adiantamentos },
      };

    case ActionTypes.NOTA_PROMISSORIA_MODAL:
      return {
        ...state,
        isNotaPromissoriaModalOpen: action.payload.status ?? false,
      }
    case ActionTypes.VIEW_NOTA_PROMISSORIA_MODAL:
      return {
        ...state,
        adiantamento: action.payload?.adiantamento ?? { ...InitialState.adiantamento },
      }
    default:
      return { ...state };
  }
};
