import { connect } from 'react-redux';
import ResetPassword from './resetPassword';
import { resetPassword, validateResetToken } from '../../store/user/actions';
import { IAppState } from '../../store/types';

const mapStateToProps = (state: IAppState) => ({
  isLoading: state.user.loading,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = {
  resetPassword,
  validateResetToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
