export interface IPageState {
  loading: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  SUBMIT_BUSINESS = 'SUBMIT_BUSINESS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
