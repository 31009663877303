export const formatAcquirer = (value: number): string => {
  let result: string = '';
  switch (value) {
    case 3:
      result = "Adiq";
      break;
    case 10:
      result = "Rede";
      break;
    default:
      result = "";
  }

  return result;
}

export function formatDate(dateString?: string): string {
  if (!dateString) {
    return ''; // Retorna vazio se a string estiver indefinida ou vazia
  }

  // Converte a string de data para um objeto Date
  const date = new Date(dateString);

  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    return ''; // Retorna vazio se a data for inválida
  }

  // Extrai os componentes da data
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é baseado em 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Formata a data como DD/MM/AAAA - HH:MM:SS
  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

export const formatModel = (value: string): string => {
  if (value === "L3") {
    value = "L300";
  }

  return value;
}
