export const formatCurrency = (value: any) => {
  if (!value) return '';

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  return formatter.format(value / 100);
};

export const checkValor = (valor: string): boolean =>  {
  const valorInt = formatCurrencyIntoInt(valor);
  return valorInt > 0;
};

export const checkBandeira = (bandeira: number): boolean => {
  return bandeira !== 0;
};

export const formatCurrencyIntoInt = (valor: string): number => {
  const valorInt = parseInt(valor.substr(3, valor.length - 3)
    .replaceAll('.', '')
    .replaceAll(',', '.')) ;

  return valorInt;
}
