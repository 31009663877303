import {Doughnut} from "react-chartjs-2";
import React from "react";
import {DoughnutChartProps} from "./types";
import {PieChartProps} from "../PieChart/types";

const DoughnutChart: React.FC<PieChartProps> = (props: DoughnutChartProps) => {
  const {chartName, chartData, chartOptions} = props;
  return (
    <div className={"chart-container"}>
      <h2 style={{textAlign: "center"}}>{chartName}</h2>
      <Doughnut
        data={chartData}
        options={chartOptions}/>
    </div>
  );
};
export default DoughnutChart;
