import {getAxiosInstance} from "../../../../services/providers/api";
import {FormValues} from "../types";

export default class GerenciadorEdiService {

  updateEdi = async(filter: FormValues) => {
    const referenceDate = filter?.referenceDate;
    return getAxiosInstance().get(`/adiq-edi/force?dataReferencia=${referenceDate}`)
  }

  downloadEdi = async (filter: FormValues) => {
    const referenceDate = filter?.referenceDate;
    return getAxiosInstance().get(`/adiq-edi/download?dataReferencia=${referenceDate}`)
  }


}
