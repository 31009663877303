import styled from 'styled-components'
import {
  Modal as AntdModal,
  Row as AntdRow,
  Col as AntdCol,
} from 'antd';
import { Button as StyledButton } from '../../../../styles/buttons';
import SelectField from '../../../Select';

export const Modal = styled(AntdModal)``;

export const Form = styled.form``;

export const Row = styled(AntdRow).attrs({
  align: 'middle',
  justify: 'center',
})``;

export const Col = styled(AntdCol).attrs({
  lg: {
    span: 21,
  },
  span: 24,
})`
 display: flex;
 flex-direction: column;
`;

export const Button = styled(StyledButton)``;

export const StyledSelectField = styled(SelectField)``;

export const SelectFieldContainer = styled.div`
  flex: 1;
  margin-bottom: 0.8rem;
`;
