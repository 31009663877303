import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import Table, { TableColumn } from '../../../../../components/Table';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import {
  getAllBusiness,
} from './actions';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../../../../styles/titles';

const PoliticaCobrancaEmpresa: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const politicaCobrancaId: any = query.get('id');

  const businessItem: any = state.businesses.records.map((item) => ({
    ...item,
    documentNumber: item.cnpj || item.cpf,
  }));

  const formatUserType = (user: number) => {
    if (user === 1) {
      return 'Pessoa Física';
    }
    if (user === 2) {
      return 'Pessoa Jurídica';
    }
    return 'Administrador';
  };

  const fetchAllBusiness = useCallback((
    initial: number = offset,
    size: number = pageSize,
    id: number,
  ) => getAllBusiness(initial, size, id)(dispatch), []);

  const formatDocument = (documentNumber: string) => {
    const documentNumberFormatted = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumberFormatted.length === 11) {
      const cpf = documentNumberFormatted.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
      if (cpf != null) {
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        return cpfFormatted;
      }
    }
    if (documentNumberFormatted.length === 14) {
      const cnpj = documentNumberFormatted.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
      if (cnpj != null) {
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        return cnpjFormatted;
      }
    }
    return documentNumberFormatted;
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllBusiness(pageSize * (currentPage - 1), (currentPage * pageSize), politicaCobrancaId);
    }
    if (currentPage === 1) {
      fetchAllBusiness(0, (currentPage * pageSize), politicaCobrancaId);
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Empresas vinculadas</T2>
      <TableContainer>
        <Table
          dataSource={businessItem}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.businesses.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Business ID" dataIndex="id" key="id" />
          <TableColumn title="Tipo" dataIndex="type" key="type" render={formatUserType} />
          <TableColumn title="CNPJ/CPF" dataIndex="documentNumber" key="documentNumber" render={formatDocument} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Contato" dataIndex="contact" key="contact" />
          <TableColumn title="Telefone" dataIndex="telephone1" key="telephone1" />
          <TableColumn title="Cidade" dataIndex="addressCity" key="addressCity" />
          <TableColumn title="UF" dataIndex="addressState" key="addressState" />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PoliticaCobrancaEmpresa;
