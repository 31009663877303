import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  pixPaymentType: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('O tipo de pagamento é obrigatório')
    .nullable()
    .required('O tipo de pagamento é obrigatório'),
  creditorKey: Yup.string().when('pixPaymentType', {
    is: { value: 1, label: 'PIX com chave' },
    then: Yup.string().required('O campo chave PIX é obrigatório'),
  }),
  amount: Yup.string().when('pixPaymentType', {
    is: { value: 1, label: 'PIX com chave' },
    then: Yup.string().required('O campo valor é obrigatório'),
  }),
  emv: Yup.string().when('pixPaymentType', {
    is: { value: 2, label: 'PIX Copia e Cola' },
    then: Yup.string().required('Este campo é obrigatório'),
  }),
});

export default validationSchema;
