import { IBusinessLinkUser } from '../../global/dataTransferObjects/business';
import { IUserBusinessService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const userBusinessService = (apiProvider : ITecpayApi) : IUserBusinessService => {
  const getByBusinessId = async (businessId : number): Promise<IApiResponse<any>> => {
    const response = apiProvider.createRequest({ url: '/user-business' })
      .withSearch({ businessId })
      .sendAsync();
    return response;
  }

  const submitNewLink = async (UserId : string, BusinessId: number): Promise<IApiResponse<any>> => {
    const body : IBusinessLinkUser = { userId: UserId, businessId: BusinessId }
    const payload = { ...body }
    const response = apiProvider.createRequest({ url: '/user-business' })
      .withMethod(HttpMethod.POST)
      .withData(payload)
      .sendAsync();
    return response;
  }

  return ({
    getByBusinessId,
    submitNewLink,
  });
}

export default userBusinessService;
