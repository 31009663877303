import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isCreateModalOpen: false,
  pagamentos: {
    count: 0,
    offset: 0,
    pageSize: 20,
    records: [],
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CREATE_MODAL_STATUS:
      return {
        ...state,
        isCreateModalOpen: action.payload?.status ?? InitialState.isCreateModalOpen,
      }
    case ActionTypes.UPDATE_PAGAMENTOS:
      return {
        ...state,
        loading: false,
        pagamentos: action.payload?.pagamentos ?? { ...InitialState.pagamentos },
      }
    default:
      return { ...state };
  }
};
