import React, { PropsWithChildren, useState } from 'react';
import { toast } from 'react-toastify';
import SideMenu from './components/SideMenu';
import HeaderMenu from './components/HeaderMenu';
import ChangePasswordModal from './components/ChangePasswordModal/modal';
import { Layout, Content } from './styles';
import { IPageWrapperProps } from './types';
import createUserService from '../../services/user';
import { IChangePassword } from '../../global/dataTransferObjects/user';

const PageWrapper: React.FC<IPageWrapperProps> = (props: PropsWithChildren<IPageWrapperProps>) => {
  const { children, doLogout } = props;
  const onSignOutButtonClick = () => doLogout();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const userService = createUserService();

  const handleViewModalStatus = () => {
    setModal(!modal)
  }

  const handleChangePassword = async (userInfo: IChangePassword) => {
    setLoading(true);
    const result = await userService.changePassword(userInfo)
    if (result.status !== 201) {
      toast.error(result.message);
      setLoading(false);
    }
    if (result.status === 201) {
      toast.success('Senha alterada com sucesso!')
      setLoading(false);
      handleViewModalStatus()
    }
  }

  const handleToggle = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <Layout style={{ height: 'auto', minHeight: '100vh' }}>
      <SideMenu collapsed={collapsed} />
      <Layout>
        <HeaderMenu
          onSignOutButtonClick={onSignOutButtonClick}
          collapsed={collapsed}
          toggle={handleToggle}
          onModalStatus={handleViewModalStatus}
        />
        <Content className="site-layout-background">
          {children}
          <ChangePasswordModal
            isVisible={modal}
            loading={loading}
            onFormSubmit={handleChangePassword}
            onClose={handleViewModalStatus}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageWrapper;
