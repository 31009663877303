import React from 'react';
import { ILoadingOverlayProps } from './types';
import { Loading } from './styles';

const LoadingOverlay: React.FC<ILoadingOverlayProps> = (props: ILoadingOverlayProps) => {
  const { show, relative, tip } = props;
  if (!show) {
    return (<></>);
  }

  return (
    <Loading
      show
      relative={relative}
      tip={tip ?? 'Carregando...'}
    />
  )
};

LoadingOverlay.defaultProps = {
  relative: false,
}

export default LoadingOverlay;
