import { ICelcoinAutorizacaoDTO, ICelcoinReceiptDto } from '../../../../global/dataTransferObjects/boletos';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isCreateModalOpen: false,
  isVerifyModalOpen: false,
  isReceiptModalOpen: false,
  autorizacao: {} as ICelcoinAutorizacaoDTO,
  pagamentos: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  receipt: {} as ICelcoinReceiptDto,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CREATE_MODAL_STATUS:
      return {
        ...state,
        isCreateModalOpen: action.payload?.status ?? InitialState.isCreateModalOpen,
      }
    case ActionTypes.UPDATE_VERIFY_MODAL_STATUS:
      return {
        ...state,
        isVerifyModalOpen: action.payload?.status ?? InitialState.isVerifyModalOpen,
      }
    case ActionTypes.UPDATE_RECEIPT_MODAL_STATUS:
      return {
        ...state,
        isReceiptModalOpen: action.payload?.status ?? InitialState.isReceiptModalOpen,
      }
    case ActionTypes.UPDATE_PAGAMENTOS:
      return {
        ...state,
        loading: false,
        pagamentos: action.payload?.pagamentos ?? { ...InitialState.pagamentos },
      }
    case ActionTypes.UPDATE_AUTORIZACAO:
      return {
        ...state,
        loading: false,
        isVerifyModalOpen: false,
        autorizacao: action.payload?.autorizacao ?? { ...InitialState.autorizacao },
      }
    case ActionTypes.UPDATE_RECEIPT:
      return {
        ...state,
        loading: false,
        isReceiptModalOpen: false,
        receipt: action.payload?.receipt ?? { ...InitialState.receipt },
      }
    default:
      return { ...state };
  }
};
