import React, { useCallback, useEffect, useState } from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../components/Forms/Input';
import validationSchema from './validationSchema';
import DatePickerField from '../../../../components/DatePicker';
import { IFilterUser } from '../../types';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown';
import { AdditionalType } from '../../../../components/Forms/AsyncFormDropdown/types';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';
import createUserService from '../../../../services/user';
import { formatterDocumentNumber } from '../../../../components/Masks';

const FilterForm: React.FC<IFilterUser> = (props: any) => {
  const {
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
  } = props;

  const [pageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const resetUserForm = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  const handleAnticipationList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userService = createUserService();
    const result = await userService.getUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      undefined,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.filter((item) => item.userType === 4).map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);
  useEffect(() => {
    const documentNumber = values.documentNumber?.replace(/[^0-9]/g, '');
    setFieldValue('documentNumber', documentNumber);
    const handleDocumentNumber = () => {
      if (documentNumber.length === 11) {
        const cpf = documentNumber.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        setFieldValue('documentNumber', cpfFormatted);
      }
      if (documentNumber.length === 14) {
        const cnpj = documentNumber.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        setFieldValue('documentNumber', cnpjFormatted);
      }
    }
    handleDocumentNumber();
  }, [values.documentNumber]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="text"
              name="name"
              label="Nome"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="documentNumber"
              label="Documento"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="city"
              label="Cidade"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="email"
              label="Email"
            />
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicial"
                label="Data inicial de cadastro"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data final"
                label="Data final de cadastro"
              />
            </DateView>
          </Col>
          {/*<Col span={6}>*/}
          {/*  <AsyncFormDropdown*/}
          {/*    name="anticipationId"*/}
          {/*    label="Por Antecipadora"*/}
          {/*    loadOptions={handleAnticipationList}*/}
          {/*    placeholder=""*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col span={6} />
          <Col>
            <ResetButton
              onClick={() => resetUserForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<any, any>({
  mapPropsToValues: (): any => ({
    name: '',
    documentNumber: '',
    city: '',
    email: '',
    anticipationId: null,
    startDate: '',
    finishDate: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const documentNumber = values.documentNumber.replace(/[^0-9]/g, '');
    const formattedValues = {
      ...values,
      documentNumber,
    };
    props.onFormSubmit(formattedValues);
  },
})(FilterForm);
