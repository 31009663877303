import styled from 'styled-components';
import {Button as StyledButton, Button2, ResetButton as StyledResetButton} from '../../../../../styles/buttons';
import {Col as AntdCol} from 'antd';

export const DateGroup = styled.div`
    flex-direction: row;
    height: 150px;
    align-items: center;
    justify-content: space-around;
    background-color: red;
    margin-top: 15px;
    margin-bottom: 30px;
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: none;
    margin-top: 2px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

export const Col = styled(AntdCol)`
  width: 12.4rem;
  margin-bottom: 0.8rem;

  input {
    width: 100%;
    height: 2rem;
    padding: 0 11px                                                                           ;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }
  }

  @media (max-width: 768px) {
    min-width: 0em;
  }
`;

export const DateView = styled.div`
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 0.8rem;
  width: 150px;
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }

    &:focus-visible {

    }
  }
  div.DayPickerInput, input {
   width: 100%;
  }

  .DayPickerInput-OverlayWrapper{
  z-index:2000;
  }
  `;

export const Button = styled(Button2)`
  margin-top: -1px;
  border-radius: 20px;

`;

export const ButtonSalvar = styled(Button2)`
  border-radius: 20px;
  @media (max-width: 768px){
      display: none;
    }
`;

export const ResetButton = styled(StyledResetButton)`
  margin-top: 20px;
`;

export const Form = styled.form``;

export const FilterContainer = styled.div`
    display: flex;
    width: 99%;

  @media (max-width: 768px) {
    display: block;
  }

`;

export const FilterRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 99%;
    justify-content: space-between;
  }
`;
export const ValueCard = styled.div`
  display: flex;
  justifyContent: flex-start;
  div {
    text-align: center;
    margin-right: 20px;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    height: 90px;
    width: 145px;
    border: 1px solid rgba(92, 92, 92, 0.22);
    box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);
  }

  @media (max-width: 940px) {
    flex-wrap: wrap;
    height: auto;
    align-content: center;
    justify-content: center;
    width: 100%;

    div {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      margin-right: 4px;
    }
  }
`;
