import React from 'react'
import { Row, Col } from 'antd';
import { Field, withFormik } from 'formik'
import DatePickerField from '../../../../components/DatePicker';
import FormInput from '../../../../components/Forms/Input';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
  Container,
  CheckboxContainer,
  AlignCheckbox,
  CheckboxTitle,
} from './styles';
import { FormProps, IFilterProxyListProps, IFilterFormProps } from './types';

const FormFilter : React.FC<FormProps> = (props: any) => {
  const { handleSubmit, resetForm } = props

  const resetFormTransaction = async () => {
    resetForm();
    await props.onSubmit({} as IFilterProxyListProps);
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder=""
                label="Data de cadastro Inicial"
              />
            </DateView>
          </Col>
          <Col span={8}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder=""
                label="Data de cadastro Final"
              />
            </DateView>
          </Col>
          <Col span={8}>
            <FormInput
              type="text"
              name="userName"
              label="Nome do Usuário"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="text"
              name="document"
              label="Documento do Usuário"
            />
          </Col>
          <Col>
            <CheckboxContainer>
              <AlignCheckbox>
                <Field
                  name="cardStatus"
                  type="checkbox"
                />
                <CheckboxTitle>Cartão Inativo</CheckboxTitle>
              </AlignCheckbox>
            </CheckboxContainer>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} />
          <Col span={6} />
          <Col span={6} />
        </Row>
        <Row gutter={16}>
          <Col span={12} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton onClick={() => resetFormTransaction()}>
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default withFormik<IFilterFormProps, IFilterProxyListProps>({
  mapPropsToValues: (): IFilterProxyListProps => ({
    startDate: '',
    finishDate: '',
    userName: '',
    document: '',
    cardStatus: false,
  }),
  handleSubmit: async (values, { props }) => props.onSubmit({ ...values }),
})(FormFilter);
