import { IAdiantamentoFilterProps, IAllAdiantamentos } from '../../global/dataTransferObjects/adiantamentos'
import { ApiException } from '../../global/exceptions'
import { IPagedResult } from '../../global/generics'
import { IAdiantamentoService } from '../@types/services'
import { IApiResponse, ITecpayApi } from '../providers/types'

const adiantamentoService = (apiProvider: ITecpayApi): IAdiantamentoService => {
  const getAllAdiantamentos = async (
    offset: number,
    pageSize: number,
    filter?: IAdiantamentoFilterProps,
  ): Promise<IApiResponse<IPagedResult<IAllAdiantamentos>>> => {
    const folhaMes = filter?.folhaMes || null;
    const folhaAno = filter?.folhaAno || null;
    const documentNumber = filter?.documentNumber || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllAdiantamentos>>({ url: '/nossa-cidade/emprestimo-consignado' })
      .withSearch({
        offset,
        pageSize,
        folhaMes,
        folhaAno,
        documentNumber,
        startDate,
        finishDate,
      })
      .sendAsync()

    if (response.status !== 200) {
      throw new ApiException(response);
    }
    return response;
  };

  return ({ getAllAdiantamentos })
}

export default adiantamentoService;
