import { IPoliticaContaDigitalDTO } from '../../../../global/dataTransferObjects/politicaContaDigital';
import { UserDto } from '../../../../global/dataTransferObjects/user';

export interface IPageState {
  loading: boolean;
  politicaContaDigital: IPoliticaContaDigitalDTO
  selectedUsers: Array<UserDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_POLITICA_CONTA_DIGITAL = 'UPDATE_POLITICA_CONTA_DIGITAL',
  GET_POLITICA_CONTA_DIGITAL = 'GET_POLITICA_CONTA_DIGITAL',
  GET_POLITICA_CONTA_DIGITAL_USERS = 'GET_POLITICA_CONTA_DIGITAL_USERS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
