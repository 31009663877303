import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isViewBoletoModalOpen: false,
  boleto: {
    id: '',
    businessId: 0,
    nossoNumero: 0,
    vlrNominal: 0,
    dataEmissao: new Date(),
    dataVencimento: new Date(),
    seuNumero: 0,
    cpfCnpj: 0,
    tipoPessoa: 0,
    nome: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: 0,
    email: '',
    tipoPessoaSacador: 0,
    cnpjCpfSacador: 0,
    nomeSacador: '',
    agencia: 0,
    contaCorrente: 0,
    numCarteira: 0,
    codBanco: 0,
    possuiAgenda: '',
    qtdConsultaRegistroCip: 0,
    tituloIncluidoDDa: '',
    numControleLegado: 0,
    nossoNumeroBancos: '',
    codAgenciaCob: 0,
    msgErro: '',
    linhaDigitavel: 0,
    intervaloConsultaRegistroCip: 0,
    indPagadorEletronico: 0,
    dataLegado: new Date(),
    codModalBancos: '',
    codErro: '',
    codColigada: 0,
    codBarras: 0,
    situacao: '',
    boletoGerado: false,
    descricaoErroBoletoGerado: '',
  },
  allBoletos: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_BOLETOS:
      return {
        ...state,
        loading: false,
        allBoletos: action.payload?.allBoletos ?? { ...InitialState.allBoletos },
      };
    case ActionTypes.UPDATE_BOLETO_MODAL:
      return {
        ...state,
        boleto: action.payload?.boleto ?? { ...InitialState.boleto },
      }
    case ActionTypes.UPDATE_BOLETO_MODAL_STATUS:
      return {
        ...state,
        isViewBoletoModalOpen: action.payload.status ?? false,
      };
    default:
      return { ...state };
  }
};
