import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  diaDebitoMensal: Yup.number().nullable()
    .min(1, 'Dia do débito deve ser maior ou igual a 1').max(28, 'Dia do débito deve ser menor ou igual a 28'),
  valorContrato: Yup.string()
    .required('Valor do contrato é obrigatório'),
  valorLiquidado: Yup.string(),
  valorParaDebito: Yup.string(),
  clienteTravadoId: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('O usuário é obrigatório')
    .nullable()
    .required('O usuário é obrigatório'),
  contratoAtivo: Yup.boolean(),
  tipoContrato: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('O tipo de contrato é obrigatório')
    .nullable()
    .required('O tipo de contrato é obrigatório'),
});

export default validationSchema;
