import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../../components/Forms/Input';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
  Container,
} from './styles';
import SelectField from '../../../../../components/Select';
import { ISelectValue } from '../../../../../global/types';
import { FormProps, IFilterChargebackProps, IFilterChargebackValues } from './types';

const pagamentoOptions: ISelectValue[] = [
  { label: 'Em análise', value: 'Em análise' },
  { label: 'Cancelado', value: 'Cancelado' },
  { label: 'Pago', value: 'Pago' },
];

const chargebackOptions: ISelectValue[] = [
  { label: 'Recebido', value: 'Recebido' },
  { label: 'Enviado EC', value: 'Enviado EC' },
  { label: 'Aguardando Prazo', value: 'Aguardando Prazo' },
  { label: 'Documentação Recebida', value: 'Documentação Recebida' },
  { label: 'Documentação Enviada', value: 'Documentação Enviada' },
  { label: 'Em Análise', value: 'Em Análise' },
  { label: 'Descontado', value: 'Descontado' },
  { label: 'Revertido', value: 'Revertido' },
];

const FilterForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
  } = props;
  const resetFormTransaction = async () => {
    resetForm();
    await props.onFormSubmit({} as IFilterChargebackValues);
  }

  useEffect(() => {
    const numeroDocumento = values.numeroDocumento?.replace(/[^0-9]/g, '');
    const nsuParcela = values.nsuParcela?.replace(/[^0-9]/g, '');
    setFieldValue('numeroDocumento', numeroDocumento);
    setFieldValue('nsuParcela', nsuParcela);

    const handleSetMaskedInput = () => {
      if (numeroDocumento.length === 14) {
        const cnpj = numeroDocumento.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        if (cnpj !== null) {
          const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
          setFieldValue('numeroDocumento', cnpjFormatted);
        }
      }

      if (nsuParcela.length === 14) {
        const nsu = nsuParcela.match(/^([0-9]{12})([0-9]{1})([0-9]{1})/);
        if (nsu !== null) {
          const nsuFormatted = `${nsu[1]}-${nsu[2]}/${nsu[3]}`;
          setFieldValue('nsuParcela', nsuFormatted);
        }
      }
    }

    handleSetMaskedInput();
  }, [values.numeroDocumento, values.nsuParcela, values.numeroIdentificadorChargeback]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              type="text"
              name="numeroIdentificadorChargeback"
              label="Identificador"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="adquirente"
              label="Adquirente"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="estabelecimento"
              label="Empresa"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="numeroDocumento"
              label="CNPJ"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              type="text"
              name="nsuParcela"
              label="NSU/Parcela"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="numeroCartao"
              label="Número do cartão"
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dataHoraTransacaoInicio"
                placeholder=""
                label="Data de transação início"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dataHoraTransacaoFim"
                placeholder=""
                label="Data de transação fim"
              />
            </DateView>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <SelectField
              name="statusPagamento"
              placeholder=""
              label="Status pagamento"
              options={pagamentoOptions}
            />
          </Col>
          <Col span={6}>
            <SelectField
              name="statusChargeback"
              placeholder=""
              label="Status chargeback"
              options={chargebackOptions}
            />
          </Col>
          <Col span={6} />
          <Col span={3}>
            <ResetButton onClick={() => resetFormTransaction()}>
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default withFormik<IFilterChargebackProps, IFilterChargebackValues>({
  mapPropsToValues: (): IFilterChargebackValues => ({
    numeroIdentificadorChargeback: '',
    adquirente: '',
    estabelecimento: '',
    numeroDocumento: '',
    nsuParcela: '',
    numeroCartao: '',
    dataHoraTransacaoInicio: '',
    dataHoraTransacaoFim: '',
    statusPagamento: null,
    statusChargeback: null,
  }),
  handleSubmit: async (values, { props }) => {
    const nsuParcela = values.nsuParcela.replace(/[^0-9]/g, '');
    const numeroDocumento = values.numeroDocumento.replace(/[^0-9]/g, '');
    await props.onFormSubmit({
      ...values,
      nsuParcela,
      numeroDocumento,
    });
  },
})(FilterForm);
