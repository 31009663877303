import { ISelectValue } from '../types';

export interface AllBoletosDto {
  id: string,
  businessId: number,
  nossoNumero?: number,
  vlrNominal?: number,
  dataEmissao: Date,
  dataVencimento: Date,
  seuNumero: number,
  cpfCnpj: number,
  tipoPessoa: number,
  nome: string,
  endereco: string,
  bairro: string,
  cidade: string,
  uf: string,
  cep: number,
  email?: string,
  tipoPessoaSacador: number,
  cnpjCpfSacador: number,
  nomeSacador?: string,
  agencia: number,
  contaCorrente: number,
  numCarteira: number,
  codBanco: number,
  possuiAgenda: string,
  qtdConsultaRegistroCip: number,
  tituloIncluidoDDa: string,
  numControleLegado: number,
  nossoNumeroBancos: string,
  codAgenciaCob: number,
  msgErro: string,
  linhaDigitavel: number,
  intervaloConsultaRegistroCip: number,
  indPagadorEletronico: number,
  dataLegado: Date,
  codModalBancos: string,
  codErro: string,
  codColigada: number,
  codBarras: number,
  situacao?: string,
  boletoGerado?: boolean,
  descricaoErroBoletoGerado?: string;
  boleto64?: string;
}

export interface IAllBoletos {
  allBoletos: AllBoletosDto[];
}

export interface IBoletosFilterProps {
  nossoNumero?: number,
  status: ISelectValue | null,
  email?: string;
  vlrNominal?: number;
  sacado?: string;
  sacador?: string;
}

export interface ICelcoinPagamentoDTO {
  transactionId: string;
  userId: string;
  businessId: number;
  status: number;
  dueDate: string;
  authorizeTransactionId: string;
  value: number;
  originalValue: number;
  valueWithDiscount: number;
  valueWithAdditional: number;
  type: number;
  digitable: string;
}

export interface ICelcoinCreatePagamentoDTO {
  billData: ICelcoinBillDataDTO;
  barCode: ICelcoinBarCodeDTO;
  dueDate: string;
  transactionIdAuthorize: number;
  recipient: string;
  documentRecipient: string;
  cpfcnpj: string;
}

export interface ICelcoinCreateAutorizacaoDTO {
  barCode: ICelcoinBarCodeDTO;
}

export interface ICelcoinAutorizacaoDTO {
  assignor: string;
  registerData: ICelcoinRegisterDataDto;
  settleDate: string;
  dueDate: string;
  endHour: string;
  initeHour: string;
  nextSettle: string;
  digitable: string;
  transactionId: number;
  type: number;
  value: number;
  errorCode: string;
  status: number;
}

export interface ICelcoinBarCodeDTO {
  digitable: string;
  type?: number;
}

export interface ICelcoinRegisterDataDto {
  documentRecipient: string;
  documentPayer: string;
  payDueDate: string;
  dueDateRegister: string;
  allowChangeValue: boolean;
  recipient: string;
  payer: string;
  discountValue: number;
  interestValueCalculated: number;
  maxValue: number;
  minValue: number;
  fineValueCalculated: number;
  originalValue: number;
  totalUpdated: number;
  totalWithDiscount: number;
  totalWithAdditional: number;
}

export interface ICelcoinBillDataDTO {
  value: number;
  originalValue: number;
  valueWithAdditional: number;
  valueWithDiscount: number;
}

export interface ICelcoinReceiptDto {
  authentication: string
  receiptformatted: string
  createDate: string
  externalNSU: string
  transactionId: number
  externalTerminal: string
  errorCode: string
  message: string
  status: number
}

export enum CelcoinStatusPagamentoEnum {
  sucesso = 0,
}
