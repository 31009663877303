import React, { useCallback } from 'react';
import { LogoutOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Menu, AvatarOverlayItem } from '../styles';
import { IAvatarOverlayProps } from '../types';

const AvatarOverlay: React.FC<IAvatarOverlayProps> = (props: IAvatarOverlayProps) => {
  const { onSignOutButtonClick, onModalStatus } = props;
  const history = useHistory();
  const redirectToMyProfilePage = useCallback(() => history.push('/meu-perfil'), [history]);

  return (
    <Menu>
      <AvatarOverlayItem
        icon={<UserOutlined />}
        onClick={redirectToMyProfilePage}
      >
        Meu perfil
      </AvatarOverlayItem>
      <AvatarOverlayItem
        icon={<LockOutlined />}
        onClick={onModalStatus}
      >
        Alterar Senha
      </AvatarOverlayItem>
      <AvatarOverlayItem
        icon={<LogoutOutlined />}
        onClick={onSignOutButtonClick}
      >
        Sair
      </AvatarOverlayItem>
    </Menu>
  );
};

export default AvatarOverlay;
