import { ICarteiraVitrualService } from '../@types/services';
import { createApi } from '../providers/factories';
import carteiraVirtualService from './carteiraVirtualService';

const createcarteiraVirtualService = (): ICarteiraVitrualService => {
  const apiProvider = createApi();
  return carteiraVirtualService(apiProvider);
};

export default createcarteiraVirtualService;
