import { IPagedResult } from '../../../../global/generics';
import {
  ICelcoinAutorizacaoDTO,
  ICelcoinPagamentoDTO,
  ICelcoinReceiptDto,
} from '../../../../global/dataTransferObjects/boletos';

export interface IPageState {
  loading: boolean;
  isVerifyModalOpen: boolean;
  isCreateModalOpen: boolean;
  isReceiptModalOpen: boolean;
  pagamentos: IPagedResult<ICelcoinPagamentoDTO>;
  autorizacao: ICelcoinAutorizacaoDTO;
  receipt: ICelcoinReceiptDto;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CREATE_MODAL_STATUS = 'UPDATE_CREATE_MODAL_STATUS',
  UPDATE_VERIFY_MODAL_STATUS = 'UPDATE_VERIFY_MODAL_STATUS',
  UPDATE_RECEIPT_MODAL_STATUS = 'UPDATE_RECEIPT_MODAL_STATUS',
  UPDATE_PAGAMENTOS = 'UPDATE_PAGAMENTOS',
  UPDATE_AUTORIZACAO = 'UPDATE_AUTORIZACAO',
  UPDATE_RECEIPT = 'UPDATE_RECEIPT',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
