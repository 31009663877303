import React, {useEffect} from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
} from './styles';
import { ILiveTransactionsFilterProps } from '../../../../../global/dataTransferObjects/transacoesTempoReal';
import { FormProps, IFormProps } from '../../types';
import { formatDate } from '../../../../../utils/formatDate';

const FormFilter: React.FC<FormProps> = (props: FormProps) => {
  const { handleSubmit } = props;

  useEffect(() => {
    props.setFieldValue('date', new Date());
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Selecione uma data:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="date"
                placeholder="selecione uma data..."
                label="Data"
              />
            </DateView>
          </Col>
          <Col>
            <Button htmlType="submit">
              Consultar
            </Button>
          </Col>

        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFormProps, ILiveTransactionsFilterProps>({
  mapPropsToValues: (): ILiveTransactionsFilterProps => ({
    date: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const dateValue = values.date;
    const date = formatDate(dateValue);
    const test = {
      ...values,
      date,
    }
    props.onFormSubmit(test);
  },
})(FormFilter);
