import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn } from '../../../components/Table';
import {
  CelcoinStatusPagamentoEnum,
  ICelcoinCreateAutorizacaoDTO,
  ICelcoinCreatePagamentoDTO,
} from '../../../global/dataTransferObjects/boletos';
import { T2 } from '../../../styles/titles';
import {
  createAutorizacao,
  createPagamento,
  getPaymentReceipt,
  onUpdateCreateModalStatus,
  onUpdateReceiptModalStatus,
  onUpdateVerifyModalStatus,
  updatePagamentos,
} from './actions';
import { InitialState, Reducer } from './actions/reducer';
import { IFormValues } from './components/verifyModal/types';
import {
  Button,
  Container,
  TableContainer,
} from './styles';
import VerifyModal from './components/verifyModal';
import CreateModal from './components/createModal/createModal';
import { createFormatProvider } from '../../../services/providers/factories';
import ReceiptModal from './components/receiptModal';
import DigitalBalance from '../../../components/DigitalBalance';

const Pagamento: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const fetchPagamentos = useCallback((
    initial: number = offset,
    size: number = pageSize,
  ) => updatePagamentos(initial, size)(dispatch), []);

  const fetchPaymentReceipt = useCallback((transactionId: number) => getPaymentReceipt(transactionId)(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPagamentos(pageSize * (currentPage - 1), (currentPage * pageSize))
      return;
    }
    if (currentPage === 1) {
      fetchPagamentos(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleUpdateCreateModalStatus = (status: boolean) => onUpdateCreateModalStatus(status)(dispatch);

  const handleUpdateVerifyModalStatus = (status: boolean) => onUpdateVerifyModalStatus(status)(dispatch);

  const handleUpdateReceiptModalStatus = (status: boolean) => onUpdateReceiptModalStatus(status)(dispatch);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const formattedDate = useMemo(() => (date: string) => (date !== undefined
    ? moment(date).utc().format('DD/MM/YYYY')
    : 'N/A'), []);

  const handleVerifySubmit = async (values: IFormValues) => {
    const formattedValues: ICelcoinCreateAutorizacaoDTO = {
      barCode: {
        ...values,
      },
    }

    const result = await createAutorizacao({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    handleUpdateCreateModalStatus(true);
  };

  const handleCreatePagamento = async (): Promise<void> => {
    const formattedValues: ICelcoinCreatePagamentoDTO = {
      barCode: {
        digitable: state.autorizacao.digitable,
        type: state.autorizacao.type,
      },
      billData: {
        value: state.autorizacao.value,
        originalValue: state.autorizacao.registerData?.originalValue,
        valueWithAdditional: state.autorizacao.registerData?.totalWithAdditional,
        valueWithDiscount: state.autorizacao.registerData?.totalWithDiscount,
      },
      dueDate: state.autorizacao.dueDate,
      transactionIdAuthorize: state.autorizacao.transactionId,
      recipient: state.autorizacao.registerData?.recipient,
      documentRecipient: state.autorizacao.registerData?.documentRecipient,
      cpfcnpj: state.autorizacao.registerData?.documentPayer,
    }

    const result = await createPagamento({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    fetchPagamentos();
    toast.success('Pagamento de boleto enviando com sucesso!')
  };

  const getStatusPagamento = (status: CelcoinStatusPagamentoEnum): string => {
    switch (status) {
      case CelcoinStatusPagamentoEnum.sucesso:
        return 'Pagamento enviado';
      default:
        return 'Aguardando atualização do sistema';
    }
  };

  const handleOpenReceiptModal = async (transactionId: number) => {
    const result = await fetchPaymentReceipt(transactionId);
    if (!result) {
      return;
    }

    handleUpdateReceiptModalStatus(true);
  }

  return (
    <>
      <Container>
        <LoadingOverlay
          show={state.isVerifyModalOpen || state.isCreateModalOpen ? false : state.loading}
          relative
        />
        <T2>Pagamentos</T2>
        <DigitalBalance />
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleUpdateVerifyModalStatus(true)}
        >
          Enviar pagamento
        </Button>
        <TableContainer>
          <Table
            dataSource={[...state.pagamentos.records]}
            pagination={
              {
                showSizeChanger: true,
                onShowSizeChange: (current: number, size: number) => {
                  setPageSize(size);
                },
                defaultPageSize: 20,
                total: state.pagamentos.count,
                onChange: (page: number) => {
                  setCurrentPage(page);
                },
              }
            }
          >
            <TableColumn title="Id da Transação" dataIndex="transactionId" key="transactionId" />
            <TableColumn title="Valor" dataIndex="value" key="value" render={formattedValue} />
            <TableColumn title="Linha digitável" dataIndex="digitable" key="digitable" />
            <TableColumn title="Status" dataIndex="status" key="status" render={getStatusPagamento} />
            <TableColumn title="Data de vencimento" dataIndex="dueDate" key="dueDate" render={formattedDate} />
            <TableColumn title="Data de pagamento" dataIndex="createdAt" key="createdAt" render={formattedDate} />
            <TableColumn
              title="Comprovante"
              dataIndex="comprovante"
              key="comprovante"
              align="center"
              render={(value: string, record: any) => (state.pagamentos.records.length >= 1 ? (
                <EyeOutlined onClick={() => handleOpenReceiptModal(record.transactionId)} />
              ) : null)}
            />
          </Table>
        </TableContainer>
      </Container>
      <VerifyModal
        isVisible={state.isVerifyModalOpen}
        onVerifyModalClose={() => handleUpdateVerifyModalStatus(false)}
        loading={state.loading}
        onVerifySubmit={handleVerifySubmit}
      />
      <CreateModal
        isVisible={state.isCreateModalOpen}
        onCreateModalClose={() => handleUpdateCreateModalStatus(false)}
        loading={state.loading}
        autorizacao={{ ...state.autorizacao }}
        onCreatePagamento={handleCreatePagamento}
      />
      <ReceiptModal
        isVisible={state.isReceiptModalOpen}
        onReceiptModalClose={() => handleUpdateReceiptModalStatus(false)}
        loading={state.loading}
        receipt={{ ...state.receipt }}
      />
    </>
  );
}

export default Pagamento;
