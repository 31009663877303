import { NotaPromissoriaDTO } from '../../../global/dataTransferObjects/notaPromissoria';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean;
  notaPromissoria: IPagedResult<NotaPromissoriaDTO>;
  isPaymentModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  OPEN_NOTA_MODAL = 'OPEN_NOTA_MODAL',
  UPDATE_NOTA_MODAL_STATUS = 'UPDATE_NOTA_MODAL_STATUS',
  GET_ALL_NOTAS = 'GET_ALL_NOTAS',
  GET_USER_INFO = 'GET_USER_INFO',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
