import {
  IPixCreatePagamentoDTO,
  IPixPagamentoDTO,
} from '../../../../global/dataTransferObjects/pix/pagamento';
import { IPagedResult } from '../../../../global/generics';
import { ITransactionProps } from '../components/comprovanteModal/types';

export interface IPageState {
  loading: boolean;
  isCreateModalOpen: boolean;
  isVerifyModalOpen: boolean;
  isDetailsPaymentModalOpen: boolean;
  transaction: ITransactionProps;
  pagamentos: IPagedResult<IPixPagamentoDTO>;
  createPagamento: IPixCreatePagamentoDTO;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CREATE_MODAL_STATUS = 'UPDATE_CREATE_MODAL_STATUS',
  UPDATE_VERIFY_MODAL_STATUS = 'UPDATE_VERIFY_MODAL_STATUS',
  UPDATE_PAGAMENTOS = 'UPDATE_PAGAMENTOS',
  UPDATE_CREATE_PAGAMENTO = 'UPDATE_CREATE_PAGAMENTO',
  UPDATE_DETAILS_PAYMENT_MODAL_STATUS = 'UPDATE_DETAILS_PAYMENT_MODAL_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
