import React from 'react';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import Modal from '../../../components/Modal';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
  ZeroState,
  ZeroDescription,
} from '../styles';
import { IViewNotaPromissoriaModalProps } from '../types';
import { NotaStatus } from '../../../global/enum';

const NotaPromissoriaModal: React.FC<IViewNotaPromissoriaModalProps> = (props: IViewNotaPromissoriaModalProps) => {
  const { adiantamento, isVisible, onClose } = props;

  const { notaPromissoria } = adiantamento;

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const solicitationValue = notaPromissoria?.valorSolicitacao;

  const formattedAmount = solicitationValue ? format(solicitationValue) : 0;

  const handleStatus = (statusParam: number) => {
    let status = ''
    if (statusParam === NotaStatus.naoSolicitado) {
      status = 'Não Solicitado';
    }
    if (statusParam === NotaStatus.pendente) {
      status = 'Pendente';
    }
    if (statusParam === NotaStatus.aprovado) {
      status = 'Aprovado';
    }
    if (statusParam === NotaStatus.reprovado) {
      status = 'Aprovado';
    }
    return status;
  }

  return (
    <Modal
      title="Nota Promissoria"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        {!notaPromissoria ? (
          <ZeroState>
            <FaMoneyCheckAlt size={50} color="#bdbdbd" />
            <ZeroDescription>Não há nota promissória vinculada</ZeroDescription>
          </ZeroState>
        ) : (
          <>
            <TextGroup>
              <Description>No. Controle:</Description>
              <Item>{notaPromissoria?.numeroControle}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Status:</Description>
              <Item>{notaPromissoria?.status ? handleStatus(notaPromissoria?.status) : 0}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Valor Solicitação:</Description>
              <Item>{formattedAmount}</Item>
            </TextGroup>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default NotaPromissoriaModal;
