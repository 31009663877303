import React, {useEffect, useState} from "react";
import {formatBandeira, formatMoney} from "../../utils";
import {BrandTable, IconCircle} from "../../styles";
import {CreditCardOutlined, TagOutlined} from "@ant-design/icons";
import MASTER from "../../../../assets/brandIcons/masterCard.png";
import VISA from "../../../../assets/brandIcons/VISA.png";
import HIPER from "../../../../assets/brandIcons/Hipercard.png";
import PIX from "../../../../assets/brandIcons/pix.png";
import ELO from "../../../../assets/brandIcons/Elo.png";
import AMEX from "../../../../assets/brandIcons/amex-svgrepo-com 1.png";
import {TableProps} from "../../types";

const Table: React.FC<TableProps> = (props) => {
  const { tableData } = props;
  const [percentuaisBandeiras, setPercentuaisBandeiras] = useState({
    percentualVisa: 0,
    percentualMaster: 0,
    percentualElo: 0,
    percentualAmex: 0,
    percentualHipercard: 0,
    percentualPix: 0
  });

  const calcularPorcentagens = () => {
    const percentualMaster = parseFloat(((tableData.totalMaster * 100) / tableData.totalGross).toFixed(2));
    const percentualVisa = parseFloat(((tableData.totalVisa * 100) / tableData.totalGross).toFixed(2));
    const percentualElo = parseFloat(((tableData.totalElo * 100) / tableData.totalGross).toFixed(2));
    const percentualHiper = parseFloat(((tableData.totalHipercard * 100) / tableData.totalGross).toFixed(3));
    const percentualAmex = parseFloat(((tableData.totalAmex * 100) / tableData.totalGross).toFixed(2));
    const percentualPix = parseFloat(((tableData.totalPix * 100) / tableData.totalGross).toFixed(2));

    const tempPercentuais = {...percentuaisBandeiras};

    tempPercentuais.percentualMaster = percentualMaster;
    tempPercentuais.percentualVisa = percentualVisa;
    tempPercentuais.percentualElo = percentualElo;
    tempPercentuais.percentualHipercard = percentualHiper;
    tempPercentuais.percentualAmex = percentualAmex;
    tempPercentuais.percentualPix = percentualPix;

    setPercentuaisBandeiras(tempPercentuais);
  };

  useEffect(() => {
    calcularPorcentagens();
  }, [tableData]);

  return (
    <BrandTable>
      <div style={{display: 'flex', padding: '1em'}}>
        <IconCircle style={{backgroundColor: 'rgba(170,200,255,0.5)'}}>
          <CreditCardOutlined
            style={{
              fontSize: 18,
              color: 'rgb(0,111,255)'
            }}
          />
        </IconCircle>
        <p style={{fontSize: '25px', marginTop: '0', fontWeight: 'normal', marginLeft: '10px', marginBottom: '20px'}}>
          Vendas/Bandeira
        </p>
      </div>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={MASTER} alt="MasterCard Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalMaster)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualMaster}%</p>
        </td>
      </tr>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={VISA} alt="Visa Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalVisa)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualVisa}%</p>
        </td>
      </tr>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={ELO} alt="Elo Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalElo)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualElo}%</p>
        </td>
      </tr>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={HIPER} alt="Hipercard Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalHipercard)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualHipercard}%</p>
        </td>
      </tr>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={AMEX} alt="Amex Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalAmex)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualAmex}%</p>
        </td>
      </tr>
      <tr>
        <td style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={PIX} alt="Pix Logo"/>
            <p style={{marginLeft: '10px'}}>{formatMoney(tableData.totalPix)}</p>
          </div>
          <p style={{fontSize: '15px', marginLeft: 'auto', color:'#686A6C'}}>{percentuaisBandeiras.percentualPix}%</p>
        </td>
      </tr>
    </BrandTable>
  );
};

export default Table;
