import { UserDto } from '../../../global/dataTransferObjects/user';

export interface IPageState {
  loading: boolean;
  user: UserDto;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_USER_ME = 'GET_USER_ME',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
