import React, {
  useReducer,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { IFormValues, ICargaCartaoDto } from '../../../global/dataTransferObjects/pix/pagamento';
import { T2 } from '../../../styles/titles';
import { createTransferenciaEntreCartoes } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import { Container } from './styles';
import CardForm from './components/cardRechardForm';
import DigitalBalance from '../../../components/DigitalBalance';

const Pagamento: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const history = useHistory();

  const handleSubmit = async (values: IFormValues) => {
    const formattedValues: ICargaCartaoDto = {
      ...values,
      valorTransferencia: parseFloat(values.valorTransferencia.replace('.', '').replace(',', '.')),
    }
    const result = await createTransferenciaEntreCartoes({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    toast.success('Recarga efetuada com sucesso!');
    history.push('/conta-digital/extrato');
  };

  return (
    <>
      <Container>
        <LoadingOverlay
          show={state.isCreateModalOpen ? false : state.loading}
          relative
        />
        <T2>Recarregar cartão</T2>
        <DigitalBalance />
        <CardForm onFormSubmit={handleSubmit} />
      </Container>
    </>
  );
}

export default Pagamento;
