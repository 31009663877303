import { IContratoDto } from '../../../global/dataTransferObjects/contratoEscrow';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean;
  contratos: IPagedResult<IContratoDto>;
  contrato: IContratoDto;
  isContratoModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL',
  UPDATE_PAYMENT_MODAL_STATUS = 'UPDATE_PAYMENT_MODAL_STATUS',
  UPDATE_USER_MODAL = 'UPDATE_USER_MODAL',
  OPEN_CONTRATO_MODAL = 'OPEN_CONTRATO_MODAL',
  VIEW_USER_MODAL_STATUS = 'VIEW_USER_MODAL_STATUS',
  CREATE_NEW_CONTRATO = 'CREATE_NEW_CONTRATO',
  GET_ALL_CONTRATOS = 'GET_ALL_CONTRATOS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
