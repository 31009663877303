import { connect } from 'react-redux';
import SignIn from './signin';
import { authenticateUser } from '../../store/user/actions';
import { IAppState } from '../../store/types';

const mapStateToProps = (state: IAppState) => ({
  isLoading: state.user.loading,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = {
  authenticateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
