import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import FormInput from '../../../../../components/Forms/Input';
import { FormTextArea } from '../../../../../components/Forms/TextArea';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
} from './styles';
import { FormProps, IVerifyModalProps, IFormValues } from './types';
import validationSchema from './validationSchema';
import FormDropdown from '../../../../../components/Forms/FormDropdown';

const VerifyModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
    setFieldValue,
    values,
  } = props;

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  const pixPaymentTypeOptions = [
    { value: 1, label: 'PIX com chave' },
    { value: 2, label: 'PIX Copia e Cola' },
  ];

  return (
    <Modal
      title="Enviar pagamento via PIX"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
        />
        <Row>
          <Col>
            <FormDropdown
              label="Tipo de pagamento PIX"
              options={pixPaymentTypeOptions}
              name="pixPaymentType"
              placeholder="Selecione o tipo de pagamento PIX"
              isRequired
            />
            {values.pixPaymentType?.value === 1 && (
              <div style={{ marginTop: '20px' }}>
                <FormInput
                  type="text"
                  name="creditorKey"
                  label="Chave PIX"
                  required
                />
                <FormInput
                  type="text"
                  name="amount"
                  label="Valor"
                  required
                  prefix="R$"
                  placeholder="0,00"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'amount')}
                />
                <FormTextArea
                  name="description"
                  label="Descrição"
                  rows={3}
                />
              </div>
            )}
            {values.pixPaymentType?.value === 2 && (
              <div style={{ marginTop: '20px' }}>
                <FormTextArea
                  name="emv"
                  label="Código do QR Code"
                  rows={3}
                  required
                />
              </div>
            )}
            <Button htmlType="submit">
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<IVerifyModalProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    amount: '',
    creditorKey: '',
    description: '',
    pixPaymentType: null,
    emv: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => props.onSubmit({ ...values }),
})(VerifyModal);
