import { ITransactionsService } from '../@types/services';
import { createApi } from '../providers/factories';
import transactionsService from './transactionsService';

const createTransactionsService = (): ITransactionsService => {
  const apiProvider = createApi();
  return transactionsService(apiProvider);
};

export default createTransactionsService;
