import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn } from '../../../components/Table';
import FormFilter from './components/filter';
import { Reducer, InitialState } from './actions/reducer';
import { getAllAdjustments } from './actions';
import * as S from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { IAdjustmentFilterProps } from '../../../global/dataTransferObjects/adjustments';

const Adjustment: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formattedDocumentNumber = (numeroDocumento: string) => (
    numeroDocumento?.length === 14
      ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
      : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
  );

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const adjustmentPagination = (initialPage: number, finalPage: number) => {
    getAllAdjustments(initialPage, finalPage, filterOrder)(dispatch);
  }
  useEffect(() => {
    if (currentPage > 1) {
      adjustmentPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      adjustmentPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const adjustmentList = state?.adjustments?.records.map((items) => (
    {
      ...items,
    }
  ));

  // if (_.isEmpty(adjustmentList) && !state.loading) {
  //   return (
  //     <ZeroState />
  //   )
  // }

  const handleFilter = async (filter: IAdjustmentFilterProps) => {
    setFilterOrder(filter);
    getAllAdjustments(offSet, pageSize, filter)(dispatch);
  };

  return (
    <S.Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Ajustes</T2>
      <FormFilter onFormSubmit={handleFilter} />
      <S.TableContainer>
        <Table
          dataSource={adjustmentList}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.adjustments.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn
            title="CNPJ/CPF"
            dataIndex="identificacaoLoja"
            key="identificacaoLoja"
            render={formattedDocumentNumber}
          />
          <TableColumn
            title="Data Transação"
            dataIndex="dataTransacaoOriginal"
            key="dataTransacaoOriginal"
            render={formattedDate}
          />
          <TableColumn title="Data Lançamento" dataIndex="dataLancamento" key="dataLancamento" render={formattedDate} />
          <TableColumn title="Código Produto" dataIndex="codigoProduto" key="codigoProduto" />
          <TableColumn title="No. Parcela" dataIndex="numeroParcela" key="numeroParcela" />
          <TableColumn title="Valor Bruto" dataIndex="valorBruto" key="valorBruto" render={format} />
          <TableColumn title="Valor Liquido" dataIndex="valorLiquido" key="valorLiquido" render={format} />
          <TableColumn title="Bandeira" dataIndex="codigoBandeira" key="codigoBandeira" />
          <TableColumn title="Descrição" dataIndex="descricaoAjuste" key="descricaoAjuste" />
          <TableColumn title="NSU" dataIndex="nsuTransacaoOriginal" key="nsuTransacaoOriginal" />
          <TableColumn title="No. Cartão" dataIndex="numeroCartao" key="numeroCartao" />
        </Table>
      </S.TableContainer>
    </S.Container>
  );
};

export default Adjustment;
