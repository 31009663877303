import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import {
  IUpdateUserDto,
  UserDto,
} from '../../../global/dataTransferObjects/user';
import createUserService from '../../../services/user';
import createContaDigitalService from '../../../services/contaDigital';
import { HttpStatus } from '../../../services/providers/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetUser = (user: UserDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_USER_ME,
  payload: {
    user: { ...user },
  },
});

export const UpdateUser = (userId: string, user: IUpdateUserDto) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.updateUser(userId, user);
  if (result.status === undefined) {
    toast.error('Erro ao alterar dados do usuário');
    return;
  }

  onUpdateLoadingStatus(false)(dispatch);
  toast.success('Usuário alterado com sucesso!')
};

export const getUser = () => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.getCurrentUser();

  onGetUser(result)(dispatch);
};

export const changePasswordCard = () => async (dispatch: Dispatch<any>): Promise<any> => {
  const contaDigitalService = createContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaDigitalService.changeCardPassword();
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    return result;
  }

  return result.response;
}

export const recoverPasswordCard = () => async (dispatch: Dispatch<any>): Promise<any> => {
  const contaDigitalService = createContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaDigitalService.recoverCardPassword();
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  return result.response;
}
