import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding-top: 17px;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  padding-left: 5px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 24px;
`;
