import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { BusinessDto } from '../../../../../../global/dataTransferObjects/business';
import { IPagedResult } from '../../../../../../global/generics';
import createPoliticaCobrancaService from '../../../../../../services/politicaCobranca';
import { HttpStatus } from '../../../../../../services/providers/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllBusiness = (businesses: IPagedResult<BusinessDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_BUSINESS,
  payload: {
    businesses: { ...businesses },
  },
});

export const getAllBusiness = (
  offSet: number,
  pageSize: number,
  billingPolicyId?: number,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createPoliticaCobrancaService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.getBusinessByBillingPolicy(offSet, pageSize, billingPolicyId);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Não foi possível obter lista de empresas vinculadas!');
    return;
  }

  onGetAllBusiness(result.response)(dispatch);
};
