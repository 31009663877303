import { IUserService } from '../@types/services';

import { createApi } from '../providers/factories';
import userService from './userService';

const createUserService = (): IUserService => {
  const apiProvider = createApi();
  return userService(apiProvider);
};

export default createUserService;
