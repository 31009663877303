import { IBoletosService } from '../../@types/services';
import { createApi } from '../../providers/factories';
import listAllBoletoService from './listaBoletos';

const createBoletoService = (): IBoletosService => {
  const apiProvider = createApi();
  return listAllBoletoService(apiProvider);
}

export default createBoletoService;
