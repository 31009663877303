import * as Yup from 'yup';
import { OptionType } from '../../../Select';

const validationSchema = Yup.object().shape({
  nomeIdentificador: Yup.string().required('O nome do estabelecimento é obrigatório'),
  tipoPessoa: Yup.string(),
  numeroDocumento: Yup
    .string()
    .required('O número do documento é obrigatório'),
  dataInicio: Yup.date()
    .required('A data de inicio é obrigatória')
    .typeError('A data de inicio é obrigatória'),
  dataFim: Yup.date()
    .required('A data fim é obrigatória')
    .typeError('A data de inicio é obrigatória'),
  listaCodigoArranjoPagamento: Yup.array()
    .of(Yup.mixed<OptionType>())
    .nullable()
    .required('É obrigatório selecionar ao menos uma bandeira'),
  credenciadora: Yup.array()
    .of(Yup.mixed<OptionType>())
    .nullable()
    .required('É obrigatório inserir ao menos uma credenciadora'),
});

export default validationSchema;
