export enum RoutesEnum {
  Default = '/',
  Dashboard = '/dashboard',
  DashboardComercial = '/comercial/dashboard',
  SignIn = '/login',
  SignUp = '/register',
  Veiculos = '/veiculos',
  Usuários = '/usuarios',
  UnidadesRecebiveis = '/cerc/solicitacao-consulta',
  Vendas = '/tecpay/admin/painel-de-vendas'
}
