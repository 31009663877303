import { IAdiantamentoService } from '../@types/services';
import { createApi } from '../providers/factories';
import salesService from './adiantamentosService';

const createAdiantamentoService = (): IAdiantamentoService => {
  const apiProvider = createApi();
  return salesService(apiProvider);
}

export default createAdiantamentoService;
