import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IPoliticaCobrancaDTO, IUpdatePoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';
import createPoliticaCobrancaService from '../../../../services/politicaCobranca';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePoliticaCobranca = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_POLITICA_COBRANCA,
});

export const onGetPoliticaCobranca = (politicaCobranca: IPoliticaCobrancaDTO) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_POLITICA_COBRANCA,
  payload: {
    politicaCobranca: { ...politicaCobranca },
  },
});

export const updatePoliticaCobranca = (
  politicaCobrancaId: number,
  updatePoliticaCobrancaDTO: IUpdatePoliticaCobrancaDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  onUpdateLoadingStatus(true)(dispatch);
  const politicaCobrancaService = createPoliticaCobrancaService();
  const result = await politicaCobrancaService
    .updatePoliticaCobranca(politicaCobrancaId, { ...updatePoliticaCobrancaDTO });

  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao salvar alterações da política de cobrança');
    return false;
  }

  onUpdatePoliticaCobranca()(dispatch);
  return true;
}

export const getPoliticaCobranca = (
  politicaCobrancaId: number,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  onUpdateLoadingStatus(true)(dispatch);
  const politicaCobrancaService = createPoliticaCobrancaService();
  const result = await politicaCobrancaService.getPoliticaCobranca(politicaCobrancaId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao obter política de cobrança');
    return false;
  }

  onGetPoliticaCobranca({ ...result.response })(dispatch);
  return true;
}
