import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IFilterBusinessProps, BusinessDto } from '../../../global/dataTransferObjects/business';
import { IPagedResult } from '../../../global/generics';
import createBusinessService from '../../../services/business';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllBusiness = (business: IPagedResult<BusinessDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_BUSINESS,
  payload: {
    business: { ...business },
  },
});

export const onOpenCredentialModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CREDENTIAL_MODAL,
});

export const onUpdateBusinessModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_BUSINESS_MODAL,
  payload: { status },
});

export const onUpdateCredentialModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREDENTIAL_MODAL_STATUS,
  payload: { status },
});

export const onUpdateBusiness = (
  business: BusinessDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_BUSINESS_MODAL,
  payload: {
    businessInfo: { ...business },
  },
});

export const openBusinessViewModal = (
  business: BusinessDto,
) => (dispatch: Dispatch<any>): void => {
  onUpdateBusiness({ ...business })(dispatch);
  onUpdateBusinessModalStatus(true)(dispatch);
}

export const getAllBusiness = (
  offSet: number,
  pageSize: number,
  filter?: IFilterBusinessProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createBusinessService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await userService.getBusinessList(offSet, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllBusiness(result.response)(dispatch);
};
