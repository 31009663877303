import { IAdjustmentService } from '../@types/services';
import { createApi } from '../providers/factories';
import adjustmentService from './adjustment';

const createAdjustmentService = (): IAdjustmentService => {
  const apiProvider = createApi();
  return adjustmentService(apiProvider);
};

export default createAdjustmentService;
