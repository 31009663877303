/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IAllTransfers } from '../../../../global/dataTransferObjects/transfers';
import createTransferService from '../../../../services/transfer';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllTransfers = (transfers: IPagedResult<IAllTransfers>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_TRANSFERS,
  payload: {
    transfers: { ...transfers },
  },
});

export const getAllTransfers = (
  offSet: number,
  pageSize: number,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const transferService = createTransferService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await transferService.getAllTransfers(offSet, pageSize);
  if (result.status !== 200) {
    // logica zero state
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message);
    return;
  }

  onGetAllTransfers(result.response)(dispatch);
};
