import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import { BiCopy } from 'react-icons/bi';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn } from '../../../components/Table';
import { IPixCopiaEColaResponseDto, IPixCreateCopiaEColaDTO } from '../../../global/dataTransferObjects/pix/copiaECola';
import { createFormatProvider } from '../../../services/providers/factories';
import { T2 } from '../../../styles/titles';
import {
  createPixCopiaECola,
  onUpdateCreateModalStatus,
  onUpdateInfoModalStatus,
  onUpdatePixCopiaECola,
  updatePagedPixCopiaECola,
} from './actions';
import { Reducer, InitialState } from './actions/reducer';
import CreateModal from './components/createModal';
import { IFormValues } from './components/createModal/types';
import InfoModal from './components/infoModal';
import { Container, TableContainer, Button } from './styles';
import { ICopiaEColaProps } from './types';

const CopiaECola: React.FC<ICopiaEColaProps> = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const handleUpdateCreateModalStatus = (status: boolean) => onUpdateCreateModalStatus(status)(dispatch);

  const handleUpdateInfoModalStatus = (status: boolean) => onUpdateInfoModalStatus(status)(dispatch);

  const fetchPagedPixCopiaECola = useCallback((
    initial: number = offset,
    size: number = pageSize,
  ) => updatePagedPixCopiaECola(initial, size)(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPagedPixCopiaECola(pageSize * (currentPage - 1), (currentPage * pageSize))
      return;
    }
    if (currentPage === 1) {
      fetchPagedPixCopiaECola(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleSubmit = async (values: IFormValues) => {
    const formattedValues: IPixCreateCopiaEColaDTO = {
      cobrancaImediata: {
        valor: {
          original: parseFloat(values.valor.replace('.', '').replace(',', '.')).toFixed(2),
        },
      },
    }

    const result = await createPixCopiaECola({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    toast.success('PIX Copia e Cola gerado com sucesso!');
    fetchPagedPixCopiaECola();
    handleUpdateInfoModalStatus(true);
  }

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? moment(date).utcOffset('-06:00').format('DD/MM/YYYY HH:mm')
    : 'N/A'), []);

  const handleOpenInfoModal = (record: IPixCopiaEColaResponseDto) => {
    onUpdatePixCopiaECola({ ...record })(dispatch);
    onUpdateInfoModalStatus(true)(dispatch);
  }

  const copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    toast.success('Pix Copia e Cola copiado com sucesso!')
  };

  return (
    <>
      <Container>
        <LoadingOverlay
          show={state.isCreateModalOpen ? false : state.loading}
          relative
        />
        <T2>Recebimento - PIX Copia e Cola</T2>
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleUpdateCreateModalStatus(true)}
        >
          Gerar Copia e Cola
        </Button>
        <TableContainer>
          <Table
            dataSource={[...state.pagedPixCopiaECola.records]}
            pagination={
              {
                showSizeChanger: true,
                onShowSizeChange: (current: number, size: number) => {
                  setPageSize(size);
                },
                defaultPageSize: 20,
                total: state.pagedPixCopiaECola.count,
                onChange: (page: number) => {
                  setCurrentPage(page);
                },
              }
            }
          >
            <TableColumn title="Valor" dataIndex="valor" key="valor" render={formattedValue} />
            <TableColumn title="Copia e Cola" dataIndex="pixCopiaECola" key="pixCopiaECola" />
            <TableColumn
              title="Copiar"
              dataIndex="Copiar"
              key="copiar"
              align="center"
              render={(value: any, record: any) => (state.pagedPixCopiaECola.records.length >= 1 ? (
                <BiCopy
                  onClick={() => copyToClipboard(record.pixCopiaECola)}
                  style={{ cursor: 'pointer' }}
                />
              ) : null)}
            />
            <TableColumn title="Status" dataIndex="status" key="status" />
            <TableColumn title="Criado em" dataIndex="createdAt" key="createdAt" render={formattedDate} />
            <TableColumn title="Expira em" dataIndex="expiracao" key="expiracao" render={formattedDate} />
            <TableColumn
              title="Visualizar"
              dataIndex="visualizar"
              key="visualizar"
              align="center"
              render={(value: any, record: any) => (state.pagedPixCopiaECola.records.length >= 1 ? (
                <EyeOutlined
                  onClick={() => handleOpenInfoModal(record)}
                />
              ) : null)}
            />
          </Table>
        </TableContainer>
      </Container>
      <CreateModal
        isVisible={state.isCreateModalOpen}
        onModalClose={() => handleUpdateCreateModalStatus(false)}
        loading={state.loading}
        onSubmit={handleSubmit}
      />
      <InfoModal
        isVisible={state.isCopiaEColaInfoModalOpen}
        onModalClose={() => handleUpdateInfoModalStatus(false)}
        pixCopiaECola={state.pixCopiaECola}
      />
    </>
  );
};

export default CopiaECola;
