import React, {useCallback} from 'react';
import {ShoppingCartOutlined, CreditCardOutlined, UserOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {Menu, AvatarOverlayItem} from '../styles';
import { VscLiveShare, VscDiscard  } from 'react-icons/vsc';
import {MdOutlineCancel} from "react-icons/md";

const PageOverlayPJ: React.FC = () => {

  const history = useHistory();
  const redirectToSaleSimulatorPage = useCallback(() => history.push('/simulador/venda'),[history]);
  const redirectToIncomeSimulatorPage = useCallback(() => history.push('/simulador/recebimento'),[history]);
  const redirectToSalesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-vendas'),[history]);
  const redirectToRecivablesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-recebiveis'),[history]);
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) : null;
  const selectedLink = useCallback( () => history.push('/cadastrar-links'), [history]);
  const selectedLinkHistory = useCallback( () => history.push('/listar-links'), [history]);
  const selectedSignature = useCallback( () => history.push('/listar-assinaturas'), [history]);
  const selectedSignatureHistory = useCallback( () => history.push('/cadastrar-assinaturas'), [history]);


  return (
    <Menu>
      {/* Menu responsivo */}
      <AvatarOverlayItem
        icon={<VscDiscard/>}
        onClick={redirectToRecivablesPanel}
      >
        Painel de Recebiveis
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<ShoppingCartOutlined/>}
        onClick={redirectToSalesPanel}
      >
        Painel de Vendas
      </AvatarOverlayItem>

      {selectedBusiness?.allowPaymentLink && (
        <AvatarOverlayItem
          icon={<MdOutlineCancel/>}
          onClick={selectedLink}>
          Gerar Link
        </AvatarOverlayItem>
      )}

      {selectedBusiness?.allowPaymentLink && (
        <AvatarOverlayItem
          icon={<VscLiveShare/>}
          onClick={selectedLinkHistory}>
          Histórico de links
        </AvatarOverlayItem>
      )}

      {selectedBusiness?.allowPaymentLink && (
        <AvatarOverlayItem
          icon={<VscLiveShare/>}
          onClick={selectedSignatureHistory}>
          Gerar assinaturas
        </AvatarOverlayItem>
      )}

      {selectedBusiness?.allowPaymentLink && (
        <AvatarOverlayItem
          icon={<VscLiveShare/>}
          onClick={selectedSignature}>
          Histórico assinaturas
        </AvatarOverlayItem>
      )}

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToIncomeSimulatorPage}
      >
        Simulador - Recebimento
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToSaleSimulatorPage}
      >
        Simulador - Venda
      </AvatarOverlayItem>
    </Menu>
  );
};
export default PageOverlayPJ;
