import React from 'react';
import { useHistory } from 'react-router-dom';

import SignInForm from './signForm';
import { IFormValues } from './signForm/types';
import { RoutesEnum } from '../../routes/types';
import { SignInProps } from './types';

const SignIn: React.FC<SignInProps> = (props: SignInProps) => {
  const history = useHistory();
  const { isLoading, isAuthenticated } = props;

  const onSignFormSubmit = async (values: IFormValues) => {
    const { email, password } = values;
    await props.authenticateUser({ email, password, grantType: 'password' });
  };

  if (isAuthenticated) {
    history.push(RoutesEnum.Veiculos);
    return null;
  }

  return (
    <SignInForm
      loading={isLoading}
      onSignInFormSubmit={onSignFormSubmit}
    />
  );
}

export default SignIn;
