import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import Table, { TableColumn } from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import { BusinessDto, IFilterBusinessProps } from '../../global/dataTransferObjects/credencial';
import FilterForm from './components/FilterForm';
import ViewBusinessModal from './components/viewBusinessModal';
import ViewBillingPolicyModal from '../politicaCobranca/list/components/detailsModal/index';
import ViewTerminalsModal from '../terminais/components/detailsModal/index'

import {
  getAllBusiness,
  openBusinessViewModal,
  onUpdateBusinessModalStatus,
} from './actions';
import {
  Button,
  Container,
  Link,
  TableContainer,
  ResponsiveRow,
  ResponsiveTableTitle,
  ResponsiveTable, ResponsiveColumn,
} from './styles';
import { T2 } from '../../styles/titles';
import useGetMe from "../../hooks/useCurrentUser";
import createPoliticaCobrancaService from "../../services/politicaCobranca";
import politicaCobranca from "../../services/politicaCobranca";
import TerminaisServices from "../terminais/services/TerminaisServices";
import BusinessService from "./services/BusinessService";

const Business: React.FC = () => {
  const user = useGetMe();
  const businessService = new BusinessService();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [isBillingPolicyDetailsVisible, setIsBillingPolicyDetailsVisible] = useState<boolean>(false);
  const [billingPolicy, setBillingPolicy] = useState<any>(null);
  const [isTerminalsDetailsVisible, setTerminalsDetailsVisible] = useState<boolean>(false);
  const [terminals, setTerminals] = useState<any>(null);

  const businessItem: any = state.business.records.map((item) => ({
    ...item,
    documentNumber: item.cnpj || item.cpf,
  }));

  const formatUserType = (user: number) => {
    if (user === 1) {
      return 'Pessoa Física';
    }
    if (user === 2) {
      return 'Pessoa Jurídica';
    }
    if (user === 6) {
      return 'Comercial'
    }
    return 'Administrador';
  };

  const fetchAllBusiness = useCallback(() => getAllBusiness(0, 5000)(dispatch), []); // Fetch all business without pagination
  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);

  const handleViewModalStatus = (status: boolean) => onUpdateBusinessModalStatus(status)(dispatch);

  const handleOpenViewUBusinessModal = (business: BusinessDto) => {
    openBusinessViewModal({ ...business })(dispatch)
  };

  const handleOpenViewBillingPolicyModal = async (business: BusinessDto) => {
    const politicaCobrancaService = createPoliticaCobrancaService();
    const billingPolicy = await politicaCobrancaService.getPoliticaCobranca(business.billingPolicyId as number);

    setBillingPolicy(billingPolicy.response);
    setIsBillingPolicyDetailsVisible(true);
  };

  const handleOpenViewTerminalsModal = async (business: BusinessDto) => {
    const terminalsService = new TerminaisServices();
    const terminals = await terminalsService.getTerminalById(parseInt(business.id));

    setTerminals(terminals.data.response);
    setTerminalsDetailsVisible(true);
  };


  const handleBusinessFilter = async (filter: IFilterBusinessProps) => {
    setFilterOrder(filter);
    getAllBusiness(0, 1000, filter)(dispatch); // Fetch all filtered business without pagination
  };

  const handleBusinessDownload = async () => {
    const result = await businessService.getBusinessDownload(filterOrder);

    try {
      const objectUrl = window.URL.createObjectURL(result);
      const a = document.createElement('a');
      a.href = objectUrl;

      a.download = 'Empresas.xlsx';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
    }
  }

  const formatDocument = (documentNumber: string) => {
    const documentNumberFormatted = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumberFormatted.length === 11) {
      const cpf = documentNumberFormatted.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
      if (cpf != null) {
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        return cpfFormatted;
      }
    }
    if (documentNumberFormatted.length === 14) {
      const cnpj = documentNumberFormatted.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
      if (cnpj != null) {
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        return cnpjFormatted;
      }
    }
    return documentNumberFormatted;
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
      />
      <T2>Empresas</T2>
      <FilterForm
        onFormSubmit={handleBusinessFilter}
      />
      <Button onClick={handleBusinessDownload}>Download</Button>
      {(user.userType === 3) && (
        <Link to="/tecpay/admin/empresas/novaEmpresa">
          <Button icon={<PlusOutlined />}>Cadastrar nova empresa</Button>
        </Link>
      )}
      <TableContainer>
        <Table
          dataSource={businessItem}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.business.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Business ID" dataIndex="id" key="id" />
          <TableColumn title="Tipo" dataIndex="type" key="type" render={formatUserType} />
          <TableColumn title="CNPJ/CPF" dataIndex="documentNumber" key="documentNumber" render={formatDocument} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Contato" dataIndex="contact" key="contact" />
          <TableColumn title="Telefone" dataIndex="telephone1" key="telephone1" />
          <TableColumn title="Cidade" dataIndex="addressCity" key="addressCity" />
          <TableColumn title="UF" dataIndex="addressState" key="addressState" />
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(i: any, element: any) => (state.business.records.length >= 1 ? (
              <EyeOutlined onClick={() => handleOpenViewUBusinessModal(element)} />
            ) : null)}
          />
          <TableColumn
            title="Política de cobrança"
            dataIndex="politica"
            key="politica"
            render={(i: any, element: any) => (state.business.records.length >= 1 ? (
              <EyeOutlined onClick={() => handleOpenViewBillingPolicyModal(element)}/>
            ) : null)}
          />
          {user.userType !== 7 && (
            <TableColumn
              title="Terminais"
              dataIndex="terminais"
              key="terminais"
              render={(i: any, element: any) => (state.business.records.length >= 1 ? (
                <EyeOutlined onClick={() => handleOpenViewTerminalsModal(element)}/>
              ) : null)}
            />
          )}
        </Table>
      </TableContainer>
      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
          <ResponsiveTableTitle>CPF/CNPJ</ResponsiveTableTitle>
          <ResponsiveTableTitle>Empresa</ResponsiveTableTitle>
          <ResponsiveTableTitle>Telefone</ResponsiveTableTitle>
        </ResponsiveRow>
        {businessItem.map((item: any) => (
          <ResponsiveRow
            key={item.id}
            style={{ backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
            onClick={() => handleOpenViewUBusinessModal(item)}
          >
            <ResponsiveColumn><p>{item.documentNumber}</p></ResponsiveColumn>
            <ResponsiveColumn><p>{item.name}</p></ResponsiveColumn>
            <ResponsiveColumn><p>{item.telephone1}</p></ResponsiveColumn>
          </ResponsiveRow>

        ))}
      </ResponsiveTable>
      <ViewBusinessModal
        business={{ ...state.businessInfo }}
        isVisible={state.isBusinessModalOpen}
        onClose={() => handleViewModalStatus(false)}
      />
      {(billingPolicy &&
        <ViewBillingPolicyModal
          politicaCobranca={billingPolicy}
          isVisible={isBillingPolicyDetailsVisible}
          onClose={() => {setIsBillingPolicyDetailsVisible(false)}}
        />
      )}
      {(terminals &&
        <ViewTerminalsModal
          terminals={terminals}
          isVisible={isTerminalsDetailsVisible}
          onClose={() => {setTerminalsDetailsVisible(false)}}
        />
      )}


    </Container>
  );
};

export default Business;
