import { Bar } from 'react-chartjs-2';
import {BarChartProps} from "./types";

const BarChart: React.FC<{ chartData: any ,chartOptions: any }> = (props: BarChartProps) => {
  const {chartData, chartOptions} = props;
  return <Bar data={chartData} options={chartOptions} style={{
    height:'600px !important',
  }}/>;
};

export default BarChart;
