import { CarteiraVirtualDto } from '../../../global/dataTransferObjects/carteiraVirtual';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean;
  virtualCheckingAccountList: IPagedResult<CarteiraVirtualDto>;
  isPaymentModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL',
  UPDATE_PAYMENT_MODAL_STATUS = 'UPDATE_PAYMENT_MODAL_STATUS',
  GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
