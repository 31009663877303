import {getAxiosInstance} from "../../../services/providers/api";

export default class BusinessService {
  getBusinessDownload = async (filter: any) => {
    const name = filter?.name || null;
    const cnpj = filter?.cnpj || null;

    const result = await getAxiosInstance().get('/payments/business-xls', {
      params: {
        name: name,
        cnpj: cnpj
      },
      responseType: 'blob'
    });

    return result.data;
  }
}
