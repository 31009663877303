import { ICredencialService } from '../@types/services';
import {
  ICreateCredencialDto,
  ICredencialDto,
  IFilterBusinessProps,
  BusinessDto,
} from '../../global/dataTransferObjects/credencial';
import { IPagedResult } from '../../global/generics';
import { ApiException } from '../../global/exceptions';
import {
  HttpMethod,
  IApiResponse,
  ITecpayApi,
} from '../providers/types';
import { ISelectValue } from '../../global/types';

const credencialService = (apiProvider: ITecpayApi): ICredencialService => {
  const createNewCredential = async (veiculoDto: ICreateCredencialDto): Promise<IApiResponse<ICredencialDto>> => {
    const payload = { ...veiculoDto };
    return apiProvider.createRequest<ICreateCredencialDto, ICredencialDto>({ url: '/payments/credentials' })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync();
  };

  const getAllBusiness = async (
    offset: number,
    pageSize: number,
    filter: IFilterBusinessProps,
  ): Promise<IApiResponse<IPagedResult<BusinessDto>>> => {
    const cnpj = filter?.cnpj || null;
    const name = filter?.name || null;
    const response = await apiProvider
      .createRequest<IPagedResult<BusinessDto>>({ url: '/payments/credentials' })
      .withSearch({
        offset,
        pageSize,
        cnpj,
        name,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  const getCredentialList = async (): Promise<IApiResponse<IPagedResult<BusinessDto>>> => {
    const response = await apiProvider
      .createRequest<IPagedResult<BusinessDto>>({ url: '/payments/business' })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  const mapToSelectableBusiness = (business: Array<any>): Array<ISelectValue> => business
    .map((item) => ({
      label: item.business.name,
      value: item.business.id,
    }));

  return ({
    createNewCredential,
    getAllBusiness,
    mapToSelectableBusiness,
    getCredentialList,
  });
};

export default credencialService;
