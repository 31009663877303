import { IInstallmentDto } from '../../global/dataTransferObjects/installment';
import { IInstallmentService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';

export const installmentService = (apiProvider: ITecpayApi): IInstallmentService => {
  const getInstallmentByNsuParcela = async (nsuParcela: string): Promise<IApiResponse<IInstallmentDto>> => apiProvider
    .createRequest<IInstallmentDto>({ url: `/installments/${nsuParcela}` })
    .sendAsync()

  return ({
    getInstallmentByNsuParcela,
  });
};
