import { IConsultaDto } from '../../../../global/dataTransferObjects/consulta';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  consultas: IPagedResult<IConsultaDto>;
  isPaymentModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CNPJ = 'UPDATE_CNPJ',
  OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL',
  UPDATE_PAYMENT_MODAL_STATUS = 'UPDATE_PAYMENT_MODAL_STATUS',
  CREATE_NEW_CNPJ = 'CREATE_NEW_CNPJ',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
