import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  apelido: Yup.string().required('O Apelido do veículo é obrigatório'),
  renavam: Yup
    .string()
    .matches(/^(\d){11}$/, 'O Renavam precisa ter 11 caracteres, com zeros a esquerda.')
    .required('O Renavam do veículo é obrigatório'),
  uf: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('O Estado do veículo é obrigatório')
    .nullable()
    .required('O Estado do veículo é obrigatório'),
  placa: Yup.string()
    .required('A placa do veículo é obrigatória'),
});

export default validationSchema;
