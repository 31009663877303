import React, { useEffect, useMemo } from 'react';
import { withFormik } from 'formik';
import { cnpj } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../../LoadingOverlay';
import FormField from '../../Input';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  DateView,
  DateField,
  SelectFieldContainer,
  StyledSelectField,
} from './styles';
import { InputGroup } from '../../../../styles/inputs';
import { FormProps, IFormValues, IFormProps } from './types';
import { createFormatProvider } from '../../../../services/providers/factories';
import { ISelectValue } from '../../../../global/types';
import createInstallmentService from '../../../../services/installment';
import { HttpStatus } from '../../../../services/providers/types';
import InputSearch from '../../InputSearch';

const pagamentoOptions: ISelectValue[] = [
  { label: 'Em análise', value: 'Em análise' },
  { label: 'Cancelado', value: 'Cancelado' },
  { label: 'Pago', value: 'Pago' },
];

const chargebackOptions: ISelectValue[] = [
  { label: 'Recebido', value: 'Recebido' },
  { label: 'Enviado EC', value: 'Enviado EC' },
  { label: 'Aguardando Prazo', value: 'Aguardando Prazo' },
  { label: 'Documentação Recebida', value: 'Documentação Recebida' },
  { label: 'Documentação Enviada', value: 'Documentação Enviada' },
  { label: 'Em Análise', value: 'Em Análise' },
  { label: 'Descontado', value: 'Descontado' },
  { label: 'Revertido', value: 'Revertido' },
];

const EditarChargebackModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
    chargeback,
    setFieldValue,
    values,
  } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = useMemo(() => (date: Date): Date | undefined => (date !== undefined
    ? new Date(date.toString().slice(0, 10).replace(/-/g, '/'))
    : undefined), []);

  const formattedValue = useMemo(() => (cents: number) => (cents !== undefined
    ? formatProvider.convertFromCents(cents).replace(/[R$]/g, '')
    : formatProvider.convertFromCents(0).replace(/[R$]/g, '')), []);

  useEffect(() => {
    setFieldValue('adquirente', chargeback.adquirente);
    setFieldValue('arn', chargeback.arn);
    setFieldValue('bandeiraCartao', chargeback.bandeiraCartao);
    setFieldValue('codigoAutorizacao', chargeback.codigoAutorizacao);
    setFieldValue('dataChargeback', formattedDate(chargeback.dataChargeback));
    setFieldValue('dataHoraTransacao', formattedDate(chargeback.dataHoraTransacao));
    setFieldValue('dataLimiteRetorno', formattedDate(chargeback.dataLimiteRetorno));
    setFieldValue('dataPlanilha', formattedDate(chargeback.dataPlanilha));
    setFieldValue('estabelecimento', chargeback.estabelecimento);
    setFieldValue('filial', chargeback.filial);
    setFieldValue('nsuParcela', chargeback.nsuParcela?.replace(/[^0-9]/g, ''));
    setFieldValue('numeroCartao', chargeback.numeroCartao);
    setFieldValue('numeroDocumento', chargeback.numeroDocumento?.replace(/[^0-9]/g, ''));
    setFieldValue('observacao', chargeback.observacao);
    setFieldValue('parcela', chargeback.parcela);
    setFieldValue('razaoChargeback', chargeback.razaoChargeback);
    setFieldValue('statusChargeback', { label: chargeback.statusChargeback, value: chargeback.statusChargeback });
    setFieldValue('statusPagamento', { label: chargeback.statusPagamento, value: chargeback.statusPagamento });
    setFieldValue('tipoChargeback', chargeback.tipoChargeback);
    setFieldValue('tipoTransacao', chargeback.tipoTransacao);
    setFieldValue('valorChargeback', formattedValue(chargeback.valorChargeback));
    setFieldValue('valorVenda', formattedValue(chargeback.valorVenda));
    setFieldValue('dataAviso', formattedDate(chargeback.dataAviso));
  }, [isVisible, chargeback]);

  useEffect(() => {
    const handleSetMaskedInput = () => {
      if (values.numeroDocumento?.length === 14) {
        const documento = values.numeroDocumento.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        if (documento !== null) {
          const documentoFormatted = `${documento[1]}.${documento[2]}.${documento[3]}/${documento[4]}-${documento[5]}`;
          setFieldValue('numeroDocumento', documentoFormatted);
        }
      }

      if (values.nsuParcela?.length === 14) {
        const nsu = values.nsuParcela.match(/^([0-9]{12})([0-9]{1})([0-9]{1})/);
        if (nsu !== null) {
          const nsuFormatted = `${nsu[1]}-${nsu[2]}/${nsu[3]}`;
          setFieldValue('nsuParcela', nsuFormatted);
        }
      }
    };

    handleSetMaskedInput();
  }, [values.numeroDocumento, values.nsuParcela]);

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  const handleGetInstallment = useMemo(() => async (value: string): Promise<void> => {
    const formattedNsuParcela = value.replace(/[^0-9]/g, '');
    if (formattedNsuParcela.length !== 14) {
      return;
    }

    const installmentService = createInstallmentService();
    const data = await installmentService.getInstallmentByNsuParcela(formattedNsuParcela);
    if (data.status !== HttpStatus.OK) {
      toast.error(`Não foi possível obter informações com o número de NSU/Pacela: ${values.nsuParcela}`);
      return;
    }

    setFieldValue('numeroCartao', data.response?.cardNumber);
    setFieldValue('parcela', data.response?.installmentNumber);
    setFieldValue('estabelecimento', data.response?.businessName);
    setFieldValue('adquirente', data.response?.adquirente);
  }, [values.nsuParcela]);

  return (
    <Modal
      title="Editar chargeback"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={800}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay show={loading} relative />
        <Row>
          <Col>
            <InputGroup>
              <DateView>
                <DateField
                  name="dataChargeback"
                  label="Data de chargeback"
                  placeholder="Data de chargeback"
                />
              </DateView>
              <DateView>
                <DateField
                  name="dataPlanilha"
                  label="Data recebimento"
                  placeholder="Data recebimento"
                />
              </DateView>
            </InputGroup>
            <InputGroup>
              <DateView>
                <DateField
                  name="dataLimiteRetorno"
                  label="Data limite de retorno"
                  placeholder="Data limite de retorno"
                />
              </DateView>
              <FormField
                name="numeroCartao"
                type="text"
                label="Número do cartão"
                placeholder="Número do cartão"
              />
            </InputGroup>
            <InputGroup>
              <DateView>
                <DateField
                  name="dataHoraTransacao"
                  label="Data de transação"
                  placeholder="Data de transação"
                />
              </DateView>
              <InputSearch
                name="nsuParcela"
                type="text"
                label="NSU/Parcela"
                placeholder="NSU/Parcela"
                onSearch={handleGetInstallment}
                allowClear
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="codigoAutorizacao"
                type="text"
                label="Código autorização"
                placeholder="Código autorização"
              />
              <FormField
                name="valorVenda"
                type="text"
                label="Valor venda"
                placeholder="valor venda"
                onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorVenda')}
                prefix="R$"
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="arn"
                type="text"
                label="ARN"
                placeholder="ARN"
              />
              <FormField
                name="valorChargeback"
                type="text"
                label="Valor de chargeback"
                placeholder="Valor de chargeback"
                onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorChargeback')}
                prefix="R$"
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="estabelecimento"
                type="text"
                label="Empresa"
                placeholder="Empresa"
              />
              <FormField
                name="filial"
                type="text"
                label="Filial"
                placeholder="Filial"
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="razaoChargeback"
                type="text"
                label="Razão chargeback"
                placeholder="Razão chargeback"
              />
              <FormField
                name="parcela"
                type="text"
                label="Número de parcelas"
                placeholder="Número de parcelas"
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="adquirente"
                type="text"
                label="Adquirente"
                placeholder="Adquirente"
              />
              <FormField
                name="numeroDocumento"
                type="text"
                label="CNPJ"
                placeholder="CNPJ"
              />
            </InputGroup>
            <InputGroup>
              <FormField
                name="bandeiraCartao"
                type="text"
                label="Bandeira"
                placeholder="Bandeira"
              />
              <FormField
                name="tipoTransacao"
                type="text"
                label="Tipo de transação"
                placeholder="Tipo de transação"
              />
            </InputGroup>
            <InputGroup>
              <SelectFieldContainer>
                <StyledSelectField
                  name="statusChargeback"
                  placeholder="Status chgbk"
                  label="Status chgbk"
                  options={chargebackOptions}
                />
              </SelectFieldContainer>
              <SelectFieldContainer>
                <StyledSelectField
                  name="statusPagamento"
                  placeholder="Status pgto"
                  label="Status pgto"
                  options={pagamentoOptions}
                />
              </SelectFieldContainer>
            </InputGroup>
            <InputGroup>
              <FormField
                name="tipoChargeback"
                type="text"
                label="Tipo de chargeback"
                placeholder="Tipo de chargeback"
              />
              <FormField
                name="observacao"
                type="text"
                label="Observação"
                placeholder="Observação"
              />
            </InputGroup>
            <InputGroup>
              <DateView>
                <DateField
                  name="dataAviso"
                  label="Data de aviso"
                  placeholder="Data de aviso"
                />
              </DateView>
            </InputGroup>
            <Button htmlType="submit">
              Atualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const SignForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    dataChargeback: '',
    dataPlanilha: '',
    dataLimiteRetorno: '',
    numeroCartao: '',
    dataHoraTransacao: '',
    nsuParcela: '',
    codigoAutorizacao: '',
    valorChargeback: '',
    arn: '',
    parcela: '',
    estabelecimento: '',
    filial: '',
    razaoChargeback: '',
    observacao: '',
    adquirente: '',
    numeroDocumento: '',
    bandeiraCartao: '',
    tipoTransacao: '',
    valorVenda: '',
    statusPagamento: null,
    tipoChargeback: '',
    statusChargeback: null,
    dataAviso: '',
  }),
  handleSubmit: async (values, { props }) => {
    const numeroDocumento = values.numeroDocumento.replace(/[^0-9]/g, '');
    if (!cnpj.isValid(numeroDocumento)) {
      toast.error('Número de documento inválido!');
      return;
    }

    const nsuParcela = values.nsuParcela.replace(/[^0-9]/g, '');
    const valorChargeback = parseFloat(values.valorChargeback.replace('.', '').replace(',', '.')) * 100;
    const valorVenda = parseFloat(values.valorVenda.replace('.', '').replace(',', '.')) * 100;
    const formattedValues = {
      ...values,
      valorChargeback: valorChargeback.toString(),
      valorVenda: valorVenda.toString(),
      numeroDocumento,
      nsuParcela,
    };

    await props.onFormSubmit({ ...formattedValues });
  },
})(EditarChargebackModal);

export default SignForm;
