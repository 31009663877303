import React, {useEffect, useState} from 'react';
import {
  BarcodeOutlined,
  CarOutlined,
  CarryOutOutlined,
  FileSearchOutlined,
  QrcodeOutlined, UserOutlined,
} from '@ant-design/icons';
import {BiCopy, BiTransfer} from 'react-icons/bi';
import {VscLiveShare} from 'react-icons/vsc';
import {RiFolderTransferLine} from 'react-icons/ri';
import {FaRegCreditCard} from 'react-icons/fa';
import {BsBoxArrowUp, BsCardList} from 'react-icons/bs';
import {AiOutlineFilePdf, AiOutlineProfile, AiFillCreditCard} from 'react-icons/ai';
import {MdOutlineAccountBalanceWallet, MdOutlineRequestPage} from 'react-icons/md';
import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import {ISideMenuProps} from '../types';
import {RoutesEnum} from '../../../routes/types';
import {IConfigData} from "../../../store/config/types";
import logo from "../../../assets/tecpay-no-bg.png";

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const screenSize = window.screen.width;
  const {collapsed} = props;
  const [primaryColor, setPrimaryColor] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) : null;

  useEffect(() => {
    const domain = window.location.hostname;
    const cachedConfig: IConfigData = JSON.parse(localStorage.getItem(`config_${domain}`) as string);
    if (cachedConfig) {
      setPrimaryColor(cachedConfig.corPrimaria);
      setLogoUrl(cachedConfig.linkImagem2);
    } else {
      setPrimaryColor('#0e2b27');
    }
  }, []);

  return (
    <Sider
      width={screenSize > 768 ? 230 : 130}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{backgroundColor: primaryColor}}
    >
      <Logo to="/dashboard">
        <TecpayLogo src={logoUrl} />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
        style={{backgroundColor: primaryColor}}
      >
        <Item icon={<UserOutlined/>} key="">
          <a target="_blank" rel="noreferrer" href="https://valoriapp.dbs.moneyp.com.br/login">
            <b>Acesso à conta</b>
          </a>
        </Item>

        {selectedBusiness?.allowPaymentLink && (
          <SubMenu key="linkpagamento" title="Link de pagamento" icon={<BiTransfer/>}>
            <Item icon={<VscLiveShare/>} key="listarlinks">
              <Link to="/listar-links">
                Histórico de links
              </Link>
            </Item>
            <Item icon={<VscLiveShare/>} key="cadastrarlinks">
              <Link to="/cadastrar-links">
                Gerar Link
              </Link>
            </Item>
          </SubMenu>
        )}

        {selectedBusiness?.allowPaymentLink && (
          <SubMenu key="assinatura" title="Assinaturas" icon={<BiTransfer/>}>
            <Item icon={<VscLiveShare/>} key="listarAssinaturas">
              <Link to="/listar-assinaturas">
                Histórico de assinaturas
              </Link>
            </Item>
            <Item icon={<VscLiveShare/>} key="cadastrarAssinaturas">
              <Link to="/cadastrar-assinaturas">
                Gerar assinaturas
              </Link>
            </Item>
          </SubMenu>
        )}

        <Item icon={<VscLiveShare/>} key="painelVendas">
          <Link to="/tecpay/admin/painel-de-vendas">
            Vendas
          </Link>
        </Item>
        <Item icon={<VscLiveShare/>} key="painelRecebiveis">
          <Link to="/tecpay/admin/painel-de-recebiveis">
            Recebíveis
          </Link>
        </Item>
        <SubMenu key="simulador" title="Simulador" icon={<BiTransfer/>}>
          <Item key="simulador-recebimento">
            <Link to="/simulador/recebimento">
              Quero receber
            </Link>
          </Item>
          <Item key="simulador-venda">
            <Link to="/simulador/venda">
              Quero vender por
            </Link>
          </Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
