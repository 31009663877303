import { IPaymentService } from '../@types/services';
import {
  IPagamentoDebitoVeicularDto,
  IPagamentoDebitoVeicularResponseDto,
} from '../../global/dataTransferObjects/debitosVeiculares';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';

const paymentService = (apiProvider: ITecpayApi): IPaymentService => {
  const efetuarPagamento = async (
    paymentDto: IPagamentoDebitoVeicularDto,
  ): Promise<IApiResponse<IPagamentoDebitoVeicularResponseDto>> => {
    const url = '/veiculos/debitos-veiculares/pagamento';
    return apiProvider
      .createRequest<IPagamentoDebitoVeicularDto, IPagamentoDebitoVeicularResponseDto>({ url })
      .withMethod(HttpMethod.POST)
      .withData(paymentDto)
      .sendAsync();
  }

  const efetuarPagamentoMultasRenainf = async (
    paymentDto: IPagamentoDebitoVeicularDto,
  ): Promise<IApiResponse<IPagamentoDebitoVeicularResponseDto>> => {
    const url = '/veiculos/multas-renainf/pagamento';
    return apiProvider
      .createRequest<IPagamentoDebitoVeicularDto, IPagamentoDebitoVeicularResponseDto>({ url })
      .withMethod(HttpMethod.POST)
      .withData(paymentDto)
      .sendAsync();
  }

  const getVeiculoPagamentos = (veiculoId: string): Promise<IApiPagedResponse<IPagamentoDebitoVeicularResponseDto>> => {
    const url = '/veiculos/debitos-veiculares/:veiculoId/pagamentos';
    return apiProvider
      .createRequest<IPagedResult<IPagamentoDebitoVeicularResponseDto>>({ url })
      .withParameters({ veiculoId })
      .sendAsync();
  }

  return ({
    efetuarPagamento,
    getVeiculoPagamentos,
    efetuarPagamentoMultasRenainf,
  });
};

export default paymentService;
