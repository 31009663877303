import { ITerminalsTable } from "../../types";
import {ResponsiveColumn, ResponsiveRow, TableContainer} from "../../styles";
import Table, { TableColumn } from "../../../../components/Table";
import React, { useEffect, useState } from "react";
import TerminaisPhoebusService from "../../service/terminaisPhoebusService";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import {formatAcquirer, formatDate, formatModel} from "../../utils";

export const LiveTable: React.FC<ITerminalsTable> = (props: ITerminalsTable) => {
  const { dataFilter } = props;
  const terminaisPhoebusService = new TerminaisPhoebusService();
  const [terminals, setTerminals] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [terminalTotals, setTerminalTotals] = useState<number>(0);
  const [dataFilterState, setDataFilterState] = useState<any>();
  const formattedAcquirer = (value: number) => formatAcquirer(value);
  const formattedDate = (date: string) => formatDate(date);
  const formattedModel = (model: string) => formatModel(model);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await terminaisPhoebusService.getAllTerminals(
          currentPage,
          pageSize,
          dataFilter
        );

        const parsedResponse = JSON.parse(response.data.response);
        const content = parsedResponse.content.map((item: any) => {
          const tefConfigWithPriorityOne = item.tef_config.find(
            (config: any) => config.merchant_config.priority === 1
          );

          return {
            ...item,
            tef_acquirer: tefConfigWithPriorityOne ? tefConfigWithPriorityOne.tef_acquirer : null,
          };
        });

        setTerminals(content);
        setTerminalTotals(parsedResponse.total_elements);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize, dataFilterState]);

  useEffect(() => {
    if (dataFilter) {
      setCurrentPage(1);
      setDataFilterState(dataFilter);
    }
  }, [dataFilter]);

  return (
    <div>
      <LoadingOverlay show={isLoading} relative />
      <TableContainer>
        <Table
          dataSource={terminals}
          pagination={{
            defaultPageSize: 20,
            defaultCurrent: 1,
            current: currentPage,
            onShowSizeChange: (current: number, size: number) => {
              setPageSize(size);
              setCurrentPage(current);
            },
            total: terminalTotals,
            onChange: (pageNum, pageSize) => {
              setCurrentPage(pageNum);
            },
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} Terminais`,
          }}
        >
          <TableColumn title="Merchant" dataIndex="merchant" key="merchant" />
          <TableColumn title="Terminal" dataIndex="terminal" key="terminal" />
          <TableColumn title="Status" dataIndex="status" key="status" />
          <TableColumn title="Serial Number" dataIndex="serial_number" key="serial_number" />
          <TableColumn title="Modelo" dataIndex="model_name" key="model_name" render={formatModel}/>
          <TableColumn title="Adquirente"  dataIndex="tef_acquirer" key="tef_acquirer" render={formattedAcquirer}/>
          <TableColumn title="Data Da Ultima Transação" dataIndex="last_transaction_date" key="last_transaction_date" render={formatDate}/>
          <TableColumn title="Data Da Criação" dataIndex="created_at" key="created_at" render={formatDate}/>
          <TableColumn title="Data Da ultima atualização" dataIndex="updated_at" key="updated_at" render={formatDate} />
        </Table>
      </TableContainer>

      <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
        <ResponsiveColumn style={{width: "33%"}}><h4>Merchant</h4></ResponsiveColumn>
        <ResponsiveColumn style={{width: "33%"}}><h4>Terminal</h4></ResponsiveColumn>
        <ResponsiveColumn style={{width: "33%"}}><h4>Serial Number</h4></ResponsiveColumn>
      </ResponsiveRow>
      {terminals.map((terminal: any) =>{
        return (
          <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>
            <ResponsiveColumn style={{width: "33%"}}>
              <p>{(terminal.merchant)}</p>
            </ResponsiveColumn>
            <ResponsiveColumn style={{width: "33%"}}>
              <p>{(terminal.terminal)}</p>
            </ResponsiveColumn>
            <ResponsiveColumn style={{width: "33%"}}>
              <p>{(terminal.serial_number)}</p>
            </ResponsiveColumn>
          </ResponsiveRow>
        )
      })}
    </div>
  );
};

export default LiveTable;
