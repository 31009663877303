import { SalesDto } from '../../../global/dataTransferObjects/vendas';
import { IPagedResult } from '../../../global/generics';

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_SALES = 'GET_ALL_SALES',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}

export interface IPageState {
  sales: IPagedResult<SalesDto>;
  loading: boolean;
}
