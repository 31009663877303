import React, { useEffect } from 'react';
import {
  useLocation,
} from 'react-router-dom';

const ResetPasswordApp: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery();

  const id: string | null = query.get('id');

  useEffect(() => {
    window.location.replace(`tecpay://app/redefinirsenha/${id}`);
  }, []);
  return (
    <>
      <p> </p>
    </>
  );
};

export default ResetPasswordApp;
