import React from 'react';
import { useHistory } from 'react-router-dom';

import SignUpForm from './signForm';
import { IFormValues } from './signForm/types';
import { RoutesEnum } from '../../routes/types';
import { SignUpProps } from './types';
import { ICreateNewUserDto } from '../../global/dataTransferObjects/user';

const SignUp: React.FC<SignUpProps> = (props: SignUpProps) => {
  const history = useHistory();
  const { isLoading, isAuthenticated } = props;

  const onSignUpFormSubmit = async (values: IFormValues) => {
    const newUserInfo: ICreateNewUserDto = {
      ...values,
      endereco: {
        cep: values.cep,
        logradouro: values.logradouro,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        uf: values.uf,
      },
    }

    const result = await props.createNewUser(newUserInfo);
    if (!result) {
      return;
    }

    history.push(RoutesEnum.SignIn);
  };

  if (isAuthenticated) {
    history.push(RoutesEnum.Veiculos);
    return null;
  }

  return (
    <SignUpForm
      loading={isLoading}
      onSignUpFormSubmit={onSignUpFormSubmit}
    />
  );
}

export default SignUp;
