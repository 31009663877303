import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withFormik } from 'formik';
import {
  Table,
  Col,
  Row,
  Popconfirm,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { FormProps, IFormProps, IFormValues } from './types';
import FormInput from '../../../../../components/Forms/Input';
import { Form } from './styles';
import { FormTextArea } from '../../../../../components/Forms/TextArea';
import { Button } from '../../../../../styles/buttons';
import { createFormatProvider } from '../../../../../services/providers/factories';
import validationSchema from './validationSchema';
import { TableColumn } from '../../../../../components/Table';
import AsyncFormDropdown from '../../../../../components/Forms/AsyncFormDropdown';
import { ISelectValue } from '../../../../../global/types';
import { IAsyncDropdownOptions } from '../../../../../global/dataTransferObjects/dropdown';
import { IFilterUserProps, UserDto } from '../../../../../global/dataTransferObjects/user';
import { AdditionalType } from '../../../../../components/Forms/AsyncFormDropdown/types';
import createUserService from '../../../../../services/user';

const PoliticaCobrancaForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    setFieldValue,
    politicaContaDigital,
    foundedSelectedUsers,
  } = props;

  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [users, setUsers] = useState<Array<UserDto>>([]);
  const [pageSize] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter] = useState<IFilterUserProps>({
    hasAccountAttached: 'true',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value).replace(/[R$%]/g, '')
    : formatProvider.formatPrice(0).replace(/[R$%]/g, '')), []);

  useEffect(() => {
    setFieldValue('nome', politicaContaDigital.nome);
    setFieldValue('descricao', politicaContaDigital.descricao);
    setFieldValue('taxaPixPagamento', formattedValue(politicaContaDigital.taxaPixPagamento));
    setFieldValue('taxaPixRecebimento', formattedValue(politicaContaDigital.taxaPixRecebimento));
    setFieldValue('taxaPixConsultaInfo', formattedValue(politicaContaDigital.taxaPixConsultaInfo));
    setFieldValue('taxaMensalidadeCartao', formattedValue(politicaContaDigital.taxaMensalidadeCartao));
    setFieldValue('taxaMensalideConta', formattedValue(politicaContaDigital.taxaMensalideConta));
    setFieldValue('taxaCargaCartao', formattedValue(politicaContaDigital.taxaCargaCartao));
    setFieldValue('taxaTransferenciaEntreProxies', formattedValue(politicaContaDigital.taxaTransferenciaEntreProxies));
    setFieldValue('taxaTransferenciaInterna', formattedValue(politicaContaDigital.taxaTransferenciaInterna));
    setFieldValue('taxaEmissaoBoleto', formattedValue(politicaContaDigital.taxaEmissaoBoleto));
    setFieldValue('taxaPagamentoBoleto', formattedValue(politicaContaDigital.taxaPagamentoBoleto));
    setFieldValue('taxaLiquidacaoBoleto', formattedValue(politicaContaDigital.taxaLiquidacaoBoleto));
    setFieldValue('taxaConsultaInfoBoleto', formattedValue(politicaContaDigital.taxaConsultaInfoBoleto));
    setFieldValue('tarifaOriginacao', formattedValue(politicaContaDigital.tarifaOriginacao));
    setFieldValue('tarifaGerenciamento', formattedValue(politicaContaDigital.tarifaGerenciamento));
  }, [politicaContaDigital]);

  const handleLoadUsersOptions = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userService = createUserService();
    const result = await userService.getUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      filter,
    );

    setCurrentPage(page + 1);
    return ({
      options: userService.mapToSelectableUsers([...result.response.records]),
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  const handleAddUserToTable = () => {
    const foundedUser = users.find((element: UserDto) => element.id === user.id);

    if (foundedUser) {
      return;
    }

    setUsers((prevState: Array<UserDto>) => [{ ...user }, ...prevState]);
    setFieldValue('selectedUsers', null);
  };

  const handleRemoveRecord = (id: string) => {
    const newUsers = users.filter((element: UserDto) => element.id !== id);
    setUsers([...newUsers]);
  };

  useEffect(() => { setFieldValue('users', [...users]); }, [users]);

  const mappedUsers = useMemo(() => users.map((element: UserDto) => ({
    ...element,
    fullName: `${element.name?.trim()} ${element.lastName?.trim()}`,
  })), [users]);

  useEffect(() => { setUsers([...foundedSelectedUsers]); }, [foundedSelectedUsers]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={18} align="top" justify="center">
        <Col span={12}>
          <FormInput
            type="text"
            name="nome"
            label="Nome"
            required
            placeholder="Nome"
          />
          <FormTextArea
            name="descricao"
            label="Descrição"
            required
            rows={6}
            placeholder="Descrição"
          />
          <FormInput
            type="text"
            name="taxaPixPagamento"
            label="Taxa de pagamento via PIX"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPixPagamento')}
          />
          <FormInput
            type="text"
            name="taxaPixRecebimento"
            label="Taxa de recebimento via PIX"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPixRecebimento')}
          />
          <FormInput
            type="text"
            name="taxaPixConsultaInfo"
            label="Taxa para obter informações de transação PIX"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPixConsultaInfo')}
          />
          <FormInput
            type="text"
            name="taxaMensalidadeCartao"
            label="Taxa de mensalidade do cartão"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaMensalidadeCartao')}
          />
          <FormInput
            type="text"
            name="taxaMensalideConta"
            label="Taxa de mensalidade da conta digital"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaMensalideConta')}
          />
          <FormInput
            type="text"
            name="taxaCargaCartao"
            label="Taxa para carregar o cartão"
            required
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaCargaCartao')}
          />
          <FormInput
            type="text"
            name="taxaTransferenciaEntreProxies"
            label="Taxa de transferência entre proxies"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaTransferenciaEntreProxies')}
          />
          <FormInput
            type="text"
            name="taxaTransferenciaInterna"
            label="Taxa de transfêrencia interna"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaTransferenciaInterna')}
          />
          <FormInput
            type="text"
            name="taxaEmissaoBoleto"
            label="Taxa de emissão de boleto"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaEmissaoBoleto')}
          />
          <FormInput
            type="text"
            name="taxaPagamentoBoleto"
            label="Taxa pagamento de boleto"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaPagamentoBoleto')}
          />
          <FormInput
            type="text"
            name="taxaLiquidacaoBoleto"
            label="Taxa de liquidação de boleto"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaLiquidacaoBoleto')}
          />
          <FormInput
            type="text"
            name="taxaConsultaInfoBoleto"
            label="Taxa para obter informações de um boleto"
            placeholder="0,00"
            prefix="R$"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'taxaConsultaInfoBoleto')}
          />
          <FormInput
            type="text"
            name="tarifaOriginacao"
            label="Tarifa de originação"
            placeholder="0,00"
            prefix="%"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'tarifaOriginacao')}
          />
          <FormInput
            type="text"
            name="tarifaGerenciamento"
            label="Tarifa de gerenciamento"
            placeholder="0,00"
            prefix="%"
            onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'tarifaGerenciamento')}
          />
        </Col>
        <Col span={12}>
          <Row gutter={18} align="middle" justify="center" style={{ marginBottom: '1rem' }}>
            <Col span={18}>
              <AsyncFormDropdown
                name="selectedUsers"
                label="Contas de usuários"
                placeholder="Selecione uma ou mais conta de usuário..."
                loadOptions={handleLoadUsersOptions}
                onChange={(option: ISelectValue) => setUser(option.value)}
              />
            </Col>
            <Col span={6}>
              <Button
                style={{ marginTop: '1rem' }}
                htmlType="button"
                onClick={handleAddUserToTable}
                icon={<PlusOutlined />}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={[...mappedUsers]}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 20,
              total: mappedUsers.length,
            }}
          >
            <TableColumn title="Nome" dataIndex="fullName" key="fullName" />
            <TableColumn title="E-mail" dataIndex="email" key="email" />
            <TableColumn
              title="Excluir"
              align="center"
              render={(_, record: any) => (users.length >= 1 ? (
                <Popconfirm
                  title="Quer remover usuário da lista?"
                  onConfirm={() => handleRemoveRecord(record.id)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ) : null)}
            />
          </Table>
        </Col>
      </Row>
      <Row gutter={18} align="middle" justify="center">
        <Col span={12} />
        <Col span={12}>
          <Button htmlType="submit">
            Alterar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default withFormik<IFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    nome: '',
    descricao: '',
    taxaPixPagamento: '',
    taxaPixRecebimento: '',
    taxaPixConsultaInfo: '',
    taxaMensalidadeCartao: '',
    taxaMensalideConta: '',
    taxaCargaCartao: '',
    taxaTransferenciaEntreProxies: '',
    taxaTransferenciaInterna: '',
    taxaEmissaoBoleto: '',
    taxaPagamentoBoleto: '',
    taxaLiquidacaoBoleto: '',
    taxaConsultaInfoBoleto: '',
    tarifaOriginacao: '',
    tarifaGerenciamento: '',
    users: [],
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => props.onSubmit({ ...values }),
})(PoliticaCobrancaForm);
