import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ICreatePoliticaContaDigitalRequestDTO } from '../../../../global/dataTransferObjects/politicaContaDigital';
import createPoliticaContaDigitalService from '../../../../services/politicaContaDigital';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onCreatePoliticaContaDigital = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_POLITICA_CONTA_DIGITAL,
});

export const createPoliticaContaDigital = (
  politicaContaDigital: ICreatePoliticaContaDigitalRequestDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const politicaContaDigitalService = createPoliticaContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await politicaContaDigitalService.createPoliticaContaDigital({ ...politicaContaDigital });
  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao cadastrar a política de cobrança');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onCreatePoliticaContaDigital()(dispatch);
  return true;
};
