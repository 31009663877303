import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isPaymentModalOpen: false,
  virtualCheckingAccountList: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_TRANSACTIONS:
      return {
        ...state,
        loading: false,
        virtualCheckingAccountList: action.payload?.transactions ?? { ...InitialState.virtualCheckingAccountList },
      };
    case ActionTypes.OPEN_PAYMENT_MODAL:
      return {
        ...state,
        isPaymentModalOpen: true,
      };
    case ActionTypes.UPDATE_PAYMENT_MODAL_STATUS:
      return {
        ...state,
        isPaymentModalOpen: action.payload.status ?? false,
      };
    default:
      return { ...state };
  }
};
