import {
  ICreatePoliticaContaDigitalRequestDTO,
  IPoliticaContaDigitalDTO,
  IPoliticaContaDigitalFilterProps,
  IUpdatePoliticaContaDigitalRequestDTO,
} from '../../global/dataTransferObjects/politicaContaDigital';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';
import { IPoliticaContaDigitalService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const politicaContaDigitalService = (apiProvider: ITecpayApi): IPoliticaContaDigitalService => {
  const getPoliticaContaDigitalList = async (
    offset: number,
    pageSize: number,
    filter: IPoliticaContaDigitalFilterProps,
  ): Promise<IApiPagedResponse<IPoliticaContaDigitalDTO>> => {
    const nome = filter?.nome || null;
    const descricao = filter?.descricao || null;
    const startCreatedAt = filter?.startCreatedAt || null;
    const finishCreatedAt = filter?.finishCreatedAt || null;
    const orderBy = filter?.orderBy || null;

    return apiProvider
      .createRequest<IPagedResult<IPoliticaContaDigitalDTO>>({ url: '/politicas-cobrancas' })
      .withSearch({
        offset,
        pageSize,
        nome,
        descricao,
        startCreatedAt,
        finishCreatedAt,
        orderBy,
      })
      .sendAsync();
  };

  const createPoliticaContaDigital = async (politicaContaDigital: ICreatePoliticaContaDigitalRequestDTO) => {
    const payload = { ...politicaContaDigital };

    return apiProvider
      .createRequest<ICreatePoliticaContaDigitalRequestDTO, IPoliticaContaDigitalDTO>({ url: '/politicas-cobrancas' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  };

  const deletePoliticaContaDigital = async (politicaCobrancaId: string): Promise<IApiResponse<string>> => {
    const url = `/politicas-cobrancas/${politicaCobrancaId}`;

    return apiProvider
      .createRequest<string>({ url })
      .withMethod(HttpMethod.DELETE)
      .sendAsync();
  };

  const updatePoliticaContaDigital = async (
    politicaCobrancaId: string,
    updatePoliticaCobrancaDTO: IUpdatePoliticaContaDigitalRequestDTO,
  ): Promise<IApiResponse<IPoliticaContaDigitalDTO>> => {
    const url = `/politicas-cobrancas/${politicaCobrancaId}`;
    const paylod = { ...updatePoliticaCobrancaDTO }

    return apiProvider
      .createRequest<IUpdatePoliticaContaDigitalRequestDTO, IPoliticaContaDigitalDTO>({ url })
      .withMethod(HttpMethod.PUT)
      .withData({ ...paylod })
      .sendAsync();
  }

  const getPoliticaContaDigital = async (politicaCobrancaId: string): Promise<IApiResponse<IPoliticaContaDigitalDTO>> => {
    const url = `/politicas-cobrancas/${politicaCobrancaId}`;

    return apiProvider
      .createRequest<IPoliticaContaDigitalDTO>({ url })
      .sendAsync();
  }

  return ({
    getPoliticaContaDigitalList,
    createPoliticaContaDigital,
    deletePoliticaContaDigital,
    updatePoliticaContaDigital,
    getPoliticaContaDigital,
  })
}

export default politicaContaDigitalService;
