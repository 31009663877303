export function formatBandeira(bandeira: string): string {
  let bandeiraFormatada = '';

  switch (bandeira) {
    case "MASTERCARD":
    case "MAESTRO":
    case "MASTERCARD DEBITO":
    case "MAESTROCP":
      bandeiraFormatada = 'MASTERCARD';
      break;
    case "VISA ELECTRON":
    case "VISA":
    case "VISA ELECTRON NACIO":
      bandeiraFormatada = 'VISA';
      break;
    case "ELO DEBITO":
    case "ELO CREDITO":
    case "ELO VISA DEBITO":
    case "ELO VISA CREDITO":
      bandeiraFormatada = 'ELO';
      break;
    case "AMEX CREDITO":
    case "AMEX":
      bandeiraFormatada = 'AMEX';
      break;
    case "HIPERCARD CRD":
    case "HIPERCARD":
      bandeiraFormatada = 'HIPERCARD';
      break;
    default:
      break;
  }
  return bandeiraFormatada;
}

export function formatMoney(value: number) {
  const formattedValue = (value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue;
}
