import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../LoadingOverlay';
import CredencialForm from '../../CredencialForm';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
} from './styles';
import { ICredencialFormValues, FormProps, ICadastroCredencialModalProps } from './types';
import validationSchema from './validationSchema';

const CadastroCredencialModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    values,
    onModalClose,
    handleSubmit,
    loading,
    onLoadUfOptions,
    setFieldValue,
  } = props;

  useEffect(() => {
    const documentNumber = values.cnpj?.replace(/[^0-9]/g, '');
    setFieldValue('cnpj', documentNumber);
    const handleDocumentNumber = () => {
      if (documentNumber.length === 14) {
        const cnpj = documentNumber.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        if (cnpj != null) {
          const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
          setFieldValue('cnpj', cnpjFormatted);
        }
      }
    }
    handleDocumentNumber();
  }, [values.cnpj]);

  return (
    <Modal
      title="Cadastrar nova credencial"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro da credencial, por favor aguarde..."
        />
        <Row>
          <Col>
            <CredencialForm onLoadUfOptions={onLoadUfOptions} />
            <Button htmlType="submit">
              Cadastrar credencial
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<ICadastroCredencialModalProps, ICredencialFormValues>({
  mapPropsToValues: (): ICredencialFormValues => ({
    mid: '',
    cnpj: '',
    terminal: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    props.onFormSubmit(values);
  },
})(CadastroCredencialModal);
