import { Dispatch } from 'react';
import { toast } from 'react-toastify';

import { ActionTypes, IPageState } from './types';
import { IAction } from '../../../../../global/types';
import { IPagamentoDebitoVeicularResponseDto } from '../../../../../global/dataTransferObjects/debitosVeiculares';
import createPaymentService from '../../../../../services/payment';
import { HttpStatus } from '../../../../../services/providers/types';

export const InitialState: IPageState = {
  solicitacoes: [],
  loading: false,
};

export const onUpdateLoadingStatus = (status: boolean) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateSolicitacoes = (
  solicitacoes: Array<IPagamentoDebitoVeicularResponseDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_SOLICITACOES,
  payload: {
    solicitacoes: [...solicitacoes],
  },
});

export const getSolicitacoes = (veiculoId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  onUpdateLoadingStatus(true)(dispatch);
  const paymentService = createPaymentService();

  const { response, status } = await paymentService.getVeiculoPagamentos(veiculoId);
  if (status !== HttpStatus.OK) {
    toast.error('Houve um erro ao tentar completar sua solicitação. Tente novamente mais tarde.');
    onUpdateSolicitacoes([])(dispatch);
    return;
  }

  onUpdateSolicitacoes([...response.records])(dispatch);
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_SOLICITACOES:
      return {
        ...state,
        loading: false,
        solicitacoes: [...action.payload.solicitacoes],
      };
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.status ?? false,
      };
    default:
      return { ...state };
  }
};
