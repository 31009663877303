import { IPoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';

export interface IPageState {
  loading: boolean;
  politicaCobranca: IPoliticaCobrancaDTO
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_POLITICA_COBRANCA = 'UPDATE_POLITICA_COBRANCA',
  GET_POLITICA_COBRANCA = 'GET_POLITICA_COBRANCA'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
