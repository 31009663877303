/* eslint-disable camelcase */
import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../components/DatePicker';
import FormInput from '../../../../components/Forms/Input';
import { IFilterArranjo, IFilterArranjosProps } from './types';

import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const FormFilter: React.FC<IFilterArranjo> = (props: any) => {
  const { handleSubmit, resetForm } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={8}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="De"
              />
            </DateView>
          </Col>
          <Col span={8}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Até"
              />
            </DateView>
          </Col>
          <Col span={8}>
            <FormInput
              type="string"
              name="CNPJCreddr"
              label="CNPJ Credidário"
              placeholder="Filtrar pelo CNPJ Credidário"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="string"
              name="NomCreddr"
              label="Nome Credidário"
              placeholder="Filtrar pelo Nome Credidário"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="string"
              name="NULiquid"
              label="Número Liquido"
              placeholder="Filtrar pelo Número Liquido"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="string"
              name="CNPJ_CPFPontoVenda"
              label="CNPJ/CPF do Ponto de Venda"
              placeholder="Filtrar pelo CNPJ/CPF do Ponto de Venda"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="string"
              name="NomePontoVenda"
              label="Nome Ponto de Venda"
              placeholder="Filtrar pelo Nome do Ponto de Venda"
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
          <Col span={2} />
          <Col span={6}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterArranjo, IFilterArranjosProps>({
  mapPropsToValues: (): IFilterArranjosProps => ({
    startDate: '',
    finishDate: '',
    CNPJCreddr: 0,
    NomCreddr: 0,
    NULiquid: 0,
    NomePontoVenda: '',
    CNPJ_CPFPontoVenda: 0,
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const startDate = values?.startDate;
    const finishDate = values?.finishDate;
    const CNPJCreddr = values?.CNPJCreddr;
    const NomCreddr = values?.NomCreddr;
    const NULiquid = values?.NULiquid;
    const NomePontoVenda = values?.NomePontoVenda;
    const CNPJ_CPFPontoVenda = values?.CNPJ_CPFPontoVenda;

    const formattedValues = {
      startDate,
      finishDate,
      CNPJCreddr,
      NomCreddr,
      NULiquid,
      NomePontoVenda,
      CNPJ_CPFPontoVenda,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
