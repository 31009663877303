export const bandeirasCartoes = [
  { value: '99T', label: 'Todos' },
  { value: 'ACC', label: 'Amex Cartão de Crédito' },
  { value: 'AUC', label: 'Aura Cartão de Crédito' },
  { value: 'BCC', label: 'Banescard Cartão de Crédito' },
  { value: 'BNC', label: 'Banese Crédito' },
  { value: 'BRS', label: 'Banrisul (Compra)' },
  { value: 'CBD', label: 'Cabal Débito' },
  { value: 'CSC', label: 'Credi-Shop Cartão de Crédito' },
  { value: 'CZC', label: 'Credz Cartão de Crédito' },
  { value: 'DBC', label: 'Discover Cartão de Crédito' },
  { value: 'ECC', label: 'Elo Cartão de Crédito' },
  { value: 'FRC', label: 'Fortbrasil Cartão de Crédito' },
  { value: 'HCC', label: 'Hipercard Cartão de Crédito' },
  { value: 'JCC', label: 'JCB Cartão de Crédito' },
  { value: 'MCC', label: 'Mastercard Cartão de Crédito' },
  { value: 'MXC', label: 'Maxifrota Cartão de Crédito' },
  { value: 'RCC', label: 'Redesplan Cartão de Crédito' },
  { value: 'SCD', label: 'Sorocred Cartão de Débito' },
  { value: 'SIC', label: 'Sicred (Compra)' },
  { value: 'TKC', label: 'Ticketlog Pós Cartão de Crédito' },
  { value: 'VCD', label: 'Visa Cartão de Débito' },
];
