import {
  ISelectedBusiness,
} from '../../global/dataTransferObjects/business';

export interface IPageState {
  loading: boolean;
  company: Array<ISelectedBusiness>;
  selectedCompany: ISelectedBusiness;
  zeroState: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_COMPANY_LIST = 'GET_COMPANY_LIST',
  SELECTED_COMPANY = 'SELECTED_COMPANY',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
  Logout = '@user/LOGOUT',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
