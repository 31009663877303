import styled from 'styled-components';
import { AsyncPaginate } from 'react-select-async-paginate';
import { IProps } from '../../../styles/types';

export const AsyncPaginateField = styled(AsyncPaginate)`
  & .react-select__control--is-focused {
    border-color: ${(props: IProps) => props.variant} !important;
    box-shadow: none !important;
  }
`;
