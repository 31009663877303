import { IAllSales } from '../../global/dataTransferObjects/vendas'
import { ApiException } from '../../global/exceptions'
import { IPagedResult } from '../../global/generics'
import { ISalesFilterProps } from '../../pages/vendas/types'
import { ISalesService } from '../@types/services'
import { IApiResponse, ITecpayApi } from '../providers/types'

const salesService = (apiProvider: ITecpayApi): ISalesService => {
  const getAllVendas = async (
    offset: number,
    pageSize: number,
    filter?: ISalesFilterProps,
  ): Promise<IApiResponse<IPagedResult<IAllSales>>> => {
    const nomePagador = filter?.nomePagador || null;
    const nomeRecebedor = filter?.nomeRecebedor || null;
    const status = filter?.status || null;
    const start = filter?.start || null;
    const finish = filter?.finish || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllSales>>({ url: '/nossa-cidade/vendas' })
      .withSearch({
        offset,
        pageSize,
        nomePagador,
        nomeRecebedor,
        status,
        start,
        finish,
      })
      .sendAsync()

    if (response.status !== 200) {
      throw new ApiException(response);
    }
    return response;
  };

  return ({ getAllVendas })
}

export default salesService;
