import { IAntecipadoraTransacoesDto } from '../../global/dataTransferObjects/contaDigital/antecipadoraTransacoes';
import { IPagedResult } from '../../global/generics';
import { IAgilitasVincularProxyDto } from '../../global/dataTransferObjects/cartaoAgilitas/vincularProxy'
import { IFilterAntecipadoraTransacoes } from '../../pages/contaEscrow/trava/components/filterForm/types';
import { IAntecipadoraTransacoesService } from '../@types/services';

import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';
import { IAgilitasListarProxyDto } from '../../global/dataTransferObjects/cartaoAgilitas/listarProxys';
import { IFilterProxyListProps } from '../../pages/cartaoProxy/components/FormFilter/types';
import { IContratoDto, ICreateContratoDto, IFilterContratoDto } from '../../global/dataTransferObjects/contratoEscrow';

const contaEscrowService = (apiProvider: ITecpayApi): IAntecipadoraTransacoesService => {
  const getTransacoes = async (
    offset: number,
    pageSize: number,
    filter: IFilterAntecipadoraTransacoes,
  ): Promise<IApiResponse<IPagedResult<IAntecipadoraTransacoesDto>>> => {
    const url = '/contas-digitais/antecipadora/transacoes';

    const start = filter?.start || null;
    const finish = filter?.finish || null;
    const pagamentoId = filter?.pagamentoId || null;
    const tipoServico = filter?.tipoServico || null;
    const originName = filter?.originName || null;
    const originAccountNumber = filter?.originAccountNumber || null;

    const response = apiProvider
      .createRequest<IPagedResult<IAntecipadoraTransacoesDto>>({ url })
      .withSearch({
        offset,
        pageSize,
        start,
        finish,
        pagamentoId,
        tipoServico,
        originName,
        originAccountNumber,
      })
      .sendAsync();

    return response;
  }

  const submitProxy = (
    body: IAgilitasVincularProxyDto,
  ): Promise<IApiResponse<any>> => {
    const url = '/carga-cartao/vincular-proxy'
    const payload = { ...body }
    const response = apiProvider.createRequest<any>({ url })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync()
    return response;
  }

  const getProxyList = async (
    offset: number,
    pageSize: number,
    filter: IFilterProxyListProps | undefined,
  ): Promise<IApiResponse<IPagedResult<IAgilitasListarProxyDto>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const userName = filter?.userName || null;
    const document = filter?.document || null;
    const cardStatus = filter?.cardStatus || null;

    const response = await apiProvider
      .createRequest<IPagedResult<IAgilitasListarProxyDto>>({ url: '/carga-cartao/list-proxys' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
        userName,
        document,
        cardStatus,
      })
      .sendAsync();

    return response;
  }

  const getContratoList = async (
    offset: number,
    pageSize: number,
    filter: IFilterContratoDto | undefined,
  ): Promise<IApiResponse<IPagedResult<IContratoDto>>> => {
    const IdentificadorContrato = filter?.IdentificadorContrato || null;
    const contratoAtivo = filter?.ContratoAtivo || null;
    const tipoContrato = filter?.TipoContrato || null;
    const diaDebitoMensal = filter?.diaDebitoMensal || null;
    const startCreatedAt = filter?.startCreatedAt || null;
    const FinishCreatedAt = filter?.FinishCreatedAt || null;

    const response = await apiProvider
      .createRequest<IPagedResult<IContratoDto>>({ url: '/contratos-trava' })
      .withSearch({
        offset,
        pageSize,
        IdentificadorContrato,
        contratoAtivo,
        tipoContrato,
        diaDebitoMensal,
        startCreatedAt,
        FinishCreatedAt,
      })
      .sendAsync();

    return response;
  }

  const createNewContrato = (
    values: ICreateContratoDto,
  ): Promise<IApiResponse<any>> => {
    const url = '/contratos-trava'
    const payload = { ...values }
    const response = apiProvider.createRequest<any>({ url })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync()
    return response;
  }

  return ({
    getTransacoes,
    submitProxy,
    getProxyList,
    createNewContrato,
    getContratoList,
  });
};

export default contaEscrowService;
