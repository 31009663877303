import React, { useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Container, Box } from './styles';
import { createPoliticaContaDigital } from './actions';
import { IFormValues } from './components/CreateForm/types';
import { InitialState, Reducer } from './actions/reducer';
import PoliticaCobrancaForm from './components/CreateForm';
import { T2 } from '../../../styles/titles';
import { ICreatePoliticaContaDigitalRequestDTO } from '../../../global/dataTransferObjects/politicaContaDigital';

const PoliticaContaDigitalCreate: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();

  const toDecimal = useMemo(() => (value: string) => parseFloat(value.replace('.', '').replace(',', '.')), []);

  const onFormSubmit = async (values: IFormValues) => {
    const formattedValues: ICreatePoliticaContaDigitalRequestDTO = {
      ...values,
      taxaPixPagamento: toDecimal(values.taxaPixPagamento),
      taxaPixRecebimento: toDecimal(values.taxaPixRecebimento),
      taxaPixConsultaInfo: toDecimal(values.taxaPixConsultaInfo),
      taxaMensalidadeCartao: toDecimal(values.taxaMensalidadeCartao),
      taxaMensalideConta: toDecimal(values.taxaMensalideConta),
      taxaCargaCartao: toDecimal(values.taxaCargaCartao),
      taxaTransferenciaEntreProxies: toDecimal(values.taxaTransferenciaEntreProxies),
      taxaTransferenciaInterna: toDecimal(values.taxaTransferenciaInterna),
      taxaEmissaoBoleto: toDecimal(values.taxaEmissaoBoleto),
      taxaPagamentoBoleto: toDecimal(values.taxaPagamentoBoleto),
      taxaLiquidacaoBoleto: toDecimal(values.taxaLiquidacaoBoleto),
      taxaConsultaInfoBoleto: toDecimal(values.taxaConsultaInfoBoleto),
      tarifaOriginacao: toDecimal(values.tarifaOriginacao),
      tarifaGerenciamento: toDecimal(values.tarifaGerenciamento),
    };

    const result = await createPoliticaContaDigital({ ...formattedValues })(dispatch);
    if (!result) {
      return;
    }

    toast.success('Política de cobrança cadastrada com sucesso!');
    history.push('/contas-digitais/politicas-cobrancas');
  };

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
        tip="Realizando o cadastro da política de cobrança, por favor aguarde..."
      />
      <T2>
        Conta Digital
        <br />
        <small>Criar política de cobrança</small>
      </T2>
      <Box>
        <PoliticaCobrancaForm onSubmit={onFormSubmit} />
      </Box>
    </Container>
  );
}

export default PoliticaContaDigitalCreate;
