export enum ConfigActionTypes {
  SET_CONFIG = 'SET_CONFIG',
}

export interface IConfigData {
  // Defina aqui as propriedades específicas da configuração, por exemplo:
  id: number;
  nome: string;
  razaoSocial: string;
  document: string;
  telefone: string;
  linkImagem: string;
  linkImagem2: string;
  dominio: string;
  corPrimaria: string;
  corSecundaria: string;
  ativo: boolean;
  idImagem: string;
  email: string;
  expirationDate?: string;
}

export interface IConfigState {
  data: IConfigData | null;
}
