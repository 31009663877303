import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
  Container,
} from './styles';
import { FormProps, IFilterExtratoCartao, IFilterFormProps } from './types';

const FilterForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    resetForm,
  } = props;

  const resetFormTransaction = async () => {
    resetForm();
    await props.onSubmit({} as IFilterExtratoCartao);
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="start"
                placeholder=""
                label="Data de início (Transação)"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finish"
                placeholder=""
                label="Data do fim (Transação)"
              />
            </DateView>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12} />
          <Col span={6} />
          <Col span={6} />
        </Row>
        <Row gutter={16}>
          <Col span={12} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton onClick={() => resetFormTransaction()}>
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default withFormik<IFilterFormProps, IFilterExtratoCartao>({
  mapPropsToValues: (): IFilterExtratoCartao => ({
    start: '',
    finish: '',
  }),
  handleSubmit: async (values, { props }) => props.onSubmit({ ...values }),
})(FilterForm);
