import React, { useState } from 'react';
import { withFormik } from 'formik';
import { RadioChangeEvent } from 'antd';
import LoadingOverlay from '../../../LoadingOverlay';
import CnpjForm from '../../CnpjForm';
import CpfForm from '../../CpfForm';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Warning,
  Radio,
  RadioGroup,
  Space,
} from './styles';
import { IConsultaFormValues, FormProps, ICadastroCnpjModalProps } from './types';
import validationSchema from './validationSchema';

const CadastroVeiculoModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
    setFieldValue,
    onLoadUfOptions,
    resetForm,
    values,
  } = props;

  const [userType, setUserType] = useState<number>(2);

  const handleChangeUserType = (e: RadioChangeEvent): void => {
    setUserType(e.target.value);
    resetForm();
    setFieldValue('tipoPessoa', e.target.value)
  };

  return (
    <Modal
      title="Cadastrar Nova solicitação"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro da solicitação, por favor aguarde..."
        />
        <RadioGroup onChange={handleChangeUserType} value={userType}>
          <Radio name="userType" value={2}>Pessoa jurídica</Radio>
          <Radio name="userType" value={1}>Pessoa física</Radio>
        </RadioGroup>
        <Row>
          <Col>
            {userType === 2
              && (
                <CnpjForm
                  onLoadUfOptions={onLoadUfOptions}
                  values={values}
                />
              )}
            {userType === 1
              && (
                <CpfForm
                  onLoadUfOptions={onLoadUfOptions}
                  values={values}
                />
              )}

            <Space />
            <Button htmlType="submit">
              Cadastrar
            </Button>
            <Warning>
              **A solicitação pode levar algum tempo até ser analisada.
            </Warning>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<ICadastroCnpjModalProps, IConsultaFormValues>({
  mapPropsToValues: (): IConsultaFormValues => ({
    tipoPessoa: 2,
    nomeIdentificador: '',
    numeroDocumento: '',
    dataInicio: null,
    dataFim: null,
    listaCodigoArranjoPagamento: '',
    credenciadora: undefined,
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const numeroDocumento = values.numeroDocumento.replace(/[^a-zA-Z0-9]/g, '');
    const formattedValues = {
      ...values, numeroDocumento,
    };

    props.onFormSubmit(formattedValues);
  },
})(CadastroVeiculoModal);
