import { IChargebackDto, ICreateChargebackDto } from '../../../global/dataTransferObjects/creditCard/chargeback';
import { IPagedResult } from '../../../global/generics';
import { IFilterChargebackValues } from '../../../pages/creditCard/chargeback/components/FilterForm/types';
import { IChargebackService } from '../../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../../providers/types';

const chargebackService = (apiProvider: ITecpayApi): IChargebackService => {
  const getAllChargebacks = async (
    offset = 0,
    pageSize = 20,
    filter?: IFilterChargebackValues,
  ): Promise<IApiResponse<IPagedResult<IChargebackDto>>> => apiProvider
    .createRequest<IPagedResult<IChargebackDto>>({ url: '/payments/chargebacks' })
    .withSearch({
      offset,
      pageSize,
      ...filter,
      statusPagamento: filter?.statusPagamento?.value,
      statusChargeback: filter?.statusChargeback?.value,
    })
    .sendAsync();

  const createChargeback = async (chargeback: ICreateChargebackDto): Promise<IApiResponse<IChargebackDto>> => {
    const payload = { ...chargeback }

    return apiProvider
      .createRequest<ICreateChargebackDto, IChargebackDto>({ url: '/payments/chargebacks' })
      .withMethod(HttpMethod.POST)
      .withData(payload)
      .sendAsync();
  };

  const deleteChargeback = async (chargebackId: string): Promise<IApiResponse<any>> => apiProvider
    .createRequest<any>({ url: `/payments/chargebacks/${chargebackId}` })
    .withMethod(HttpMethod.DELETE)
    .sendAsync();

  const editChargeback = async (
    chargebackId: string,
    chargeback: ICreateChargebackDto,
  ): Promise<IApiResponse<IChargebackDto>> => {
    const payload = { ...chargeback }

    return apiProvider
      .createRequest<IChargebackDto>({ url: `/payments/chargebacks/${chargebackId}` })
      .withMethod(HttpMethod.PUT)
      .withData(payload)
      .sendAsync();
  };

  const updateChargebackStatus = async (
    statusChargeback: string,
    statusPagamento: string,
    chargebacks: Array<any>,
  ): Promise<IApiResponse<Array<IChargebackDto>>> => {
    const payload = { statusChargeback, statusPagamento, chargebacks: [...chargebacks] }

    return apiProvider
      .createRequest<Array<IChargebackDto>>({ url: '/payments/chargebacks/status' })
      .withMethod(HttpMethod.PATCH)
      .withData(payload)
      .sendAsync();
  }

  return ({
    getAllChargebacks,
    createChargeback,
    deleteChargeback,
    editChargeback,
    updateChargebackStatus,
  });
}

export default chargebackService;
