import React, { useState } from 'react';
import Cards, { Focused, CallbackArgument } from 'react-credit-cards';
import { useFormikContext } from 'formik';

import FormInput from '../Input';
import { ICardInfoValues } from './types';
import {
  Row,
  Col,
  RightCol,
  LeftCol,
  CardContainer,
  FormContainer,
  CardFormContainer,
} from './styles';

const CardForm: React.FC = () => {
  const [focus, setFocus] = useState<Focused>('name');
  const formikContext = useFormikContext<ICardInfoValues>();

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.name;
    setFocus(inputName as Focused);
  };

  const onCardCallbackCalled = (cardInfo: CallbackArgument) => formikContext.setFieldValue('brand', cardInfo.issuer);

  return (
    <CardFormContainer>
      <CardContainer>
        <Cards
          focused={focus}
          callback={onCardCallbackCalled}
          cvc={formikContext.values.cvc}
          name={formikContext.values.cardholderName}
          expiry={formikContext.values.expiry}
          number={formikContext.values.cardNumber}
          locale={{ valid: 'Vencimento' }}
          placeholders={{ name: 'NOME' }}
          preview
        />
      </CardContainer>
      <FormContainer>
        <Row>
          <Col>
            <FormInput
              name="cardNumber"
              label="Número do cartão"
              type="text"
              onFocus={onInputFocus}
              mask="9999999999999999"
            />
          </Col>
        </Row>
        <Row>
          <RightCol>
            <FormInput
              name="cardholderName"
              label="Nome completo"
              type="text"
              onFocus={onInputFocus}
            />
          </RightCol>
          <LeftCol>
            <FormInput
              name="documentNumber"
              label="CPF"
              type="text"
              onFocus={onInputFocus}
              mask="999.999.999/99"
            />
          </LeftCol>
        </Row>
        <Row>
          <RightCol>
            <FormInput
              name="expiry"
              label="Data de expiração"
              type="text"
              onFocus={onInputFocus}
              mask="99/99"
            />
          </RightCol>
          <LeftCol>
            <FormInput
              name="cvc"
              label="Código de segurança"
              type="text"
              mask="999"
              onFocus={onInputFocus}
            />
          </LeftCol>
        </Row>
      </FormContainer>
    </CardFormContainer>
  )
};

export default CardForm;
