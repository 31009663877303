import styled from 'styled-components';
import { Layout } from 'antd';
import { Button as StyledButton } from '../../../../../styles/buttons';

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding-top: 17px;
  width: 20em;
`;

export const CheckboxTitle = styled.text`
  font-size: 18px;
  padding-left: 5px;
  color: black;
`;

export const BusinessForm = styled.form``;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 24px;
`;

export const DateView = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 0.8rem;

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }

    &:focus-visible {

    }
  }
  div.DayPickerInput, input {
   width: 100%;
  }`;

export const Button = styled(StyledButton)`
  margin-top: 20px;
`;
