import * as Yup from 'yup';

const VeiculoValidationSchema = Yup.object().shape({
  vehicles: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .typeError('O veículo é obrigatório')
    .nullable()
    .required('O veículo é obrigatório'),
});

export default VeiculoValidationSchema;
