import React from 'react';
import { useField } from 'formik';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import Label from '../Forms/FieldLabel';
import { DatePickerFieldProps } from './types';
import {
  DatePickerStyled,
  Form,
  ErrorMessage,
} from './styles';

function parseDate(str: string, format: string): Date | void {
  const parsed = dateFnsParse(str, format, new Date());
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: Date, format: string): string {
  const newDate = date.setHours(0, 0, 0);
  return dateFnsFormat(newDate, format);
}

const DatePickerField: React.FC<DatePickerFieldProps> = (props: DatePickerFieldProps) => {
  const {
    name,
    placeholder,
    label,
    required,
  } = props;

  const [field, meta, helpers] = useField<Date>(name);
  const isInvalid = meta.error && meta.touched;

  return (
    <Form>
      {!!label && (
        <Label
          htmlFor={name}
          text={label}
          required={required}
        />
      )}
      <DatePickerStyled
        classNames={{
          container: `${isInvalid ? 'is-invalid' : ''} DayPickerInput`,
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay',
        }}
        inputProps={{ style: { flex: 1, borderWidth: 1, borderColor: '#d9d9d9' }, locale }}
        format="dd/MM/yyyy"
        placeholder={placeholder}
        parseDate={parseDate}
        formatDate={formatDate}
        onDayChange={(day) => helpers.setValue(day)}
        onBlur={field.onBlur}
        value={field.value || undefined}
        dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-Br' }}
      />
      {meta.error && (
        <ErrorMessage>
          {meta.error}
        </ErrorMessage>
      )}
    </Form>
  );
};

export default DatePickerField;
