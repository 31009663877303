import styled from 'styled-components'
import { Button as StyledButton } from '../../styles/buttons';
import {darken} from "polished";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  background-color: #eee;
`

export const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 420px;
  height: auto;
  margin-top: 15px;

  @media(max-width: 950px){
    width: 90%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 2px solid #eee;
  margin: 0 15px;
`

export const Title = styled.text`
  padding: 1rem;
`

export const TitleWrapper = styled.text`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Item = styled.text`
  padding: 8px 0;
  text-transform: uppercase;
`

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 8px;
`

export const ButtonWrapper = styled.div`
  justify-content: right;
  padding: 4px 0;
`

export const ButtonLogoutWrapper = styled.div`
  justify-content: center;
  // padding: 10px 0;
`

export const Button = styled.button`
  padding: 6px;
  background-color: ${(props) => props.theme.colors.blueCyanDarkest};
  border: none;
  color: white;
  cursor: pointer;

  &:active, &:hover, &:focus {
    background-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
    border-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
  }
`;

export const ButtonLogout = styled(StyledButton)`
  margin-top: 20px;
  width: 200px;
`;
