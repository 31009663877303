import React from 'react';
import FormInput from '../Input';
import { ICredencialFormProps } from './types';
import { InputGroup } from '../../../styles/inputs'

const CredencialForm: React.FC<ICredencialFormProps> = () => (
  <>
    <FormInput
      type="text"
      name="mid"
      label="MID"
      required
    />
    <InputGroup>
      <FormInput
        type="text"
        name="cnpj"
        label="CNPJ"
        required
      />
      <FormInput
        type="text"
        name="terminal"
        label="TID/Terminal"
        required
      />
    </InputGroup>
  </>
);

export default CredencialForm;
