import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('O campo senha é obrigatório'),
  password: Yup.string()
    .required('O campo senha é obrigatório')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,30}$/,
      'A senha deve ter no mínimo 6 e no máximo 30 caracteres, pelo menos um número e um caractere especial, exemplo: @$!%*#?&'),
  passwordConfirmation: Yup.string()
    .required('O campo confirmação de senha é obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
});

export default validationSchema;
