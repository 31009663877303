import { IContaDigitalDTO, ISaldoCartaoDto } from '../../../global/dataTransferObjects/contaDigital';

export interface IPageState {
  loading: boolean;
  contaDigital: IContaDigitalDTO;
  saldoCartao: ISaldoCartaoDto;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CONTA_DIGITAL = 'UPDATE_CONTA_DIGITAL',
  SALDO_CARTAO = 'SALDO_CARTAO',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
