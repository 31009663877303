import React, { useReducer, useCallback, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify';
import Table, { TableColumn } from '../../components/Table';
import CadastroVeiculoModal from '../../components/Forms/Pages/CadastroVeiculoModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import {
  getUserVeiculos,
  onUpdatePaymentModalStatus,
  onOpenPaymentModal,
  createNewVeiculo,
} from './actions';
import {
  Container,
  TableContainer,
  Button,
} from './styles';
import { T2 } from '../../styles/titles';
import { ISelectValue } from '../../components/Forms/AsyncFormDropdown/types';
import { IVeiculoFormValues } from '../../components/Forms/Pages/CadastroVeiculoModal/types'
import createIbgeService from '../../services/ibge';

const Veiculos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const fetchUserVeiculos = useCallback(() => getUserVeiculos()(dispatch), []);

  useEffect(() => {
    fetchUserVeiculos();
  }, [fetchUserVeiculos]);

  const updatePaymentModalStatus = (status: boolean) => onUpdatePaymentModalStatus(status)(dispatch);

  const handleLoadUfOptions = async (): Promise<ISelectValue[]> => {
    const ibgeService = createIbgeService();
    const response = await ibgeService.getUfsAsync().catch(() => []);

    return ibgeService.mapUfsToSelect(response);
  };

  const handleButtonClick = () => onOpenPaymentModal()(dispatch);

  const onModalFormSubmit = async (values: IVeiculoFormValues) => {
    const result = await createNewVeiculo(values)(dispatch);
    if (!result) {
      return;
    }

    toast.success('Veículo cadastrado com sucesso');
    fetchUserVeiculos();
  };

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
      />
      <T2>Meus Veículos</T2>
      <Button
        icon={<PlusOutlined />}
        onClick={handleButtonClick}
      >
        Cadastrar veículo
      </Button>
      <TableContainer>
        <Table dataSource={state.veiculos.records}>
          <TableColumn title="Apelido" dataIndex="apelido" key="apelido" />
          <TableColumn title="Renavam" dataIndex="renavam" key="renavam" />
          <TableColumn title="Placa" dataIndex="placa" key="placa" />
          <TableColumn title="Estado" dataIndex="uf" key="uf" />
        </Table>
      </TableContainer>
      <CadastroVeiculoModal
        loading={state.loading}
        isVisible={state.isPaymentModalOpen}
        onModalClose={() => updatePaymentModalStatus(false)}
        onLoadUfOptions={handleLoadUfOptions}
        onFormSubmit={onModalFormSubmit}
      />
    </Container>
  );
};

export default Veiculos;
