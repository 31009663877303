import React, {useMemo, useRef, useState} from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/es/table';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons/lib';
import Table, { TableColumn } from '../../../../../components/Table';
import { ITransactionsTable } from '../../types';
import { createFormatProvider } from '../../../../../services/providers/factories';
import { TableContainer } from '../../styles';

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  terminal: string;
  brand:string;
  authorizationNumber:string;
  cardNumber:string;
  productName:string;
  acquirerNsu:string;
  originalValue:string;
  status:string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const { liveTransactions } = props;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  // const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formattedDate = (date: string) => formatDate(date);
  const formattedMoney = (value: number) => formatMoney(value);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex:any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const formatMoney = (value:number) => {
    const formattedValue = (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const formatStatus = (status:any) => {
    console.log(status);
    let statusStr = '';
    if (status === 0) {
      statusStr = 'Pendente';
    } else if (status === 1) {
      statusStr = 'Confirmada'
    } else if (status === 2) {
      statusStr = 'Desfeita'
    } else if (status === 3) {
      statusStr = 'Negada'
    }

    return statusStr;
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);

    // Pad single digits with leading zero
    // @ts-ignore
    const pad = (num) => (num < 10 ? `0${num}` : num);

    const formattedDate = `${pad(date.getUTCDate())}/${pad(date.getUTCMonth() + 1)}/${date.getUTCFullYear()} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;

    return formattedDate;
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  // if (_.isEmpty(transfer) && !state.loading) {
  //   return (
  //     <ZeroState />
  //   )
  // }
  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };
  return (
    <TableContainer>
      <Table dataSource={liveTransactions}>
        <TableColumn title="Data/Hora" dataIndex="paymentDate" key="paymentDate" render={formattedDate} onFilter={filter('paymentDate')} filterIcon={getColumnSearchProps('paymentDate').filterIcon} filterDropdown={getColumnSearchProps('paymentDate').filterDropdown} />
        <TableColumn title="Tempo" dataIndex="tempo" key="tempo" onFilter={filter('tempo')} filterIcon={getColumnSearchProps('tempo').filterIcon} filterDropdown={getColumnSearchProps('tempo').filterDropdown} />
        <TableColumn title="Empresa" dataIndex="empresa" key="empresa" onFilter={filter('empresa')} filterIcon={getColumnSearchProps('empresa').filterIcon} filterDropdown={getColumnSearchProps('empresa').filterDropdown} />
        <TableColumn title="Terminal" dataIndex="terminal" key="terminal" onFilter={filter('terminal')} filterIcon={getColumnSearchProps('terminal').filterIcon} filterDropdown={getColumnSearchProps('terminal').filterDropdown} />
        <TableColumn title="Autorizacao" dataIndex="authorizationNumber" key="authorizationNumber" onFilter={filter('authorizationNumber')} filterIcon={getColumnSearchProps('authorizationNumber').filterIcon} filterDropdown={getColumnSearchProps('authorizationNumber').filterDropdown} />
        <TableColumn title="Bandeira" dataIndex="brand" key="brand" onFilter={filter('brand')} filterIcon={getColumnSearchProps('brand').filterIcon} filterDropdown={getColumnSearchProps('brand').filterDropdown} />
        <TableColumn title="No. Cartao" dataIndex="cardNumber" key="cardNumber" onFilter={filter('cardNumber')} filterIcon={getColumnSearchProps('cardNumber').filterIcon} filterDropdown={getColumnSearchProps('cardNumber').filterDropdown} />
        <TableColumn title="Tipo" dataIndex="productName" key="productName" onFilter={filter('productName')} filterIcon={getColumnSearchProps('productName').filterIcon} filterDropdown={getColumnSearchProps('productName').filterDropdown} />
        <TableColumn title="NSU" dataIndex="acquirerNsu" key="acquirerNsu" onFilter={filter('acquirerNsu')} filterIcon={getColumnSearchProps('acquirerNsu').filterIcon} filterDropdown={getColumnSearchProps('acquirerNsu').filterDropdown} />
        <TableColumn title="Valor (R$)" render={formatMoney} dataIndex="originalValue" key="originalValue" onFilter={filter('originalValue')} filterIcon={getColumnSearchProps('originalValue').filterIcon} filterDropdown={getColumnSearchProps('originalValue').filterDropdown} />
        <TableColumn title="Status" render={formatStatus} dataIndex="status" key="status" onFilter={filter('status')} filterIcon={getColumnSearchProps('status').filterIcon} filterDropdown={getColumnSearchProps('status').filterDropdown} />
      </Table>
    </TableContainer>
  )
}

export default LiveTable;
