import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React, { useMemo } from 'react';
import Table, { TableColumn } from '../../../../components/Table';
import { createFormatProvider } from '../../../../services/providers/factories';
import { IChargebackTableProps } from '../types';

const ChargebackTable: React.FC<IChargebackTableProps> = (props: IChargebackTableProps) => {
  const {
    chargebacks,
    handleOpenChagebackDetailsModal,
    handleDeleteChargeback,
    handleOpenEditchargebackModal,
    pagination,
    onChargebackSelectionChange,
    selectedChargebacks,
  } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  const convertedMoney = useMemo(() => (cents: number) => (cents !== undefined
    ? formatProvider.convertFromCents(cents)
    : formatProvider.convertFromCents(0)), []);

  const formattedDocumentNumber = useMemo(() => (documentNumber: string) => (documentNumber !== '' || undefined
    ? formatProvider.formatCnpj(documentNumber)
    : 'N/A'), []);

  const chargebacksSelectedKeys: React.Key[] = useMemo(() => selectedChargebacks.map((x) => x.key), [selectedChargebacks]);

  return (
    <Table
      dataSource={[...chargebacks]}
      pagination={{ ...pagination }}
      rowSelection={{
        type: 'checkbox',
        onChange: onChargebackSelectionChange,
        selectedRowKeys: chargebacksSelectedKeys,
      }}
    >
      <TableColumn
        title="Identificador"
        dataIndex="numeroIdentificadorChargeback"
        key="numeroIdentificadorChargeback"
        render={(value) => value || 'N/A'}
      />
      <TableColumn title="Adquirente" dataIndex="adquirente" render={(value) => value || 'N/A'} />
      <TableColumn title="Transação" dataIndex="dataHoraTransacao" render={formattedDate} />
      <TableColumn title="Recebimento" dataIndex="dataPlanilha" render={formattedDate} />
      <TableColumn title="Empresa" dataIndex="estabelecimento" render={(value) => value || 'N/A'} />
      <TableColumn title="CNPJ" dataIndex="numeroDocumento" render={formattedDocumentNumber} />
      <TableColumn title="Bandeira" dataIndex="bandeiraCartao" render={(value) => value || 'N/A'} />
      <TableColumn title="Num. cartão" dataIndex="numeroCartao" render={(value) => value || 'N/A'} />
      <TableColumn title="Tipo transação" dataIndex="tipoTransacao" render={(value) => value || 'N/A'} />
      <TableColumn title="NSU/Parcela" dataIndex="nsuParcela" render={(value) => value || 'N/A'} />
      <TableColumn title="Valor venda" dataIndex="valorVenda" render={convertedMoney} />
      <TableColumn title="Status pgto" dataIndex="statusPagamento" render={(value) => value ?? 'N/A'} />
      <TableColumn title="Tipo chargeback" dataIndex="tipoChargeback" render={(value) => value || 'N/A'} />
      <TableColumn title="Status chgbk" dataIndex="statusChargeback" render={(value) => value ?? 'N/A'} />
      <TableColumn
        title="Detalhes"
        dataIndex="Detalhes"
        key="Detalhes"
        render={(i: any, e: any) => (chargebacks.length >= 1 ? (
          <EyeOutlined
            onClick={() => handleOpenChagebackDetailsModal(e)}
          />
        ) : null)}
      />
      <TableColumn
        title="Editar"
        dataIndex="editar"
        key="editar"
        render={(i: any, e: any) => (chargebacks.length >= 1 ? (
          <Popconfirm
            title="Deseja realmente editar?"
            cancelText="Cancelar"
            okText="Editar"
            onConfirm={() => handleOpenEditchargebackModal(e)}
          >
            <EditOutlined />
          </Popconfirm>
        ) : null)}
      />
      <TableColumn
        title="Excluir"
        dataIndex="excluir"
        key="excluir"
        render={(i: any, e: any) => (chargebacks.length >= 1 ? (
          <Popconfirm
            title="Deseja realmente excluir?"
            cancelText="Cancelar"
            okText="Excluir"
            onConfirm={() => handleDeleteChargeback(e.id)}
          >
            <DeleteOutlined />
          </Popconfirm>
        ) : null)}
      />
    </Table>
  )
};

export default ChargebackTable;
