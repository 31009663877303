import React, { useMemo } from 'react';
import Table, { TableColumn } from '../../../../components/Table';
import { TableContainer } from '../styles';
import { T3 } from '../../../../styles/titles';
import { IDebitosTable } from '../types';
import { createFormatProvider } from '../../../../services/providers/factories';

const DebitosTable: React.FC<IDebitosTable> = (props: IDebitosTable) => {
  const {
    debits,
    selectedDebitos,
    onLicenciamentoSelectionChange,
    onDpvatSelectionChange,
    onIpvaSelectionChange,
    onMultaSelectionChange,
  } = props;

  const licenciamentos = useMemo(() => [debits.licenciamento], [debits]);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedPrice = (price: number) => formatProvider.convertFromCents(price);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const dpvatSelectedKeys = useMemo(() => selectedDebitos.dpvaTs.map((x) => x.key), [selectedDebitos]);
  const ipvaSelectedKeys = useMemo(() => selectedDebitos.ipvas.map((x) => x.key), [selectedDebitos]);
  const multasSelectedKeys = useMemo(() => selectedDebitos.multas.map((x) => x.key), [selectedDebitos]);
  const licenciamentoSelectedKeys = useMemo(() => {
    if (!selectedDebitos.licenciamento.key) {
      return [];
    }

    return licenciamentos.map((x) => x.key);
  }, [selectedDebitos, licenciamentos]);

  return (
    <>
      <TableContainer>
        <T3>Licencimento</T3>
        <Table
          dataSource={licenciamentos}
          rowSelection={{
            type: 'checkbox',
            onChange: onLicenciamentoSelectionChange,
            selectedRowKeys: licenciamentoSelectedKeys,
          }}
        >
          <TableColumn
            title="Taxa Licenciamento"
            dataIndex="taxaLicenciamento"
            render={formattedPrice}
          />
          <TableColumn
            title="Taxa Transferência"
            dataIndex="taxaTransferencia"
            render={formattedPrice}
          />
          <TableColumn title="Código Ocorrencia" dataIndex="codigoOcorrencia" />
          <TableColumn title="Código Receptora" dataIndex="codigoReceptora" />
          <TableColumn title="Identificador" dataIndex="identificador" />
          <TableColumn title="Lote Online" dataIndex="loteOnline" />
          <TableColumn title="Número Prodesp" dataIndex="numeroProdesp" />
          <TableColumn title="Número Transação" dataIndex="numeroTransacao" />
          <TableColumn title="Ocorrência" dataIndex="ocorrencia" />
          <TableColumn title="Serviço" dataIndex="servico" />
          <TableColumn title="Troca Cheque" dataIndex="trocaCheque" />
          <TableColumn
            title="Valor Total"
            dataIndex="valorTotal"
            render={formattedPrice}
          />
        </Table>
      </TableContainer>
      <TableContainer>
        <T3>DPVAT</T3>
        <Table
          dataSource={debits.dpvaTs}
          rowSelection={{
            type: 'checkbox',
            onChange: onDpvatSelectionChange,
            selectedRowKeys: dpvatSelectedKeys,
          }}
        >
          <TableColumn title="Valor" dataIndex="valor" render={formattedPrice} />
          <TableColumn title="Vencimento" dataIndex="vencimento" render={formattedDate} />
          <TableColumn title="Categoria" dataIndex="categoria" />
          <TableColumn title="Identificação Veiculo" dataIndex="identificacaoVeiculo" />
          <TableColumn title="Número parcela" dataIndex="numeroParcela" />
          <TableColumn title="Exercício" dataIndex="exercicio" />
        </Table>
      </TableContainer>
      <TableContainer>
        <T3>IPVA</T3>
        <Table
          dataSource={debits.ipvas}
          rowSelection={{
            type: 'checkbox',
            onChange: onIpvaSelectionChange,
            selectedRowKeys: ipvaSelectedKeys,
          }}
        >
          <TableColumn title="Cota" dataIndex="cota" />
          <TableColumn title="Exercício" dataIndex="exercicio" />
          <TableColumn title="Vencimento" dataIndex="vencimento" render={formattedDate} />
          <TableColumn title="Valor" dataIndex="valor" render={formattedPrice} />
        </Table>
      </TableContainer>
      <TableContainer>
        <T3>Multas</T3>
        <Table
          dataSource={debits.multas}
          rowSelection={{
            type: 'checkbox',
            onChange: onMultaSelectionChange,
            selectedRowKeys: multasSelectedKeys,
          }}
        >
          <TableColumn title="AIIP" dataIndex="aiip" />
          <TableColumn title="Código Orgão Autuador" dataIndex="codigoOrgaoAutuador" />
          <TableColumn title="Data Infração" dataIndex="dataInfracao" render={formattedDate} />
          <TableColumn title="Descrição Equandramento" dataIndex="descricaoEnquadramento" />
          <TableColumn title="Empresa" dataIndex="empresa" />
          <TableColumn title="Guia" dataIndex="guia" />
          <TableColumn title="Hora Infração" dataIndex="horaInfracao" />
          <TableColumn title="Municipio Infração" dataIndex="municipioInfracao" />
          <TableColumn title="Identificador Municipio" dataIndex="municipioId" />
          <TableColumn title="Orgão Autuador" dataIndex="orgaoAutuador" />
          <TableColumn title="Vencimento" dataIndex="vencimento" render={formattedDate} />
          <TableColumn title="Valor" dataIndex="valor" render={formattedPrice} />
        </Table>
      </TableContainer>
    </>
  );
};

export default DebitosTable;
