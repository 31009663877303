import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { format, parseJSON } from 'date-fns';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import { getTransacoes } from './actions';
import { Container, TableContainer } from './styles';
import { T2 } from '../../../styles/titles';
import FilterForm from './components/filterForm';
import { IFilterAntecipadoraTransacoes } from './components/filterForm/types';
import { createFormatProvider } from '../../../services/providers/factories';
import { ContaDigitalTipoServicoEnum } from '../../../global/dataTransferObjects/contaDigital/transacoes';
import { InitialState, Reducer } from './actions/reducer';

const Trava: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterAntecipadoraTransacoes>({});
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const fetchTransacoes = useCallback((
    initial: number = offSet,
    size: number = pageSize,
    filter: IFilterAntecipadoraTransacoes = filterOrder,
  ) => getTransacoes(initial, size, filter)(dispatch), []);
  const data = useMemo(() => state.transacoes.records, [state]);

  const handleSubmit = async (filter : IFilterAntecipadoraTransacoes) => {
    getTransacoes(offSet, pageSize, filter)(dispatch);

    setFilterOrder(filter);
  }

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const getTipoServico = (servico: ContaDigitalTipoServicoEnum): string => {
    switch (servico) {
      case ContaDigitalTipoServicoEnum.pix:
        return 'Pix';
      case ContaDigitalTipoServicoEnum.cargaCartao:
        return 'Cartão';
      case ContaDigitalTipoServicoEnum.contaDigital:
        return 'Conta digital';
      case ContaDigitalTipoServicoEnum.boleto:
        return 'Boleto';
      default:
        return 'Não informado';
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchTransacoes(pageSize * (currentPage - 1), (currentPage * pageSize), filterOrder)
      return;
    }
    if (currentPage === 1) {
      fetchTransacoes(0, (currentPage * pageSize), filterOrder);
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Extrato Escrow</T2>
      <FilterForm onSubmit={handleSubmit} />
      <TableContainer>
        <Table
          dataSource={data.map((prev) => (
            {
              ...prev,
              createdAt: format(parseJSON(prev.createdAt), 'dd/MM/yyyy HH:mm'),
            }
          ))}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.transacoes.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0}>Valor total</TableSumCell>
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} />
                <TableSumCell index={5} />
                <TableSumCell index={6} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0}>Id da Transação</TableSumCell>
                <TableSumCell index={1}>Tipo de pagamento</TableSumCell>
                <TableSumCell index={2}>Valor</TableSumCell>
                <TableSumCell index={3}>Taxa</TableSumCell>
                <TableSumCell index={4}>Taxa de desconto</TableSumCell>
                <TableSumCell index={5}>Data de transação</TableSumCell>
                <TableSumCell index={6}>Tipo de transação</TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn
            title="Id do pagamento"
            dataIndex="pagamentoId"
            key="pagamentoId"
          />
          <TableColumn
            title="Tipo de pagamento"
            dataIndex="tipoServico"
            key="tipoServico"
            render={getTipoServico}
          />
          <TableColumn
            title="Valor"
            dataIndex="amount"
            key="amount"
            render={(item: number) => ({
              props: {
                style: { color: item >= 0 ? '#1fd655' : '#d0342c' },
              },
              children:
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {item >= 0 ? <AiOutlineArrowDown /> : <AiOutlineArrowUp />}
    {formattedValue(item)}
  </div>,
            })}
          />
          <TableColumn
            title="Taxa"
            dataIndex="fee"
            key="fee"
          />
          <TableColumn title="Taxa de desconto" dataIndex="feeAmount" key="feeAmount" />
          <TableColumn title="Nome de Origem" dataIndex="originName" key="originName" />
          <TableColumn
            title="Número da conta de Origem"
            dataIndex="originAccountNumber"
            key="originAccountNumber"
            render={((item : string) => parseInt(item, 10) || '')}
          />
          <TableColumn title="Data da transação" dataIndex="createdAt" key="createdAt" />
        </Table>
      </TableContainer>
    </Container>
  )
}

export default Trava;
