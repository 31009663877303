/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AdiantamentoDto, IAdiantamentoFilterProps, IAllAdiantamentos } from '../../../global/dataTransferObjects/adiantamentos';
import { IPagedResult } from '../../../global/generics';
import createAdiantamentoService from '../../../services/adiantamentos';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_ADIANTAMENTOS,
  payload: {
    status,
  },
});

export const onViewNotaPromissoriaStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.NOTA_PROMISSORIA_MODAL,
  payload: { status },
});

export const onViewNotaPromissoria = (adiantamento: AdiantamentoDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.VIEW_NOTA_PROMISSORIA_MODAL,
  payload: {
    adiantamento: { ...adiantamento },
  },
});

export const openNotaPromissoriaModal = (
  nota: AdiantamentoDto,
) => (dispatch: Dispatch<any>): void => {
  onViewNotaPromissoria({ ...nota })(dispatch);
  onViewNotaPromissoriaStatus(true)(dispatch);
}

export const onGetAllAdiantamentos = (adiantamentos: IPagedResult<IAllAdiantamentos>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_ADIANTAMENTOS,
  payload: {
    adiantamentos: { ...adiantamentos },
  },
})

export const getAllAdiantamentos = (
  offSet: number,
  pageSize: number,
  filter?: IAdiantamentoFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const adiantamentosService = createAdiantamentoService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await adiantamentosService.getAllAdiantamentos(offSet, pageSize, filter);

  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllAdiantamentos(result.response)(dispatch);
}
