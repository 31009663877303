import React, { useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay'
import { T2 } from '../../../styles/titles'
import { submitNewBusiness } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import NewBusinessForm from './components/Form'
import { IFormValues } from './components/Form/types';
import {
  Container,
} from './styles'

const NewBusiness : React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();
  const handleForm = async (values : IFormValues) => {
    const body = {
      ...values,
      type: values.type?.value,
      billingPolicyId: parseInt(values.billingPolicyId?.value, 10),
      anticipationPolicyId: parseInt(values.anticipationPolicyId?.value, 10),
      commercialHierId: parseInt(values.commercialHierId?.value, 10),
      categoriaCode: values.categoriaCode?.value.toString(),
    }
    await submitNewBusiness(body)(dispatch)
    history.push('/tecpay/admin/empresas');
  }
  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Criar Nova Empresa</T2>
      <NewBusinessForm onSubmit={handleForm} />
    </Container>
  )
}

export default NewBusiness;
