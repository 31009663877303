import React from 'react';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForm from './forgotPasswordForm';
import { IFormValues } from './forgotPasswordForm/types';
import { RoutesEnum } from '../../routes/types';
import { IForgotPasswordProps } from './types';

const SignUp: React.FC<IForgotPasswordProps> = (props: IForgotPasswordProps) => {
  const history = useHistory();
  const { isLoading, isAuthenticated } = props;

  const onForgotPasswordFormSubmit = async (values: IFormValues): Promise<void> => props
    .forgotPassword({ ...values });

  if (isAuthenticated) {
    history.push(RoutesEnum.Veiculos);
    return null;
  }

  return (
    <ForgotPasswordForm
      loading={isLoading}
      onForgotPasswordFormSubmit={onForgotPasswordFormSubmit}
    />
  );
}

export default SignUp;
