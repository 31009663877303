import { IPagedResult } from '../../../global/generics';
import { IAgilitasListarProxyDto } from '../../../global/dataTransferObjects/cartaoAgilitas/listarProxys';

export interface IPageState {
  loading: boolean,
  proxys: IPagedResult<IAgilitasListarProxyDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_PROXY_LIST = 'UPDATE_PROXY_LIST',
  SUBMIT_PROXY = 'SUBMIT_PROXY',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
