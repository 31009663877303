import React, { useCallback, useState } from 'react'
import { Col, Row } from 'antd'
import { Field, withFormik } from 'formik'
import { toast } from 'react-toastify';
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown'
import FormInput from '../../../../components/Forms/Input';
import validationSchema from './validationSchema';
import { formatterDocumentNumber } from '../../../../components/Masks';
import createUserService from '../../../../services/user'
import {
  AlignCheckbox,
  Button,
  CheckboxContainer,
  CheckboxTitle,
  Container,
  Form,
  Label,
} from './style'
import { FormProps, IFilterFormProps, IFormValues } from './types'
import { IFilterUserProps } from '../../../usuarios/types';
import { AdditionalType } from '../../../../components/Forms/AsyncFormDropdown/types';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';

const NewProxyForm = (props: FormProps) => {
  const [pageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter] = useState<IFilterUserProps>();

  const {
    handleSubmit,
  } = props;

  const handleUserList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userService = createUserService();
    const result = await userService.getUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      filter,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Dados de um novo Proxy</Label>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <AsyncFormDropdown
              name="codEstabelecimento"
              placeholder=""
              label="Código/ Usuário"
              loadOptions={handleUserList}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <CheckboxContainer>
              <AlignCheckbox>
                <Field
                  name="statusCartao"
                  type="checkbox"
                />
                <CheckboxTitle>Cartão Ativo?</CheckboxTitle>
              </AlignCheckbox>
            </CheckboxContainer>
          </Col>
        </Row>
        <Row gutter={64}>
          <Col span={8}>
            <FormInput
              type="text"
              name="proxyCartao"
              label="Proxy Cartão"
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="number"
              name="packageId"
              label="Pacote"
            />
          </Col>
          <Col span={5}>
            <Button htmlType="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default withFormik<IFilterFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    codEstabelecimento: null,
    statusConta: false,
    statusCartao: false,
    proxyCartao: '',
    packageId: 0,
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const userId = values.codEstabelecimento?.value;
    if (!userId) {
      toast.error('Selecione um usuário para vincular o proxy.');
      return null;
    }

    return props.onSubmit({ ...values })
  },
})(NewProxyForm);
