import { createApi } from '../providers/factories';
import { IConsulta } from '../@types/services';
import consultaService from './consultaService';

const createConsultaService = (): IConsulta => {
  const apiProvider = createApi();
  return consultaService(apiProvider);
};

export default createConsultaService;
