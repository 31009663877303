import {Container} from "./styles";
import {T2} from "../../../styles/titles";
import {useEffect, useState} from "react";
import {TableColumn} from "../../../components/Table";
import Table from "../../../components/Table/table";
import CancelamentoService from "../cancelamentoDeTransacoes/services/CancelamentoService";
import {formatMoney} from "./utils";

const HistoricoDeCancelamentos: React.FC = () => {
  const [cancelHistory, setCancelHistory] = useState<any[]>([]);
  const cancelamentoService = new CancelamentoService();
  const formattedMoney = (value: number) => formatMoney(value)

  const formatDateTime = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.toISOString().slice(0, 16).replace("T", " ");
  };

  const formatDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.toISOString().slice(0, 10);
  };

  useEffect(() => {
    const fetchCancelHistory = async () => {
      try {
        const response = await cancelamentoService.getAllCancelTransactions();

        const formattedData = Array.isArray(response.data.response)
          ? response.data.response.map((item: any) => ({
            ...item,
            cancelationDate: formatDateTime(item.cancelationDate),
            transactionDate: formatDate(item.transactionDate),
          }))
          : [];

        setCancelHistory(formattedData);
      } catch (error) {
        console.error('Error fetching cancel history:', error);
        setCancelHistory([]);
      }
    };

    fetchCancelHistory();
  }, []);

  return(
    <Container>
      <T2>Histórico De Cancelamento</T2>

      <Table dataSource={cancelHistory}>
        <TableColumn title="ID" dataIndex="id" key="id" />
        <TableColumn title="Código do Lojista (PayStore)" dataIndex="merchantId" key="merchantId" />
        <TableColumn title="Código do Lojista (Adquirente)" dataIndex="acquirerMerchantId" key="acquirerMerchantId" />
        <TableColumn title="Data da transação" dataIndex="transactionDate" key="transactionDate" />
        <TableColumn title="NSU Adquirente" dataIndex="acquirerNsu" key="acquirerNsu" />
        <TableColumn title="Autorização" dataIndex="authorizationCode" key="authorizationCode" />
        <TableColumn title="Valor da Venda" dataIndex="transactionValue" key="transactionValue" render={formattedMoney} />
        <TableColumn title="Valor Cancelado" dataIndex="cancelValue" key="cancelValue" render={formattedMoney} />
        <TableColumn title="Observações de Cancelamento" dataIndex="cancelObservations" key="cancelObservations" />
        <TableColumn title="Data do Cancelamento" dataIndex="cancelationDate" key="cancelationDate" />
      </Table>
    </Container>
  )
};

export default HistoricoDeCancelamentos;
