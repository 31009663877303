import {
  ICelcoinAutorizacaoDTO, ICelcoinCreateAutorizacaoDTO, ICelcoinCreatePagamentoDTO, ICelcoinPagamentoDTO, ICelcoinReceiptDto,
} from '../../global/dataTransferObjects/boletos';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';
import { IBoletoService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const boletoService = (apiProvider: ITecpayApi): IBoletoService => {
  const getPagamentos = async (
    offset: number,
    pageSize: number,
  ): Promise<IApiPagedResponse<ICelcoinPagamentoDTO>> => apiProvider
    .createRequest<IPagedResult<ICelcoinPagamentoDTO>>({ url: '/boletos/pagamentos' })
    .withSearch({
      offset,
      pageSize,
    })
    .sendAsync();

  const createPagamento = async (pagamento: ICelcoinCreatePagamentoDTO): Promise<IApiResponse<ICelcoinPagamentoDTO>> => {
    const payload = { ...pagamento };

    return apiProvider
      .createRequest<ICelcoinCreatePagamentoDTO, ICelcoinPagamentoDTO>({ url: '/boletos/pagamentos' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const createAutorizacao = async (autorizacao: ICelcoinCreateAutorizacaoDTO): Promise<IApiResponse<ICelcoinAutorizacaoDTO>> => {
    const payload = { ...autorizacao };

    return apiProvider
      .createRequest<ICelcoinCreateAutorizacaoDTO, ICelcoinAutorizacaoDTO>({ url: '/boletos/autorizacoes' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const getPaymentReceiptByTransactionId = async (transactionId: number): Promise<IApiResponse<ICelcoinReceiptDto>> => apiProvider
    .createRequest<ICelcoinReceiptDto>({ url: `/boletos/comprovantes/${transactionId}` })
    .sendAsync();

  return ({
    getPagamentos,
    createPagamento,
    createAutorizacao,
    getPaymentReceiptByTransactionId,
  })
}

export default boletoService;
