import { IPixCopiaEColaResponseDto } from '../../../../global/dataTransferObjects/pix/copiaECola';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isCreateModalOpen: false,
  isCopiaEColaInfoModalOpen: false,
  pixCopiaECola: {} as IPixCopiaEColaResponseDto,
  pagedPixCopiaECola: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CREATE_MODAL_STATUS:
      return {
        ...state,
        isCreateModalOpen: action.payload?.status ?? InitialState.isCreateModalOpen,
      }
    case ActionTypes.UPDATE_PIX_COPIA_E_COLA:
      return {
        ...state,
        loading: false,
        pixCopiaECola: action.payload?.pixCopiaECola ?? { ...InitialState.pixCopiaECola },
      }
    case ActionTypes.UPDATE_COPIA_E_COLA_INFO_MODAL_STATUS:
      return {
        ...state,
        isCopiaEColaInfoModalOpen: action.payload?.status ?? InitialState.isCopiaEColaInfoModalOpen,
      }
    case ActionTypes.UPDATE_PAGED_PIX_COPIA_E_COLA:
      return {
        ...state,
        loading: false,
        pagedPixCopiaECola: action.payload?.pagedPixCopiaECola ?? { ...InitialState.pagedPixCopiaECola },
      }
    default:
      return { ...state };
  }
};
