import styled from 'styled-components';
import {Card as AntdCard, Layout} from 'antd';

const { Meta: AntdMeta } = AntdCard;

export const Container = styled(Layout)`

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: relative;
  align-items: flex-start;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const CardContainer = styled.div`

  border-radius: 12px;
  background-color: #f9fbff;
  box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);

  text-align: center;

  padding-top: 20px;
  padding-right: 16px;
  padding-left: 12px;

  height: 143px !important;
  width: 400px !important;

  p{
    margin-left: 1rem;
    margin-bottom: 12px;
  }

`;

export const Meta = styled(AntdMeta)`
  font-size: 2rem;
`;

export const ChartPanel = styled.div`

  border-radius: 12px;
  background-color: #f9fbff;
  box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);

  padding: 1rem;
  margin-top: 12px;
  display: flex;
  width: 1265px;
  justify-content: space-between;

  @media (max-width: 769px) {
    display: none;
  }

  canvas{
    margin-top: 30px;
    height: 350px !important;
    width: fit-content !important;
  }

`;

export const IconCircle = styled.div`
  display: flex;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const BrandTable = styled.table`
  margin-left: 1rem;
  margin-top: 12px;
  border-radius: 12px;
  background-color: #f9fbff;
  box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);

  width: 403px;
  height: 560px !important;

  padding: 2em;

  th {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
  }

  img {
    width: 56px;
    margin-left: 15px;
  }

  td {
    margin-right: 1em;
  }

  tr {
    display: flex;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
    vertical-align: center;
    text-align: center;
    height: 75px;
  }

  p {
    font-size: 25px;
    margin-block: 0;
    vertical-align: center;
    align-items: center;
    font-weight: bold;
  }


`;
