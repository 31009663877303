import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes, IPageState } from './types';
import createVeiculoService from '../../../../services/veiculo';
import { ISelectValue } from '../../../../components/Forms/AsyncFormDropdown/types';
import { IDebitosDto, ILicenciamentoDto, IMultaDto } from '../../../../global/dataTransferObjects/debitosVeiculares';
import { TiposDebitosEnum } from '../../../../global/enum';
import { IPagamentoDebitosFormValues } from '../../../../components/Forms/Pages/PagamentoDebitosVeicularesModal/types';
import createPaymentService from '../../../../services/payment';
import { HttpStatus } from '../../../../services/providers/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: { status },
});

export const onUpdateDebitos = (debitos: IDebitosDto, veiculoId: string) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_DEBITS_VEHICLES,
  payload: {
    veiculoId,
    debitos: { ...debitos },
  },
});

export const onUpdateMultasRenainfSelection = (
  multasRenainf: Array<IMultaDto> = [],
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_MULTAS_SELECTION,
  payload: {
    multas: [...multasRenainf],
  },
});

export const onUpdatePaymentModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const onClosePaymentModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CLOSE_PAYMENT_MODAL,
});

export const onOpenPaymentModal = (
  valorLiquido: number,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
  payload: {
    valorLiquido,
  },
});

export const onUpdateTotal = (valorTotal: number) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_TOTAL,
  payload: {
    valorTotal,
  },
});

export const onLoadVeiculosDropdown = async (offset = 0, pageSize = 20): Promise<ISelectValue[]> => {
  const veiculoService = createVeiculoService();
  return veiculoService.loadVeiculosDropdown(offset, pageSize);
};

export const onConsultaVeiculoMultasRenainf = (veiculoId: string) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const veiculoService = createVeiculoService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await veiculoService.getVeiculoMultasRenaif(veiculoId);
  if (result.status !== 200) {
    toast.error(result.message ?? 'Erro ao obter os multas renainf do veiculo.');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  const multasRenainf = veiculoService.mapDebitos(result.response?.multasRenainf, TiposDebitosEnum.MultaRenainf);

  const debitos: IDebitosDto = {
    licenciamento: {} as ILicenciamentoDto,
    dpvaTs: [],
    ipvas: [],
    multas: multasRenainf,
  }

  onUpdateDebitos(debitos, veiculoId)(dispatch);
  return true;
}

export const sendPayment = (
  state: IPageState,
  values: IPagamentoDebitosFormValues,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const paymentService = createPaymentService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await paymentService.efetuarPagamentoMultasRenainf({
    veiculoId: state.vehicleId,
    paymentInfo: {
      installments: values.installments?.value,
      cardInfo: {
        cardBrand: values.brand,
        cardHolder: values.cardholderName,
        cardNumber: values.cardNumber,
        expirationMonth: values.expiry.split('/')[0],
        expirationYear: values.expiry.split('/')[1],
        securityCode: values.cvc,
      },
    },
    dpvaTs: [...state.selectedDebitos.dpvaTs],
    ipvas: [...state.selectedDebitos.ipvas],
    multas: [...state.selectedDebitos.multas],
    licenciamento: state.selectedDebitos.licenciamento.key
      ? { ...state.selectedDebitos.licenciamento }
      : null,
  });

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  return true;
};

export const openPaymentModal = (state: IPageState) => async (dispatch: Dispatch<any>): Promise<void> => {
  const veiculoService = createVeiculoService();
  const { selectedDebitos } = state;
  onUpdateLoadingStatus(true)(dispatch);

  const valorLiquido = await veiculoService.calcularValorTotalDebitosVeiculares({ ...selectedDebitos });

  if (!valorLiquido) {
    toast.error('Selecione ao menos um débito para realizar o pagamento');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onOpenPaymentModal(valorLiquido)(dispatch);
};

export const updateTotal = (
  installment: number,
  state: IPageState,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const veiculoService = createVeiculoService();
  const { selectedDebitos } = state;
  onUpdateLoadingStatus(true)(dispatch);

  const result = await veiculoService.getDebitosVeicularesTaxas();
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Erro ao obter a taxa do servico!')
    return;
  }

  const valorTotal = await veiculoService
    .calcularValorTotalDebitosVeicularesComTaxas(installment, { ...selectedDebitos }, result.response);

  if (!valorTotal) {
    toast.error('Não foi possivel obter o valor total');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onUpdateTotal(valorTotal)(dispatch);
}
