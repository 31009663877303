import React, { useMemo, useState, useCallback } from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
} from './styles';
import { IContratoFormValues, FormProps, IContratoRegisterlModalProps } from './types';
import validationSchema from './validationSchema';
import FormInput from '../../../../components/Forms/Input';
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';
import { AdditionalType } from '../../../../components/Forms/AsyncFormDropdown/types';
import FormDropdown from '../../../../components/Forms/FormDropdown';
import { formatterDocumentNumber } from '../../../../components/Masks';
import createUserAnticipationService from '../../../../services/userAnticipation';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const CadastroUsuarioModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    values,
    onModalClose,
    handleSubmit,
    loading,
    setFieldValue,
  } = props;

  const [pageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { id } = useCurrentUser();

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  const TypeOptions = [
    { value: 1, label: 'Retenção integral diária' },
    { value: 2, label: 'Retenção parcial diária' },
    { value: 3, label: 'Retenção parcial mensal' },
  ];

  const handleUserList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userAnticipationService = createUserAnticipationService();
    const result = await userAnticipationService.listLinkedUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      id,
      undefined,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  return (
    <Modal
      title="Cadastrar novo contrato"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro do novo contrato, por favor aguarde..."
        />
        <Row>
          <Col>
            <FormDropdown
              label="Tipo de contrato"
              options={TypeOptions}
              name="tipoContrato"
              placeholder="Selecione o tipo de contrato"
              isRequired
            />
            <AsyncFormDropdown
              name="clienteTravadoId"
              placeholder=""
              label="Código/ Usuário"
              loadOptions={handleUserList}
            />
            <FormInput
              type="text"
              name="valorContrato"
              label="Valor total do contrato"
              required
              prefix="R$"
              placeholder="0,00"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorContrato')}
            />
            {values.tipoContrato?.value !== null && values.tipoContrato?.value !== 1
            && (
              <>
                <FormInput
                  type="text"
                  name="valorParaDebito"
                  label="Valor a ser debitado parcialmente"
                  required
                  prefix="R$"
                  placeholder="0,00"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorParaDebito')}
                />
              </>
            )}
            {values.tipoContrato?.value === 3
            && (
              <FormInput
                type="text"
                name="diaDebitoMensal"
                label="Dia do débito do contrato"
                required
              />
            )}
          </Col>
        </Row>
        <Button
          htmlType="submit"
        >
          Cadastrar Contrato
        </Button>
      </Form>
    </Modal>
  );
};

export default withFormik<IContratoRegisterlModalProps, IContratoFormValues>({
  mapPropsToValues: (): IContratoFormValues => ({
    valorContrato: '',
    clienteTravadoId: { label: '', value: '' },
    diaDebitoMensal: null,
    tipoContrato: null,
    valorParaDebito: '',
    valorLiquidado: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const valorContrato = parseFloat(values.valorContrato.replace('.', '').replace(',', '.'));
    const valorParaDebito = values.valorParaDebito ? parseFloat(values.valorParaDebito.replace('.', '').replace(',', '.')) : null;
    const valorLiquidado = values.valorLiquidado ? parseFloat(values.valorParaDebito.replace('.', '').replace(',', '.')) : null;
    const tipoContrato = values?.tipoContrato
      ? values.tipoContrato.value : null;
    const clienteTravadoId = values.clienteTravadoId.value;

    const formattedValues = {
      ...values,
      valorContrato,
      valorParaDebito,
      valorLiquidado,
      tipoContrato,
      clienteTravadoId,
    };
    props.onFormSubmit(formattedValues);
  },
})(CadastroUsuarioModal);
