import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  detalhes: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
    totalRepasses: {
      grossAmount: 0,
      payedAmount: 0,
      paymentAmount: 0,
      quantityTotal: 0,
      numRows: 0,
      quantityAsked: 0,
      quantityPayed: 0,
    },
  },
  totalRepasses: {
    AnticipationAmount: 0,
    AnticipationDiscount: 0,
    GrossAmount: 0,
    NumPayments: 0,
    payedAmount: 0,
    paymentAmount: 0,
    ProfitAmount: 0,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_DETALHES_REPASSES:
      return {
        ...state,
        loading: false,
        detalhes: action.payload?.detalhes ?? { ...InitialState.detalhes },
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    default:
      return { ...state };
  }
};
