import { createConfigurationProvider } from '../providers/factories';
import { IViaCepService } from '../@types/services';
import viaCepService from './viaCepService';

const createViaCepService = (): IViaCepService => {
  const configurationProvider = createConfigurationProvider();

  return viaCepService(configurationProvider);
};

export default createViaCepService;
