import { IPoliticaContaDigitalService } from '../@types/services';
import { createApi } from '../providers/factories';
import politicaContaDigitalService from './politicaContaDigitalService';

const createPoliticaContaDigitalService = (): IPoliticaContaDigitalService => {
  const apiProvider = createApi();
  return politicaContaDigitalService(apiProvider);
};

export default createPoliticaContaDigitalService;
