import React from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../LoadingOverlay';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  SelectFieldContainer,
  StyledSelectField,
} from './styles';
import { InputGroup } from '../../../../styles/inputs';
import { FormProps, IFormUpdateCharegbackStatusValues, IFormProps } from './types';
import { ISelectValue } from '../../../../global/types';

const pagamentoOptions: ISelectValue[] = [
  { label: 'Em análise', value: 'Em análise' },
  { label: 'Cancelado', value: 'Cancelado' },
  { label: 'Pago', value: 'Pago' },
];

const chargebackOptions: ISelectValue[] = [
  { label: 'Recebido', value: 'Recebido' },
  { label: 'Enviado EC', value: 'Enviado EC' },
  { label: 'Aguardando Prazo', value: 'Aguardando Prazo' },
  { label: 'Documentação Recebida', value: 'Documentação Recebida' },
  { label: 'Documentação Enviada', value: 'Documentação Enviada' },
  { label: 'Em Análise', value: 'Em Análise' },
  { label: 'Descontado', value: 'Descontado' },
  { label: 'Revertido', value: 'Revertido' },
];

const UpdateChargebackStatusModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
  } = props;

  return (
    <Modal
      title="Editar status de chargebacks"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay show={loading} relative />
        <Row>
          <Col>
            <InputGroup>
              <SelectFieldContainer>
                <StyledSelectField
                  name="statusChargeback"
                  placeholder="Status chargeback"
                  label="Status chargeback"
                  options={chargebackOptions}
                />
              </SelectFieldContainer>
              <SelectFieldContainer>
                <StyledSelectField
                  name="statusPagamento"
                  placeholder="Status pagamento"
                  label="Status pagamento"
                  options={pagamentoOptions}
                />
              </SelectFieldContainer>
            </InputGroup>
            <Button htmlType="submit">
              Atualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const SignForm = withFormik<IFormProps, IFormUpdateCharegbackStatusValues>({
  mapPropsToValues: () => ({
    statusPagamento: null,
    statusChargeback: null,
  }),
  handleSubmit: async (values, { props }) => props.onFormSubmit({ ...values }),
})(UpdateChargebackStatusModal);

export default SignForm;
