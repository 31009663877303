import styled from 'styled-components'

export const SelectGroup = styled.div`
    flex-direction: row;
    height: 20px;
    margin-top: 15px;
`;

export const DateGroup = styled.div`
    flex-direction: row;
    height: 150px;
    align-items: center;
    justify-content: space-around;
    background-color: red;
    margin-top: 15px;
    margin-bottom: 30px;
`;

export const DateView = styled.div`
  height: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border:  '1px solid #C9C9C9';
  border-radius: 5px;
  margin-top: 15px;
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: none;
    margin-top: 2px;
`;
