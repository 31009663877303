import React from 'react';
import { ITableProps } from './types';
import { Table } from './styles';

const CustomTable: React.FC<ITableProps> = (props: ITableProps) => {
  const {
    children,
    dataSource,
    rowSelection,
    onRow,
    isMouseEnteredRow,
    pagination,
    summary,
  } = props;

  return (
    <Table
      bordered
      rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
      locale={{
        emptyText: 'Sem resultados...',
      }}
      dataSource={dataSource}
      rowSelection={rowSelection}
      scroll={{ x: true }}
      onRow={onRow}
      pagination={pagination}
      summary={summary}
    >
      {children}
    </Table>
  );
};

export default CustomTable;
