import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../../components/Forms/Input';
import {
  Button,
  Form,
  Container,
} from './styles';
import { FormProps, IFormValues, IFilterFormProps } from './types';

const FilterForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    setFieldValue,
  } = props;

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              type="text"
              name="valorTransferencia"
              label="Valor"
              required
              prefix="R$"
              placeholder="0,00"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valorTransferencia')}
            />
          </Col>
          <Col span={6}>
            <Button htmlType="submit">
              Recarregar
            </Button>
          </Col>

        </Row>
      </Form>
    </Container>
  )
}
export default withFormik<IFilterFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    valorTransferencia: '',
  }),
  handleSubmit: async (values, { props }) => props.onFormSubmit({ ...values }),
})(FilterForm);
