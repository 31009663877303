import { createConfigurationProvider } from '../providers/factories';
import { ibgeService } from './ibgeService';
import { IIBgeService } from '../@types/services';

const createIbgeService = (): IIBgeService => {
  const configurationProvider = createConfigurationProvider();
  return ibgeService(configurationProvider);
};

export default createIbgeService;
