/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IRepassesFilterProps, IAllRepasses } from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';
import { IAppState } from '../../../../store/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetAllRepasses = (repasses: IPagedResult<IAllRepasses>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_REPASSES,
  payload: {
    repasses: { ...repasses },
  },
});

export const getAllRepasses = (
  offSet: number,
  pageSize: number,
  filter?: IRepassesFilterProps,
  userStateCompany?: IAppState,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getAllRepasses(offSet, pageSize, filter, userStateCompany);
  if (result.status === 500) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error('No momento não podemos listar os repasses, tente novamente mais tarde!');
  }
  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetAllRepasses(result.response)(dispatch);
};
