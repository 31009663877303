import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  mid: Yup.string().required('o valor MID é obrigatório'),
  cnpj: Yup.string()
    .required('O CNPJ é obrigatório'),
  terminal: Yup.string()
    .required('O terminal é obrigatório'),
});

export default validationSchema;
