export enum ActionTypes {
  CREATE_BOLETO_CNAB = 'CREATE_BOLETO_CNAB',
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
export interface IPageState {
  loading: boolean;
}
