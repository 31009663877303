import {
  IAuthenticationRequestDto,
  IAuthenticationResponseDto,
  ICreateNewUserDto,
  ICreateNewUserResponseDto,
  IForgotPasswordDto,
  IResetPasswordDto,
  IValidateResetTokenDto,
  UserDto,
  IUpdateUserDto,
  IFilterUserProps,
  IChangePassword,
  IChangePasswordResponseDto,
} from '../../global/dataTransferObjects/user';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';
import { IUserService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const userService = (apiProvider: ITecpayApi): IUserService => {
  const authenticate = async (userInfo: IAuthenticationRequestDto) => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<IAuthenticationRequestDto, IAuthenticationResponseDto>({ url: '/authentication/user/token' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData(payload)
      .sendAsync();
  };

  const refreshAccessToken = (refreshToken: string): Promise<IApiResponse<IAuthenticationResponseDto>> => {
    const payload: IAuthenticationRequestDto = { refreshToken, grantType: 'refresh_token' };
    return apiProvider
      .createRequest<IAuthenticationRequestDto, IAuthenticationResponseDto>({ url: '/authentication/user/token' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData(payload)
      .sendAsync();
  };

  const getCurrentUser = async (): Promise<UserDto> => {
    const response = await apiProvider
      .createRequest<UserDto>({ url: '/user/me' })
      .sendAsync();

    return response.response;
  };

  const getUsers = async (
    offset: number,
    pageSize: number,
    filter: IFilterUserProps,
  ): Promise<IApiPagedResponse<UserDto>> => {
    const name = filter?.name || null;
    const email = filter?.email || null;
    const documentNumber = filter?.documentNumber || null;
    const city = filter?.city || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const ignoreUserType = filter?.ignoreUserType || null;
    const hasAccountAttached = filter?.hasAccountAttached || null;
    const anticipationId = filter?.anticipationId?.value || null;
    const travaAntecipadora = filter?.travaAntecipadora || null;

    return apiProvider
      .createRequest<IPagedResult<UserDto>>({ url: '/user' })
      .withSearch({
        offset,
        pageSize,
        name,
        email,
        documentNumber,
        city,
        startDate,
        finishDate,
        ignoreUserType,
        hasAccountAttached,
        anticipationId,
        travaAntecipadora,
      })
      .sendAsync();
  };

  const createNewUser = async (userInfo: ICreateNewUserDto) => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<ICreateNewUserDto, ICreateNewUserResponseDto>({ url: '/user' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData({ ...payload })
      .sendAsync();
  }

  const adminCreateNewUser = async (userInfo: ICreateNewUserDto) => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<ICreateNewUserDto, ICreateNewUserResponseDto>({ url: '/user/admin-create-user' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(true)
      .withData({ ...payload })
      .sendAsync();
  }

  const forgotPassword = async (userInfo: IForgotPasswordDto): Promise<IApiResponse<ICreateNewUserResponseDto>> => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<IForgotPasswordDto, ICreateNewUserResponseDto>({ url: 'user/forgot-password' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData({ ...payload })
      .sendAsync();
  }

  const resetPassword = async (userInfo: IResetPasswordDto): Promise<IApiResponse<ICreateNewUserResponseDto>> => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<IResetPasswordDto, ICreateNewUserResponseDto>({ url: 'user/reset-password' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData({ ...payload })
      .sendAsync();
  }

  const validateResetToken = async (userInfo: IValidateResetTokenDto): Promise<IApiResponse<ICreateNewUserResponseDto>> => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<IValidateResetTokenDto, ICreateNewUserResponseDto>({ url: 'user/validate-reset-token' })
      .withMethod(HttpMethod.POST)
      .useAuthentication(false)
      .withData({ ...payload })
      .sendAsync();
  }

  const deleteUser = async (userId: string): Promise<IApiResponse<any>> => apiProvider
    .createRequest<any>({ url: `/user/${userId}` })
    .withMethod(HttpMethod.DELETE)
    .sendAsync();

  const desvincularUsuario = async (userId: string): Promise<IApiResponse<any>> => apiProvider
    .createRequest<any>({ url: `user-anticipation/${userId}` })
    .withMethod(HttpMethod.DELETE)
    // .withData({ userId })
    .sendAsync();

  const updateUser = async (userId: string, userUpdate: IUpdateUserDto): Promise<IApiResponse<any>> => {
    const payload = { ...userUpdate };
    return apiProvider
      .createRequest<any>({ url: `/user/${userId}` })
      .withMethod(HttpMethod.PUT)
      .withData(payload)
      .sendAsync();
  }

  const mapToSelectableUsers = (users: Array<UserDto>) => users.map((user: UserDto) => ({
    label: `${user.name.trim()} ${user.lastName.trim()} - ${user.email}`,
    value: user,
  }));

  const getUsersByPoliticaCobrancaId = async (politicaCobrancaId: string): Promise<IApiResponse<Array<UserDto>>> => {
    const url = `/user/politicas-cobrancas/${politicaCobrancaId}`;

    return apiProvider
      .createRequest<Array<UserDto>>({ url })
      .sendAsync();
  }

  const lockUser = async (userId: string): Promise<IApiResponse<UserDto>> => {
    const url = `/user/trava-antecipadoras/${userId}`;

    return apiProvider
      .createRequest<UserDto>({ url })
      .withMethod(HttpMethod.PATCH)
      .sendAsync();
  }

  const getUserById = async (userId: string): Promise<IApiResponse<UserDto>> => {
    const url = `/user/${userId}`;

    return apiProvider
      .createRequest<UserDto>({ url })
      .sendAsync();
  }

  const changePassword = async (userInfo: IChangePassword) => {
    const payload = { ...userInfo };
    return apiProvider
      .createRequest<IChangePassword, IChangePasswordResponseDto>({ url: '/user/change-password' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  return ({
    refreshAccessToken,
    getCurrentUser,
    getUsers,
    authenticate,
    createNewUser,
    adminCreateNewUser,
    forgotPassword,
    resetPassword,
    validateResetToken,
    deleteUser,
    updateUser,
    mapToSelectableUsers,
    getUsersByPoliticaCobrancaId,
    lockUser,
    getUserById,
    changePassword,
    desvincularUsuario,
  });
};

export default userService;
