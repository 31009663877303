import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IFormValues } from '../../../../components/Forms/Pages/CadastroChargebackModal/types';
import { IFormUpdateCharegbackStatusValues } from '../../../../components/Forms/Pages/UpdateChargebackStatusModal/types';
import { IChargebackDto, ICreateChargebackDto } from '../../../../global/dataTransferObjects/creditCard/chargeback';
import { IPagedResult } from '../../../../global/generics';
import createChargebackService from '../../../../services/creditCard/chargeback';
import { createSecurityProvider } from '../../../../services/providers/factories';
import { HttpStatus } from '../../../../services/providers/types';
import { IFilterChargebackValues } from '../components/FilterForm/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateChargebacks = (
  chargebacks: IPagedResult<IChargebackDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACKS,
  payload: {
    chargebacks: { ...chargebacks },
  },
});

export const onUpdateCadastroChargebackModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CADASTRO_CHARGEBACK_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const onOpenCadastroChargebackModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CADASTRO_CHARGEBACK_MODAL,
});

export const onUpdateChargebackDetailsModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACK_DETAILS_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const onOpenChargebackDetailsModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CHARGEBACK_DETAILS_MODAL,
});

export const onUpdateChargeback = (
  chargeback: IChargebackDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACK,
  payload: {
    chargeback: { ...chargeback },
  },
});

export const onUpdateEditChargebackModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACK_EDIT_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const onUpdateChargebackStatusModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACK_STATUS_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const onOpenChargebackStatusModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CHARGEBACK_STATUS_MODAL,
});

export const onUpdateChargebackSelection = (
  chargebacks: Array<IChargebackDto> = [],
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CHARGEBACKS_SELECTION,
  payload: {
    chargebacks: [...chargebacks],
  },
});

export const getAllChargebacks = (
  offset = 0,
  pageSize = 20,
  filter?: IFilterChargebackValues,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const chargebackService = createChargebackService();
  const securityProvider = createSecurityProvider();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await chargebackService.getAllChargebacks(offset, pageSize, filter);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message);
    return;
  }

  const mappedResponse: IPagedResult<IChargebackDto> = {
    ...result.response,
    records: result.response.records.map((chargeback) => ({
      ...chargeback,
      key: securityProvider.createUuid(),
    })),
  }

  onUpdateChargebacks(mappedResponse)(dispatch);
};

export const createChargeback = (values: IFormValues) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const chargebackService = createChargebackService();
  onUpdateLoadingStatus(true)(dispatch);

  const formattedValues: ICreateChargebackDto = {
    ...values,
    valorChargeback: parseFloat(values.valorChargeback),
    valorVenda: parseFloat(values.valorVenda),
    statusChargeback: values.statusChargeback?.value,
    statusPagamento: values.statusPagamento?.value,
  }

  const result = await chargebackService.createChargeback(formattedValues);
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Houve um erro ao cadastrar novo chargeback');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  onUpdateCadastroChargebackModalStatus(false)(dispatch);
  toast.success('Chargeback cadastrado com sucesso!');
  return true;
}

export const openChargebackDetailsModal = (chargeback: IChargebackDto) => (dispatch: Dispatch<any>): void => {
  onUpdateChargeback({ ...chargeback })(dispatch);
  onUpdateChargebackDetailsModalStatus(true)(dispatch);
}

export const deleteChargeback = (chargebackId: string) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const chargebackService = createChargebackService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await chargebackService.deleteChargeback(chargebackId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Houve um erro ao excluir chargeback');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  toast.success('Chargeback excluído com sucesso!');
  return true;
}

export const editChargeback = (
  chargebackId: string,
  values: IFormValues,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const chargebackService = createChargebackService();
  onUpdateLoadingStatus(true)(dispatch);

  const formattedValues: ICreateChargebackDto = {
    ...values,
    valorChargeback: parseFloat(values.valorChargeback),
    valorVenda: parseFloat(values.valorVenda),
    statusChargeback: values.statusChargeback?.value,
    statusPagamento: values.statusPagamento?.value,
  }

  const result = await chargebackService.editChargeback(chargebackId, { ...formattedValues });
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Houve um erro ao salvar alterações do chargeback');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  onUpdateEditChargebackModalStatus(false)(dispatch);
  toast.success('Alterações do chargeback salvas com sucesso!');
  return true;
}

export const openEditChargebackModal = (chargeback: IChargebackDto) => (dispatch: Dispatch<any>): void => {
  onUpdateChargeback({ ...chargeback })(dispatch);
  onUpdateEditChargebackModalStatus(true)(dispatch);
}

export const updateChargebackStatus = (
  values: IFormUpdateCharegbackStatusValues,
  chargebacks: Array<IChargebackDto>,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const chargebackService = createChargebackService();
  onUpdateLoadingStatus(true)(dispatch);

  const selectedChargebacks: Array<string> = chargebacks.map((x) => x.id);

  const result = await chargebackService.updateChargebackStatus(
    values.statusChargeback?.value,
    values.statusPagamento?.value,
    [...selectedChargebacks],
  );

  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Houve um erro ao salvar alterações do chargeback');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  onUpdateChargebackStatusModalStatus(false)(dispatch);
  toast.success('Alterações do chargeback salvas com sucesso!');
  return true;
}
