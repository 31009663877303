import styled from "styled-components";

export const ValueCard = styled.div`
  display: flex;
  justifyContent: space-between;
  width: 100%;

  div {
    text-align: center;
    margin-right: 20px;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    height: 90px;
    width: 145px;
    border: 1px solid rgba(92, 92, 92, 0.22);
    box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);
  }

  @media (max-width: 940px) {
    flex-wrap: wrap;
    height: auto;
    align-content: space-around;
    justify-content: center;

    div {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      margin-right: 4px;
    }
  }
`;
