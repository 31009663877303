import axios from 'axios';
import { IConfigurationProvider } from '../@types/providers';
import { IUfDto } from '../../global/dataTransferObjects/ibge';
import { IIBgeService } from '../@types/services';
import { ISelectValue } from '../../components/Forms/AsyncFormDropdown/types';

export const ibgeService = (configurationProvider: IConfigurationProvider): IIBgeService => {
  const getUfsAsync = async (): Promise<Array<IUfDto>> => {
    const { ibgeBaseUrl } = configurationProvider.getConfig();
    const response = await axios.get<Array<IUfDto>>(`${ibgeBaseUrl}/v1/localidades/estados`);
    return response.data;
  }

  const mapUfsToSelect = (ufs: Array<IUfDto>): Array<ISelectValue> => ufs.map((x) => ({
    value: x.sigla,
    label: x.nome,
  }));

  return ({
    getUfsAsync,
    mapUfsToSelect,
  });
};
