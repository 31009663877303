import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import {
  IPixCreatePagamentoDTO,
  IPixPagamentoDTO,
  IPixPagamentoFilterDTO,
} from '../../../../global/dataTransferObjects/pix/pagamento';
import { IPagedResult } from '../../../../global/generics';
import createPixService from '../../../../services/pix';
import { HttpStatus } from '../../../../services/providers/types';
import { ITransactionProps } from '../components/comprovanteModal/types';
import { ActionTypes } from './types';

export const onUpdateCreateModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREATE_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateVerifyModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_VERIFY_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateDetailsPaymentModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_DETAILS_PAYMENT_MODAL_STATUS,
  payload: {
    status,
  },
});

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePagamentos = (pagamentos: IPagedResult<IPixPagamentoDTO>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAGAMENTOS,
  payload: {
    pagamentos: { ...pagamentos },
  },
});

export const onUpdatePagamento = (createPagamento: IPixCreatePagamentoDTO) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREATE_PAGAMENTO,
  payload: {
    createPagamento: { ...createPagamento },
  },
});

export const onUpdateTransaction = (
  transaction: ITransactionProps,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_DETAILS_PAYMENT_MODAL_STATUS,
  payload: {
    status: true,
    transaction: { ...transaction },
  },
});

export const createPagamento = (
  pagamento: IPixCreatePagamentoDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.createPixPagamento({ ...pagamento });
  if (result.status !== HttpStatus.CREATED) {
    onUpdateLoadingStatus(false)(dispatch);
    if (!result || !(result.response instanceof Array) || result.response.length === 0) {
      toast.error(result.message ?? 'Houve um erro ao enviar o pagamento via PIX');
      return false;
    }

    result.response.forEach((error) => {
      if (error.message === 'Invalid key format') {
        toast.error('Formato de chave PIX inválida');
        return false;
      }
      if (error.message === 'Bacen Message - Entry not found') {
        toast.error('Chave PIX não encontrada ou inválida');
        return false;
      }

      toast.error('Houve um erro ao enviar o pagamento via PIX');
      return false;
    });

    return false;
  }

  onUpdateCreateModalStatus(false)(dispatch);
  onUpdateLoadingStatus(false)(dispatch);
  return true;
};

export const updatePagamentos = (
  offset: number,
  pageSize: number,
  filter: IPixPagamentoFilterDTO,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.getPagamentos(offset, pageSize, filter);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Houve um erro obter a lista de pagamentos');
    return;
  }

  onUpdatePagamentos(result.response)(dispatch);
};

export const updateCreatePagamentoByDecodedQrCode = (emv: string) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.decodeQrCode(emv);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Código qr code do PIX Copia e Cola inválido!');
    return false;
  }

  const formattedCreatePagamento: IPixCreatePagamentoDTO = {
    amount: result.response.originalValue,
    creditorKey: result.response.entry.key,
    creditorName: result.response.entry.owner.name,
    keyType: result.response.entry.keyType,
    txId: result.response.txId,
    pixPaymentType: 2,
  }

  onUpdatePagamento({ ...formattedCreatePagamento })(dispatch);
  return true;
}

export const updateCreatePagamentoByChavePixInfo = (
  pagamento: IPixCreatePagamentoDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.getChavePixInfo(pagamento.creditorKey);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Erro ao obter informações da chave PIX');
    return false;
  }

  const formattedCreatePagamento: IPixCreatePagamentoDTO = {
    ...pagamento,
    keyType: result.response.keyType,
    creditorName: result.response.owner.name,
  }

  onUpdatePagamento({ ...formattedCreatePagamento })(dispatch);
  return true;
}
export const getTransaction = (endToEndId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.getTransaction(endToEndId);
  onUpdateTransaction(result.response)(dispatch);
  onUpdateLoadingStatus(false)(dispatch);
};
