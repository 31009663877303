import React, { useMemo } from 'react';
import { createFormatProvider } from '../../../../services/providers/factories';
import { IMultasRenainfTable } from '../types';
import Table, { TableColumn } from '../../../../components/Table';
import { TableContainer } from '../styles';

const MultasRenainfTable: React.FC<IMultasRenainfTable> = (props: IMultasRenainfTable) => {
  const { debitos, onMultaSelectionChange } = props;
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  return (
    <TableContainer>
      <Table
        dataSource={debitos.multas}
        rowSelection={{ type: 'checkbox', onChange: onMultaSelectionChange }}
      >
        <TableColumn title="AIIP" dataIndex="aiip" />
        <TableColumn title="Código Orgão Autuador" dataIndex="codigoOrgaoAutuador" />
        <TableColumn title="Data Infração" dataIndex="dataInfracao" render={formattedDate} />
        <TableColumn title="Descrição Equandramento" dataIndex="descricaoEnquadramento" />
        <TableColumn title="Empresa" dataIndex="empresa" />
        <TableColumn title="Guia" dataIndex="guia" />
        <TableColumn title="Hora Infração" dataIndex="horaInfracao" />
        <TableColumn title="Municipio Infração" dataIndex="municipioInfracao" />
        <TableColumn title="Identificador Municipio" dataIndex="municipioId" />
        <TableColumn title="Orgão Autuador" dataIndex="orgaoAutuador" />
        <TableColumn title="Vencimento" dataIndex="vencimento" render={formattedDate} />
        <TableColumn title="Valor" dataIndex="valor" render={formatProvider.convertFromCents} />
      </Table>
    </TableContainer>
  );
};

export default MultasRenainfTable;
