import { ITransferService } from '../@types/services';
import { createApi } from '../providers/factories';
import transferService from './transferService';

const createTransferService = (): ITransferService => {
  const apiProvider = createApi();
  return transferService(apiProvider);
};

export default createTransferService;
