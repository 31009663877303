import cancellationService from './cancellationService';
import { createApi } from '../../providers/factories';
import { ICancellationService } from '../../@types/services';

const createCancellationService = (): ICancellationService => {
  const apiProvider = createApi();
  return cancellationService(apiProvider);
}

export default createCancellationService;
