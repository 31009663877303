import styled from 'styled-components'
import {
  Row as AntdRow,
  Col as AntdCol,
} from 'antd';

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
  lg: { span: 8 },
})``;

export const VerticalLine = styled.hr`
  margin-top: 40px;
  margin-bottom: 0;
  width: 20%;
  height: 100%;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.colors.cyanDark};
  float: center;
  background-color: ${(props) => props.theme.colors.cyanDark};
  `;
