import { TransferDTO } from '../../../../global/dataTransferObjects/transfers';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  transfers: IPagedResult<TransferDTO>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_TRANSFERS = 'GET_ALL_TRANSFERS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
