export const formatCurrency = (value: any) => {
  if (!value) return '';

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
  });
  return formatter
    .format(value / 100)
    .replaceAll(".","")
    .replaceAll(",",".");
};

export const parseCurrency = (value: string) => {
  return parseFloat(value.replace(/\./g, '').replace(',','.'));
;}

export const formatErrorMessage = (value: string) => {
  if (value === '{"message":"  20 -  Transação já cancelada "}') {
    return 'Transação já cancelada';
  } else if (value === '{"message":"transaction not found"}') {
    return 'Transação não encontrada';
  } else if (value === '{"message":"  21 -  O valor do cancelamento excede o disponível. "}') {
    return 'O valor do cancelamento excede o disponível';
  } else {
    return 'Erro não identificado'
  }
};
