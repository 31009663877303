import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IPixCopiaEColaResponseDto, IPixCreateCopiaEColaDTO } from '../../../../global/dataTransferObjects/pix/copiaECola';
import { IPagedResult } from '../../../../global/generics';
import createPixService from '../../../../services/pix';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateCreateModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CREATE_MODAL_STATUS,
  payload: { status },
});

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePixCopiaECola = (pixCopiaECola: IPixCopiaEColaResponseDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PIX_COPIA_E_COLA,
  payload: {
    pixCopiaECola: { ...pixCopiaECola },
  },
});

export const onUpdateInfoModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_COPIA_E_COLA_INFO_MODAL_STATUS,
  payload: { status },
});

export const onUpdatePagedPixCopiaECola = (
  pagedPixCopiaECola: IPagedResult<IPixCopiaEColaResponseDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAGED_PIX_COPIA_E_COLA,
  payload: {
    pagedPixCopiaECola: { ...pagedPixCopiaECola },
  },
});

export const createPixCopiaECola = (
  pixCopiaECola: IPixCreateCopiaEColaDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.createPixCopiaECola({ ...pixCopiaECola });
  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao gerar o PIX Copia e Cola');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onUpdateCreateModalStatus(false)(dispatch);
  onUpdatePixCopiaECola({ ...result.response })(dispatch);
  return true;
};

export const updatePagedPixCopiaECola = (
  offset: number,
  pageSize: number,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const pixService = createPixService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await pixService.getPagedPixCopiaECola(offset, pageSize);
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Houve um erro obter a lista de PIX Copia e Cola');
    return;
  }

  onUpdatePagedPixCopiaECola(result.response)(dispatch);
};
