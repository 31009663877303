import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import Table, { TableColumn } from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import { createFormatProvider } from '../../services/providers/factories';
import {
  getAllcontratos,
  onOpenContratoModal,
  createNewContrato,
} from './actions';
import {
  formatterDocumentNumber,
} from '../../components/Masks';
import {
  Container,
  TableContainer,
  Button,
} from './styles';
import { T2 } from '../../styles/titles';
import CadastroContratoModal from './components/CadastrarContratoModal';

const ContratoEscrow: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder] = useState<any>();
  const userList = state.contratos.records.map((contrato) => ({
    ...contrato,
    name: contrato.clienteTravado.name,
    documentNumber: contrato.clienteTravado.documentNumber,
  }));

  const fetchAllContratos = useCallback(() => getAllcontratos(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllContratos();
  }, [fetchAllContratos]);

  const handleViewContratoModal = (status: boolean) => {
    onOpenContratoModal(status)(dispatch);
  }

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const fetchAllContratosPagination = (initialPage: number, finalPage: number) => {
    getAllcontratos(initialPage, finalPage, filterOrder)(dispatch);
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllContratosPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllContratosPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const onFormContratoSubmit = async (values: any) => {
    const result = await createNewContrato(values)(dispatch);
    if (!result) {
      return;
    }
    toast.success('Contrato cadastrado com sucesso');
    fetchAllContratos();
  }

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatAmount = (amount: any) => {
    if (amount === null || amount === undefined) {
      return format(0);
    }
    return format(amount)
  }

  const handleTipoContrato = (tipoContrato: number) => {
    if (tipoContrato === 1) {
      return 'Retenção integral diária';
    }
    if (tipoContrato === 2) {
      return 'Retenção parcial diária';
    }
    if (tipoContrato === 3) {
      return 'Retenção parcial mensal';
    }
    return ''
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Contrato Domiciliado</T2>
      {/* <FilterForm
        onFormSubmit={handleUserFilter}
      /> */}
      <Button
        onClick={() => handleViewContratoModal(true)}
        icon={<PlusOutlined />}
      >
        Cadastrar Contrato
      </Button>
      <TableContainer>
        <Table
          dataSource={userList}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.contratos.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Contrato ID" dataIndex="identificadorContrato" key="name" />
          <TableColumn title="Tipo de contrato" dataIndex="tipoContrato" key="tipoContrato" render={handleTipoContrato} />
          <TableColumn title="Data de cadastro" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn title="Valor do contrato" dataIndex="valorContrato" key="valorContrato" render={formatAmount} />
          <TableColumn title="Valor pago" dataIndex="valorLiquidado" key="valorLiquidado" render={formatAmount} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Documento" dataIndex="documentNumber" key="documentNumber" render={formatterDocumentNumber} />

        </Table>
      </TableContainer>
      <CadastroContratoModal
        loading={state.loading}
        isVisible={state.isContratoModalOpen}
        onModalClose={() => handleViewContratoModal(false)}
        onFormSubmit={onFormContratoSubmit}
      />
    </Container>
  );
};

export default ContratoEscrow;
