export interface UpdateUserInfo { id: string }

export enum UserActionTypes {
  Logout = '@user/LOGOUT',
  UpdateUserInfo = '@user/UPDATE_USER_INFO',
  AuthenticateRequest = '@user/AUTHENTICATE_REQUEST',
  AuthenticateSuccess = '@user/AUTHENTICATE_SUCCESS',
  AuthenticateFailure = '@user/AUTHENTICATE_FAILURE',
  CreateNewUserRequest = '@user/CREATE_NEW_USER_REQUEST',
  CreateNewUserSuccess = '@user/CREATE_NEW_USER_SUCCESS',
  CreateNewUserFailure = '@user/CREATE_NEW_USER_FAILURE',
  ForgotPasswordRequest = '@user/FORGOT_PASSWORD_REQUEST',
  ForgotPasswordSuccess = '@user/FORGOT_PASSWORD_SUCCESS',
  ForgotPasswordFailure = '@user/FORGOT_PASSWORD_FAILURE',
  ResetPasswordRequest = '@user/RESET_PASSWORD_REQUEST',
  ResetPasswordSuccess = '@user/RESET_PASSWORD_SUCCESS',
  ResetPasswordFailure = '@user/RESET_PASSWORD_FAILURE',
  ValidateResetTokenRequest = '@user/VALIDATE_RESET_TOKEN_REQUEST',
  ValidateResetTokenSuccess = '@user/VALIDATE_RESET_TOKEN_SUCCESS',
  ValidateResetTokenFailure = '@user/VALIDATE_RESET_TOKEN_FAILURE',
}
