import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../components/DatePicker';
import { IFilterRepasse, IFilterRepasseProps } from './types';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';

import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown';

const FormFilter: React.FC<IFilterRepasse> = (props: any) => {
  const { handleSubmit, resetForm, onLoadEmpresas } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  const handleLoadBusinessOptions = async (filter: any, loadOption: any): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadEmpresas(filter, loadOption);
    return ({
      options,
      hasMore: false,
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="De"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Até"
              />
            </DateView>
          </Col>
          <Col span={12}>
            <AsyncFormDropdown
              name="businessName"
              label="Empresa"
              placeholder="Filtre pelo nome da empresa..."
              loadOptions={handleLoadBusinessOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
          <Col span={2} />
          <Col span={6}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterRepasse, IFilterRepasseProps>({
  mapPropsToValues: (): IFilterRepasseProps => ({
    businessName: null,
    startDate: '',
    finishDate: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const businessId = values.businessName?.value;
    const startDate = values?.startDate;
    const finishDate = values?.finishDate;
    const formattedValues = {
      startDate,
      finishDate,
      businessId,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
