import { BarcodeOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useMemo } from 'react';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { createFormatProvider } from '../../../../../services/providers/factories';
import {
  Modal,
  Row,
  Col,
  Button,
  Warning,
} from './styles';
import { ICreateModalProps } from './types';

const CreateModal: React.FC<ICreateModalProps> = (props: ICreateModalProps) => {
  const {
    isVisible,
    onCreateModalClose,
    loading,
    autorizacao,
    onCreatePagamento,
  } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const formattedDate = useMemo(() => (date: string) => (date !== undefined
    ? moment(date).utc().format('DD/MM/YYYY')
    : 'N/A'), []);

  return (
    <Modal
      title="Pagamento de boletos"
      centered
      visible={isVisible}
      onCancel={onCreateModalClose}
      footer={null}
      width={600}
    >
      <LoadingOverlay
        show={loading}
        relative
        tip="Enviando pagamento de boleto, por favor aguarde..."
      />
      <Row>
        <Col>
          <span style={{ fontSize: '30px' }}><BarcodeOutlined /></span>
          <strong>{autorizacao.registerData?.recipient ?? autorizacao?.assignor}</strong>
          <br />
          <span>
            Total:
            {' '}
            <h1>{formattedValue(autorizacao.registerData?.totalUpdated ?? autorizacao?.value)}</h1>
          </span>
          <br />
          {autorizacao.type === 2 ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{`Valor original: ${formattedValue(autorizacao.registerData?.originalValue)}`}</span>
                <span>{`Valor atualizado: ${formattedValue(autorizacao.registerData?.totalUpdated)}`}</span>
                <span>{`Acréscimo: ${formattedValue(autorizacao.registerData?.totalWithAdditional)}`}</span>
                <span>{`Desconto: ${formattedValue(autorizacao.registerData?.totalWithDiscount)}`}</span>
              </div>
              <strong>{`Vencimento: ${formattedDate(autorizacao.dueDate)}`}</strong>
            </div>
          ) : null}
          <Button onClick={onCreatePagamento}>Efetuar pagamento</Button>
          <Warning>
            **O pagamento de boleto pode levar algum tempo até ser efetivado
          </Warning>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateModal;
