import React from 'react';
import { withFormik } from 'formik';
import { toast } from 'react-toastify';

import { FormProps, IFormProps, IVehiclesFormValues } from './types';
import AsyncFormDropdown from '../../AsyncFormDropdown';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';
import {
  Container,
  Form,
  Separator,
  ButtonGroup,
  Button,
  Warning,
} from './styles';
import schema from './schema';

const ConsultaDebitosVeiculoForm: React.FC<FormProps> = (props: FormProps) => {
  const { onLoadVehiclesOptions, handleSubmit } = props;

  const handleLoadVehiclesOptions = async (): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadVehiclesOptions();
    return ({
      options,
      hasMore: false,
    });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Separator>
          <AsyncFormDropdown
            required
            name="vehicle"
            label="Veículos"
            placeholder="Escolha um veículo para consulta..."
            loadOptions={handleLoadVehiclesOptions}
          />
        </Separator>
        <ButtonGroup>
          <Warning>
            **As consultas podem levar algum tempo até serem concluídas
          </Warning>
          <Button htmlType="submit">
            Consultar
          </Button>
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default withFormik<IFormProps, IVehiclesFormValues>({
  validationSchema: schema,
  mapPropsToValues: (): IVehiclesFormValues => ({
    vehicle: null,
  }),
  handleSubmit: (values, { props }) => {
    const veiculoId = values.vehicle?.value;
    if (!veiculoId) {
      toast.error('Selecione um veiculo para efetuar a consulta.');
      return null;
    }

    return props.onFormSubmit(veiculoId);
  },
})(ConsultaDebitosVeiculoForm);
