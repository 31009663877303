import { ICancellationDto } from '../../../global/dataTransferObjects/creditCard/cancellation';
import { IPagedResult } from '../../../global/generics';
import { IFilterCancellationValues } from '../../../pages/creditCard/cancellation/components/FilterForm/types';
import { ICancellationService } from '../../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../../providers/types';

const cancellationService = (apiProvider: ITecpayApi): ICancellationService => {
  const getAllCancellations = async (
    offset = 0,
    pageSize = 20,
    filter?: IFilterCancellationValues,
  ): Promise<IApiResponse<IPagedResult<ICancellationDto>>> => apiProvider
    .createRequest<IPagedResult<ICancellationDto>>({ url: '/payments/cancel' })
    .withSearch({
      offset,
      pageSize,
      ...filter,
      statusCancelamento: filter?.statusCancelamento?.value,
    })
    .sendAsync();

  const updateCancellationStatus = async (
    id: string,
    statusDescription: string,
  ): Promise<IApiResponse<ICancellationDto>> => {
    const payload = { statusDescription }

    return apiProvider
      .createRequest<ICancellationDto>({ url: `/payments/cancel/${id}` })
      .withMethod(HttpMethod.PATCH)
      .withData(payload)
      .sendAsync();
  }

  return ({
    getAllCancellations,
    updateCancellationStatus,
  });
}

export default cancellationService;
