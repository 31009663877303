import {
  IUnidadeRecebivelResponseDto,
} from '../../../../global/dataTransferObjects/unidadeRecebivel';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loadingStatus: boolean;
  isModalOpen: boolean;
  unidadeRecebivel: IUnidadeRecebivelResponseDto;
  unidadesRecebiveis: IPagedResult<IUnidadeRecebivelResponseDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_MODAL_STATUS = 'UPDATE_MODAL_STATUS',
  UPDATE_UNIDADE_RECEBIVEL = 'UPDATE_UNIDADE_RECEBIVEL',
  UPDATE_UNIDADES_RECEBIVEIS = 'UPDATE_UNIDADES_RECEBIVEIS'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
