import React, { useState, useEffect } from 'react';
import {Provider, useDispatch} from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import GlobalStyle from './styles/globals';
import store from './store';
import PersistGate from './components/PersistGate';
import 'react-credit-cards/es/styles-compiled.css';
import {theme} from "./styles/theme";
import axios from "axios";
import {setConfig} from "./store/config/action";
import {IConfigData} from "./store/config/types";
import IConfig from '../src/config/index';

const App: React.FC = () => {
  const customTheme = {
    ...theme,
    colors: {
      ...theme.colors,
    },
  };
  const [custom, setCustom] = useState(customTheme);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchConfig = async () => {
      const domain = window.location.hostname;
      const cachedConfig = localStorage.getItem(`config_${domain}`);

      if (cachedConfig) {
        if (new Date() < new Date(JSON.parse(cachedConfig as string).expirationDate)) {
          const config: IConfigData = JSON.parse(cachedConfig as string);
          customTheme.colors.cyanDark = config.corPrimaria;
          customTheme.colors.blueCyanDarkest = config.corSecundaria;
          setCustom(customTheme);
          setLoading(false);
        } else {
          localStorage.clear();
          fetchConfig();
        }
      } else {
        try {
          let body = {
            "Ativo" : true,
            "Dominio": domain
          }

          const response = await axios.post(
            `${IConfig.apiBaseUrl}/whitelabel`,
            body);

          //@ts-ignore
          let config: IConfigData = response.data != null && response.data.length > 0 ? response.data[0] : null;

          customTheme.colors.cyanDark = '#0e2b27';
          customTheme.colors.blueCyanDarkest = '#fbb651';

          if (config) {
            let expirationDate = new Date();
            expirationDate.setHours(expirationDate.getHours() + 6);

            config.expirationDate = expirationDate.toString();

            localStorage.setItem(`config_${domain}`, JSON.stringify(config));

            customTheme.colors.cyanDark = config.corPrimaria;
            customTheme.colors.blueCyanDarkest = config.corSecundaria;
          }

          setCustom(customTheme);
        } catch (error) {
          console.error('Erro ao buscar as configurações:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchConfig();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={custom}>
        <PersistGate>
          <Routes />
          <GlobalStyle />
        </PersistGate>
        <ToastContainer autoClose={3000} />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
