import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay';
import MultasRenainfTable from './components/multasRenainfTable';
import ConsultaDebitosVeiculoForm from '../../../components/Forms/Pages/ConsultaDebitosVeiculoForm';
import PagamentoModal from '../../../components/Forms/Pages/PagamentoDebitosVeicularesModal';
import TotalCard from '../../../components/TotalCard'
import { InitialState, Reducer } from './actions/reducer';
import {
  onLoadVeiculosDropdown,
  onConsultaVeiculoMultasRenainf,
  onUpdateMultasRenainfSelection,
  openPaymentModal,
  sendPayment,
  updateTotal,
  onClosePaymentModal,
} from './actions';
import createVeiculoService from '../../../services/veiculo';
import { IConsultaMultasRenaifProps } from './types';
import { IPagamentoDebitosFormValues } from '../../../components/Forms/Pages/PagamentoDebitosVeicularesModal/types';
import { ISelectValue } from '../../../components/Forms/AsyncFormDropdown/types';
import { IMultaDto, IRendimentoTaxasServico } from '../../../global/dataTransferObjects/debitosVeiculares';
import { Container } from './styles';
import { T2 } from '../../../styles/titles';

const ConsultaMultasRenainf: React.FC<IConsultaMultasRenaifProps> = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const pageHistory = useHistory();
  const veiculoService = createVeiculoService();

  const handleLoadVehiclesOptions = () => onLoadVeiculosDropdown();

  const onConsultaVeiculoFormSubmit = (veiculoId: string) => {
    onConsultaVeiculoMultasRenainf(veiculoId)(dispatch);
  }

  const onMultaRenainfSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Array<IMultaDto>) => {
    onUpdateMultasRenainfSelection([...selectedRows])(dispatch);
  };

  const onContinueButtonClick = () => openPaymentModal({ ...state })(dispatch);

  const onPaymentModalFormSubmit = async (values: IPagamentoDebitosFormValues): Promise<void> => {
    const result = await sendPayment({ ...state }, { ...values })(dispatch);

    if (!result) {
      toast.error('Houve um erro na solicitação do pagamento!');
      return;
    }

    toast.success('Solicitação de pagamento enviada com sucesso!');
    pageHistory.push('/debitos-veiculares/pagamentos');
  };

  const updatePaymentModalStatus = () => onClosePaymentModal()(dispatch);

  const handleSelectChange = ({ value }: ISelectValue) => updateTotal(value, { ...state })(dispatch);

  const getTotalAmount = (
    installment: number,
    taxasServico: IRendimentoTaxasServico,
  ): Promise<number> => veiculoService
    .calcularValorTotalDebitosVeicularesComTaxas(installment, { ...state.selectedDebitos }, taxasServico);

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
        tip="Realizando a consulta de multas renainf, por favor aguarde..."
      />
      <T2>Multas Renainf</T2>
      <ConsultaDebitosVeiculoForm
        onLoadVehiclesOptions={handleLoadVehiclesOptions}
        onFormSubmit={onConsultaVeiculoFormSubmit}
      />
      <MultasRenainfTable
        debitos={state.debitos}
        onMultaSelectionChange={onMultaRenainfSelectionChange}
      />
      <TotalCard
        debitos={state.selectedDebitos}
        onContinueButtonClick={onContinueButtonClick}
      />
      <PagamentoModal
        loading={state.loading}
        valorTotal={state.valorTotal}
        valorLiquido={state.valorLiquido}
        isVisible={state.isPaymentModalOpen}
        onFormSubmit={onPaymentModalFormSubmit}
        onModalClose={() => updatePaymentModalStatus()}
        onSelectChange={handleSelectChange}
        onGetTotalAmount={getTotalAmount}
      />
    </Container>
  );
};

export default ConsultaMultasRenainf;
