import React, { useEffect, useMemo, useState } from 'react';
import { withFormik } from 'formik';
import _ from 'lodash';
import { toast } from 'react-toastify';
import validationSchema from './validationSchema';
import {
  Modal,
  HeaderRow,
  SubTitle,
  HeaderCol,
  BodyCol,
  BodyRow,
  Separator,
  Button,
} from './styles';
import { IPagamentoDebitosFormValues, FormProps, IPagamentoModalProps } from './types';
import CardForm from '../../CardForm';
import FormDropdown from '../../FormDropdown';
import { createFormatProvider } from '../../../../services/providers/factories';
import LoadingOverlay from '../../../LoadingOverlay';
import { ISelectValue } from '../../../../global/types';
import createVeiculoService from '../../../../services/veiculo';
import { HttpStatus } from '../../../../services/providers/types';

const PagamentoModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    valorTotal,
    valorLiquido,
    handleSubmit,
    loading,
    onSelectChange,
    onGetTotalAmount,
    setFieldValue,
  } = props;

  const [installments, setIntallments] = useState<Array<ISelectValue>>();
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const veiculoService = createVeiculoService();
  const onUpdateInstallments = async (): Promise<Array<ISelectValue>> => {
    const numeroParcela = 12;
    const parcelas = _.range(3, (numeroParcela + 1));
    const result = await veiculoService.getDebitosVeicularesTaxas();
    if (result.status !== HttpStatus.OK) {
      toast.error(result.message ?? 'Erro ao obter as taxas de servico!')
    }

    return Promise.all(parcelas.map(async (parcela) => {
      const valorComTaxa = await onGetTotalAmount(parcela, result.response);
      const totalParcelaComTaxa = formatProvider.convertFromCents(valorComTaxa / parcela);
      const totalComTaxa = await formatProvider.convertFromCents(valorComTaxa);

      return ({
        value: parcela,
        label: `${parcela}x de ${totalParcelaComTaxa}, total de: ${totalComTaxa}`,
      });
    }));
  };

  useEffect(() => {
    const updateInstallments = async () => {
      const updatedInstallments = await onUpdateInstallments();
      if (!isVisible && updatedInstallments === null) {
        toast.error('Erro ao atualizar o valor das parcelas!')
        return;
      }

      setIntallments(updatedInstallments);
      setFieldValue('installments', null);
    }

    updateInstallments();
  }, [valorLiquido, isVisible]);

  const valorTotalDisplay = useMemo(() => formatProvider.convertFromCents(valorTotal), [valorTotal, formatProvider]);
  const valorLiquidoDisplay = useMemo(() => formatProvider.convertFromCents(valorLiquido), [valorLiquido, formatProvider]);
  const taxasDisplay = useMemo(() => (valorTotal <= 0
    ? '0,00'
    : formatProvider.convertFromCents(valorTotal - valorLiquido)),
  [formatProvider, valorTotal, valorLiquido]);

  return (
    <Modal
      title="Efetuar Pagamento"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={1000}
    >
      <form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Por favor aguarde..."
        />
        <HeaderRow>
          <HeaderCol>
            <SubTitle>
              {`Valor Liquido: ${valorLiquidoDisplay}`}
            </SubTitle>
            <SubTitle>
              {`Taxas: ${taxasDisplay}`}
            </SubTitle>
            <SubTitle>
              {`Valor Total: ${valorTotalDisplay}`}
            </SubTitle>
          </HeaderCol>
        </HeaderRow>
        <BodyRow>
          <BodyCol>
            <Separator>
              <FormDropdown
                label="Parcelas"
                options={installments ?? [] as Array<ISelectValue>}
                onChange={onSelectChange}
                name="installments"
                placeholder="Selecione o número de parcelas..."
                isRequired
              />
            </Separator>
            <CardForm />
            <Button htmlType="submit">
              Confirmar
            </Button>
          </BodyCol>
        </BodyRow>
      </form>
    </Modal>
  );
};

export default withFormik<IPagamentoModalProps, IPagamentoDebitosFormValues>({
  mapPropsToValues: () => ({
    cvc: '',
    brand: '',
    cardholderName: '',
    cardNumber: '',
    expiry: '',
    installments: null,
    documentNumber: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const documentNumber = values.documentNumber.replace(/[^0-9]/g, '');

    if (props.documentNumber !== documentNumber) {
      toast.error('O proprietário do cartão deve ser o mesmo que o do veículo')
      return;
    }

    await props.onFormSubmit(values);
  },
})(PagamentoModal);
