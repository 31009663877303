import { IAllBoletos, IBoletosFilterProps } from '../../../global/dataTransferObjects/boletos';
import { IPagedResult } from '../../../global/generics';
import { IBoletosService } from '../../@types/services';
import { IApiResponse, ITecpayApi } from '../../providers/types';

const listAllBoletos = (apiProvider: ITecpayApi): IBoletosService => {
  const getAllBoletos = async (
    offset: number,
    pageSize: number,
    filter?: IBoletosFilterProps,
  ): Promise<IApiResponse<IPagedResult<IAllBoletos>>> => {
    const nossoNumero = filter?.nossoNumero || null;
    const status = filter?.status || null;
    const email = filter?.email || null;
    const vlrNominal = filter?.vlrNominal || null;
    const sacado = filter?.sacado || null;
    const sacador = filter?.sacador || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllBoletos>>({ url: '/boletos' })
      .withSearch({
        offset,
        pageSize,
        nossoNumero,
        status,
        email,
        vlrNominal,
        sacado,
        sacador,
      })
      .sendAsync();

    return response;
  };

  return ({
    getAllBoletos,
  });
};

export default listAllBoletos;
