import {FormValues} from "../types";
import {getAxiosInstance} from "../../../../services/providers/api";

export default class CancelamentoService {
  cancelTransactions = async (filter: FormValues) => {
    const merchant_id = filter?.merchant_id || null;
    const transaction_date = filter?.transaction_date || null;
    const nsu = filter?.nsu || null;
    const authorization_code = filter?.authorization_code || null;
    const value_tx_ori = filter?.value_tx_ori || null;
    const cancel_amount = filter?.cancel_amount || null;
    const cancel_observations = filter?.cancel_observations || null;
    const codigo_lojista = filter?.codigo_lojista || null;

    try {
      const response = await getAxiosInstance().post('/cancel', {
        merchant_id,
        transaction_date,
        nsu,
        authorization_code,
        value_tx_ori,
        cancel_amount,
        cancel_observations,
        codigo_lojista
      });

      // Retorna o `response.data` para o front-end
      return response.data;
    } catch (error) {
      return { success: false, message: "Erro" };
    }
  };

  getAllCancelTransactions = async () => {
    return getAxiosInstance().get("/cancel/all");
  }
}
