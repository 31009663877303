import axios from 'axios';
import { IConfigurationProvider } from '../@types/providers';
import { IViaCepDto } from '../../global/dataTransferObjects/viaCep';
import { IViaCepService } from '../@types/services';

const viaCepService = (configurationProvider: IConfigurationProvider): IViaCepService => {
  const getAddressByCep = async (cep: string): Promise<IViaCepDto> => {
    const { viaCepBaseUrl } = configurationProvider.getConfig();
    const response = await axios.get<IViaCepDto>(`${viaCepBaseUrl}/${cep}/json/`);

    return response.data
  };

  return ({
    getAddressByCep,
  });
}

export default viaCepService;
