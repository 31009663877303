import React from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Modal,
  Button,
} from './styles';
import { IInfoModalProps } from './types';

const InfoModal: React.FC<IInfoModalProps> = (props: IInfoModalProps) => {
  const {
    isVisible,
    onModalClose,
    pixCopiaECola,
  } = props;

  const copyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    toast.success('Copia e Cola copiado com sucesso!')
  };

  return (
    <Modal
      title="Copia e Cola"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Row>
        <Col>
          <div style={{ textAlign: 'center' }}>
            <h3>QR Code:</h3>
            {pixCopiaECola.base64Image
              ? (
                <img
                  src={`data:image/jpeg;base64,${pixCopiaECola.base64Image}`}
                  alt="Código QrCode do Copia e Cola"
                />
              )
              : null}
          </div>
          <h3>Código Copia e Cola:</h3>
          <p>{pixCopiaECola.pixCopiaECola}</p>
          <Button
            onClick={() => copyToClipboard(pixCopiaECola.pixCopiaECola)}
          >
            Copiar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default InfoModal;
