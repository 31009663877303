/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IAdjustmentFilterProps, IAllAdjustments } from '../../../../global/dataTransferObjects/adjustments';
import createAdjustmentService from '../../../../services/adjustments';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetAllAdjustments = (adjustments: IPagedResult<IAllAdjustments>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_ADJUSTMENTS,
  payload: {
    adjustments: { ...adjustments },
  },
});

export const getAllAdjustments = (
  offSet: number,
  pageSize: number,
  filter?: IAdjustmentFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const adjustmentService = createAdjustmentService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await adjustmentService.getAllAdjustments(offSet, pageSize, filter);
  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    // onUpdateZeroState(true)(dispatch);
    // logica zero state
    toast.error(result.message);
    return;
  }

  onGetAllAdjustments(result.response)(dispatch);
};
