import { ICancellationDto } from '../../../../global/dataTransferObjects/creditCard/cancellation';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  cancellation: {} as ICancellationDto,
  cancellations: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  isModalOpen: false,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CANCELLATIONS:
      return {
        ...state,
        loading: false,
        cancellations: action.payload?.cancellations ?? { ...InitialState.cancellations },
      };
    case ActionTypes.UPDATE_MODAL_STATUS:
      return {
        ...state,
        isModalOpen: action.payload?.modalStatus ?? InitialState.isModalOpen,
      }
    case ActionTypes.UPDATE_CANCELLATION:
      return {
        ...state,
        cancellation: action.payload?.cancellation ?? { ...InitialState.cancellation },
      }
    default:
      return { ...state };
  }
};
