import styled from 'styled-components'
import {
  Layout,
} from 'antd';

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Box = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 4px;
  border-top: 3px solid #00677a;
`;
