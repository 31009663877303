import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  proxys: {
    count: 0,
    offset: 0,
    pageSize: 0,
    records: [],
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_PROXY_LIST:
      return {
        ...state,
        loading: action.payload?.status ?? false,
        proxys: action.payload?.proxys ?? { ...InitialState.proxys },
      };
    default:
      return { ...state };
  }
};
