import paymentService from './paymentService';
import { createApi } from '../providers/factories';
import { IPaymentService } from '../@types/services';

const createPaymentService = (): IPaymentService => {
  const apiProvider = createApi();
  return paymentService(apiProvider);
}

export default createPaymentService;
