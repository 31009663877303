import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IVeiculoFormValues } from '../../../components/Forms/Pages/CadastroVeiculoModal/types';
import { IVeiculoDto } from '../../../global/dataTransferObjects/veiculo';
import { IPagedResult } from '../../../global/generics';
import createVeiculoService from '../../../services/veiculo';
import { HttpStatus } from '../../../services/providers/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateVeiculos = (veiculos: IPagedResult<IVeiculoDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_VEICULOS,
  payload: {
    veiculos: { ...veiculos },
  },
});

export const onOpenPaymentModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
});

export const onUpdatePaymentModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const onCreateNewVeiculo = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_NEW_VEICULO,
});

export const getUserVeiculos = (pageSize = 20, offset = 0) => async (dispatch: Dispatch<any>): Promise<void> => {
  const veiculoService = createVeiculoService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await veiculoService.getUserVeiculos(offset, pageSize);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onUpdateVeiculos(result.response)(dispatch);
};

export const createNewVeiculo = (values: IVeiculoFormValues) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const veiculoService = createVeiculoService();
  const { uf } = values;

  onUpdateLoadingStatus(true)(dispatch);
  const payload = { ...values, uf: uf?.value };
  const result = await veiculoService.createNewVeiculo(payload);

  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao cadastrar o veículo');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onCreateNewVeiculo()(dispatch);
  return true;
};
