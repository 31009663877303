import { IPixPagamentoDTO } from '../../../../global/dataTransferObjects/pix/pagamento';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  isCreateModalOpen: boolean;
  pagamentos: IPagedResult<IPixPagamentoDTO>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_CREATE_MODAL_STATUS = 'UPDATE_CREATE_MODAL_STATUS',
  UPDATE_PAGAMENTOS = 'UPDATE_PAGAMENTOS'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
