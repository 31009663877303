import React from 'react';
import { withFormik } from 'formik';
import Modal from '../../../Modal';
import LoadingOverlay from '../../../LoadingOverlay';
import { IChangeUserPasswordProps, IRedefinePasswordModalProps, FormProps } from './types';
import validationSchema from './validationSchema';
import FormInput from '../../../Forms/Input';
import {
  Form,
  Space,
  Button,
  ModalBody,
} from './styles';

const EditUserModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible, onClose, handleSubmit, loading,
  } = props;

  return (
    <Modal
      title="Alterar senha"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando a solicitação, por favor aguarde..."
        />
        <ModalBody>
          <FormInput
            type="password"
            name="oldPassword"
            label="Senha atual"
            required
          />
          <FormInput
            type="password"
            name="password"
            label="Nova senha"
            required
          />
          <FormInput
            type="password"
            name="passwordConfirmation"
            label="Confirmar nova senha"
            required
          />
        </ModalBody>
        <Space />
        <Button htmlType="submit">
          Salvar
        </Button>
      </Form>
    </Modal>
  );
};

export default withFormik<IRedefinePasswordModalProps, IChangeUserPasswordProps>({
  mapPropsToValues: (): IChangeUserPasswordProps => ({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    props.onFormSubmit(values);
  },
})(EditUserModal);
