import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { IPixCreatePagamentoDTO, IPixPagamentoFilterDTO } from '../../../global/dataTransferObjects/pix/pagamento';
import { T2 } from '../../../styles/titles';
import {
  onUpdateCreateModalStatus,
  updateCreatePagamentoByChavePixInfo,
  updateCreatePagamentoByDecodedQrCode,
  updatePagamentos,
  onUpdateVerifyModalStatus,
  createPagamento,
  onUpdateDetailsPaymentModalStatus,
  getTransaction,
} from './actions';
import { InitialState, Reducer } from './actions/reducer';
import VerifyModal from './components/VerifyModal';
import { IFormValues } from './components/VerifyModal/types';
import {
  Button,
  Container,
  TableContainer,
} from './styles';
import Table, { TableColumn } from '../../../components/Table';
import { createFormatProvider } from '../../../services/providers/factories';
import FilterForm from './components/FilterForm';
import { IFormValues as IFilterFormValues } from './components/FilterForm/types';
import DigitalBalance from '../../../components/DigitalBalance';
import CreateModal from './components/CreateModal';
import ComprovantePixModal from './components/comprovanteModal/comprovanteModal';

const Pagamento: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IPixPagamentoFilterDTO>({});
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const handleUpdateVerifyModalStatus = (status: boolean) => onUpdateVerifyModalStatus(status)(dispatch);
  const handleUpdateCreateModalStatus = (status: boolean) => onUpdateCreateModalStatus(status)(dispatch);
  const handleUpdateDetailsPaymentModalStatus = (status: boolean) => onUpdateDetailsPaymentModalStatus(status)(dispatch);
  const handleOpenViewDetailsPaymentModal = (endToEndId: string) => getTransaction(endToEndId)(dispatch);

  const fetchPagamentos = useCallback((
    initial: number = offset,
    size: number = pageSize,
    filter: IPixPagamentoFilterDTO = filterOrder,
  ) => updatePagamentos(initial, size, { ...filter })(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPagamentos(pageSize * (currentPage - 1), (currentPage * pageSize), filterOrder)
      return;
    }
    if (currentPage === 1) {
      fetchPagamentos(0, (currentPage * pageSize), filterOrder);
    }
  }, [currentPage, pageSize]);

  const handleVerifyModalSubmit = async (values: IFormValues) => {
    const formattedValues: IPixCreatePagamentoDTO = {
      ...values,
      pixPaymentType: values.pixPaymentType?.value,
      amount: parseFloat(values.amount.replace('.', '').replace(',', '.')),
    }

    const result = values.pixPaymentType?.value === 1
      ? await updateCreatePagamentoByChavePixInfo({ ...formattedValues })(dispatch)
      : await updateCreatePagamentoByDecodedQrCode(values.emv.trim())(dispatch);

    if (!result) {
      return;
    }

    handleUpdateCreateModalStatus(true);
  };

  const handleCreateModalSubmit = async () => {
    const result = await createPagamento({ ...state.createPagamento })(dispatch);

    if (!result) {
      return;
    }

    fetchPagamentos();
    toast.success('Pagamento via PIX enviado com sucesso!');
  };

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? moment(date).utcOffset('-06:00').format('DD/MM/YYYY HH:mm')
    : 'N/A'), []);

  const handleFilterForm = async (values: IFilterFormValues) => {
    const formattedValues: IPixPagamentoFilterDTO = { ...values, status: values.status?.value }
    setFilterOrder({
      ...formattedValues,
    });

    updatePagamentos(offset, pageSize, { ...formattedValues })(dispatch);
  };

  return (
    <>
      <Container>
        <LoadingOverlay
          show={state.isVerifyModalOpen || state.isCreateModalOpen ? false : state.loading}
          relative
        />
        <T2>Pagamentos</T2>
        <DigitalBalance />
        <FilterForm onFormSubmit={handleFilterForm} />
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleUpdateVerifyModalStatus(true)}
        >
          Enviar pagamento
        </Button>
        <TableContainer>
          <Table
            dataSource={[...state.pagamentos.records]}
            pagination={
              {
                showSizeChanger: true,
                onShowSizeChange: (current: number, size: number) => {
                  setPageSize(size);
                },
                defaultPageSize: 20,
                total: state.pagamentos.count,
                onChange: (page: number) => {
                  setCurrentPage(page);
                },
              }
            }
          >
            <TableColumn title="EndToEndId" dataIndex="endToEndId" key="endToEndId" />
            <TableColumn title="Valor" dataIndex="amount" key="amount" render={formattedValue} />
            <TableColumn title="Status" dataIndex="status" key="status" />
            <TableColumn
              title="Chave PIX"
              dataIndex="creditorPrivateIdentification"
              key="creditorPrivateIdentification"
            />
            <TableColumn title="Pagamento" dataIndex="createdAt" key="createdAt" render={formattedDate} />
            <TableColumn
              title="Comprovante"
              dataIndex="comprovante"
              key="comprovante"
              render={(i: any, e: any) => (state.pagamentos.records.length >= 1 ? (
                <EyeOutlined
                  onClick={() => handleOpenViewDetailsPaymentModal(e.endToEndId)}
                />
              ) : null)}
            />
          </Table>
        </TableContainer>
      </Container>
      <VerifyModal
        isVisible={state.isVerifyModalOpen}
        onModalClose={() => handleUpdateVerifyModalStatus(false)}
        loading={state.loading}
        onSubmit={handleVerifyModalSubmit}
      />
      <ComprovantePixModal
        isVisible={state.isDetailsPaymentModalOpen}
        onModalClose={() => handleUpdateDetailsPaymentModalStatus(false)}
        loading={state.loading}
        transaction={state.transaction}
      />
      <CreateModal
        isVisible={state.isCreateModalOpen}
        onModalClose={() => handleUpdateCreateModalStatus(false)}
        loading={state.loading}
        onCreatePagamento={handleCreateModalSubmit}
        createPagamento={{ ...state.createPagamento }}
      />
    </>
  );
}

export default Pagamento;
