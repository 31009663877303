import { ILiveTransactionsService } from '../@types/services';
import { createApi } from '../providers/factories';
import liveTransactionService from './transacoesTempoRealService';

const createLiveTransactionsService = (): ILiveTransactionsService => {
  const apiProvider = createApi();
  return liveTransactionService(apiProvider);
};

export default createLiveTransactionsService;
