import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { Reducer, InitialState } from './actions/reducer';
import Table, { TableColumn } from '../../../components/Table';
import { Button, Container, TableContainer } from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { T2 } from '../../../styles/titles';
import { deletePoliticaContaDigital, updatePagedPoliticas } from './actions';
import Filter from './components/FilterForm';
import { IFormValues } from './components/FilterForm/types';
import { IPoliticaContaDigitalFilterProps } from '../../../global/dataTransferObjects/politicaContaDigital';
import { createFormatProvider } from '../../../services/providers/factories';

const PoliticaContaDigitalList: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [politicaCobrancaFilter, setPoliticaCobrancaFilter] = useState<IPoliticaContaDigitalFilterProps>({});

  const handleButtonClick = () => history.push('/contas-digitais/politicas-cobrancas/cadastrar');

  const fetchPoliticasCobrancas = useCallback((
    initial: number = offset,
    size: number = pageSize,
    filter: IPoliticaContaDigitalFilterProps = politicaCobrancaFilter,
  ) => updatePagedPoliticas(initial, size, filter)(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPoliticasCobrancas(pageSize * (currentPage - 1), (currentPage * pageSize))
      return;
    }
    if (currentPage === 1) {
      fetchPoliticasCobrancas(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleDeletePoliticaCobranca = async (politicaId: string): Promise<void> => {
    const result = await deletePoliticaContaDigital(politicaId)(dispatch);
    if (!result) {
      return;
    }

    fetchPoliticasCobrancas();
  };

  const handleFilterSubmit = async (values: IFormValues): Promise<void> => {
    const formattedValues: IPoliticaContaDigitalFilterProps = {
      ...values,
    };

    setPoliticaCobrancaFilter({ ...formattedValues });
    fetchPoliticasCobrancas(offset, pageSize, { ...formattedValues });
  };

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>
        Conta Digital
        <br />
        <small>Políticas de cobranças</small>
      </T2>
      <Button
        icon={<PlusOutlined />}
        onClick={handleButtonClick}
      >
        Nova politica de cobrança
      </Button>
      <Filter
        onSubmit={handleFilterSubmit}
      />
      <TableContainer>
        <Table
          dataSource={[...state.pagedPoliticas.records]}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.pagedPoliticas.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
        >
          <TableColumn title="Identificador" dataIndex="politicaCobrancaId" key="politicaCobrancaId" />
          <TableColumn title="Nome" dataIndex="nome" key="nome" />
          <TableColumn title="Descrição" dataIndex="descricao" key="descricao" />
          <TableColumn title="Data de criação" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn
            title="Editar"
            dataIndex="editar"
            key="editar"
            align="center"
            render={(value: any, record: any) => (state.pagedPoliticas.records.length >= 1 ? (
              <Link
                style={{ color: '#000' }}
                to={`/contas-digitais/politicas-cobrancas/editar/${record.id}`}
              >
                <EditOutlined />
              </Link>
            ) : null)}
          />
          <TableColumn
            title="Excluir"
            dataIndex="excluir"
            key="excluir"
            align="center"
            render={(value: any, record: any) => (state.pagedPoliticas.records.length >= 1 ? (
              <Popconfirm
                title="Deseja realmente excluir?"
                cancelText="Cancelar"
                okText="Excluir"
                onConfirm={() => handleDeletePoliticaCobranca(record.id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : null)}
          />
        </Table>
      </TableContainer>
    </Container>
  )
};

export default PoliticaContaDigitalList;
