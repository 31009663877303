import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import moment from 'moment';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import {
  ContaDigitalTipoServicoEnum,
  IFilterTransacoesValues,
} from '../../../global/dataTransferObjects/contaDigital/transacoes';
import { createFormatProvider } from '../../../services/providers/factories';
import { T2 } from '../../../styles/titles';
import { updateTransacoes } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import FilterForm from './components/filterForm';
import { IFormValues } from './components/filterForm/types';
import { Container, TableContainer } from './styles';
import DigitalBalance from '../../../components/DigitalBalance';

const Extrato: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterTransacoesValues>({});
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const fetchTransacoes = useCallback((
    initial: number = offset,
    size: number = pageSize,
    filter: IFilterTransacoesValues = filterOrder,
  ) => updateTransacoes(initial, size, { ...filter })(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchTransacoes(pageSize * (currentPage - 1), (currentPage * pageSize), filterOrder)
      return;
    }
    if (currentPage === 1) {
      fetchTransacoes(0, (currentPage * pageSize), filterOrder);
    }
  }, [currentPage, pageSize]);

  const handleFilterForm = async (values: IFormValues) => {
    const formattedValues: IFilterTransacoesValues = {
      ...values,
      tipoServico: values.tipoServico?.value,
    }

    setFilterOrder({
      ...formattedValues,
    });

    updateTransacoes(offset, pageSize, { ...formattedValues })(dispatch);
  };

  const formattedTransacoes = state.transacoes.records.map((transacao) => ({
    ...transacao,
    type: transacao.paymentAmount >= 0 ? 'Entrada' : 'Saída',
  }));

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? moment(date).format('DD/MM/YYYY HH:mm')
    : 'N/A'), []);

  const getTipoServico = (servico: ContaDigitalTipoServicoEnum): string => {
    switch (servico) {
      case ContaDigitalTipoServicoEnum.pix:
        return 'Pix';
      case ContaDigitalTipoServicoEnum.cargaCartao:
        return 'Cartão';
      case ContaDigitalTipoServicoEnum.contaDigital:
        return 'Conta digital';
      case ContaDigitalTipoServicoEnum.boleto:
        return 'Boleto';
      default:
        return 'Não informado';
    }
  };

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Extrato</T2>
      <DigitalBalance />
      <FilterForm onSubmit={handleFilterForm} />
      <TableContainer>
        <Table
          dataSource={[...formattedTransacoes]}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (_, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.transacoes.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0}>Valor total</TableSumCell>
                <TableSumCell index={1} />
                <TableSumCell index={2}>{formattedValue(state.transacoes.totalPaymentAmount)}</TableSumCell>
                <TableSumCell index={3} />
                <TableSumCell index={4} />
                <TableSumCell index={5} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0}>Id da Transação</TableSumCell>
                <TableSumCell index={1}>Tipo de pagamento</TableSumCell>
                <TableSumCell index={2}>Valor</TableSumCell>
                <TableSumCell index={3}>Taxa/tarifa</TableSumCell>
                <TableSumCell index={4}>Data de transação</TableSumCell>
                <TableSumCell index={5}>Tipo de transação</TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn
            title="Id do pagamento"
            dataIndex="pagamentoId"
            key="pagamentoId"
          />
          <TableColumn
            title="Tipo de pagamento"
            dataIndex="tipoServico"
            key="tipoServico"
            render={getTipoServico}
          />
          <TableColumn
            title="Valor"
            dataIndex="paymentAmount"
            key="paymentAmount"
            render={(item: number) => ({
              props: {
                style: { color: item >= 0 ? '#1fd655' : '#d0342c' },
              },
              children:
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {item >= 0 ? <AiOutlineArrowDown /> : <AiOutlineArrowUp />}
    {formattedValue(item)}
  </div>,
            })}
          />
          <TableColumn title="Taxa/tarifa" dataIndex="feeAmount" key="feeAmount" render={formattedValue} />
          <TableColumn title="Data da transação" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn title="Tipo de transação" dataIndex="type" key="type" />
        </Table>
      </TableContainer>
    </Container>
  )
};

export default Extrato;
