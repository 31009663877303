export interface ITheme {
  colors: {
    white: string;
    blueCyanDarkest: string;
    cyanDark: string;
    cyanLightest: string;
    grayLight: string;
    grayDark: string;
    redDanger: string;
    orange: string;
  },
  components: any,
  fonts: Array<string>;
}

export const theme: ITheme = {
  colors: {
    white: '#fff',
    blueCyanDarkest: '#0e2b27',
    cyanDark: '#0e2b27',
    cyanLightest: '#dde5e8',
    grayLight: '#999',
    grayDark: '#626262',
    redDanger: '#dc3545',
    orange: '#f56a00',
  },
  components: {
    Menu: {
      itemSelectedColor: '#fbb651',
      colorItemBgSelected: '#fbb651',
    },
    Layout: {
      siderBg: '#fff',
      bodyBg: '#fff',
    },
  },
  fonts: ['Roboto', 'sans-serif'],
};
