import React, { useMemo, useState } from 'react';
import Table, { TableColumn } from '../../../../components/Table';
import { TableContainer } from '../styles';
import { IUnidadeRecebivelTableProps } from '../types';
import { createFormatProvider } from '../../../../services/providers/factories';
import { IUnidadeRecebivelResponseDto } from '../../../../global/dataTransferObjects/unidadeRecebivel';

const UnidadesRecebiveisTable: React.FC<IUnidadeRecebivelTableProps> = (props: IUnidadeRecebivelTableProps) => {
  const { unidadesRecebiveis, onOpenUnidadeRecebivelModal, onPaginationChange } = props;
  const [isMouseEnteredRow, setIsMouseEnteredRow] = useState<boolean>(false);

  const formatProvider = useMemo(() => createFormatProvider(), []);

  const formattedPrice = (price: number) => formatProvider.formatPrice(price);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formattedDocumentNumber = (numeroDocumento: string) => (
    numeroDocumento?.length === 14
      ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
      : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
  );

  const formattedUnidadesRecebiveis = unidadesRecebiveis.records.map((unidadeRecebivel) => ({
    ...unidadeRecebivel,
    dataLiquidacao: formattedDate(unidadeRecebivel.dataLiquidacao),
    instituicaoCredenciadora: formattedDocumentNumber(unidadeRecebivel.instituicaoCredenciadora),
    documentoFinalRecebedor: formattedDocumentNumber(unidadeRecebivel.documentoFinalRecebedor),
    valorConstituidoTotal: formattedPrice(unidadeRecebivel.valorConstituidoTotal),
    valorConstituidoAntecipacaoPre: formattedPrice(unidadeRecebivel.valorConstituidoAntecipacaoPre),
    valorBloqueado: formattedPrice(unidadeRecebivel.valorBloqueado),
    valorLivre: formattedPrice(unidadeRecebivel.valorLivre),
    valorTotalUr: formattedPrice(unidadeRecebivel.valorTotalUr),
  }));

  const handleInteractionOnRow = (unidadeRecebivel: IUnidadeRecebivelResponseDto) => ({
    onClick: () => onOpenUnidadeRecebivelModal({ ...unidadeRecebivel }),
    onMouseEnter: () => setIsMouseEnteredRow(true),
    onMouseLeave: () => setIsMouseEnteredRow(false),
  });

  return (
    <TableContainer>
      <Table
        dataSource={[...formattedUnidadesRecebiveis]}
        onRow={handleInteractionOnRow}
        isMouseEnteredRow={isMouseEnteredRow}
        pagination={{
          onChange: onPaginationChange,
        }}
      >
        <TableColumn
          title="Credenciadora/Subcredenciadora"
          dataIndex="instituicaoCredenciadora"
          key="instituicaoCredenciadora"
        />
        <TableColumn
          title="Usuário final recebedor"
          dataIndex="documentoUsuarioFinalRecebedor"
          key="documentoUsuarioFinalRecebedor"
        />
        <TableColumn title="Arranjo de pagamento" dataIndex="codigoArranjoPagamento" key="codigoArranjoPagamento" />
        <TableColumn
          title="Valor constituido total"
          dataIndex="valorConstituidoTotal"
          key="valorConstituidoTotal"
        />
        <TableColumn
          title="Valor bloqueado"
          dataIndex="valorBloqueado"
          key="valorBloqueado"
        />
      </Table>
    </TableContainer>
  )
};

export default UnidadesRecebiveisTable;
