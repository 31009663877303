import React from 'react';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import {
  Modal,
} from './styles';
import { IReceiptModalProps } from './types';

const ReceiptModal: React.FC<IReceiptModalProps> = (props: IReceiptModalProps) => {
  const {
    isVisible,
    onReceiptModalClose,
    loading,
    receipt,
  } = props;

  return (
    <Modal
      title="Pagamento de boletos"
      centered
      visible={isVisible}
      onCancel={onReceiptModalClose}
      footer={null}
      width={400}
    >
      <LoadingOverlay show={loading} relative />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{receipt?.receiptformatted}</span>
      </div>
    </Modal>
  );
};

export default ReceiptModal;
