import { createApi } from '../providers/factories';
import { ICercService } from '../@types/services';
import cercService from './cercService';

const createCercService = (): ICercService => {
  const apiProvider = createApi();
  return cercService(apiProvider);
};

export default createCercService;
