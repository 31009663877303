import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import { IFilterAdjustment, IFilterAdjustmentProps } from './types';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const FormFilter: React.FC<IFilterAdjustment> = (props: any) => {
  const { handleSubmit, resetForm } = props;
  const resetAdjustmentForm = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="Data Inicio"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Data fim"
              />
            </DateView>
          </Col>
        </Row>

        <Row
          gutter={16}
        >
          <Col span={6} />
          <Col span={6} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton
              onClick={() => resetAdjustmentForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterAdjustment, IFilterAdjustmentProps>({
  mapPropsToValues: (): IFilterAdjustmentProps => ({
    startDate: '',
    finishDate: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const formattedValues = {
      ...values,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
