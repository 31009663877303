import { createApi } from '../providers/factories';
import { IVeiculoService } from '../@types/services';
import veiculoService from './veiculoService';

const createVeiculoService = (): IVeiculoService => {
  const apiProvider = createApi();
  return veiculoService(apiProvider);
};

export default createVeiculoService;
