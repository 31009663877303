import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import LoadingOverlay from '../../components/LoadingOverlay';
import { T2 } from '../../styles/titles';
import Table, { TableColumn } from '../../components/Table';
import { getProxys, submitNewProxy } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import NewProxyForm from './components/newProxyForm';
import { IFormValues } from './components/newProxyForm/types';
import { Container, TableContainer } from './style';
import FormFilter from './components/FormFilter';
import { IFilterProxyListProps } from './components/FormFilter/types';

const CartaoProxy : React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterProxyListProps>({});

  const fetchTransacoes = useCallback((
    initial: number = offSet,
    size: number = pageSize,
    filter: IFilterProxyListProps = filterOrder,
  ) => getProxys(initial, size, filter)(dispatch), []);

  const data = useMemo(() => state.proxys.records, [state]);

  const handleSubmit = async (filter : IFilterProxyListProps) => {
    getProxys(offSet, pageSize, filter)(dispatch);

    setFilterOrder(filter);
  }

  const handleFilterForm = async (values: IFormValues) => {
    const proxyOptions = {
      userId: values?.codEstabelecimento?.value,
      statusConta: values.statusConta,
      statusCartao: values.statusCartao,
      proxyCartao: values.proxyCartao,
      packageId: values.packageId,
    };

    await submitNewProxy(proxyOptions)(dispatch);
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchTransacoes(pageSize * (currentPage - 1), (currentPage * pageSize), filterOrder)
      return;
    }
    if (currentPage === 1) {
      fetchTransacoes(0, (currentPage * pageSize), filterOrder);
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Proxy</T2>
      <NewProxyForm onSubmit={handleFilterForm} />
      <FormFilter onSubmit={handleSubmit} />
      <TableContainer>
        <Table
          dataSource={data}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.proxys.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
        >
          <TableColumn
            title="Nome do Usuário"
            dataIndex="userName"
            key="userName"
          />
          <TableColumn
            title="Documento"
            dataIndex="document"
            key="document"
          />
          <TableColumn
            title="Proxy Cartão"
            dataIndex="cardProxy"
            key="cardProxy"
          />
          <TableColumn
            title="Pacote"
            dataIndex="package"
            key="package"
          />
          <TableColumn
            title="Status do Cartão"
            dataIndex="cardStatus"
            key="cardStatus"
            render={((item : boolean) => (item ? 'Ativo' : 'Inativo'))}
          />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CartaoProxy;
