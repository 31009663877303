import {ITransactionsTable} from "../../types";
import React, {useEffect, useState} from "react";
import {ValueCard} from "./styles";
import DetalhesEdiServices from "../../services/detalhesEdiService";
import {formatMoney} from "../../utils";

export const Totals: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const { dataFilter } = props
  const detalhesEdiService = new DetalhesEdiServices();
  const [ediData, setEdiData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await detalhesEdiService.GetEdiTransactions(
        0,
        pageSize * currentPage,
        dataFilter);
      setEdiData(data);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataFilter) {
      fetchData();
    }
  }, [dataFilter]);

  const getValorVendaTotal = (): string => {
    let valor: number = 0;
    if (ediData.totalGross) valor = ediData.totalGross;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorAcquirer = (): string => {
    let valor: number = 0;
    if (ediData.totalAcquirer) valor = ediData.totalAcquirer;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorLiquidoTotal = (): string => {
    let valor: number = 0;
    if (ediData.totalLiquid) valor = ediData.totalLiquid;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorRevenue = (): string => {
    let valor: number = 0;
    if (ediData.totalRevenue) valor = ediData.totalRevenue;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorItc = (): string => {
    let valor: number = 0;
    if (ediData.totalItc) valor = ediData.totalItc;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorAdm = (): string => {
    let valor: number = 0;
    if (ediData.totalAdm) valor = ediData.totalAdm;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorMdr = (): string => {
    let valor: number = 0;
    if (ediData.totalMdr) valor = ediData.totalMdr;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor das vendas:</p>
          <p>{getValorVendaTotal()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Recebido Adiq:</p>
          <p>{getValorAcquirer()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Liquido:</p>
          <p>{getValorLiquidoTotal()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor ITC:</p>
          <p>{getValorItc()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor ADM:</p>
          <p>{getValorAdm()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Custo Total Mdr:</p>
          <p>{getValorMdr()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Receita:</p>
          <p>{getValorRevenue()}</p>
        </div>
      </ValueCard>
    </>

  )
}
