import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify';
import { withFormik } from 'formik';
import { Col, Row } from 'antd';
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown';
import { FormProps, IFilterFormProps, IFormValues } from './types';
import { AdditionalType } from '../../../../components/Forms/AsyncFormDropdown/types';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';
import createUserService from '../../../../services/user';
import { IFilterUserProps } from '../../../usuarios/types';
import { Button, Container, Form } from './style';
import { formatterDocumentNumber } from '../../../../components/Masks';
import createBusinessService from '../../../../services/business';

const LinkForm = (props: FormProps) => {
  const {
    handleSubmit,
  } = props;

  const [pageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userFilter] = useState<IFilterUserProps>();

  const handleUserList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userService = createUserService();
    const result = await userService.getUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      userFilter,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  const handleBusinessList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const businessService = createBusinessService();
    const result = await businessService.getEmpresasList(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      undefined,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <AsyncFormDropdown
              name="userId"
              placeholder=""
              label="Usuário"
              loadOptions={handleUserList}
            />
          </Col>
          <Col span={8}>
            <AsyncFormDropdown
              name="businessId"
              placeholder=""
              label="Empresa"
              loadOptions={handleBusinessList}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Button htmlType="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default withFormik<IFilterFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    userId: null,
    businessId: null,
  }),
  // validationSchema,
  handleSubmit: async (values, { props }) => {
    const userId = values.userId?.value;
    if (!userId) {
      toast.error('Selecione um usuário para vincular a empresa.');
      return null;
    }

    const businessId = values.businessId?.value;
    if (!businessId) {
      toast.error('Selecione uma empresa para vincular ao usuário.');
      return null;
    }

    return props.onSubmit({ ...values })
  },
})(LinkForm);
