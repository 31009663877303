import {IFilter} from "../../types";
import {Button, Col, Label} from "./styles";
import {Row} from "antd";
import Select from "antd/lib/select";
import React, {useEffect, useState} from "react";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import {Container} from "../../styles";
import { Formik, Field, Form } from "formik";
import {formatDateFilter, formatMoney} from "../../utils";

export const FormFilter: React.FC<IFilter> = (props: IFilter) => {
  const { getFilters, totals } = props
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialDateFilterType, setInitialDateFilterType] = useState<string>("text");
  const [finalDateFilterType, setFinalDateFilterType] = useState("text");

  const handleFocusInitialDateFilter = () => {
    setInitialDateFilterType("date");
  };



  const handleFocusFinalDateFilter = () => {
    setFinalDateFilterType("date");
  };

  const handleChangeInitialDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const { value } = e.target;
    setFieldValue("startDate", value);
  };

  const handleChangeFinalDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const { value } = e.target;
    setFieldValue("finishDate", value);
  };

  const getEdiTransactions = async (submit: any) => {
    if (submit.startDate === "") {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      date.setHours(0, 0, 0, 0);
      submit.startDate = formatDateFilter(date)
    } else {
      const formattedDateStart = formatDateFilter(submit.startDate)
      submit.startDate = formattedDateStart
    }

    if (submit.finishDate === "") {
      const tomorrow = new Date()
      tomorrow.setHours(0, 0, 0, 0)
      submit.finishDate = formatDateFilter(tomorrow)
    } else {
      const formattedDateFinish = formatDateFilter(submit.finishDate)
      submit.finishDate = formattedDateFinish
    }

    getFilters(submit);
    return submit;
  };

  useEffect(() => {
    const initialValues = {
      startDate: "",
      finishDate: "",
      bandeira: "",
      tipoCompra: "",
      nsuAdquirente: "",
    }

    getEdiTransactions(initialValues);
  }, []);

  return (
    <Container style={{padding: "0px"}}>
      <LoadingOverlay show={isLoading} relative/>

      <Row>
        <Label>Filtrar por:</Label>
      </Row>

      <Formik
        initialValues={{
          startDate: "",
          finishDate: "",
          bandeira: "",
          tipoCompra: "",
          nsuAdquirente: "",
        }}
        onSubmit={async (values: any) => {
          await getEdiTransactions(values);
        }}
      >
        {({ setFieldValue, values }) => (

          <Form>
            <Row gutter={16}>
              <Col>
                <input
                  type={initialDateFilterType}
                  onFocus={handleFocusInitialDateFilter}
                  onChange={(e) => handleChangeInitialDate(e, setFieldValue)}
                  name="startDate"
                  placeholder="Data Inicial"
                  step="1"
                />
              </Col>
              <Col>
                <input
                  type={finalDateFilterType}
                  onFocus={handleFocusFinalDateFilter}
                  onChange={(e) => handleChangeFinalDate(e, setFieldValue)}
                  name="finishDate"
                  placeholder="Data Final"
                  step="1"
                />
              </Col>
              <Col>
                <Select
                  value={values.bandeira}
                  style={{width: "100%" }}
                  onChange={(value) => setFieldValue("bandeira", value)}
                  options={[
                    { value: "", label: "Bandeira" },
                    { value: "1", label: "Visa" },
                    { value: "2", label: "Master" },
                    { value: "3", label: "Amex" },
                    { value: "9", label: "Hiper" },
                    { value: "171", label: "Elo" },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  value={values.tipoCompra}
                  style={{ width: "100%" }}
                  onChange={(value) => setFieldValue("tipoCompra", value)}
                  options={[
                    { value: "", label: "Tipo de Compra" },
                    { value: "1", label: "Débito" },
                    { value: "2", label: "Crédito" },
                  ]}
                />
              </Col>
              <Col>
                <Field
                  type="text"
                  name="nsuAdquirente"
                  placeholder="Nsu Adquirente"
                />
              </Col>
              <Col>
                <Button
                  style={{ background: "#fbb651" }}
                  htmlType="submit"
                >
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
