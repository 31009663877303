import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import FormFilter from './components/filter';
import {
  Container,
} from './styles';
import { T2 } from '../../../styles/titles';
import { InitialState, Reducer } from './actions/reducer';
import { getAllLiveTransactions } from './actions';
import { ILiveTransactionsFilterProps } from '../../../global/dataTransferObjects/transacoesTempoReal';
import LiveTable from './components/liveTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate} from '../../../utils/formatDate';

const TransacaoTempoReal: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);

  const fetchAllTransfers = useCallback(() => getAllLiveTransactions(page, pageSize, {date: formatDate(new Date())})(dispatch), []);
  useEffect(() => {
    setTimeout(() => {
      fetchAllTransfers();
    }, 500);
  }, [fetchAllTransfers]);

  const liveTransactions = state?.allLiveTransactions?.content.map((params) => (
    {
      ...params,
    }
  ));

  const handleFilter = async (filter: ILiveTransactionsFilterProps) => {
    getAllLiveTransactions(page, pageSize, filter)(dispatch);
  };

  if (state.zeroState) {
    return (
      <ZeroState />
    )
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Transações em Tempo Real</T2>
      <FormFilter onFormSubmit={handleFilter} />
      <LiveTable liveTransactions={liveTransactions} />
    </Container>
  );
};

export default TransacaoTempoReal;
