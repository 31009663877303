import { IPoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  politicaCobranca: {} as IPoliticaCobrancaDTO,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_POLITICA_COBRANCA:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.GET_POLITICA_COBRANCA:
      return {
        ...state,
        loading: false,
        politicaCobranca: action.payload?.politicaCobranca ?? { ...InitialState.politicaCobranca },
      }
    default:
      return { ...state };
  }
};
