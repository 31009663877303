import React, {useEffect, useReducer, useState} from "react";
import DetalhesEdiServices from "../../services/detalhesEdiService";
import {InitialState, Reducer} from "../../../../transacoesErepasses/painelDeVendas/actions/reducer";
import {
  formatBrand,
  formatDateHourView,
  formatDateView,
  formatMoney,
  formatTransactionTax,
  formatTypePurchase
} from "../../utils";
import {ResponsiveColumn, ResponsiveRow, TableContainer} from "../../styles";
import Table, {TableColumn} from "../../../../../components/Table";
import {ITransactionsTable} from "../../types";
import LoadingOverlay from "../../../../../components/LoadingOverlay";

export const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const { dataFilter } = props;
  const detalhesEdiService = new DetalhesEdiServices();
  const [ediData, setEdiData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formattedDateHour = (date: string) => formatDateHourView(date);
  const formattedMoney = (value: number) => formatMoney(value);
  const formattedDate = (date: string) => formatDateView(date);
  const formattedTypePurchase = (value: number) => formatTypePurchase(value);
  const formattedBrand = (value:number) => formatBrand(value);
  const formattedTransactionTax = (value: number) => formatTransactionTax(value)
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sales, setSales] = useState<[]>([])

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await detalhesEdiService.GetEdiTransactions(
        0,
        pageSize * currentPage,
        dataFilter);
      setEdiData(data);
      setSales(data.records)
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataFilter) {
      fetchData();
    }
  }, [currentPage, pageSize, dataFilter]);

  return (
    <div>
      <LoadingOverlay
        show={isLoading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={ediData.records}
          pagination = {
            {
              defaultPageSize: 20,
              defaultCurrent: 1,
              current: currentPage,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
                setCurrentPage(current);
              },
              total: ediData.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} vendas`
            }
          }
        >
          <TableColumn title="Data" dataIndex="dataTransacao" key="dataTransacao" render={formattedDateHour} />
          <TableColumn title="Data Pag. Lojista" dataIndex="dataPagamentoLojista" key="dataPagamentoLojista" render={formattedDate} />
          <TableColumn title="Lanç. Adiq" dataIndex="dataLançamentoAdquirente" key="dataLançamentoAdquirente" render={formattedDate} />
          <TableColumn title="CNPJ" dataIndex="cnpj" key="cnpj" />
          <TableColumn title="Tipo Compra" dataIndex="tipoCompra" key="tipoCompra" render={formattedTypePurchase} />
          <TableColumn title="Bandeira" dataIndex="bandeira" key="bandeira" render={formattedBrand} />
          <TableColumn title="Valor Bruto" dataIndex="valorBrutoTransacao" key="valorBrutoTransacao" render={formattedMoney} />
          <TableColumn title="Valor Parcela" dataIndex="valorParcelaBruto" key="valorParcelaBruto" render={formattedMoney} />
          <TableColumn title="Valor Líq. Loj." dataIndex="valorLiquidoLojista" key="valorLiquidoLojista" render={formattedMoney} />
          <TableColumn title="Total Parcelas" dataIndex="numeroTotalParcelas" key="numeroTotalParcelas" />
          <TableColumn title="Parcela Atual" dataIndex="numeroParcela" key="numeroParcela" />
          <TableColumn title="NSU Adiq" dataIndex="nsuAdquirente" key="nsuAdquirente" />
          <TableColumn title="Autorização" dataIndex="codigoAutorizacao" key="codigoAutorizacao" />
          <TableColumn title="Valor Recebido Adiq" dataIndex="valorRecebidoAcquirer" key="valorRecebidoAcquirer" render={formattedMoney} />
          <TableColumn title="Valor Recebido Adiq Parcela" dataIndex="valorRecebidoAcquirerParcela" key="valorRecebidoAcquirerParcela" render={formattedMoney} />
          <TableColumn title="Valor Taxa ITC" dataIndex="valorTaxaInterchange" key="valorTaxaInterchange" render={formattedMoney} />
          <TableColumn title="Taxa Interchange" dataIndex="taxaInterchange" key="taxaInterchange" render={formattedTransactionTax}/>
          <TableColumn title="Valor ITC Parcela" dataIndex="valorTaxaInterchangeParcela" key="valorTaxaInterchangeParcela" render={formattedMoney} />
          <TableColumn title="Taxa ITC Parcela" dataIndex="taxaInterchangeParcela" key="taxaInterchangeParcela" render={formattedTransactionTax}/>
          <TableColumn title="Valor Taxa Adm" dataIndex="valorTaxaAdm" key="valorTaxaAdm" render={formattedMoney} />
          <TableColumn title="Taxa Adm" dataIndex="taxaAdm" key="taxaAdm" render={formattedTransactionTax}/>
          <TableColumn title="Valor Taxa Adm Parcela" dataIndex="valorTaxaAdmParcela" key="valorTaxaAdmParcela" render={formattedMoney} />
          <TableColumn title="Taxa Adm Parcela" dataIndex="taxaAdmParcela" key="taxaAdmParcela" render={formattedTransactionTax} />
          <TableColumn title="Valor Antecip." dataIndex="valorTaxaAntecipacao" key="valorTaxaAntecipacao" render={formattedMoney} />
          <TableColumn title="Taxa Antecip." dataIndex="taxaAntecipacao" key="taxaAntecipacao" render={formattedTransactionTax}/>
          <TableColumn title="Custo Transação" dataIndex="custoTotalTransacao" key="custoTotalTransacao" render={formattedMoney} />
          <TableColumn title="Taxa Transação" dataIndex="taxaCustoTotalTransacao" key="taxaCustoTotalTransacao" render={formattedTransactionTax}/>
          <TableColumn title="Custo Parcela" dataIndex="custoTotalParcela" key="custoTotalParcela" render={formattedMoney} />
          <TableColumn title="Taxa Parcela" dataIndex="taxaCustoTotalParcela" key="taxaCustoTotalParcela"render={formattedTransactionTax}/>
          <TableColumn title="Taxa Total Lojista" dataIndex="taxaTotalLojista" key="taxaTotalLojista" render={formattedTransactionTax} />
          <TableColumn title="N.º Cartão" dataIndex="numeroCartao" key="numeroCartao" />
          <TableColumn title="MCC" dataIndex="mcc" key="mcc" />
          <TableColumn title="Receita" dataIndex="receita" key="receita" render={formattedMoney} />
        </Table>
      </TableContainer>

      <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
        <ResponsiveColumn><h4>Data da Transação</h4></ResponsiveColumn>
        <ResponsiveColumn><h4>Valor Bruto</h4></ResponsiveColumn>
        <ResponsiveColumn><h4>Receita</h4></ResponsiveColumn>
      </ResponsiveRow>
      {sales.map((sale: any) => {
        return (
          <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>
            <ResponsiveColumn style={{maxWidth: "92px"}}>
              <p>{formatDateView(sale.dataTransacao)}</p>
            </ResponsiveColumn>
            <ResponsiveColumn>
              <p>{formatMoney(sale.valorBrutoTransacao)}</p>
            </ResponsiveColumn>
            <ResponsiveColumn style={{maxWidth: "88px"}}>
              <p>{formatMoney(sale.receita)}</p>
            </ResponsiveColumn>
          </ResponsiveRow>
        )
      })}
    </div>

  )
}

export default LiveTable;
