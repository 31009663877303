import { IBoletoCnab } from '../../@types/services';
import { createApi } from '../../providers/factories';
import boletoCnabService from './boletosCnab';

const createBoletoCnab = (): IBoletoCnab => {
  const apiProvider = createApi();
  return boletoCnabService(apiProvider);
}

export default createBoletoCnab;
