import styled from 'styled-components'
import {
  Row as AntdRow,
  Col as AntdCol,
} from 'antd';

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
  lg: { span: 8 },
})``;

export const SubTitle = styled.h4`
  text-align: center;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 450px) {
    max-width: 300px;
  }
`;
