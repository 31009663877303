import { ICompanyLinked } from '../@types/services';
import { createApi } from '../providers/factories';
import companyLinkedService from './CompanyLinked';

const createCompanyLinkedService = (): ICompanyLinked => {
  const apiProvider = createApi();
  return companyLinkedService(apiProvider);
};

export default createCompanyLinkedService;
