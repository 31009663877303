import React, { FC } from 'react';

import { Label } from '../../../styles/inputs';
import { IFieldLabelProps } from './types';

const FieldLabel: FC<IFieldLabelProps> = ({ text, required, htmlFor }: IFieldLabelProps) => (
  <Label htmlFor={htmlFor}>
    {text}
    {required && (
      <Label variant="danger">
        *
      </Label>
    )}
    <Label>
      :
    </Label>
  </Label>
);

export default FieldLabel;
