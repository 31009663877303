import { IChargebackDto } from '../../../../global/dataTransferObjects/creditCard/chargeback';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  chargeback: {} as IChargebackDto,
  chargebacks: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  isCadastroChargebackModalOpen: false,
  isChargebackDetailsModalOpen: false,
  isChargebackEditModalOpen: false,
  isChargebackStatusModalOpen: false,
  selectedChargebacks: [],
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CHARGEBACKS:
      return {
        ...state,
        loading: false,
        chargebacks: action.payload?.chargebacks ?? { ...InitialState.chargebacks },
      };
    case ActionTypes.UPDATE_CADASTRO_CHARGEBACK_MODAL_STATUS:
      return {
        ...state,
        isCadastroChargebackModalOpen: action.payload?.modalStatus ?? InitialState.isCadastroChargebackModalOpen,
      };
    case ActionTypes.UPDATE_CHARGEBACK:
      return {
        ...state,
        chargeback: action.payload?.chargeback ?? { ...InitialState.chargeback },
      };
    case ActionTypes.OPEN_CADASTRO_CHARGEBACK_MODAL:
      return {
        ...state,
        isCadastroChargebackModalOpen: true,
      };
    case ActionTypes.UPDATE_CHARGEBACK_DETAILS_MODAL_STATUS:
      return {
        ...state,
        isChargebackDetailsModalOpen: action.payload?.modalStatus ?? InitialState.isChargebackDetailsModalOpen,
      };
    case ActionTypes.OPEN_CHARGEBACK_DETAILS_MODAL:
      return {
        ...state,
        isChargebackDetailsModalOpen: true,
      };
    case ActionTypes.UPDATE_CHARGEBACK_EDIT_MODAL_STATUS:
      return {
        ...state,
        isChargebackEditModalOpen: action.payload?.modalStatus ?? InitialState.isChargebackEditModalOpen,
      };
    case ActionTypes.OPEN_CHARGEBACK_EDIT_MODAL:
      return {
        ...state,
        isChargebackEditModalOpen: true,
      };
    case ActionTypes.UPDATE_CHARGEBACKS_SELECTION:
      return {
        ...state,
        selectedChargebacks: [...action.payload.chargebacks],
      };
    case ActionTypes.OPEN_CHARGEBACK_STATUS_MODAL:
      return {
        ...state,
        isChargebackStatusModalOpen: true,
      };
    case ActionTypes.UPDATE_CHARGEBACK_STATUS_MODAL_STATUS:
      return {
        ...state,
        isChargebackStatusModalOpen: action.payload?.modalStatus ?? InitialState.isChargebackStatusModalOpen,
      };
    default:
      return { ...state };
  }
};
