import React from 'react';
import { Redirect } from 'react-router-dom';
import { IAuthGateProps } from './types';
import useGetMe from '../../hooks/useCurrentUser';

const AuthGate: React.FC<IAuthGateProps> = (props: IAuthGateProps) => {
  const { isUserAuthenticated, children } = props;
  const user = useGetMe();

  if (!isUserAuthenticated) {
    return (
      <Redirect to="/login" />
    );
  }

  if (isUserAuthenticated && (user.userType === 2)) {
    return (
      <>
        {/* <Redirect to="/tecpay/lista-empresa" /> */}
        {children}
      </>
    );
  }

  return (
    <Redirect to="/" />
  );
};

export default AuthGate;
