import styled from 'styled-components';
import {Card as AntdCard, Layout} from 'antd';

const { Meta: AntdMeta } = AntdCard;

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;
  align-items: flex-start;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const CardContainer = styled.div`
  border-radius: 12px;
  background-color: #f9fbff;
  box-shadow: 1px 2px 9px rgba(92, 92, 92, 0.55);

  text-align: center;
  vertical-align: center;

  padding-top: 20px;
  padding-right: 12px;
  padding-left: 12px;

  margin-top: 20px;

  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-content: center;
  align-self: center;

  height: 110px !important;
  widht: 250px !important;
`;

export const Meta = styled(AntdMeta)`
  font-size: 2rem;
`;

export const ChartPanel = styled.div`

  padding: 4px;
  padding-left: 16px;
  margin: 16px;

  display: flex;

  min-width: 1000px;

  justify-content: space-between;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-content: center;
  align-self: center;

  @media (max-width: 769px) {
    display: none;
  }

  canvas{
    height: 425px !important;
    width: 425px !important;
  }

`;
