import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { T2 } from '../../../styles/titles';
import {
  getAllChargebacks,
  onOpenCadastroChargebackModal,
  onUpdateCadastroChargebackModalStatus,
  createChargeback,
  onUpdateChargebackDetailsModalStatus,
  openChargebackDetailsModal,
  deleteChargeback,
  openEditChargebackModal,
  onUpdateEditChargebackModalStatus,
  editChargeback,
  onUpdateChargebackSelection,
  onUpdateChargebackStatusModalStatus,
  onOpenChargebackStatusModal,
  updateChargebackStatus,
} from './actions';
import { InitialState, Reducer } from './actions/reducer';
import ChargebackTable from './components/table';
import { Container, Button } from './styles';
import CadastroChargebackModal from '../../../components/Forms/Pages/CadastroChargebackModal';
import { IFormValues } from '../../../components/Forms/Pages/CadastroChargebackModal/types';
import ChargebackDetailsModal from './components/modal';
import { IChargebackDto } from '../../../global/dataTransferObjects/creditCard/chargeback';
import EditarChargebackModal from '../../../components/Forms/Pages/EditarChargebackModal';
import FilterFormChargebacks from './components/FilterForm';
import { IFilterChargebackValues } from './components/FilterForm/types';
import UpdateChargebackStatusModal from '../../../components/Forms/Pages/UpdateChargebackStatusModal';
import { IFormUpdateCharegbackStatusValues } from '../../../components/Forms/Pages/UpdateChargebackStatusModal/types';

const Chargeback: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterChargebackValues>();

  const fetchChargebacks = useCallback(() => getAllChargebacks(offset, pageSize)(dispatch), []);

  useEffect(() => {
    fetchChargebacks();
  }, [fetchChargebacks]);

  const handleButtonClick = () => onOpenCadastroChargebackModal()(dispatch);

  const updateCadastroChargebackModalStatus = (status: boolean) => onUpdateCadastroChargebackModalStatus(status)(dispatch);

  const onCadastroChargebackFormSubmit = async (values: IFormValues): Promise<void> => {
    const result = await createChargeback({ ...values })(dispatch);
    if (!result) {
      return;
    }

    fetchChargebacks();
  }

  const onOpenChargebackDetailsModal = (chargeback: IChargebackDto) => {
    openChargebackDetailsModal({ ...chargeback })(dispatch);
  }

  const updateChargebackDetailsModalStatus = (status: boolean) => onUpdateChargebackDetailsModalStatus(status)(dispatch);

  const onDeleteChargeback = async (chargebackId: string): Promise<void> => {
    const result = await deleteChargeback(chargebackId)(dispatch);
    if (!result) {
      return;
    }

    fetchChargebacks();
  }

  const onOpenEditChargegebackModal = async (
    chargeback: IChargebackDto,
  ): Promise<void> => openEditChargebackModal({ ...chargeback })(dispatch);

  const updatEditChargebackModalStatus = (status: boolean) => onUpdateEditChargebackModalStatus(status)(dispatch);

  const onEditChargebackFormSubmit = async (values: IFormValues): Promise<void> => {
    const result = await editChargeback(state.chargeback.id, { ...values })(dispatch);
    if (!result) {
      return;
    }

    fetchChargebacks();
  }

  const fetchAllChargebacksPagination = (initialPage: number, finalPage: number) => {
    getAllChargebacks(initialPage, finalPage, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllChargebacksPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllChargebacksPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleChargebackFilter = async (filter: IFilterChargebackValues) => {
    setFilterOrder({ ...filter });
    getAllChargebacks(offset, pageSize, { ...filter })(dispatch);
  };

  const handleChargebackSelectionChange = (selectedRowKeys: Array<React.Key>, selectedRows: Array<IChargebackDto>) => {
    onUpdateChargebackSelection([...selectedRows])(dispatch);
  };

  const updateStatusChargebackModalStatus = (status: boolean) => onUpdateChargebackStatusModalStatus(status)(dispatch);

  const handleStatusButtonClick = () => onOpenChargebackStatusModal()(dispatch);

  const onUpdateChargebackStatusFormSubmit = async (values: IFormUpdateCharegbackStatusValues): Promise<void> => {
    const result = await updateChargebackStatus({ ...values }, [...state.selectedChargebacks])(dispatch);
    if (!result) {
      return;
    }

    fetchChargebacks();
  }

  return (
    <Container>
      <LoadingOverlay
        show={
          state.isCadastroChargebackModalOpen
            || state.isChargebackEditModalOpen
            || state.isChargebackStatusModalOpen
            ? false
            : state.loading
        }
        relative
      />
      <T2>CHARGEBACKS</T2>
      <Button icon={<PlusOutlined />} onClick={handleButtonClick}>
        Cadastrar chargeback
      </Button>
      <FilterFormChargebacks
        onFormSubmit={handleChargebackFilter}
      />
      {state.selectedChargebacks.length >= 1 ? (
        <Button icon={<PlusOutlined />} onClick={handleStatusButtonClick} style={{ alignSelf: 'flex-start' }}>
          Atualizar status
        </Button>
      ) : null}
      <ChargebackTable
        chargebacks={[...state.chargebacks.records]}
        handleOpenChagebackDetailsModal={onOpenChargebackDetailsModal}
        handleDeleteChargeback={onDeleteChargeback}
        handleOpenEditchargebackModal={onOpenEditChargegebackModal}
        pagination={
          {
            showSizeChanger: true,
            onShowSizeChange: (current: number, size: number) => {
              setPageSize(size);
            },
            defaultPageSize: 20,
            total: state.chargebacks.count,
            onChange: (page: number) => {
              setCurrentPage(page);
            },
          }
        }
        selectedChargebacks={[...state.selectedChargebacks]}
        onChargebackSelectionChange={handleChargebackSelectionChange}
      />
      <CadastroChargebackModal
        isVisible={state.isCadastroChargebackModalOpen}
        loading={state.loading}
        onModalClose={() => updateCadastroChargebackModalStatus(false)}
        onFormSubmit={onCadastroChargebackFormSubmit}
      />
      <ChargebackDetailsModal
        isVisible={state.isChargebackDetailsModalOpen}
        onClose={() => updateChargebackDetailsModalStatus(false)}
        chargeback={{ ...state.chargeback }}
      />
      <EditarChargebackModal
        onModalClose={() => updatEditChargebackModalStatus(false)}
        chargeback={{ ...state.chargeback }}
        isVisible={state.isChargebackEditModalOpen}
        loading={state.loading}
        onFormSubmit={onEditChargebackFormSubmit}
      />
      <UpdateChargebackStatusModal
        onModalClose={() => updateStatusChargebackModalStatus(false)}
        isVisible={state.isChargebackStatusModalOpen}
        loading={state.loading}
        onFormSubmit={onUpdateChargebackStatusFormSubmit}
      />
    </Container>
  )
};

export default Chargeback;
