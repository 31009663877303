import {da} from "date-fns/locale";

export const formatDate = (date: any): string | undefined => {
  if (date && date != undefined && date != '') {
    const d: Date = new Date(date);

    let month: string = `${d.getMonth() + 1}`;
    let day: string = `${d.getDate()}`;
    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    const year: number = d.getFullYear();
    const hour: number = d.getHours();
    const minutes: number = d.getMinutes();
    const seconds: number = d.getSeconds();

    if (hour || minutes) {
      const dateString = [year, month, day].join('-');
      return `${dateString} ${hour}:${minutes}:${seconds}`;
      // output format: yyyy-mm-dd
    } else return [year, month, day].join('-');
  }
  return undefined;
};

export const formatDateToDateTime = (date: Date): string => {
  let year: number = date.getFullYear();
  let month: number | string = date.getMonth();
  if (month < 10) month = `0${month}`;

  let day: number | string = date.getDate();
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}T00:00`
};
