import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import createBoletoCnab from '../../../../services/boletos/boletosCnab';
import { ActionTypes } from './types';
import { HttpStatus } from '../../../../services/providers/types';
import { IGerarBoletoCnabValues } from '../types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onCreateBoletoCnab = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_BOLETO_CNAB,
});

export const gerarBoletoCnab = (values: IGerarBoletoCnabValues) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const boletoCnabService = createBoletoCnab();
  const result = await boletoCnabService.createBoletoCnab({ ...values });
  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao obter informações do arquivo CNAB');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  return true;
}
