import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../../components/Forms/Input';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
  Container,
} from './styles';
import SelectField from '../../../../../components/Select';
import { ISelectValue } from '../../../../../global/types';
import { FormProps, IFormValues, IFilterFormProps } from './types';

const options: ISelectValue[] = [
  { label: 'Aguardando atualização do sistema', value: '0' },
  { label: 'Pendente de aprovação', value: '1' },
];

const FilterForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    resetForm,
  } = props;
  const resetFormTransaction = async () => {
    resetForm();
    await props.onFormSubmit({} as IFormValues);
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              type="text"
              name="endToEndId"
              label="EndToEndId"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="correlationId"
              label="CorrelationId"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="creditorPrivateIdentification"
              label="Chave PIX"
            />
          </Col>
          <Col span={6}>
            <SelectField
              name="status"
              placeholder=""
              label="Status"
              options={options}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder=""
                label="Data de início (Pagamento)"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder=""
                label="Data do fim (Pagamento)"
              />
            </DateView>
          </Col>
          <Col span={6} />
          <Col span={6} />
        </Row>
        <Row gutter={16}>
          <Col span={6} />
          <Col span={6} />
          <Col span={6} />
          <Col span={3}>
            <ResetButton onClick={() => resetFormTransaction()}>
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default withFormik<IFilterFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    endToEndId: '',
    correlationId: '',
    creditorPrivateIdentification: '',
    startDate: '',
    finishDate: '',
    status: null,
  }),
  handleSubmit: async (values, { props }) => props.onFormSubmit({ ...values }),
})(FilterForm);
