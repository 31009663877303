import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import FormInput from '../../../../../components/Forms/Input';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
} from './styles';
import { FormProps, IFormValues, ICreateModalProps } from './types';
import validationSchema from './validationSchema';

const CreateModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onModalClose,
    handleSubmit,
    loading,
    setFieldValue,
  } = props;

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value)
    return e;
  }, []);

  return (
    <Modal
      title="Gerar Copia e Cola"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Gerando Copia e Cola, por favor aguarde..."
        />
        <Row>
          <Col>
            <FormInput
              type="text"
              name="valor"
              label="Valor"
              required
              prefix="R$"
              placeholder="0,00"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, 'valor')}
            />
            <Button htmlType="submit">
              Gerar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<ICreateModalProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    valor: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => props.onSubmit({ ...values }),
})(CreateModal);
