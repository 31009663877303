import {getAxiosInstance} from "../../../services/providers/api";
import {FilterData} from "../types";

export default class DashboardServices {
  getVendas = async (filter: FilterData) => {
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;
    let status = null;
    if (filter.Status === '0') status = 0;
    else status = filter.Status;
    return getAxiosInstance().post('/transacoes/sales-panel', {
      offSet: 0,
      PageSize: 20,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      Status: status === -1 ? null : status,
      Paged: false,
    })
  };

  getRecebiveis = async (filter: FilterData) => {
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;
    const status = filter?.Status || null;
    return getAxiosInstance().post('/transacoes/receivables', {
      offSet: 0,
      PageSize: 20,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      StatusPagamento: status,
      Paged: false,
    })
  }
}
