export enum TiposDebitosEnum {
  Licenciamento,
  Dpvat,
  Ipva,
  Multa,
  MultaRenainf,
}

export enum NossaCidadeAntecipacaoStatus {
  NaoSolicitado = 1,
  Solicitado = 2,
  Aprovado = 3,
  Reprovado = 4,
}

export enum NotaStatus {
  naoSolicitado = 1,
  pendente = 2,
  aprovado = 3,
  reprovado = 4,
}
