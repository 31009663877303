import {
  createStore,
  Store,
  applyMiddleware,
  AnyAction,
} from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import { IAppState } from './types';
import rootReducer from './rootReducer';

const store: Store<IAppState> = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware),
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch<ExtraArgument = unknown> = ThunkDispatch<
RootState,
ExtraArgument,
AnyAction
>;

export default store;
