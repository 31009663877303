import React from "react";
import {CardProps} from "../../types";
import {CardContainer} from "../../styles";
import {formatMoney} from "../../utils";
import {TagOutlined} from "@ant-design/icons";

const Card: React.FC<CardProps> = (props) => {
  const {salesData} = props;

  function sumAllValues(sales: any) {
    const total = sales.reduce((total: number, sale: any) => total + sale.value, 0);
    return total;
  }

  return (
    <CardContainer>
      <h2>
        Valor De Vendas
      </h2>
      <p style={{fontSize: '24px'}}>{formatMoney(sumAllValues(salesData))}</p>
    </CardContainer>
  )
};

export default Card;
