/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { CarteiraVirtualDto, IFilterCarteiraVirtualProps } from '../../../global/dataTransferObjects/carteiraVirtual';
import { IPagedResult } from '../../../global/generics';
import createCarteiraVirtualService from '../../../services/carteiraVirtual';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onGetAllTransactions = (transactions: IPagedResult<CarteiraVirtualDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_TRANSACTIONS,
  payload: {
    transactions: { ...transactions },
  },
});

export const onOpenUserModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
});

export const onUpdateUserModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const getVirtualCheckingAccount = (
  offSet: number,
  pageSize: number,
  businessId?: string,
  filter?: IFilterCarteiraVirtualProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const carteiraVirtualService = createCarteiraVirtualService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await carteiraVirtualService.getVirtualCheckingAccount(offSet, pageSize, businessId, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetAllTransactions(result.response)(dispatch);
};
