import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('Este campo é obrigatório'),
  lastName: Yup.string()
    .required('Este campo é obrigatório'),
  email: Yup.string()
    .required('Este campo é obrigatório')
    .email('Email inválido'),
  password: Yup.string()
    .required('Este campo é obrigatório')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,30}$/,
      'A senha deve ter no mínimo 6 e no máximo 30 caracteres, pelo menos um número e um caractere especial, exemplo: @$!%*#?&'),
  passwordConfirmation: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
  phoneNumber: Yup.string()
    .required('Este campo é obrigatório'),
  cep: Yup.string()
    .required('Este campo é obrigatório'),
  logradouro: Yup.string()
    .required('Este campo é obrigatório'),
  numero: Yup.string()
    .required('Este campo é obrigatório'),
  bairro: Yup.string()
    .required('Este campo é obrigatório'),
  cidade: Yup.string()
    .required('Este campo é obrigatório'),
  uf: Yup.string()
    .required('Este campo é obrigatório')
    .matches(/^[A-Z]{2}$/, 'Este campo pode ter apenas duas letras maiúsculas, como SP ou DF'),
  documentNumber: Yup.string()
    .required('Este campo é obrigatório')
    .when('userType', {
      is: 1,
      then: Yup.string()
        .matches(/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/, 'O campo CPF está incompleto'),
    })
    .when('userType', {
      is: 2,
      then: Yup.string()
        .matches(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/, 'O campo CNPJ está incompleto'),
    }),
  birthDate: Yup.string()
    .when('userType', {
      is: 1,
      then: Yup.string().required('Este campo é obrigatório'),
    }),
  companyName: Yup.string()
    .when('userType', {
      is: 2,
      then: Yup.string().required('Este campo é obrigatório'),
    }),
  tradingName: Yup.string()
    .when('userType', {
      is: 2,
      then: Yup.string().required('Este campo é obrigatório'),
    }),
});
