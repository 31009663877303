/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IRepassesFilterProps, IDetailsRepasseDto } from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetDetalhesRepasses = (detalhes: IPagedResult<IDetailsRepasseDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_DETALHES_REPASSES,
  payload: {
    detalhes: { ...detalhes },
  },
});

export const getDetalhesRepasses = (
  offSet: number,
  pageSize: number,
  businessId: string,
  filter?: IRepassesFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getDetalhesRepasses(offSet, pageSize, businessId, filter);

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetDetalhesRepasses(result.response)(dispatch);
};
