import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Container,
  TableContainer,
} from './styles';
import { getAllNotas } from './actions';
import { Reducer, InitialState } from './actions/reducer';
import { T2 } from '../../styles/titles';
import { createFormatProvider } from '../../services/providers/factories';
import { INotaPromissoriaFilterProps } from '../../global/dataTransferObjects/notaPromissoria';
import { NotaStatus } from '../../global/enum';
import LoadingOverlay from '../../components/LoadingOverlay';
import FormFilter from './components/formFilter';
import Table, { TableColumn } from '../../components/Table';

const NotaPromissoria: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const fetchAllNotas = useCallback(() => getAllNotas(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllNotas();
  }, [fetchAllNotas]);

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const notasPagination = (initialPage: number, finalPage :number) => {
    getAllNotas(initialPage, finalPage, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      notasPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      notasPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const notas = state?.notaPromissoria?.records.map((notasPromissoria) => (
    {
      ...notasPromissoria,
      name: `${notasPromissoria.emprestimoConsignado?.user?.name} ${notasPromissoria.emprestimoConsignado?.user?.lastName}`
      || 'N/A',
      cpf: notasPromissoria.emprestimoConsignado?.user?.documentNumber || 'N/A',
    }
  ));

  const handleFilter = async (filter: INotaPromissoriaFilterProps) => {
    setFilterOrder(filter);
    getAllNotas(offSet, pageSize, filter)(dispatch);
  };

  const formatStatus = (status: number) => {
    let notaStatus = '';
    if (status === NotaStatus.aprovado) {
      notaStatus = 'Aprovado';
    }
    if (status === NotaStatus.naoSolicitado) {
      notaStatus = 'Não Solicitado';
    }
    if (status === NotaStatus.pendente) {
      notaStatus = 'Pendente';
    }
    if (status === NotaStatus.reprovado) {
      notaStatus = 'Reprovado';
    }
    return notaStatus;
  }

  const formattedDocumentNumber = (numeroDocumento: string) => (
    numeroDocumento?.length === 14
      ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
      : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
  );

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Notas Promissoria</T2>
      <FormFilter onFormSubmit={handleFilter} />
      <TableContainer>
        <Table
          dataSource={notas}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.notaPromissoria.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Data Adiantamento" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="CPF" dataIndex="cpf" key="cpf" render={formattedDocumentNumber} />
          <TableColumn title="Valor Solicitação" dataIndex="valorSolicitacao" key="valorSolicitacao" render={format} />
          <TableColumn title="No. Controle" dataIndex="numeroControle" key="numeroControle" />
          <TableColumn title="Status" dataIndex="status" key="status" render={formatStatus} />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default NotaPromissoria;
