import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { format as formatDateFns, parseISO } from 'date-fns'
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import FormFilter from './components/filter';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  LegendContainer,
  LegendText,
  LegendTitle,
  TableContainer,
  WarningMessage,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getDetalhesRepasses } from './actions';
import { IRepassesFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import createBusinessService from '../../../services/business';
import createRepasseService from '../../../services/repasses';
import { ISelectValue } from '../../../global/types';

const DetalhesRepasses: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [statisticBusiness, setStatisticBusiness] = useState<any>({});
  const [businessPageSizeList] = useState<number>(100);
  const [businessOffSetList] = useState<number>(0);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  let businessId: any = query.get('id');

  const repasseService = createRepasseService();

  const businessService = createBusinessService();

  const handleLoadBusinessOptions = async (name: any): Promise<Array<ISelectValue>> => {
    setFilterOrder({ name });
    const businessList = await businessService.getEmpresasList(businessOffSetList, businessPageSizeList, filterOrder);

    return businessService.mapToSelectableBusiness(businessList.response.records);
  };

  const getEmpresas = useCallback(async () => {
    const allBusiness = await repasseService.getStatisticsBusiness(businessId);
    const res = allBusiness.response.records.shift();
    setStatisticBusiness(res);
  }, []);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formatStatus = (status: string) => {
    if (status === 'Payed') {
      return 'Pago';
    }
    return 'Pendente';
  }

  const formatType = (type: number) => {
    if (type === 2) {
      return 'Crédito';
    }
    return 'Débito';
  }

  const formatPrepayment = (prepayment: number) => {
    if (prepayment === 1) {
      return 'Não';
    }
    return 'Sim';
  }

  const formatDateAndHours = (date: string) => {
    const dateFormat = parseISO(date);
    const formattedDateAndHours = formatDateFns(dateFormat, "dd/MM/yyyy' às 'HH:mm'h'");
    return formattedDateAndHours;
  }

  const repassesPagination = (initialPage: number, finalPage :number) => {
    getDetalhesRepasses(initialPage, finalPage, businessId, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      repassesPagination(pageSize * (currentPage - 1), (pageSize))
    }
    if (currentPage === 1) {
      repassesPagination(0, (pageSize));
    }
  }, [currentPage, pageSize]);

  const handleDetalhesRepassesFilter = async (filter: IRepassesFilterProps) => {
    businessId = filter?.businessId || businessId;
    setFilterOrder(filter);
    getDetalhesRepasses(offSet, pageSize, businessId, filter)(dispatch);
  }

  const formatFee = (value: number) => {
    const feeFormated = Math.abs(value);
    const fee = `${feeFormated.toFixed(2)}%`;
    return fee;
  }

  const allRepasses = state?.detalhes?.records.map((params) => (
    {
      ...params,
    }
  ));

  const {
    paymentAmount,
    grossAmount,
    payedAmount,
  } = state?.detalhes.totalRepasses;

  const formatColor = (amount: number) => {
    if (amount > (statisticBusiness.averageTransation + statisticBusiness.stDevTransaction)) {
      return '#D0342C';
    }
    return '';
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Repasses - Detalhes</T2>
      <WarningMessage>
        {`Atenção: Este cliente teve uma média de ${(statisticBusiness?.transactionNumber / 90).toFixed()}
        transações/dia nos últimos 90 dias,
        variando de ${format(statisticBusiness?.minimumTransaction)} até ${format(statisticBusiness?.maximumTransaction)} em
        uma média de ${format(statisticBusiness?.averageTransation)} por
        transação com desvio padrão de ${format(statisticBusiness?.stDevTransaction)}`}
      </WarningMessage>
      <FormFilter onFormSubmit={handleDetalhesRepassesFilter} onLoadEmpresas={handleLoadBusinessOptions} />
      <TableContainer>
        <Table
          dataSource={allRepasses}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.detalhes.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} />
                <TableSumCell index={5} />
                <TableSumCell index={6} />
                <TableSumCell index={7}>{format(grossAmount)}</TableSumCell>
                <TableSumCell index={8} />
                <TableSumCell index={9}>{format(paymentAmount)}</TableSumCell>
                <TableSumCell index={10} />
                <TableSumCell index={11} />
                <TableSumCell index={12} />
                <TableSumCell index={13} />
                <TableSumCell index={14}>{format(payedAmount)}</TableSumCell>
                <TableSumCell index={15} />
                <TableSumCell index={16} />
                <TableSumCell index={17} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0}>Previsão</TableSumCell>
                <TableSumCell index={1}>Op. Ant.</TableSumCell>
                <TableSumCell index={2}>Pagamento</TableSumCell>
                <TableSumCell index={3}>Empresa</TableSumCell>
                <TableSumCell index={4}>Data transação</TableSumCell>
                <TableSumCell index={5}>NSU/Parcela</TableSumCell>
                <TableSumCell index={6}>Tipo</TableSumCell>
                <TableSumCell index={7}>Venda</TableSumCell>
                <TableSumCell index={8}>Adm.</TableSumCell>
                <TableSumCell index={9}>Líquido</TableSumCell>
                <TableSumCell index={10}>Dias</TableSumCell>
                <TableSumCell index={11}>Fator</TableSumCell>
                <TableSumCell index={12}>Diferencial</TableSumCell>
                <TableSumCell index={13}>V.P.</TableSumCell>
                <TableSumCell index={14}>Vl. Pago</TableSumCell>
                <TableSumCell index={15}>Dt. Pgto</TableSumCell>
                <TableSumCell index={16}>Status</TableSumCell>
                <TableSumCell index={17}>Pgto. Antec.</TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn title="Previsão" dataIndex="paymentDate" key="paymentDate" render={formattedDate} />
          <TableColumn title="Op. Ant." dataIndex="AnticipationOperationId" key="AnticipationOperationId" />
          <TableColumn title="Pagamento" dataIndex="paymentDate" key="paymentDate" render={formattedDate} />
          <TableColumn title="Empresa" dataIndex="businessName" key="businessName" />
          <TableColumn title="Data transação" dataIndex="captureDateTime" key="captureDateTime" render={formatDateAndHours} />
          <TableColumn title="NSU/Parcela" dataIndex="stoneId" key="stoneId" />
          <TableColumn title="Tipo" dataIndex="productType" key="productType" render={formatType} />
          <TableColumn
            title="Venda"
            dataIndex="grossAmount"
            key="grossAmount"
            render={(item: number) => ({
              props: {
                style: { background: formatColor(item) },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn title="Adm." dataIndex="fee" key="fee" />
          <TableColumn title="Líquido" dataIndex="paymentAmount" key="paymentAmount" render={format} />
          <TableColumn title="Dias" dataIndex="anticipationDays" key="anticipationDays" />
          <TableColumn title="Fator" dataIndex="anticipationFee" key="anticipationFee" render={formatFee} />
          <TableColumn
            title="Diferencial"
            dataIndex="AnticipationDiscount"
            key="AnticipationDiscount"
            render={(item: number) => ({
              children: <div>{item !== 0 ? item : '' }</div>,
            })}
          />
          <TableColumn
            title="V.P."
            dataIndex="InstallmentStatus"
            key="InstallmentStatus"
            render={(item: number) => ({
              children: <div>{item !== 0 ? item : '' }</div>,
            })}
          />
          <TableColumn title="Vl. Pago" dataIndex="payedAmount" key="payedAmount" render={format} />
          <TableColumn title="Dt. Pgto." dataIndex="payedDate" key="payedDate" render={formattedDate} />
          <TableColumn title="Status" dataIndex="status" key="status" render={formatStatus} />
          <TableColumn
            title="Pgto. Antec."
            dataIndex="pagamentoPelaAntecipadora"
            key="pagamentoPelaAntecipadora"
            render={formatPrepayment}
          />

        </Table>
      </TableContainer>
      <LegendContainer>
        <LegendTitle>Legenda: </LegendTitle>
        <LegendText
          color="#cc6600"
        >
          Transação feita com a tarja magnética

        </LegendText>
      </LegendContainer>
    </Container>
  );
};

export default DetalhesRepasses;
