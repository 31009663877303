import React, {useCallback} from 'react';
import {
  ShoppingCartOutlined,
  CreditCardOutlined,
  BankOutlined,
  UserOutlined,
  IdcardOutlined,
  HomeOutlined
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {Menu, AvatarOverlayItem} from '../styles';
import { VscLiveShare, VscDiscard  } from 'react-icons/vsc';

const PageOverlayParceiro: React.FC = () => {

  const history = useHistory();
  const redirectToSaleSimulatorPage = useCallback(() => history.push('/simulador/venda'),[history]);
  const redirectToIncomeSimulatorPage = useCallback(() => history.push('/simulador/recebimento'),[history]);
  const redirectToSalesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-vendas'),[history]);
  const redirectToRecivablesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-recebiveis'),[history]);
  const redirectToPaymentPanel = useCallback(() => history.push('/tecpay/admin/painel-de-pagamentos'), [history] );
  const redirectToUserPage = useCallback(()=> history.push('/tecpay/admin/usuarios'), [history]);
  const redirectToBusinessPage = useCallback(() => history.push('/tecpay/admin/empresas'), [history]);
  const redirectToDashboardPage = useCallback(() => history.push('/dashboard'), [history]);

  return (
    <Menu>
      <AvatarOverlayItem
        icon={<HomeOutlined />}
        onClick={redirectToDashboardPage}
      >
        Home
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<UserOutlined/>}
        onClick={redirectToUserPage}
      >
        Usuários
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<IdcardOutlined />}
        onClick={redirectToBusinessPage}
      >
        Empresas
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<VscDiscard/>}
        onClick={redirectToRecivablesPanel}
      >
        Painel de Recebiveis
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<ShoppingCartOutlined/>}
        onClick={redirectToSalesPanel}
      >
        Painel de Vendas
      </AvatarOverlayItem>

      {/*<AvatarOverlayItem*/}
      {/*  icon={<CreditCardOutlined />}*/}
      {/*  onClick={redirectToIncomeSimulatorPage}*/}
      {/*>*/}
      {/*  Simulador - Recebimento*/}
      {/*</AvatarOverlayItem>*/}

      {/*<AvatarOverlayItem*/}
      {/*  icon={<CreditCardOutlined />}*/}
      {/*  onClick={redirectToSaleSimulatorPage}*/}
      {/*>*/}
      {/*  Simulador - Venda*/}
      {/*</AvatarOverlayItem>*/}
    </Menu>
  );
};
export default PageOverlayParceiro;
