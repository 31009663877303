import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { format, parseJSON } from 'date-fns';
import LoadingOverlay from '../../components/LoadingOverlay';
import Table, { TableColumn } from '../../components/Table';
import { T2 } from '../../styles/titles';
import FormFilter from './components/FormFilter'
import { getArranjos, InitialState, Reducer } from './actions';
import {
  Container,
  TableContainer,
} from './style';
import { IFilterArranjosProps } from './components/FormFilter/types';

const Arranjos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet, setOffSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchArranjos = useCallback(() => getArranjos(pageSize, offSet)(dispatch), []);
  const data = useMemo(() => state.arranjos.records, [state])
  const handleSubmit = async (filter: IFilterArranjosProps) => {
    getArranjos(pageSize, offSet, filter)(dispatch)
  };

  useEffect(() => {
    fetchArranjos()
  }, [fetchArranjos])
  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Arranjos Financeiros</T2>
      <FormFilter onFormSubmit={handleSubmit} />
      <TableContainer>
        <Table
          dataSource={
            data.map((prev) => (
              {
                ...prev,
                dtPgto: format(parseJSON(prev.dtPgto), 'dd/MM/yyyy HH:mm'),
                dtHrArq: format(parseJSON(prev.dtHrArq), 'dd/MM/yyyy HH:mm'),

              }
            ))
          }
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.arranjos.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Tipo do arquivo" dataIndex="tipoDoArquivo" key="tipoDoArquivo" />
          <TableColumn title="CNPJCreddr" dataIndex="cnpjCreddr" key="CNPJCreddr" />
          <TableColumn title="NomCreddr" dataIndex="nomCreddr" key="NomCreddr" />
          <TableColumn title="NULiquid" dataIndex="nuLiquid" key="NULiquid" />
          <TableColumn title="ISPBIFLiquidPontoVenda" dataIndex="ispbifLiquidPontoVenda" key="ISPBIFLiquidPontoVenda" />
          <TableColumn title="CodOcorc" dataIndex="codOcorc" key="CodOcorc" />
          <TableColumn title="CodPontoVenda" dataIndex="codPontoVenda" key="CodPontoVenda" />
          <TableColumn title="NomePontoVenda" dataIndex="nomePontoVenda" key="NomePontoVenda" />
          <TableColumn title="CNPJ_CPFPontoVenda" dataIndex="cnpJ_CPFPontoVenda" key="CNPJ_CPFPontoVenda" />
          <TableColumn title="CodInstitdrArrajPgto" dataIndex="codInstitdrArrajPgto" key="CodInstitdrArrajPgto" />
          <TableColumn title="DtPgto" dataIndex="dtPgto" key="DtPgto" />
          <TableColumn title="VlrPgto" dataIndex="vlrPgto" key="VlrPgto" />
          <TableColumn title="DtHrArq" dataIndex="dtHrArq" key="DtHrArq" />
        </Table>
      </TableContainer>
    </Container>
  );
}
export default Arranjos;
