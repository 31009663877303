import {getAxiosInstance} from "../../../../services/providers/api";
import {PainelVendasFilterProps} from "../../../../global/dataTransferObjects/transacoesTempoReal";
import axios from "axios";


export default class PainelDePagamentosService {

  getPainelDePagamentos(filter: any) {
    if(filter.acquirer == -1){
      delete filter.acquirer
    }
    return getAxiosInstance().post('/transacoes/payments', filter)
  }


  salvarPagamentos(liveTransactions: any) {
    return getAxiosInstance().post('/transacoes/update-payments',liveTransactions)
  }

  async getFileDownload(filter?: PainelVendasFilterProps) {
    const result = await getAxiosInstance().post('/transacoes/payments-xls', filter, {responseType: 'blob'});
    return result.data;
  }

  async getPagamentos (filter: any) {
    const formattedFilter = {
      SaleInitDate: filter.initialDate,
      SaleFinishDate: filter.finalDate,
      TipoPagamento: filter.tipoPagamento,
      BusinessName: filter.businessName,
      Acquirer: filter.acquirer
    };

    if (formattedFilter.Acquirer == -1) delete formattedFilter.Acquirer;

    return axios.post('https://link.valori.com.vc:8485/api/painel-pagamentos/filtro', {
      ...formattedFilter
    })
  }

  async updatePagamentosExternos (data: any) {
    return await axios.post('https://link.valori.com.vc:8485/api/painel-pagamentos/atualiza', data)
  }

  async updatePagamentosCredito (data: any) {
    return axios.post('https://link.valori.com.vc:8485/api/painel-pagamentos/atualiza-credito', data)
  }

  async updatePagamentosDebitoPix (data: any) {
    return axios.post('https://link.valori.com.vc:8485/api/painel-pagamentos/atualiza-debito',data)
  }

  async updatePagamentosPixAsync (data: any) {
    return axios.post('https://link.valori.com.vc:8485/api/painel-pagamentos/atualiza-pix', data)
  }

}
