import styled from 'styled-components';
import { Button as StyledButton } from '../../styles/buttons';

export const Container = styled.div`
  padding : 2rem 1rem;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
`;

export const CardTitle = styled.span`
  font-size: 1.2rem;
`;

export const Total = styled.strong`
  font-size: 1.6rem;
`;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
`;
