import React from 'react';
import {
  Modal,
} from './styles';
import { ICreateUserModalProps } from './actions/types';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import SignForm from './components/form';

const CreateUserModal: React.FC<ICreateUserModalProps> = (props: ICreateUserModalProps) => {
  const {
    isVisible,
    onModalClose,
    onFormSubmit,
    loading,
  } = props;

  return (
    <Modal
      title="Cadastrar Novo Usuário"
      centered
      visible={isVisible}
      onCancel={onModalClose}
      footer={null}
      width={700}
    >
      <SignForm onSignUpFormSubmit={onFormSubmit} loading={loading}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro do usuário, por favor aguarde..."
        />
      </SignForm>
    </Modal>
  );
}

export default CreateUserModal;
