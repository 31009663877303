import { IVeiculoService } from '../@types/services';
import { ICreateVeiculoDto, IVeiculoDto } from '../../global/dataTransferObjects/veiculo';
import {
  HttpMethod,
  IApiResponse,
  ITecpayApi,
} from '../providers/types';
import { ISelectValue } from '../../components/Forms/AsyncFormDropdown/types';
import {
  IDebitosDto,
  IMultasRenaifResponseDto,
  IRendimentoTaxasServico,
} from '../../global/dataTransferObjects/debitosVeiculares';
import { IPagedResult } from '../../global/generics';
import { TiposDebitosEnum } from '../../global/enum';
import { createSecurityProvider } from '../providers/factories';

const veiculoService = (apiProvider: ITecpayApi): IVeiculoService => {
  const securityProvider = createSecurityProvider();

  const createNewVeiculo = async (veiculoDto: ICreateVeiculoDto): Promise<IApiResponse<IVeiculoDto>> => {
    const payload = { ...veiculoDto };
    return apiProvider.createRequest<ICreateVeiculoDto, IVeiculoDto>({ url: '/veiculos' })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync();
  };

  const getUserVeiculos = async (offset = 0, pageSize = 20): Promise<IApiResponse<IPagedResult<IVeiculoDto>>> => apiProvider
    .createRequest<IPagedResult<IVeiculoDto>>({ url: '/veiculos' })
    .withSearch({ offset, pageSize })
    .sendAsync();

  const getVehicleDebits = async (vehicleId: string): Promise<IApiResponse<IDebitosDto>> => apiProvider
    .createRequest<IDebitosDto>({ url: `/veiculos/${vehicleId}/debitos` })
    .sendAsync();

  const mapToSelectableVehicles = (vehicles: Array<IVeiculoDto>): Array<ISelectValue> => vehicles
    .map((vehicle) => ({
      label: `Apelido: ${vehicle.apelido}, renavam: ${vehicle.renavam} e estado: ${vehicle.uf}`,
      value: vehicle.id,
    }));

  const getDebitosVeicularesTaxas = (): Promise<IApiResponse<IRendimentoTaxasServico>> => apiProvider
    .createRequest<IRendimentoTaxasServico>({ url: '/veiculos/taxas-servico' })
    .sendAsync();

  const calcularValorTotalDebitosVeiculares = (debitos: IDebitosDto): Promise<number> => new Promise((resolve) => {
    const reduceTotal = (list: Array<any>) => list.reduce((acc, cur) => acc + cur.valor, 0);

    const totalLicenciamento = debitos.licenciamento.taxaLicenciamento ?? 0;
    const totalDpvat = reduceTotal(debitos.dpvaTs);
    const totalIpva = reduceTotal(debitos.ipvas);
    const totalMulta = reduceTotal(debitos.multas);

    return resolve(totalLicenciamento + totalDpvat + totalIpva + totalMulta);
  });

  const calcularValorTotalDebitosVeicularesComTaxas = async (
    installment: number,
    debitos: IDebitosDto,
    valorTaxas: IRendimentoTaxasServico,
  ): Promise<number> => {
    const somaTotal = await calcularValorTotalDebitosVeiculares(debitos);
    const { aliquota, taxaFixa } = valorTaxas.debitosVeiculares;

    const valorParcial = (somaTotal / 100) * (1 + (aliquota / 100)) ** installment;
    const valorTotal = valorParcial + taxaFixa;

    return valorTotal * 100;
  };

  const getVeiculoMultasRenaif = (veiculoId: string): Promise<IApiResponse<IMultasRenaifResponseDto>> => apiProvider
    .createRequest<IMultasRenaifResponseDto>({ url: '/veiculos/:veiculoId/multas-renainf' })
    .withParameters({ veiculoId })
    .sendAsync();

  const loadVeiculosDropdown = async (offset = 0, pageSize = 20): Promise<ISelectValue[]> => {
    const veiculos = await getUserVeiculos(offset, pageSize);
    return mapToSelectableVehicles(veiculos.response.records);
  }

  const mapDebitos = (debitos: Array<any>, tipoDebito: TiposDebitosEnum) => (debitos.map((debito) => ({
    ...debito,
    key: securityProvider.createUuid(),
    tipoDebito,
  })));

  return ({
    createNewVeiculo,
    getUserVeiculos,
    getVehicleDebits,
    mapToSelectableVehicles,
    getDebitosVeicularesTaxas,
    calcularValorTotalDebitosVeiculares,
    calcularValorTotalDebitosVeicularesComTaxas,
    getVeiculoMultasRenaif,
    loadVeiculosDropdown,
    mapDebitos,
  });
};

export default veiculoService;
