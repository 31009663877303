import React from 'react';
import { useField, useFormikContext } from 'formik';
import FieldLabel from '../FieldLabel';
import { Label } from '../../../styles/inputs';
import { IFormDropdownProps } from './types';
import { SelectField } from './styles';

const FormDropdown: React.FC<IFormDropdownProps> = (props: IFormDropdownProps) => {
  const {
    label,
    placeholder,
    onChange,
    isRequired,
    childrenFieldsToReset,
    validateOnSelect,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const onFieldChange = (value: any) => {
    childrenFieldsToReset?.map((fieldName: string) => formikContext.setFieldValue(fieldName, 0));
    if (value && validateOnSelect && !validateOnSelect(value)) return;
    helpers.setValue(value);
  };

  const onValueChange = (value: any) => {
    onFieldChange(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <>
      <FieldLabel
        htmlFor={field.name}
        text={label}
        required={isRequired}
      />
      <SelectField
        onChange={onValueChange}
        placeholder={placeholder}
        value={field.value}
        noOptionsMessage={() => 'Não há resultados...'}
        id={field.name}
        classNamePrefix="react-select"
        {...rest}
      />
      {(meta.error) && (
        <Label variant="danger">
          {meta.error}
        </Label>
      )}
    </>
  );
};

export default FormDropdown;
