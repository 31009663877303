import {
  IContaDigitalTransacoesDTO,
  IPagedContaDigitalResponseDto,
} from '../../../../global/dataTransferObjects/contaDigital/transacoes';

export interface IPageState {
  loading: boolean;
  transacoes: IPagedContaDigitalResponseDto<IContaDigitalTransacoesDTO>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_TRANSACOES = 'UPDATE_TRANSACOES',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
