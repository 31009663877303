import React from 'react';
import Modal from '../../../../components/Modal';
import { ModalBody, TextGroup } from '../styles';
import { IUnidadeRecebivelModalProps } from '../types';

const UnidadeRecebivelModal: React.FC<IUnidadeRecebivelModalProps> = (props: IUnidadeRecebivelModalProps) => {
  const { unidadeRecebivel, isVisible, onClose } = props;

  return (
    <Modal
      title="Detalhes da unidade de recebível"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <div>
            <h4>Data liquidação:</h4>
            <h5>{unidadeRecebivel.dataLiquidacao}</h5>
          </div>
          <div>
            <h4>Arranjo de pagamento:</h4>
            <h5>{unidadeRecebivel.codigoArranjoPagamento}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Instituição credenciadora ou subcredenciadora:</h4>
            <h5>{unidadeRecebivel.instituicaoCredenciadora}</h5>
          </div>
          <div>
            <h4>Usuário final recebedor:</h4>
            <h5>{unidadeRecebivel.documentoFinalRecebedor}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Constituação:</h4>
            <h5>{unidadeRecebivel.constituicao}</h5>
          </div>
          <div>
            <h4>Valor constituido total:</h4>
            <h5>{unidadeRecebivel.valorConstituidoTotal}</h5>
          </div>
        </TextGroup>
        <TextGroup>
          <div>
            <h4>Valor pré-contratado:</h4>
            <h5>{unidadeRecebivel.valorConstituidoAntecipacaoPre}</h5>
          </div>
          <div>
            <h4>Valor bloqueado:</h4>
            <h5>{unidadeRecebivel.valorBloqueado}</h5>
          </div>

        </TextGroup>
        <TextGroup>
          <div>
            <h4>Valor livre:</h4>
            <h5>{unidadeRecebivel.valorLivre}</h5>
          </div>
          <div>
            <h4>Valor total da unidade de recebível:</h4>
            <h5>{unidadeRecebivel.valorTotalUr}</h5>
          </div>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default UnidadeRecebivelModal;
