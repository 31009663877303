import styled from 'styled-components';
import { Table as AntdTable } from 'antd';

const { Column, Summary } = AntdTable;

export const Table = styled(AntdTable)`
  .mouse-entered-row {
    cursor: pointer;
  }

  @media (max-width: 768px){
    display: none;
  }
`;

export const TableSum = styled(Summary)``;

export const TableSumCell = styled(Summary.Cell)`
  & .ant-table-summary > tr > th, .ant-table-summary > tr > td {
    background-color: #f2f9ff;
    color: red;
  }

`;

export const TableColumn = styled(Column)``;
