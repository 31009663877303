import styled from 'styled-components'
import {darken} from "polished";

export const SimuladorFormContainer = styled.div `
  justify-content: center;
  height: auto;
  width: 600px;
  margin-left: 24px;
  padding: 12px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  input{
    margin: 4px;
    border:none;
  }

  select{
    cursor: pointer;
    margin: 4px;
    height: 25px;
    border: none;
  }

  button {
    cursor: pointer;
    border: none;
    color: white;
    width: 150px;
    margin-left: 18px;

    background-color: ${(props) => props.theme.colors.blueCyanDarkest};

    &:active, &:hover, &:focus {
      background-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
      border-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
    }
  }

  @media only screen and (max-width: 430px){
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button{
      margin: 5px;
    }

  }
`;

export const InputContainer = styled.div `
  display: flex;
  align-items: center;

  @media (max-width: 430px){
    display: flex;
    flex-direction: column;
  }
`
export const ValueContainer = styled.div `
  display: flex;
  flex-direction: row;
`;

export const CheckboxContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 430px){
    padding: 4px;
  }
`

export const SimulatedTableContainer = styled.div `
  display: flex;
  align-items: center;
  justify-self: center;
  margin-left: 24px;
  padding: 12px;
  width: 1000px;
  border-collapse: collapse;

  table{
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    display: flex;
    text-align: left;
    width: auto;
    border: 1px solid #a09f9f;
    align-items: center;
    font-size: 20px;
  }

  th {
    color: #0e2b27;

    text-align: center;
    padding: 0;
    margin: 0;
    width: 50%;
    border-left: 1px solid #a09f9f;
    border-right: 1px solid #a09f9f;
  }

  td{
    width: 50%;
    border-left: 1px solid #a09f9f;
    border-right: 1px solid #a09f9f;
    padding: 0;
  }

  @media only screen and (max-width: 430px){

    align-content: center;
    margin: 0px;
    padding: 12px;
    width: 100%;

    tr{
      font-size: 15px;
    }

    table{
      width: 100%;
    }
    th{
      font-size: smaller;
      width: 50%;
    }
    td{
      width: 50%;
    }
  }

`;
