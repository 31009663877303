import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { createAction, createAsyncAction } from 'typesafe-actions';
import { UpdateUserInfo, UserActionTypes } from './types';
import {
  IAuthenticationRequestDto,
  IAuthenticationResponseDto,
  ICreateNewUserDto,
  ICreateNewUserResponseDto,
  IForgotPasswordDto,
  IResetPasswordDto,
  IValidateResetTokenDto,
} from '../../global/dataTransferObjects/user';

import createUserService from '../../services/user';

import { createAuthenticationProvider } from '../../services/providers/factories';
import {IConfigData} from "../config/types";

const getSignUpMessageByStatus = (status: number): string => {
  switch (status) {
    case 201:
      return 'Usuario criado com sucesso! Efetue o login agora.';
    case 400:
      return 'Informações para cadastro de usuário inválidas!';
    case 409:
      return 'Já existe um usuário com esse email ou número de documento.';
    default:
      return 'Houve um erro ao efetuar seu cadastro!';
  }
};

const getSignInMessageByStatus = (status: number): string => {
  switch (status) {
    case 401:
      return 'Email ou senha inválidos.';
    default:
      return 'Houve um erro ao efetuar o login!';
  }
};

export const updateUserInfo = createAction(UserActionTypes.UpdateUserInfo)<UpdateUserInfo>();

export const doLogout = () => (dispatch: Dispatch): void => {
  const authenticationProvider = createAuthenticationProvider();
  authenticationProvider.clearAuthenticationData();

  dispatch({ type: UserActionTypes.Logout });
};

export const authenticateUserActions = createAsyncAction(
  UserActionTypes.AuthenticateRequest,
  UserActionTypes.AuthenticateSuccess,
  UserActionTypes.AuthenticateFailure,
)<boolean, IAuthenticationResponseDto, string>();

export const createNewUserActions = createAsyncAction(
  UserActionTypes.CreateNewUserRequest,
  UserActionTypes.CreateNewUserSuccess,
  UserActionTypes.CreateNewUserFailure,
)<boolean, ICreateNewUserResponseDto, string>();

export const forgotPasswordActions = createAsyncAction(
  UserActionTypes.ForgotPasswordRequest,
  UserActionTypes.ForgotPasswordSuccess,
  UserActionTypes.ForgotPasswordFailure,
)<boolean, ICreateNewUserResponseDto, string>();

export const resetPasswordActions = createAsyncAction(
  UserActionTypes.ResetPasswordRequest,
  UserActionTypes.ResetPasswordSuccess,
  UserActionTypes.ResetPasswordFailure,
)<boolean, ICreateNewUserResponseDto, string>();

export const validateResetTokenActions = createAsyncAction(
  UserActionTypes.ValidateResetTokenRequest,
  UserActionTypes.ValidateResetTokenSuccess,
  UserActionTypes.ValidateResetTokenFailure,
)<boolean, ICreateNewUserResponseDto, string>();

export const liftSession = () => async (dispatch: Dispatch): Promise<boolean> => {
  const userService = createUserService();
  const authProvider = createAuthenticationProvider();
  const tokenDto = authProvider.getAuthenticationRefreshToken();
  if (!tokenDto) {
    doLogout()(dispatch);
    return true;
  }

  const authData = await userService.refreshAccessToken(tokenDto);
  if (!authData || authData.status !== 201) {
    doLogout()(dispatch);
    return true;
  }

  authProvider.setAuthenticationInfo(authData.response);
  dispatch(authenticateUserActions.success(authData.response));
  return true;
};

export const authenticateUser = (userInfo: IAuthenticationRequestDto) => async (dispatch: Dispatch): Promise<void> => {
  const userService = createUserService();
  const authProvider = createAuthenticationProvider();
  dispatch(authenticateUserActions.request(true));
  const authData = await userService.authenticate(userInfo);
  const message = getSignInMessageByStatus(authData.status);

  if (!authData || authData.status !== 201) {
    toast.error(message);
    await dispatch(authenticateUserActions.failure(message));
    return;
  }

  const domain = window.location.hostname;
  const cachedConfig: IConfigData = JSON.parse(localStorage.getItem(`config_${domain}`) as string);
  const domainWhiteLabelId = cachedConfig.id;

  const userBusinessData = authData.response.businessList;
  let userBusinessDataWhiteLabelId: number | null = null;

  if (userBusinessData && userBusinessData.length >= 1) {
    if (userBusinessData.length === 1) {
      userBusinessDataWhiteLabelId = userBusinessData[0]?.whiteLabelId ?? null;
    } else {
      const foundElement = userBusinessData.find((element: any) => element?.whiteLabelId !== undefined);
      userBusinessDataWhiteLabelId = foundElement?.whiteLabelId ?? null;
    }
  }

  if (userBusinessDataWhiteLabelId) {
    if (domainWhiteLabelId !== userBusinessDataWhiteLabelId && userInfo.grantType !== 'support_access') {
      toast.error(message);
      await dispatch(authenticateUserActions.failure(message));
      return;
    }
  }

  authProvider.setAuthenticationInfo(authData.response);
  dispatch(authenticateUserActions.success(authData.response));
};

export const createNewUser = (userInfo: ICreateNewUserDto) => async (dispatch: Dispatch): Promise<boolean> => {
  const userService = createUserService();
  dispatch(createNewUserActions.request(true));
  const createdUser = await userService.createNewUser(userInfo);
  const message = getSignUpMessageByStatus(createdUser.status);

  if (!createdUser || createdUser.status !== 201) {
    toast.error(createdUser.message ?? message);
    dispatch(createNewUserActions.failure(createdUser.message ?? message));
    return false;
  }

  toast.success(createdUser.message ?? message);
  dispatch(createNewUserActions.success(createdUser.response));
  return true;
}

export const forgotPassword = (userInfo: IForgotPasswordDto) => async (dispatch: Dispatch): Promise<void> => {
  const userService = createUserService();
  dispatch(forgotPasswordActions.request(true));
  const result = await userService.forgotPassword({ ...userInfo });

  if (!result || result.status !== 201) {
    toast.error('Erro ao solicitar redefinção de senha, tente mais tarde!');
    dispatch(forgotPasswordActions.failure(result.message ?? 'Erro ao solicitar redefinção de senha, tente mais tarde!'));
    return;
  }

  toast.success(result.message ?? 'Verifique seu e-mail para obter instruções de redefinição de senha');
  dispatch(forgotPasswordActions.success(result.response));
}

export const resetPassword = (userInfo: IResetPasswordDto) => async (dispatch: Dispatch): Promise<boolean> => {
  const userService = createUserService();
  dispatch(resetPasswordActions.request(true));
  const result = await userService.resetPassword({ ...userInfo });

  if (!result || result.status !== 201) {
    toast.error('Erro ao redefinir de senha, tente mais tarde!');
    dispatch(resetPasswordActions.failure(result.message ?? 'Erro ao redefinir de senha, tente mais tarde!'));
    return false;
  }

  toast.success(result.message ?? 'Redefinição de senha bem-sucedida, agora você pode fazer o login!');
  dispatch(resetPasswordActions.success(result.response));
  return true;
}

export const validateResetToken = (userInfo: IValidateResetTokenDto) => async (dispatch: Dispatch): Promise<boolean> => {
  const userService = createUserService();
  dispatch(validateResetTokenActions.request(true));
  const result = await userService.validateResetToken({ ...userInfo });

  if (!result || result.status !== 200) {
    dispatch(validateResetTokenActions
      .failure(result.message ?? 'Erro ao validar token de redefinição de senha, tente mais tarde!'));
    return false;
  }

  dispatch(validateResetTokenActions.success(result.response));
  return true;
}
