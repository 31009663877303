/* eslint-disable no-debugger */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IPagedResult } from '../../../global/generics';
import { IAgilitasVincularProxyDto } from '../../../global/dataTransferObjects/cartaoAgilitas/vincularProxy';
import { IAgilitasListarProxyDto } from '../../../global/dataTransferObjects/cartaoAgilitas/listarProxys';
import createContaEscrowService from '../../../services/contaEscrow';
import { ActionTypes } from './types';
import { IFilterProxyListProps } from '../components/FormFilter/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateProxyList = (proxys : IPagedResult<IAgilitasListarProxyDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PROXY_LIST,
  payload: {
    proxys: { ...proxys },
  },
});

export const onSubmitProxy = () => (dispatch : Dispatch<any>): void => dispatch({
  type: ActionTypes.SUBMIT_PROXY,
  payload: {},
})

export const submitNewProxy = (
  body: IAgilitasVincularProxyDto,
) => async (dispatch: Dispatch<any>) : Promise<void> => {
  const contaEscrowService = createContaEscrowService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaEscrowService.submitProxy(body);

  if (result.status === 200) {
    toast.success('O Proxy foi vinculado com sucesso.');
    onUpdateLoadingStatus(false)(dispatch);
  } else {
    toast.error(result.message);
    onUpdateLoadingStatus(false)(dispatch);
  }

  onSubmitProxy();
}

export const getProxys = (
  offset: number,
  pageSize: number,
  filter? : IFilterProxyListProps,
) => async (dispatch : Dispatch<any>) : Promise<void> => {
  const contaEscrowService = createContaEscrowService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaEscrowService.getProxyList(offset, pageSize, filter);

  if (result.status !== 200) {
    toast.error(result.message);
  }

  onUpdateProxyList(result.response)(dispatch);
}
