import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  description: Yup.string().required('Este campo é obrigatório'),
  accessionFee: Yup.string().required('Este campo é obrigatório'),
  debitFee: Yup.string().required('Este campo é obrigatório'),
  debitFeeMasterCard: Yup.string().required('Este campo é obrigatório'),
  debitFeeAmex: Yup.string().required('Este campo é obrigatório'),
  debitFeeHipercard: Yup.string().required('Este campo é obrigatório'),
  debitFeeVisaAdiq: Yup.string().required('Este campo é obrigatório'),
  debitFeeMasterCardAdiq: Yup.string().required('Este campo é obrigatório'),
  debitFeeAmexAdiq: Yup.string().required('Este campo é obrigatório'),
  debitFeeHipercardAdiq: Yup.string().required('Este campo é obrigatório'),
  debitFeeElo: Yup.string().required('Este campo é obrigatório'),
  debitFeeEloAdiq: Yup.string().required('Este campo é obrigatório'),
  anticipationFee: Yup.string().required('Este campo é obrigatório'),
  taxaExtra: Yup.string().required('Este campo é obrigatório'),
  minAnticipationAmount: Yup.string().required('Este campo é obrigatório'),
  anticipationComissionFee: Yup.string().required('Este campo é obrigatório'),
  anticipationLimit: Yup.string().required('Este campo é obrigatório'),
  debitFloat: Yup.string().required('Este campo é obrigatório'),
  daysGracePeriod: Yup.string().required('Este campo é obrigatório'),
  minimumTransactionValueToChargeFixedFee: Yup.string().required('Este campo é obrigatório'),
  fixedFeeToChargeOnMinimumTransaction: Yup.string().required('Este campo é obrigatório'),
  dailyProvisionValue: Yup.string().required('Este campo é obrigatório'),
  valorMinimoRepasse: Yup.string().required('Este campo é obrigatório'),
  originacaoArquivoCip: Yup.string().required('Este campo é obrigatório'),
  mensalidadeConta: Yup.string().required('Este campo é obrigatório'),
  tarifaGeracaoBoletoLote: Yup.string().required('Este campo é obrigatório'),
  taxaPixRecebimento: Yup.string().required('Este campo é obrigatório'),
  taxaPixPagamento: Yup.string().required('Este campo é obrigatório'),
  mensalidadeCartao: Yup.string().required('Este campo é obrigatório'),
});

export default validationSchema;
