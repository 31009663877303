import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const TableContainer = styled.div`
  .mouse-entered-row {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const TextGroup = styled.div`
  h4 {
    font-weight: bold;
  }
`;
