import styled from 'styled-components';
import { Layout } from 'antd';
import { Button as StyledButton } from '../../styles/buttons'

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const FilterView = styled.div`
  display: flex;
  background-color: red;
  height: 40px;
`;

export const TableContainer = styled.div``;

export const Description = styled.text`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

export const Item = styled.text`
  margin-left: 10px;
  font-size: 16px;
  color: black;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextGroup = styled.div`
  margin-bottom: 10px;
`;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  align-self: flex-end;
`;
