import { createApi } from '../providers/factories';
import { IArranjoService } from '../@types/services';
import arranjoService from './arranjoService';

const createArranjoService = (): IArranjoService => {
  const apiProvider = createApi();
  return arranjoService(apiProvider);
};

export default createArranjoService;
