import { AdjustmentDTO } from '../../../../global/dataTransferObjects/adjustments';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  adjustments: IPagedResult<AdjustmentDTO>;
  zeroState: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_ADJUSTMENTS = 'GET_ALL_ADJUSTMENTS',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
