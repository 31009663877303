import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IUnidadeRecebivelResponseDto } from '../../../../global/dataTransferObjects/unidadeRecebivel';
import { IPagedResult } from '../../../../global/generics';
import createCercService from '../../../../services/cerc';

export const onUpdateLoadingStatus = (loadingStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    loadingStatus,
  },
});

export const onUpdateUnidadeRecebivel = (
  unidadeRecebivel: IUnidadeRecebivelResponseDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_UNIDADE_RECEBIVEL,
  payload: {
    unidadeRecebivel: { ...unidadeRecebivel },
  },
});

export const onUpdateModalStatus = (modalStatus = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_MODAL_STATUS,
  payload: {
    modalStatus,
  },
});

export const onUpdateUnidadesRecebiveis = (
  unidadesRecebiveis: IPagedResult<IUnidadeRecebivelResponseDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_UNIDADES_RECEBIVEIS,
  payload: {
    unidadesRecebiveis: { ...unidadesRecebiveis },
  },
});

export const openUnidadeRecebivelModal = (
  unidadeRecebivel: IUnidadeRecebivelResponseDto,
) => (dispatch: Dispatch<any>): void => {
  onUpdateUnidadeRecebivel({ ...unidadeRecebivel })(dispatch);
  onUpdateModalStatus(true)(dispatch);
}

export const getUnidadesRecebiveis = (
  solicitacaoConsultaId: string,
  pageSize = 20,
  offset = 0,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  onUpdateLoadingStatus(true)(dispatch);
  const cercService = createCercService();
  const result = await cercService
    .getUnidadesRecebiveis(solicitacaoConsultaId, offset, pageSize);

  if (result.status !== 200) {
    onUpdateLoadingStatus()(dispatch);
    toast.error(result.message);

    return;
  }

  onUpdateUnidadesRecebiveis({ ...result.response })(dispatch);
  onUpdateLoadingStatus()(dispatch);
};
