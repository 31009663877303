import React from 'react';
import Modal from '../../../components/Modal';
import {
} from '../../../components/Masks';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from './styles';
import { IViewBusinessModalProps } from '../types';

const UnidadeRecebivelModal: React.FC<IViewBusinessModalProps> = (props: IViewBusinessModalProps) => {
  const { business, isVisible, onClose } = props;

  return (
    <Modal
      title="Detalhes do business"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Business ID:</Description>
          <Item>{business.id}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{business?.name}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cnpj:</Description>
          <Item>{business?.cnpj}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Atividade Comercial:</Description>
          <Item>{business?.businessActivity}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome Contato:</Description>
          <Item>{business?.contact}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Telefone 1:</Description>
          <Item>{business?.telephone1}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Telefone 2:</Description>
          <Item>{business?.telephone2}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cidade:</Description>
          <Item>{business?.addressCity}</Item>
        </TextGroup>
        <TextGroup>
          <Description>UF:</Description>
          <Item>{business?.addressState}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Rua:</Description>
          <Item>{business?.addressDescription}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Bairro:</Description>
          <Item>{business?.addressNeighborhood}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Numero:</Description>
          <Item>{business?.addressNumber}</Item>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default UnidadeRecebivelModal;
