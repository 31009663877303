import { Image } from 'antd';
import React, {
  useCallback,
  useEffect,
  useReducer, useState,
} from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/logo.png';
import {
  Container,
  Title,
  Section,
  Wrapper,
  Item,
  Button,
  ContentWrapper,
  ButtonWrapper,
  TitleWrapper,
  ButtonLogoutWrapper,
  ButtonLogout,
} from './styles';
import Reducer, { InitialState } from '../../store/Company/reducer';
import { getCompanyLinkedList, saveCompanySelected } from '../../store/Company';
import { doLogout } from '../../store/user/actions';
import LoadingOverlay from '../../components/LoadingOverlay';
import useGetCompany from '../../hooks/useCurrentBusiness';
import { ISelectedBusiness } from '../../global/dataTransferObjects/business';
import {IConfigData} from "../../store/config/types";

export interface ListaEmpresaProps {
  onSaveCompanySelected: (values: ISelectedBusiness) => Promise<void>;
  onDoLogout: () => void;
}

const ListaEmpresas: React.FC<ListaEmpresaProps> = (props: ListaEmpresaProps) => {
  const { onDoLogout, onSaveCompanySelected } = props;
  const onSignOutButtonClick = () => onDoLogout();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [logoUrl, setLogoUrl] = useState('');
  const fetchCompanyList = useCallback(() => getCompanyLinkedList()(dispatch), []);

  useEffect(() => {
    fetchCompanyList();
    }, [fetchCompanyList]);

  const handleCompanySelected = (item: any) => {
    const selectedCompany = {
      id: item.id,
      name: item.name,
      merchantId: item.merchantId,
      allowPaymentLink: item.allowPaymentLink,
    }
    const selectedCompanyAux = {
      value: item.id,
      label: item.name,
      id: item.id,
      name: item.name,
      merchantId: item.merchantId,
      allowPaymentLink: item.allowPaymentLink,
    }
    const empresasAux = state?.company?.map((item) => ({
      value: item.id,
      label: item.name,
      id: item.id,
      name: item.name,
      merchantId: item.merchantId,
      allowPaymentLink: item.allowPaymentLink,
    }));
    localStorage.setItem('selectedCompany', JSON.stringify(selectedCompanyAux));
    localStorage.setItem('empresas', JSON.stringify(empresasAux));
    onSaveCompanySelected(selectedCompany);
  };

  useEffect(() => {
    const domain = window.location.hostname;
    const cachedConfig: IConfigData = JSON.parse(localStorage.getItem(`config_${domain}`) as string);
    if (cachedConfig) {
      setLogoUrl(cachedConfig.linkImagem);
    }
  }, []);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <Image
        alt="logo-valori"
        src={logoUrl || logo}
        preview={false}
      />
      <Section>
        <TitleWrapper>
          <Title>Selecione uma empresa</Title>
        </TitleWrapper>
        {state?.company?.map((item) => (
          <Wrapper>
            <ContentWrapper>
              <Item>{item.name}</Item>
            </ContentWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => handleCompanySelected(item)}
              >
                Selecionar

              </Button>
            </ButtonWrapper>
          </Wrapper>
        ))}
      </Section>
      <ButtonLogoutWrapper>
        <ButtonLogout
          onClick={onSignOutButtonClick}
        >
          Sair
        </ButtonLogout>
      </ButtonLogoutWrapper>
    </Container>
  )
}

const mapStateToProps = (props: ListaEmpresaProps) => ({
  props,
});

const mapDispatchToProps = {
  onSaveCompanySelected: saveCompanySelected,
  onDoLogout: doLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaEmpresas);
