import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IContaDigitalDTO, ISaldoCartaoDto } from '../../../global/dataTransferObjects/contaDigital';
import createContaDigitalService from '../../../services/contaDigital';
import { HttpStatus } from '../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status: boolean) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateContaDigital = (contaDigital: IContaDigitalDTO) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CONTA_DIGITAL,
  payload: {
    contaDigital: { ...contaDigital },
  },
});

export const onUpdateSaldoCartao = (saldoCartao: ISaldoCartaoDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.SALDO_CARTAO,
  payload: {
    saldoCartao: { ...saldoCartao },
  },
});

export const updateContaDigital = () => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const contaDigitalService = createContaDigitalService();
  const result = await contaDigitalService.getContaDigital();
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Houve um erro ao obter informações da conta digital');
    return false;
  }

  onUpdateContaDigital({ ...result.response })(dispatch);
  return true;
};

export const updateSaldoCartao = () => async (dispatch: Dispatch<any>): Promise<void> => {
  const contaDigitalService = createContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaDigitalService.getSaldoCartao();
  if (result.status !== HttpStatus.OK) {
    if (result.status === HttpStatus.NOT_FOUND) {
      onUpdateLoadingStatus(false)(dispatch);
      return;
    }
    toast.error('Não foi possível obter informações sobre o saldo do seu cartão');
    onUpdateLoadingStatus(false)(dispatch);
  }

  onUpdateSaldoCartao({ ...result.response })(dispatch);
};
