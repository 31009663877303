import React, { FormEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import Label from '../FieldLabel'
import { IInputSearchProps } from './types';
import {
  ErrorMessage,
  FormInput,
  InputContainer,
} from '../../../styles/inputs';

const { Search } = FormInput;

const Input: React.FC<IInputSearchProps> = (props: IInputSearchProps) => {
  const {
    type,
    placeholder,
    onFocus,
    name,
    label,
    onBlur,
    required,
    disabled,
    value,
    prefix,
    onChange,
    onSearch,
    allowClear,
  } = props;
  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const onFieldChange = (e: FormEvent<HTMLInputElement>) => {
    helpers.setValue(e.currentTarget.value);
  };

  return (
    <InputContainer>
      {!!label && (
        <Label
          htmlFor={name}
          text={label}
          required={required}
        />
      )}
      <Search
        type={type}
        name={name}
        placeholder={placeholder}
        value={field.value || value}
        onChange={onChange ?? onFieldChange}
        onBlur={onBlur ?? field.onBlur}
        onFocus={onFocus ?? formikContext.handleBlur}
        disabled={disabled}
        prefix={prefix}
        onSearch={onSearch}
        allowClear={allowClear}
      />
      {meta.error && (
        <ErrorMessage>
          {meta.error}
        </ErrorMessage>
      )}
    </InputContainer>
  );
};

Input.defaultProps = {
  placeholder: '',
};

export default Input;
