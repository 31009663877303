import React, { FormEvent } from 'react';
import { Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import { TextAreaProps } from './types';
import Label from '../FieldLabel'
import { ErrorMessage, InputContainer } from '../../../styles/inputs';

export const FormTextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    placeholder,
    onFocus,
    name,
    label,
    onBlur,
    required,
    disabled,
    value,
    onChange,
    rows,
  } = props;

  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const onFieldChange = (e: FormEvent<HTMLInputElement>) => {
    helpers.setValue(e.currentTarget.value);
  };

  return (
    <InputContainer>
      {!!label && (
        <Label
          htmlFor={name}
          text={label}
          required={required}
        />
      )}
      <Input.TextArea
        name={name}
        placeholder={placeholder}
        value={field.value || value}
        onChange={onChange ?? onFieldChange}
        onBlur={onBlur ?? field.onBlur}
        onFocus={onFocus ?? formikContext.handleBlur}
        disabled={disabled}
        rows={rows}
      />
      {meta.error && (
        <ErrorMessage>
          {meta.error}
        </ErrorMessage>
      )}
    </InputContainer>
  );
};
