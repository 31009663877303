import {getAxiosInstance} from "../../../services/providers/api";
import {ITerminalDto} from "../../../global/terminal";

export default class TerminaisServices {

  getAllTerminais = async (filter: ITerminalDto) => {
    const identifier = filter?.identifier || null;
    const serialNumber = filter?.serialNumber || null;
    const tid = filter?.tid || null;

    return getAxiosInstance().get('/terminals', {
      params: {
        offSet: 0,
        PageSize: 20,
        Identifier: identifier,
        SerialNumber: serialNumber,
        Tid: tid
      }
    })
  }

  getTerminalById = async (id: number) => {
    return getAxiosInstance().get(`/terminals/${id}`);
  }
}
