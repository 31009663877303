import React from 'react';
import { Field } from 'formik';
import FormInput from '../Input';
import { IConsultaFormProps } from './types';
import { InputGroup } from '../../../styles/inputs';
import Select from '../../Select';
import DatePickerField from '../../DatePicker';
import Creatable from '../../SelectCreatable';
import {
  SelectGroup,
  DateView,
  AlignCheckbox,
  CheckboxTitle,
  CheckboxContainer,
} from './styles';

const bandeirasCartoes = [
  { value: '99T', label: 'Todos' },
  { value: 'MCC', label: 'Mastercard' },
  { value: 'VCC', label: 'Visa' },
  { value: 'ECC', label: 'ELO' },
  { value: 'ACC', label: 'Amex' },
  { value: 'HCC', label: 'Hipercard' },
];

const CpfForm: React.FC<IConsultaFormProps> = (props) => {
  const { values } = props;
  return (
    <>
      <FormInput
        type="text"
        name="nomeIdentificador"
        label="Nome"
        required
      />
      <InputGroup>
        <FormInput
          type="string"
          name="numeroDocumento"
          label="CPF"
          mask="999.999.999-99"
          required
        />
      </InputGroup>
      <CheckboxContainer>
        <CheckboxTitle>Todas Credenciadoras:</CheckboxTitle>
        <AlignCheckbox>
          <Field
            name="todasCredenciadoras"
            type="checkbox"
          />
        </AlignCheckbox>
        <Creatable
          name="credenciadora"
          isMulti
          isDisabled={values.todasCredenciadoras}
          placeholder="Digite o código da credenciadora"
        />
      </CheckboxContainer>
      <InputGroup>
        <DateView>
          <DatePickerField
            name="dataInicio"
            placeholder="Data inicio"
          />
        </DateView>
        <DateView>
          <DatePickerField
            name="dataFim"
            placeholder="Data fim"
          />
        </DateView>
      </InputGroup>
      <SelectGroup>
        <Select
          name="listaCodigoArranjoPagamento"
          placeholder="Selecione as bandeiras"
          isMulti
          options={bandeirasCartoes}
        />
      </SelectGroup>
    </>
  );
}

export default CpfForm;
