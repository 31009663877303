import React, { useMemo } from 'react';
import moment from 'moment';
import Modal from '../../../../components/Modal';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from '../styles';
import { IViewBoletoModalProps } from '../types';
import { createFormatProvider } from '../../../../services/providers/factories';

const BoletoModal: React.FC<IViewBoletoModalProps> = (props: IViewBoletoModalProps) => {
  const { boleto, isVisible, onClose } = props;

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);

  function formattedDocumentNumber(numeroDocumento: string) {
    return (
      numeroDocumento?.length === 14
        ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
        : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
    );
  }

  const handleTipoPessoa = (tipo: number) => {
    if (tipo === 1) {
      return 'Pessoa física';
    }
    if (tipo === 2) {
      return 'Pessoa jurídica';
    }
    return '';
  }

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? moment(date).format('DD/MM/YYYY')
    : 'N/A'), []);

  return (
    <Modal
      title="Detalhes do Boleto"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Id:</Description>
          <Item>{boleto.id}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nosso número:</Description>
          <Item>{boleto.nossoNumero}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor nominal:</Description>
          <Item>{boleto.vlrNominal ? format(boleto.vlrNominal) : 0}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data emissão:</Description>
          <Item>{formattedDate(boleto.dataEmissao)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Vencimento:</Description>
          <Item>{formattedDate(boleto.dataVencimento)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Seu número:</Description>
          <Item>{boleto.seuNumero}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tipo pessoa:</Description>
          <Item>{handleTipoPessoa(boleto.tipoPessoa)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>CPF/CNPJ:</Description>
          <Item>{formattedDocumentNumber(boleto.cpfCnpj.toString())}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{boleto.nome}</Item>
        </TextGroup>
        <TextGroup>
          <Description>E-mail:</Description>
          <Item>{boleto.email}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Endereço:</Description>
          <Item>{boleto.endereco}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Bairro:</Description>
          <Item>{boleto.bairro}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cidade:</Description>
          <Item>{boleto.cidade}</Item>
        </TextGroup>
        <TextGroup>
          <Description>UF:</Description>
          <Item>{boleto.uf}</Item>
        </TextGroup>
        <TextGroup>
          <Description>CEP:</Description>
          <Item>{boleto.cep}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tipo pessoa Sacador:</Description>
          <Item>{handleTipoPessoa(boleto.tipoPessoaSacador)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>CPF/CPNJ Sacador:</Description>
          <Item>{handleTipoPessoa(boleto.cnpjCpfSacador)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome Sacador:</Description>
          <Item>{boleto.nomeSacador}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Agência:</Description>
          <Item>{boleto.agencia}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Conta corrente:</Description>
          <Item>{boleto.contaCorrente}</Item>
        </TextGroup>
        <TextGroup>
          <Description>No. Carteira:</Description>
          <Item>{boleto.numCarteira}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Banco:</Description>
          <Item>{boleto.codBanco}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Possui agenda?:</Description>
          <Item>{boleto.possuiAgenda}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Qtd. Consulta Registro CIP:</Description>
          <Item>{boleto.qtdConsultaRegistroCip}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Titulo Incluido DDA:</Description>
          <Item>{boleto.tituloIncluidoDDa}</Item>
        </TextGroup>
        <TextGroup>
          <Description>No. Controle Legado:</Description>
          <Item>{boleto.numControleLegado}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nosso No. Bancos:</Description>
          <Item>{boleto.nossoNumeroBancos}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Agencia Cob:</Description>
          <Item>{boleto.codAgenciaCob}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Mensagem Erro:</Description>
          <Item>{boleto.msgErro}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Linha Digitavel:</Description>
          <Item>{boleto.linhaDigitavel}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Intervalo consulta Registro CIP:</Description>
          <Item>{boleto.intervaloConsultaRegistroCip}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Ind. Pagador Eletrônico:</Description>
          <Item>{boleto.indPagadorEletronico}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Data Legado:</Description>
          <Item>{formattedDate(boleto.dataLegado)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Modal Bancos:</Description>
          <Item>{boleto.codModalBancos}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Erro:</Description>
          <Item>{boleto.codErro}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Coligada:</Description>
          <Item>{boleto.codColigada}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Cód. Barras:</Description>
          <Item>{boleto.codBarras}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Situação:</Description>
          <Item>{boleto.situacao}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Boleto gerado:</Description>
          <Item>{boleto.boletoGerado}</Item>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default BoletoModal;
