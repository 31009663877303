import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import FormFilter from '../repasses/components/filter';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
  PredictedValueContainer,
  LegendContainer,
  LegendText,
  LegendTitle,
  ItemTable,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getTransferenciasRepasses } from './actions';
import { IRepassesFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import createBusinessService from '../../../services/business';
import { ISelectValue } from '../../../global/types';

const TransferenciasRepasses: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [businessPageSizeList] = useState<number>(100);
  const [businessOffSetList] = useState<number>(0)
  const businessService = createBusinessService();

  const handleLoadBusinessOptions = async (name: any): Promise<Array<ISelectValue>> => {
    setFilterOrder({ name });
    const businessList = await businessService.getEmpresasList(businessOffSetList, businessPageSizeList, filterOrder);

    return businessService.mapToSelectableBusiness(businessList.response.records);
  };

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formatStatusAntecipadora = (status: boolean) => {
    if (status) {
      return 'Sim';
    }
    return 'Não';
  }

  const formatFluxo = (fluxo: string) => {
    if (fluxo === 'Registrado') {
      return 'Sim';
    }
    return 'Não';
  }

  const repassesPagination = (initialPage: number, finalPage :number) => {
    getTransferenciasRepasses(initialPage, finalPage, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      repassesPagination(pageSize * (currentPage - 1), (pageSize))
    }
    if (currentPage === 1) {
      repassesPagination(0, (pageSize));
    }
  }, [currentPage, pageSize]);

  const handleDetalhesRepassesFilter = async (filter: IRepassesFilterProps) => {
    setFilterOrder(filter);
    getTransferenciasRepasses(offSet, pageSize, filter)(dispatch);
  }

  const allRepasses = state?.transferenciaRepasses?.records.map((params) => (
    {
      ...params,
    }
  ));

  const formatColorFixed = (record: any) => {
    if (record.temContaPadrao === false) {
      return 'wheat'
    }
    if (record.transacaoForaPadrao === 1) {
      return '#cc6600';
    }
    if (record.ticketId) {
      return 'paleturquoise'
    }
    return '';
  };

  const formatColorDinamic = (record: any) => {
    if (record.temContaPadrao === false) {
      return 'wheat'
    }
    if (record.ticketId) {
      return 'paleturquoise'
    }
    if (record.tarjaMagnetica === true) {
      return '#ff9966';
    }
    return '';
  };

  const {
    paidAmount,
    provisioningValue,
    transferAmount,
  } = state?.transferenciaRepasses?.totalRepasses;

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Transferência de Repasses</T2>
      <FormFilter onFormSubmit={handleDetalhesRepassesFilter} onLoadEmpresas={handleLoadBusinessOptions} />
      <TableContainer>
        <Table
          dataSource={allRepasses}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.transferenciaRepasses.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4}>{format(paidAmount)}</TableSumCell>
                <TableSumCell index={5} align="right">{format(provisioningValue)}</TableSumCell>
                <TableSumCell index={6}>{format(transferAmount)}</TableSumCell>
                <TableSumCell index={7} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0}>Visualizar</TableSumCell>
                <TableSumCell index={1}>É Antecipadora</TableSumCell>
                <TableSumCell index={2}>Data de pagamento</TableSumCell>
                <TableSumCell index={3}>Empresa</TableSumCell>
                <TableSumCell index={4}>Valor previsto</TableSumCell>
                <TableSumCell index={5}>Valor Provisionamento & TED</TableSumCell>
                <TableSumCell index={6}>Total a Transferir</TableSumCell>
                <TableSumCell index={7}>Registrado na CERC?</TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn
            title="Visualizar"
            render={(i: any, e: any) => (state?.transferenciaRepasses.records.length >= 1 ? (
              <Link
                style={{
                  color: 'white',
                  backgroundColor: '#169ead',
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingBottom: 3,
                  paddingTop: 2,
                  borderRadius: 3,
                }}
                to={`/tecpay/admin/repasses/detalhes?id=${e.businessId}`}
              >
                <EyeOutlined />
              </Link>
            ) : null)}
          />
          <TableColumn
            title="É Antecipadora"
            dataIndex="ehAntecipadora"
            key="ehAntecipadora"
            render={(item: boolean, record: any) => ({
              props: {
                style: { backgroundColor: formatColorDinamic(record) },
              },
              children: <div>{formatStatusAntecipadora(item)}</div>,
            })}
          />
          <TableColumn
            title="Data de pagamento"
            dataIndex="paidDate"
            key="paidDate"
            render={(item: string, record: any) => ({
              props: {
                style: { backgroundColor: formatColorDinamic(record) },
              },
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="Empresa"
            dataIndex="businessName"
            key="businessName"
            render={(item: string, record: any) => ({
              props: {
                style: { backgroundColor: formatColorDinamic(record) },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Valor previsto"
            dataIndex="paidAmount"
            key="paidAmount"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  background: formatColorFixed(record),
                },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Provisionamento & TED"
            dataIndex="provisioningValue"
            key="provisioningValue"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  color: 'red',
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children:
  <PredictedValueContainer>
    <ItemTable>{format(item)}</ItemTable>
    <Link
      style={{
        color: 'white',
        backgroundColor: '#169ead',
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 3,
        paddingTop: 2,
        borderRadius: 3,
        flexDirection: 'row',
        marginLeft: 5,
      }}
      to={`/tecpay/admin/carteira-virtual?id=${record.businessId}`}
    >
      <EyeOutlined />
    </Link>
  </PredictedValueContainer>,
            })}
          />
          <TableColumn
            title="Total a Transferir"
            dataIndex="transferAmount"
            key="transferAmount"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Registrado na CERC?"
            dataIndex="fluxo005"
            key="fluxo005"
            render={(item: string, record: any) => ({
              props: {
                style: {
                  color: item === 'Registrado' ? 'blue' : 'red',
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{formatFluxo(item)}</div>,
            })}
          />
        </Table>
      </TableContainer>
      <LegendContainer>
        <LegendTitle>Legenda: </LegendTitle>
        <LegendText
          color="wheat"
        >
          Não tem conta corrente padrão cadastrada
        </LegendText>
        <LegendText
          color="paleturquoise"
        >
          Solicitou Split de pagamento
        </LegendText>
        <LegendText
          color=" #ff9966"
        >
          Transação com tarja magnética
        </LegendText>
        <LegendText
          color="#cc6600"
        >
          Transação fora do padrão estatístico
        </LegendText>
      </LegendContainer>
    </Container>
  );
};

export default TransferenciasRepasses;
