import { Col, Row } from 'antd';
import { withFormik } from 'formik';
import React, { useCallback, useState } from 'react'
import AsyncFormDropdown from '../../../../components/Forms/AsyncFormDropdown';
import { AdditionalType } from '../../../../components/Forms/AsyncFormDropdown/types';
import { formatterDocumentNumber } from '../../../../components/Masks';
import { IAsyncDropdownOptions } from '../../../../global/dataTransferObjects/dropdown';
import createUserService from '../../../../services/user';
import { Button, Form } from '../styles';
import { FormProps, IFilterFormProps, IFormValues } from './types';

const LinkUserForm = (props: FormProps) => {
  const {
    handleSubmit,
  } = props;

  const [pageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleAnticipationList = useCallback(async (
    q: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ): Promise<IAsyncDropdownOptions> => {
    const userService = createUserService();
    const result = await userService.getUsers(
      currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      currentPage * pageSize,
      undefined,
    );

    setCurrentPage(page + 1);
    const options = result.response.records.filter((item) => item.userType === 4).map((item: any) => ({
      label: `${item.name} - ${formatterDocumentNumber(item.documentNumber)}`,
      value: item.id,
    }));
    return ({
      options,
      hasMore: Math.ceil(result.response.count / pageSize) > page,
      additional: {
        page: page + 1,
      },
    });
  }, [currentPage]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={18} align="top" justify="center">
        <Col span={18}>
          <AsyncFormDropdown
            name="anticipationId"
            label="Antecipadora"
            loadOptions={handleAnticipationList}
            placeholder="Antecipadora para vincular"
          />
          <Button htmlType="submit">Salvar</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik<IFilterFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    anticipationId: null,
  }),
  // validationSchema,
  handleSubmit: async (values, { props }) => props.onSubmit({ ...values }),
})(LinkUserForm);
