import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import {
  IPoliticaContaDigitalDTO,
  IUpdatePoliticaContaDigitalRequestDTO,
} from '../../../../global/dataTransferObjects/politicaContaDigital';
import { UserDto } from '../../../../global/dataTransferObjects/user';
import createPoliticaContaDigitalService from '../../../../services/politicaContaDigital';
import { HttpStatus } from '../../../../services/providers/types';
import createUserService from '../../../../services/user';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePoliticaContaDigital = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_POLITICA_CONTA_DIGITAL,
});

export const onGetPoliticaContaDigital = (
  politicaContaDigital: IPoliticaContaDigitalDTO,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_POLITICA_CONTA_DIGITAL,
  payload: {
    politicaContaDigital: { ...politicaContaDigital },
  },
});

export const onUpdateSelectedUsers = (
  selectedUsers: Array<UserDto>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_POLITICA_CONTA_DIGITAL_USERS,
  payload: {
    selectedUsers: [...selectedUsers],
  },
});

export const updatePoliticaContaDigital = (
  politicaCobrancaId: string,
  updatePoliticaCobrancaDTO: IUpdatePoliticaContaDigitalRequestDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  onUpdateLoadingStatus(true)(dispatch);
  const politicaContaDigitalService = createPoliticaContaDigitalService();
  const result = await politicaContaDigitalService
    .updatePoliticaContaDigital(politicaCobrancaId, { ...updatePoliticaCobrancaDTO });

  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao salvar alterações da política de cobrança');
    return false;
  }

  onUpdatePoliticaContaDigital()(dispatch);
  return true;
}

export const getPoliticaContaDigital = (
  politicaCobrancaId: string,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  onUpdateLoadingStatus(true)(dispatch);
  const politicaContaDigitalService = createPoliticaContaDigitalService();
  const result = await politicaContaDigitalService.getPoliticaContaDigital(politicaCobrancaId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao obter política de cobrança');
    return false;
  }

  onGetPoliticaContaDigital({ ...result.response })(dispatch);
  return true;
}

export const getUsersByPoliticaId = (
  politicaCobrancaId: string,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await userService.getUsersByPoliticaCobrancaId(politicaCobrancaId);

  if (result.status !== HttpStatus.OK) {
    toast.error('Não foi possível obter os usuários desta política de cobrança');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onUpdateSelectedUsers([...result.response])(dispatch);
}
