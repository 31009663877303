import styled from 'styled-components'
import {
  Row as AntdRow,
  Col as AntdCol,
} from 'antd';

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
  lg: { span: 6 },
})``;
