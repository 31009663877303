import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import {
  IPoliticaContaDigitalDTO,
  IPoliticaContaDigitalFilterProps,
} from '../../../../global/dataTransferObjects/politicaContaDigital';
import { IPagedResult } from '../../../../global/generics';
import createPoliticaContaDigitalService from '../../../../services/politicaContaDigital';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePagedPoliticas = (
  pagedPoliticas: IPagedResult<IPoliticaContaDigitalDTO>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAGED_POLITICAS,
  payload: {
    pagedPoliticas: { ...pagedPoliticas },
  },
});

export const updatePagedPoliticas = (
  offset: number,
  pageSize: number,
  filter: IPoliticaContaDigitalFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const politicaContaDigitalService = createPoliticaContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await politicaContaDigitalService.getPoliticaContaDigitalList(offset, pageSize, filter);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro obter as políticas de cobranças das contas digitais');
    return;
  }

  onUpdatePagedPoliticas({ ...result.response })(dispatch);
};

export const deletePoliticaContaDigital = (politicaId: string) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const politicaContaDigital = createPoliticaContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await politicaContaDigital.deletePoliticaContaDigital(politicaId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao excluir a política de cobrança');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  toast.success(result.response);
  return true;
}
