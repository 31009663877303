import { IPagedResult } from '../../global/generics';
import { ITransferService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { IAllTransfers } from '../../global/dataTransferObjects/transfers';

const transferService = (apiProvider: ITecpayApi): ITransferService => {
  const getAllTransfers = async (
    offset: number,
    pageSize: number,
  ): Promise<IApiResponse<IPagedResult<IAllTransfers>>> => {
    const response = await apiProvider
      .createRequest<IPagedResult<IAllTransfers>>({ url: '/transacoes' })
      .withSearch({
        offset,
        pageSize,
      })
      .sendAsync();

    return response;
  };

  return ({
    getAllTransfers,
  });
};

export default transferService;
