import React, {FocusEvent, ChangeEvent, FormEvent, useState} from 'react';
import {Container, DateView, FilterContainer, FilterRow, Label} from './styles';
import {Row} from "antd";
import {FilterData, FilterProps} from "../../types";
import {formatDate} from "../../../../utils/formatDate";

const Filter: React.FC<FilterProps> = ({onSubmit}) => {
    const [filterData, setFilterData] = useState<FilterData | object>({
      InitDate: '',
      FinishDate: '',
      Status: 1,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): void => {
      let inputValue: string | number | undefined = event.target.value;
      if (event.target.name == 'Status') inputValue = parseInt(event.target.value, 10);
      else if (event.target.name == 'InitDate' || event.target.name == 'FinishDate') inputValue = formatDate(event.target.value);

      setFilterData({...filterData, [event.target.name]: inputValue});
  };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      onSubmit(filterData);
    };

  const handleFocusInitialDateFilter = (event: FocusEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      const now: Date = new Date();
      const year: number = now.getFullYear();
      const month: string = String(now.getMonth() + 1).padStart(2, '0');
      const day: string = String(now.getDate()).padStart(2, '0');
      const midnight: string = `${year}-${month}-${day}T00:00:00`;

      event.target.value = midnight;
      setFilterData({...filterData, [event.target.name]: event.target.value});
    }
  };

  const handleFocusFinalDateFilter = (event: FocusEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      const now: Date = new Date();
      const year: number = now.getFullYear();
      const month: string = String(now.getMonth() + 1).padStart(2, '0');
      const day: string = String(now.getDate()).padStart(2, '0');
      const almostMidnight: string = `${year}-${month}-${day}T23:59:59`;

      event.target.value = almostMidnight;
      setFilterData({...filterData, [event.target.name]: event.target.value});
    }
  };


  return (
      <Container>
        <form onSubmit={handleSubmit}>
          <Row>
            <Label>Filtrar por:</Label>
          </Row>
          <Row
            gutter={16}
          >
            <FilterContainer>
              <FilterRow>
                <DateView>
                  <label htmlFor="InitDate">Data inicial</label>
                  <input type="datetime-local" onFocus={handleFocusInitialDateFilter} name="InitDate" onChange={handleChange} id="InitDate" step="1"/>
                </DateView>
                <DateView>
                  <label htmlFor="InitDate">Data final</label>
                  <input type="datetime-local" name="FinishDate" onFocus={handleFocusFinalDateFilter} onChange={handleChange} id="FinishData" step="1"/>
                </DateView>
              </FilterRow>
              <FilterRow>
                <DateView>
                  <label htmlFor="Status">Status</label>
                  <select name="Status" onChange={handleChange}>
                    <option value={0}>Pendente</option>
                    <option value={1} selected>Confirmada</option>
                    <option value={2}>Desfeita</option>
                    <option value={3}>Negada</option>
                    <option value={4}>Cancelada</option>
                  </select>
                </DateView>
                <button style={{marginTop: '19px', marginRight: '8px', marginLeft: '8px', width: '100%', background: '#fbb651', color: 'white', border: 'none', borderRadius: '20px'}} type="submit">Consultar</button>
              </FilterRow>
            </FilterContainer>
          </Row>
        </form>
      </Container>

    )
};

export default Filter;
