import React from 'react';
import { withFormik } from 'formik';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import FormInput from '../../../../../components/Forms/Input';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Warning,
} from './styles';
import { FormProps, IFormValues, IVerifyModalProps } from './types';
import validationSchema from './validationSchema';

const CreateModal: React.FC<FormProps> = (props: FormProps) => {
  const {
    isVisible,
    onVerifyModalClose,
    handleSubmit,
    loading,
  } = props;

  return (
    <Modal
      title="Pagamentos de boletos"
      centered
      visible={isVisible}
      onCancel={onVerifyModalClose}
      footer={null}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Obtendo informações para pagamento, por favor aguarde..."
        />
        <Row>
          <Col>
            <FormInput
              type="text"
              name="digitable"
              label="Linha digitável"
              mask="99999.99999 99999.999999 99999.999999 9 999999999999999"
              required
            />
            <Button htmlType="submit">
              Quero pagar
            </Button>
            <Warning>
              **O pagamento de boleto pode levar algum tempo até ser efetivado
            </Warning>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default withFormik<IVerifyModalProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    digitable: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const digitable = values.digitable.replace(/[^0-9]/g, '');
    await props.onVerifySubmit({ ...values, digitable })
  },
})(CreateModal);
