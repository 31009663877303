import { IPagedResult } from '../../global/generics';
import { ICompanyLinked } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import {
  ISelectedBusiness,
} from '../../global/dataTransferObjects/business';

const repassesService = (apiProvider: ITecpayApi): ICompanyLinked => {
  const getCompanyLinkedList = async (
  ): Promise<IApiResponse<IPagedResult<ISelectedBusiness>>> => {
    const response = await apiProvider
      .createRequest<IPagedResult<ISelectedBusiness>>({ url: 'payments/business-linked' })
      .withSearch({
      })
      .sendAsync();

    return response;
  };

  return ({
    getCompanyLinkedList,
  });
};

export default repassesService;
