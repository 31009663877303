import React from 'react';
import { withFormik } from 'formik';
import { MailOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import validationSchema from './validationSchema';
import { IFormValues, IFormProps, FormProps } from './types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormField from '../../../components/Forms/Input';
import {
  Row,
  Col,
  SubTitle,
} from './styles';
import { Form } from '../../../styles/forms';
import { Button } from '../../../styles/buttons';
import { Link, LinkGroup } from '../../../styles/links';
import {
  Container,
  Box,
} from '../../../styles/_layouts/Default';

const InnerForm = (props: FormProps) => {
  const {
    handleSubmit,
    loading,
  } = props;

  return (
    <Container>
      <Row>
        <Col>
          <Box>
            <MailOutlined style={{ fontSize: 200, color: '#169ead' }} />
            <SubTitle>Digite seu endereço de e-mail e enviaremos um link para redefinir sua senha</SubTitle>
            <LoadingOverlay relative show={loading} />
            <Form onSubmit={handleSubmit} noValidate>
              <FormField
                name="email"
                type="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                required
              />
              <Button htmlType="submit">
                Solicitar redefinição
              </Button>
              <LinkGroup style={{ textAlign: 'right' }}>
                <Link to="/login">
                  <ArrowLeftOutlined />
                  {' '}
                  Voltar
                </Link>
              </LinkGroup>
            </Form>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

const ForgotPasswordForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }): Promise<void> => props.onForgotPasswordFormSubmit({ ...values }),
})(InnerForm);

export default ForgotPasswordForm;
