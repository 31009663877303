import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import LoadingOverlay from '../../../components/LoadingOverlay';
import CancellationTable from './components/table';
import { Reducer, InitialState } from './actions/reducer';
import {
  getAllCancellations,
  updateCancellationStatus,
  openCancellationModal,
  onUpdateModalStatus,
} from './actions'
import { Container } from './styles';
import { T2 } from '../../../styles/titles';
import { IFormValues } from './types';
import { ICancellationDto } from '../../../global/dataTransferObjects/creditCard/cancellation';
import CancellationModal from './components/modal';
import FilterFormCancellations from './components/FilterForm';
import { IFilterCancellationValues } from './components/FilterForm/types';

const Cancellation: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterCancellationValues>();

  const fetchCancellations = useCallback(() => getAllCancellations(offset, pageSize)(dispatch), []);

  useEffect(() => {
    fetchCancellations();
  }, [fetchCancellations]);

  const onCancellationFormSubmit = async (values: IFormValues): Promise<void> => {
    const result = await updateCancellationStatus({ ...values })(dispatch);
    if (!result) {
      return;
    }

    fetchCancellations();
  }

  const fetchAllCancellationsPagination = (initialPage: number, finalPage: number) => {
    getAllCancellations(initialPage, finalPage, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllCancellationsPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllCancellationsPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleOpenCancellationModal = (
    cancellation: ICancellationDto,
  ) => openCancellationModal({ ...cancellation })(dispatch);

  const handleUpdateModalStatus = (status: boolean) => onUpdateModalStatus(status)(dispatch);

  const handleCancellationFilter = async (filter: IFilterCancellationValues) => {
    setFilterOrder({ ...filter });
    getAllCancellations(offset, pageSize, { ...filter })(dispatch);
  };

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Cancelamentos</T2>
      <FilterFormCancellations
        onFormSubmit={handleCancellationFilter}
      />
      <CancellationTable
        cancellations={[...state.cancellations.records]}
        onCancellationUpdateSubmit={onCancellationFormSubmit}
        pagination={
          {
            showSizeChanger: true,
            onShowSizeChange: (current: number, size: number) => {
              setPageSize(size);
            },
            defaultPageSize: 20,
            total: state.cancellations.count,
            onChange: (page: number) => {
              setCurrentPage(page);
            },
          }
        }
        onOpenCancellationModal={handleOpenCancellationModal}
      />
      <CancellationModal
        cancellation={{ ...state.cancellation }}
        isVisible={state.isModalOpen}
        onClose={() => handleUpdateModalStatus(false)}
      />
    </Container>
  )
}

export default Cancellation;
