import styled from 'styled-components';
import {
  Modal as AntdModal,
  Row as AntdRow,
  Col as AntdCol,
} from 'antd';
import { Button as StyledButton } from '../../../../styles/buttons';
import { T3 as StyledSubTitle } from '../../../../styles/titles';

export const Modal = styled(AntdModal)``;

export const HeaderRow = styled(AntdRow).attrs({
  align: 'middle',
  justify: 'center',
})`
  padding: 1rem 0;
`;

export const SubTitle = styled(StyledSubTitle)``;

export const HeaderCol = styled(AntdCol).attrs({
  md: {
    span: 18,
  },
  lg: {
    span: 21,
  },
  span: 24,
})`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media(min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;

  }

  ${StyledSubTitle} {
    margin-top: 0 !important;
  }
`;

export const BodyRow = styled(AntdRow).attrs({
  align: 'middle',
  justify: 'center',
})``;

export const BodyCol = styled(AntdCol).attrs({
  lg: {
    span: 21,
  },
  span: 24,
})`
 display: flex;
 flex-direction: column;
 gap: 1rem;
`;

export const Separator = styled.div``;

export const Button = styled(StyledButton)`
  width: unset;
  align-self: flex-end;
  margin: 0;
`;
