import styled from 'styled-components';
import { Button as StyledButton } from '../../../../styles/buttons';

export const Container = styled.div`
  margin-top: 1rem;
`;

export const Button = styled(StyledButton)`
  margin-top: 20px;
`;

export const Form = styled.form``;
