import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import FormFilter from './components/filter';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
  LegendContainer,
  LegendText,
  LegendTitle,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getAllRepasses } from './actions';
import { IRepassesFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import createBusinessService from '../../../services/business';
import { ISelectValue } from '../../../global/types';
import useGetCompany from '../../../hooks/useCurrentBusiness';

const Repasses: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [businessPageSizeList] = useState<number>(100);
  const [businessOffSetList] = useState<number>(0);
  const stateUserCompany = useGetCompany();

  const businessService = createBusinessService();

  const handleLoadBusinessOptions = async (name: any): Promise<Array<ISelectValue>> => {
    setFilterOrder({ name });
    const businessList = await businessService
      .getEmpresasList(businessOffSetList, businessPageSizeList, filterOrder);

    return businessService.mapToSelectableBusiness(businessList.response.records);
  };

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const repassesPagination = (initialPage: number, finalPage: number) => {
    getAllRepasses(initialPage, finalPage, filterOrder, stateUserCompany)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      repassesPagination(pageSize * (currentPage - 1), (pageSize))
    }
    if (currentPage === 1) {
      repassesPagination(0, (pageSize));
    }
  }, [currentPage, pageSize]);

  const handleRepassesFilter = async (filter: IRepassesFilterProps) => {
    setFilterOrder(filter);
    getAllRepasses(offSet, pageSize, filter, stateUserCompany)(dispatch);
  }

  const allRepasses = state?.repasses?.records.map((params) => (
    {
      ...params,
    }
  ));

  const formatColorDinamic = (record: any) => {
    if (record.anticipationIncluded === true && record.askedForItsOwnAnticipation === false) {
      return 'paleturquoise';
    }
    if (record.anticipationIncluded === true && record.askedForItsOwnAnticipation === true) {
      return 'wheat'
    }
    return '';
  };

  const {
    quantityAsked,
    quantityPayed,
    quantityTotal,
    payedAmount,
    grossAmount,
    paymentAmount,
  } = state?.repasses.totalRepasses;

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Repasses</T2>
      <FormFilter
        onFormSubmit={handleRepassesFilter}
        onLoadEmpresas={handleLoadBusinessOptions}
        stateUserCompany={stateUserCompany}
      />
      <TableContainer>
        <Table
          dataSource={allRepasses}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.repasses.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3}>{format(grossAmount)}</TableSumCell>
                <TableSumCell index={4}>{format(paymentAmount)}</TableSumCell>
                <TableSumCell index={5}>{format(payedAmount)}</TableSumCell>
                <TableSumCell index={6}>{quantityAsked}</TableSumCell>
                <TableSumCell index={7}>{quantityPayed}</TableSumCell>
                <TableSumCell index={8}>{quantityTotal}</TableSumCell>
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0}>Visualizar</TableSumCell>
                <TableSumCell index={1}>Previsao</TableSumCell>
                <TableSumCell index={2}>Cliente</TableSumCell>
                <TableSumCell index={3}>Valor venda</TableSumCell>
                <TableSumCell index={4}>Valor previsto</TableSumCell>
                <TableSumCell index={5}>Valor pago</TableSumCell>
                <TableSumCell index={6}>Qtd. solicitado</TableSumCell>
                <TableSumCell index={7}>Qtd. pago</TableSumCell>
                <TableSumCell index={8}>Qtd. a pagar</TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(item: string, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children:
  <Link
    style={{ color: 'black' }}
    to={`/tecpay/admin/repasses/detalhes?id=${record.businessId}`}
  >
    <EyeOutlined />
  </Link>,
            })}
          />
          <TableColumn
            title="Previsao"
            dataIndex="paymentDate"
            key="paymentDate"
            render={(item: string, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="Cliente"
            dataIndex="businessName"
            key="businessName"
            render={(item: string, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Valor Venda"
            dataIndex="grossAmount"
            key="grossAmount"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Previsto"
            dataIndex="paymentAmount"
            key="paymentAmount"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Pago"
            dataIndex="payedAmount"
            key="payedAmount"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Qtd. Solicitado"
            dataIndex="quantityAsked"
            key="quantityAsked"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Qtd. Pago"
            dataIndex="quantityReceived"
            key="quantityReceived"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Qtd. a Pagar"
            dataIndex="quantityTotal"
            key="quantityTotal"
            render={(item: number, record: any) => ({
              props: {
                style: {
                  backgroundColor: formatColorDinamic(record),
                },
              },
              children: <div>{item}</div>,
            })}
          />
        </Table>
      </TableContainer>
      <LegendContainer>
        <LegendTitle>Legenda: </LegendTitle>
        <LegendText
          color="paleturquoise"
        >
          Antecipação Automática
        </LegendText>
        <LegendText
          color="wheat"
        >
          Solicitou Split de pagamento
        </LegendText>
      </LegendContainer>
    </Container>
  );
};

export default Repasses;
