import React, {
  useReducer,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  EyeOutlined,
} from '@ant-design/icons';
import { AiOutlineDownload } from 'react-icons/ai';
import Table, { TableColumn } from '../../../components/Table';
import FormFilter from './filter'
import BoletoModalView from './components/viewBoletoModal'
import { Reducer, InitialState } from './actions/reducer';
import { getAllBoletos, openBoletoViewModal, onUpdateBoletoModalStatus } from './actions';
import { T2 } from '../../../styles/titles';
import * as S from './styles'

import { createFormatProvider } from '../../../services/providers/factories';
import { IFilterBoletosProps } from './filter/types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { AllBoletosDto } from '../../../global/dataTransferObjects/boletos';

const ListaBoletos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const boletosPagination = (initialPage: number, finalPage: number) => {
    getAllBoletos(initialPage, finalPage, filterOrder)(dispatch);
  };

  useEffect(() => {
    if (currentPage > 1) {
      boletosPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      boletosPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const boletos = state?.allBoletos?.records.map((params) => (
    {
      ...params,
      boletoGerado: params.boletoGerado === true ? 'Sim' : 'Não',
    }
  ))

  const handleFilter = async (filter: IFilterBoletosProps) => {
    setFilterOrder(filter);
    getAllBoletos(offSet, pageSize, filter)(dispatch);
  };

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formattedDocumentNumber = (numeroDocumento: string) => (
    numeroDocumento?.length === 14
      ? formatProvider.formatCnpj(numeroDocumento ?? '00000000000000')
      : formatProvider.formatCpf(numeroDocumento ?? '00000000000')
  );

  const handleOpenViewBoletoModal = (user: AllBoletosDto) => {
    openBoletoViewModal({ ...user })(dispatch)
  }

  const handleViewBoletoModalStatus = (status: boolean) => onUpdateBoletoModalStatus(status)(dispatch);

  function downloadPDF(pdf: string) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'boleto.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <S.Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Boletos</T2>
      <FormFilter onFormSubmit={handleFilter} />
      <S.TableContainer>
        <Table
          dataSource={boletos}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.allBoletos.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="ID" dataIndex="id" key="id" />
          <TableColumn title="Nosso No." dataIndex="nossoNumero" key="nossoNumero" />
          <TableColumn title="Valor Nominal" dataIndex="vlrNominal" key="vlrNominal" render={format} />
          <TableColumn title="Data Emissão" dataIndex="dataEmissao" key="dataEmissao" render={formattedDate} />
          <TableColumn title="Vencimento" dataIndex="dataVencimento" key="dataVencimento" render={formattedDate} />
          <TableColumn title="Seu No." dataIndex="seuNumero" key="seuNumero" />
          <TableColumn title="CPF/CPNJ" dataIndex="cpfCnpj" key="cpfCnpj" render={formattedDocumentNumber} />
          <TableColumn title="Nome" dataIndex="nome" key="nome" />
          <TableColumn title="Email" dataIndex="email" key="email" />
          <TableColumn title="Situação" dataIndex="situacao" key="situacao" />
          <TableColumn
            title="Boleto gerado"
            dataIndex="boletoGerado"
            key="boletoGerado"
            align="center"
            render={(item: string, record: any) => (record.boletoGerado && record.pdfBase64Gerado
              ? (
                <AiOutlineDownload
                  style={{
                    fontSize: '20px',
                    color: '#169ead',
                    cursor: 'pointer',
                  }}
                  onClick={() => downloadPDF(record.boleto64)}
                >
                  {item}
                </AiOutlineDownload>
              )
              : item)}
          />
          <TableColumn
            title="Informações"
            dataIndex="informacoes"
            key="informacoes"
            align="center"
            render={(i: any, e: any) => (state.allBoletos.records.length >= 1 ? (
              <EyeOutlined
                onClick={() => handleOpenViewBoletoModal(e)}
              />
            ) : null)}
          />
        </Table>
      </S.TableContainer>
      <BoletoModalView
        boleto={{ ...state.boleto }}
        isVisible={state.isViewBoletoModalOpen}
        onClose={() => handleViewBoletoModalStatus(false)}
      />
    </S.Container>
  )
};

export default ListaBoletos;
