import styled from 'styled-components';
import { Row as AntdRow, Col as AntdCol } from 'antd';

export const CardFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 10px;

  @media (min-width: 576px) {
    flex-direction: row;
    gap: 0;
  }
`;

export const FormContainer = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  flex: 3;
`;

export const CardContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const Row = styled(AntdRow)``;

export const Col = styled(AntdCol).attrs({
  span: 24,
})``;

export const RightCol = styled(Col).attrs({
  span: 24,
  md: {
    span: 12,
  },
})`
  @media (min-width: 768px) {
    padding-right: 5px;
  }
`;

export const LeftCol = styled(Col).attrs({
  span: 24,
  md: {
    span: 12,
  },
})`
  @media (min-width: 768px) {
    padding-left: 5px;
  }
`;
