import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import LoadingOverlay from '../../../components/LoadingOverlay';
import Table, { TableColumn } from '../../../components/Table';
import { T2 } from '../../../styles/titles';
import { getTransacoes } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import FilterForm from './components/filterForm';
import { IFilterExtratoCartao } from './components/filterForm/types';
import { Container, TableContainer } from './style';
import { createFormatProvider } from '../../../services/providers/factories';

const ExtratoCartao: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<IFilterExtratoCartao>({});

  const fetchTransacoes = useCallback((
    initial: number = offSet,
    size: number = pageSize,
    filter: IFilterExtratoCartao = filterOrder,
  ) => getTransacoes(initial, size, filter)(dispatch), []);
  const data = useMemo(() => state.transacoes.records, [state]);

  const handleSubmit = async (filter: IFilterExtratoCartao) => {
    getTransacoes(offSet, pageSize, filter)(dispatch);

    setFilterOrder(filter);
  }

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchTransacoes(pageSize * (currentPage - 1), (currentPage * pageSize), filterOrder)
      return;
    }
    if (currentPage === 1) {
      fetchTransacoes(0, (currentPage * pageSize), filterOrder);
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Extrato do Cartão</T2>
      <FilterForm onSubmit={handleSubmit} />
      <TableContainer>
        <Table
          dataSource={data}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.transacoes.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
        >
          <TableColumn
            title="Tipo da Transação"
            dataIndex="tipoDaTransacao"
            key="tipoDaTransacao"
          />
          <TableColumn
            title="Data"
            dataIndex="data"
            key="data"
          />
          <TableColumn
            title="Código da Transação"
            dataIndex="transactionCodeId"
            key="transactionCodeId"
          />
          <TableColumn
            title="Nome do estabelecimento"
            dataIndex="nomeDoEstabelecimento"
            key="nomeDoEstabelecimento"
          />
          <TableColumn
            title="Valor"
            dataIndex="valorDaMoedaLocal"
            key="valorDaMoedaLocal"
            render={formattedValue}
          />
          <TableColumn
            title="Pendente"
            dataIndex="pendente"
            key="pendente"
            render={((item: boolean) => (item ? 'Sim' : 'Não'))}
          />
        </Table>
      </TableContainer>
    </Container>
  )
}

export default ExtratoCartao
