import { Dispatch } from 'react';
import { ActionTypes } from './types';
import { IPhobeusPagedResult } from '../../../../global/generics';
import { IAllLiveTransactions, ILiveTransactionsFilterProps } from '../../../../global/dataTransferObjects/transacoesTempoReal';
import createLiveTransactionsService from '../../../../services/transacoesTempoReal';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.SHOW_ZERO_STATE,
  payload: {
    zeroState,
  },
});

export const onGetLiveTransactionsTransfers = (
  allLiveTransactions: IPhobeusPagedResult<IAllLiveTransactions>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_LIVE_TRANSACTIONS,
  payload: {
    allLiveTransactions: { ...allLiveTransactions },
  },
});

export const getAllLiveTransactions = (
  page: number,
  totalPages: number,
  filter?: ILiveTransactionsFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const liveTransfer = createLiveTransactionsService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await liveTransfer.getAllLiveTransactions(page, totalPages, filter);
  if (result.status !== 200) {
    onUpdateZeroState(true)(dispatch);
    return;
  }
  onGetLiveTransactionsTransfers(result.response)(dispatch);
};
