import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IConsultaFormValues } from '../../../../components/Forms/Pages/CadastroCnpjModal/types';
import { IPagedResult } from '../../../../global/generics';
import createConsultaService from '../../../../services/consulta';
import { HttpStatus } from '../../../../services/providers/types';
import { IConsultaDto } from '../../../../global/dataTransferObjects/consulta';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateCnpj = (consultas: IPagedResult<IConsultaDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_CNPJ,
  payload: {
    consultas: { ...consultas },
  },
});

export const onOpenPaymentModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
});

export const onUpdatePaymentModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const onCreateNewVeiculo = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_NEW_CNPJ,
});

export const onCreateNewCnpj = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_NEW_CNPJ,
});

export const getUserCnpj = (pageSize = 20, offset = 0) => async (dispatch: Dispatch<any>): Promise<void> => {
  const consultaService = createConsultaService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await consultaService.getUserCnpj(offset, pageSize);
  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message);
    return;
  }

  onUpdateCnpj(result.response)(dispatch);
};

export const createNewConsulta = (values: IConsultaFormValues) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const consultaService = createConsultaService();

  onUpdateLoadingStatus(true)(dispatch);
  const payload = { ...values };
  const result = await consultaService.createNewConsulta(payload);

  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao cadastrar o CNPJ');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onCreateNewCnpj()(dispatch);
  return true;
};
