import React, {
  useEffect,
  useCallback,
  useReducer,
  useState,
  useMemo,
} from 'react';
import {
  EyeOutlined,
} from '@ant-design/icons';
import { Reducer, InitialState } from './actions/reducer'

import {
  Container,
  TableContainer,
} from './styles';
import Table, { TableColumn } from '../../components/Table';
import { T2 } from '../../styles/titles';
import FormFilter from './components/FormFilter';
import { IAdiantamentoFilterProps } from './types';
import {
  getAllAdiantamentos, onViewNotaPromissoriaStatus, openNotaPromissoriaModal,
} from './actions';
import NotaPromissoriaModal from './components/notaPromissoriaModal';
import { AdiantamentoDto } from '../../global/dataTransferObjects/adiantamentos';
import { createFormatProvider } from '../../services/providers/factories';

const Adiantamentos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const fetchAllAdiantamentos = useCallback(() => getAllAdiantamentos(offSet, pageSize)(dispatch), []);

  useEffect(() => {
    fetchAllAdiantamentos();
  }, [fetchAllAdiantamentos]);

  const adiantamentosPagination = (initialPage: number, finalPage :number) => {
    getAllAdiantamentos(initialPage, finalPage, filterOrder)(dispatch);
  }
  const handleAdiantamentoFilter = async (filter: IAdiantamentoFilterProps) => {
    setFilterOrder(filter);
    getAllAdiantamentos(offSet, pageSize, filter)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      adiantamentosPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      adiantamentosPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const adiantamento = state?.adiantamentos?.records.map((adiantamentosParams) => (
    {
      ...adiantamentosParams,
    }
  ));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const handleViewModalStatus = (status: boolean) => onViewNotaPromissoriaStatus(status)(dispatch)

  const handleOpenViewUserModal = (nota: AdiantamentoDto) => {
    openNotaPromissoriaModal({ ...nota })(dispatch)
  }

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  return (
    <Container>
      <T2>Adiantamentos Nossa Cidade</T2>
      <FormFilter onFormSubmit={handleAdiantamentoFilter} />
      <TableContainer>
        <Table
          dataSource={adiantamento}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.adiantamentos.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Data de cadastro" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn title="Folha Mês" dataIndex="folhaMes" key="folhaMes" />
          <TableColumn title="Folha Ano" dataIndex="folhaAno" key="folhaAno" />
          <TableColumn title="Total Proventos" dataIndex="totalProventos" key="totalProventos" render={format} />
          <TableColumn title="Total Liquido" dataIndex="totalLiquido" key="totalLiquido" render={format} />
          <TableColumn title="Margem" dataIndex="margem" key="margem" render={format} />
          <TableColumn title="No. Controle" dataIndex="numeroControle" key="numeroControle" />
          <TableColumn
            title="Visualizar"
            dataIndex="notaPromissoria"
            key="notaPromissoria"
            render={(i: any, e: any) => (state.adiantamentos.records.length >= 1 ? (
              <EyeOutlined
                onClick={() => handleOpenViewUserModal(e)}
              />
            ) : null)}
          />
        </Table>
      </TableContainer>
      <NotaPromissoriaModal
        adiantamento={{ ...state.adiantamento }}
        isVisible={state.isNotaPromissoriaModalOpen}
        onClose={() => handleViewModalStatus(false)}
      />
    </Container>
  )
}

export default Adiantamentos;
