import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { IPoliticaCobrancaDTO, IPoliticaCobrancaFilterProps } from '../../../../global/dataTransferObjects/politicaCobranca';
import { IPagedResult } from '../../../../global/generics';
import createPoliticaCobrancaService from '../../../../services/politicaCobranca';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdatePoliticasCobrancas = (
  politicasCobrancas: IPagedResult<IPoliticaCobrancaDTO>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_POLITICAS_COBRANCAS,
  payload: {
    politicasCobrancas: { ...politicasCobrancas },
  },
});

export const onUpdatePoliticaCobrancaModal = (
  politicaCobranca: IPoliticaCobrancaDTO,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_POLITICA_COBRANCA_MODAL,
  payload: {
    politicaCobranca: { ...politicaCobranca },
  },
});

export const onUpdatePoliticaCobrancaModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_POLITICA_COBRANCA_MODAL,
  payload: { status },
});

export const updatePoliticasCobrancas = (
  offset: number,
  pageSize: number,
  filter: IPoliticaCobrancaFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const politicaCobrancaService = createPoliticaCobrancaService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await politicaCobrancaService.getPoliticasCobrancas(offset, pageSize, filter);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro obter as políticas de cobranças');
    return;
  }

  onUpdatePoliticasCobrancas({ ...result.response })(dispatch);
};

export const deletePoliticaCobranca = (politicaCobrancaId: number) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const politicaCobrancaService = createPoliticaCobrancaService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await politicaCobrancaService.deletePoliticaCobranca(politicaCobrancaId);
  if (result.status !== HttpStatus.OK) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error(result.message ?? 'Houve um erro ao excluir a política de cobrança');
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  toast.success(result.response);
  return true;
}

export const openDetailsModal = (politicaCobranca: IPoliticaCobrancaDTO) => (dispatch: Dispatch<any>): void => {
  onUpdatePoliticaCobrancaModal({ ...politicaCobranca })(dispatch);
  onUpdatePoliticaCobrancaModalStatus(true)(dispatch);
}
