import { IPoliticaContaDigitalDTO } from '../../../../global/dataTransferObjects/politicaContaDigital';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  pagedPoliticas: IPagedResult<IPoliticaContaDigitalDTO>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_PAGED_POLITICAS = 'UPDATE_PAGED_POLITICAS',

}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
