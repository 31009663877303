import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import LoadingOverlay from '../../../components/LoadingOverlay';
import ConsultaDebitosVeiculoForm from '../../../components/Forms/Pages/ConsultaDebitosVeiculoForm';
import TotalCard from '../../../components/TotalCard';
import { ISelectValue } from '../../../components/Forms/AsyncFormDropdown/types';
import createVeiculoService from '../../../services/veiculo';
import { T2 } from '../../../styles/titles';

import DebitosTable from './components/debitosTable';
import { IConsultaDebitosDto } from './types';
import { Container } from './styles';
import {
  IDpvatDto,
  IIpvaDto,
  ILicenciamentoDto,
  IMultaDto,
  IRendimentoTaxasServico,
} from '../../../global/dataTransferObjects/debitosVeiculares';
import PagamentoModal from '../../../components/Forms/Pages/PagamentoDebitosVeicularesModal';
import { Reducer, InitialState } from './actions/reducer';
import {
  getVehicleDebits,
  openPaymentModal,
  onDpvaTsSelectionChange,
  onIpvasSelectionChange,
  onUpdateLicenciamentoSelection,
  onUpdateMultasSelection,
  sendPayment,
  updateTotal,
  onClosePaymentModal,
} from './actions';
import { IPagamentoDebitosFormValues } from '../../../components/Forms/Pages/PagamentoDebitosVeicularesModal/types';

const ConsultaDebitos: React.FC<IConsultaDebitosDto> = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const pageHistory = useHistory();
  const veiculoService = createVeiculoService();

  const handleLoadVehiclesOptions = async (): Promise<Array<ISelectValue>> => {
    const userVehicles = await veiculoService.getUserVeiculos();

    return veiculoService.mapToSelectableVehicles(userVehicles.response.records);
  };

  const onFormSubmit = async (vehicleId: string) => getVehicleDebits(vehicleId)(dispatch);

  const onLicenciamentoSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Array<ILicenciamentoDto>) => {
    const licenciamento = selectedRows.length > 0
      ? { ...selectedRows[0] }
      : null;
    onUpdateLicenciamentoSelection(licenciamento, { ...state })(dispatch);
  };

  const onDpvatSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Array<IDpvatDto>) => {
    onDpvaTsSelectionChange([...selectedRows])(dispatch);
  };

  const onIpvaSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Array<IIpvaDto>) => {
    onIpvasSelectionChange([...selectedRows])(dispatch);
  };

  const onMultaSelectionChange = (selectedRowKeys: React.Key[], selectedRows: Array<IMultaDto>) => {
    onUpdateMultasSelection([...selectedRows])(dispatch);
  };

  const handleClosePaymentModal = () => onClosePaymentModal()(dispatch);

  const onContinueButtonClick = () => openPaymentModal({ ...state })(dispatch);

  const onPaymentModalFormSubmit = async (values: IPagamentoDebitosFormValues): Promise<void> => {
    const result = await sendPayment({ ...state }, { ...values })(dispatch);

    if (!result) {
      toast.error('Houve um erro na solicitação do pagamento!');
      return;
    }

    toast.success('Solicitação de pagamento enviada com sucesso!');
    pageHistory.push('/debitos-veiculares/pagamentos');
  };

  const handleSelectChange = ({ value }: ISelectValue) => updateTotal(value, { ...state })(dispatch);

  const getTotalAmount = (
    installment: number,
    taxasServico: IRendimentoTaxasServico,
  ): Promise<number> => veiculoService
    .calcularValorTotalDebitosVeicularesComTaxas(installment, { ...state.selectedDebitos }, taxasServico);

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
        tip="Realizando a consulta dos débitos, por favor aguarde..."
      />
      <T2>Débitos Veiculares</T2>
      <ConsultaDebitosVeiculoForm
        onLoadVehiclesOptions={handleLoadVehiclesOptions}
        onFormSubmit={onFormSubmit}
      />
      <DebitosTable
        debits={state.debits}
        selectedDebitos={state.selectedDebitos}
        onLicenciamentoSelectionChange={onLicenciamentoSelectionChange}
        onDpvatSelectionChange={onDpvatSelectionChange}
        onIpvaSelectionChange={onIpvaSelectionChange}
        onMultaSelectionChange={onMultaSelectionChange}
      />
      <TotalCard
        debitos={state.selectedDebitos}
        onContinueButtonClick={onContinueButtonClick}
      />
      <PagamentoModal
        loading={state.loading}
        valorTotal={state.valorTotal}
        valorLiquido={state.valorLiquido}
        isVisible={state.isPaymentModalOpen}
        onFormSubmit={onPaymentModalFormSubmit}
        onModalClose={() => handleClosePaymentModal()}
        onSelectChange={handleSelectChange}
        onGetTotalAmount={getTotalAmount}
      />
    </Container>
  );
};

export default ConsultaDebitos;
