import React from 'react';
import InputMask, { Props } from 'react-input-mask';
import { FormInput } from '../../../styles/inputs'
import { IInputProps } from './types';

const CustomInput: React.FC<Props> = (props: Props) => (
  <InputMask {...props}>
    {(inputProps: IInputProps) => (
      <FormInput
        {...inputProps}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    )}
  </InputMask>
);

export default CustomInput;
