import styled from 'styled-components';
import { Input } from 'antd';
import { IProps } from './types';

export const InputContainer = styled.div`
  margin-bottom: 0.8rem;
`;

export const Label = styled.label<IProps>`
  ${(props) => {
    switch (props.variant) {
      case 'danger':
        return `
          color: ${props.theme.colors.redDanger};
        `;
      case 'primary':
      default:
        return `
          color: black;
        `;
    }
  }}
`;

export const FormInput = styled(Input)`
  &:focus, &:hover {
    border-color: ${(props) => props.theme.colors.cyanDark};
  }

  &:focus {
    box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 3px;
  color: ${(props) => props.theme.colors.redDanger};
`;

export const InputGroup = styled.div`
  @media (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    ${InputContainer}, input {
      flex: 1;
    };
  }
`;
