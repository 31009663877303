import styled from "styled-components";
import {Layout} from "antd";


export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media(min-width: 768px){
    padding: 1rem 2rem;
  }
`;
