import { IContaDigitalDTO } from '../../../global/dataTransferObjects/contaDigital';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  contaDigital: {} as IContaDigitalDTO,
  saldoCartao: {
    valorDeSaldo: 0,
    dataSaldo: '',
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CONTA_DIGITAL:
      return {
        ...state,
        contaDigital: { ...action.payload?.contaDigital } ?? { ...InitialState.contaDigital },
      }
    case ActionTypes.SALDO_CARTAO:
      return {
        ...state,
        saldoCartao: { ...action.payload?.saldoCartao } ?? { ...InitialState.saldoCartao },
        loading: false,
      }
    default:
      return { ...state };
  }
};
