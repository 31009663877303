import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ICreatePoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';
import createPoliticaCobrancaService from '../../../../services/politicaCobranca';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onCreateNewPoliticaCobranca = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CREATE_NEW_POLITICA_COBRANCA,
});

export const createNewPoliticaCobranca = (
  politicaCobranca: ICreatePoliticaCobrancaDTO,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const politicaCobrancaService = createPoliticaCobrancaService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await politicaCobrancaService.createPoliticaCobranca({ ...politicaCobranca });

  if (result.status !== HttpStatus.CREATED) {
    toast.error(result.message ?? 'Houve um erro ao cadastrar a política de cobrança');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onCreateNewPoliticaCobranca()(dispatch);
  return true;
};
