import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../components/Forms/Input';
import validationSchema from './validationSchema';
import { IFilterUser } from '../../types';
import {
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const FilterForm: React.FC<IFilterUser> = (props: any) => {
  const {
    handleSubmit,
    resetForm,
    setFieldValue,
    values,
  } = props;
  const resetUserForm = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  useEffect(() => {
    const documentNumber = values.documentNumber?.replace(/[^0-9]/g, '');
    setFieldValue('documentNumber', documentNumber);
    const handleDocumentNumber = () => {
      if (documentNumber.length === 11) {
        const cpf = documentNumber.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        setFieldValue('documentNumber', cpfFormatted);
      }
      if (documentNumber.length === 14) {
        const cnpj = documentNumber.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        setFieldValue('documentNumber', cnpjFormatted);
      }
    }
    handleDocumentNumber();
  }, [values.documentNumber]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <FormInput
              type="string"
              name="documentNumber"
              label="CNPJ"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="string"
              name="name"
              label="Nome"
            />
          </Col>
          <Col span={1} />
          <Col span={3}>
            <ResetButton
              onClick={() => resetUserForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<any, any>({
  mapPropsToValues: (): any => ({
    documentNumber: '',
    name: '',
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const formattedValues = {
      ...values,
      cnpj: values.documentNumber,
    };
    props.onFormSubmit(formattedValues);
  },
})(FilterForm);
