import { Reducer } from 'redux';
import { ActionTypes } from './types';
import { ICompanyState } from '../types';

export const InitialState: ICompanyState = {
  loading: false,
  zeroState: false,
  company: [],
  selectedCompany: {
    id: '',
    name: '',
    merchantId: '',
    allowPaymentLink: false,
  },
};

const CompanyReducer: Reducer<ICompanyState> = (state: ICompanyState = InitialState, action) => {
  switch (action.type) {
    case ActionTypes.Logout:
      return { ...InitialState }
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_COMPANY_LIST:
      return {
        ...state,
        loading: false,
        company: action.payload?.company ?? { ...InitialState.company },
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    case ActionTypes.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload?.selectedCompany ?? { ...InitialState.selectedCompany },
      };
    default:
      return state;
  }
};

export default CompanyReducer;
