import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isPaymentModalOpen: false,
  consultas: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.UPDATE_CNPJ:
      return {
        ...state,
        loading: false,
        consultas: action.payload?.consultas ?? { ...InitialState.consultas },
      };
    case ActionTypes.OPEN_PAYMENT_MODAL:
      return {
        ...state,
        isPaymentModalOpen: true,
      };
    case ActionTypes.UPDATE_PAYMENT_MODAL_STATUS:
      return {
        ...state,
        isPaymentModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.CREATE_NEW_CNPJ:
      return {
        ...state,
        loading: false,
        isPaymentModalOpen: false,
      }
    default:
      return { ...state };
  }
};
