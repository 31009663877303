import { IAntecipadoraTransacoesDto } from '../../../../global/dataTransferObjects/contaDigital/antecipadoraTransacoes';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  transacoes: IPagedResult<IAntecipadoraTransacoesDto>
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_TRANSACOES = 'UPDATE_TRANSACOES',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
