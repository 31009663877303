import { createApi } from '../providers/factories';
import { IPixService } from '../@types/services';
import pixService from './pixServices';

const createPixService = (): IPixService => {
  const apiProvider = createApi();
  return pixService(apiProvider);
}

export default createPixService;
