import {
  IAllNotas,
  INotaPromissoriaFilterProps,
} from '../../global/dataTransferObjects/notaPromissoria';
import { IPagedResult } from '../../global/generics';
import { INotaPromissoriaService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { ApiException } from '../../global/exceptions';

const notaService = (apiProvider: ITecpayApi): INotaPromissoriaService => {
  const getAllNotasPromissorias = async (
    offset: number,
    pageSize: number,
    filter?: INotaPromissoriaFilterProps,
  ): Promise<IApiResponse<IPagedResult<IAllNotas>>> => {
    const name = filter?.name || null;
    const documentNumber = filter?.documentNumber || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IAllNotas>>({ url: '/nossa-cidade/solicitacao-adiantamento' })
      .withSearch({
        offset,
        pageSize,
        name,
        documentNumber,
        startDate,
        finishDate,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  return ({
    getAllNotasPromissorias,
  });
};

export default notaService;
