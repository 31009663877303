import { IPixCopiaEColaResponseDto, IPixCreateCopiaEColaDTO } from '../../global/dataTransferObjects/pix/copiaECola';
import { IPixDecodeQrCodeDto } from '../../global/dataTransferObjects/pix/emvQrCode';
import {
  IPixCreatePagamentoDTO,
  IPixGetChavePixInfoDto,
  IPixPagamentoDTO,
  IPixPagamentoFilterDTO,
} from '../../global/dataTransferObjects/pix/pagamento';
import { IApiPagedResponse, IPagedResult } from '../../global/generics';
import { ITransactionProps } from '../../pages/pix/pagamento/components/comprovanteModal/types';
import { IPixService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const pixService = (apiProvider: ITecpayApi): IPixService => {
  const createPixCopiaECola = async (
    pixCopiaECola: IPixCreateCopiaEColaDTO,
  ): Promise<IApiResponse<IPixCopiaEColaResponseDto>> => {
    const payload = { ...pixCopiaECola };

    return apiProvider.createRequest<IPixCreateCopiaEColaDTO, IPixCopiaEColaResponseDto>({ url: '/pix/qr-codes/dinamicos' })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync();
  };

  const getPagedPixCopiaECola = async (
    offset: number,
    pageSize: number,
  ): Promise<IApiPagedResponse<IPixCopiaEColaResponseDto>> => apiProvider
    .createRequest<IPagedResult<IPixCopiaEColaResponseDto>>({ url: '/pix/qr-codes/dinamicos' })
    .withSearch({
      offset,
      pageSize,
    })
    .sendAsync();

  const createPixPagamento = async (pagamento: IPixCreatePagamentoDTO): Promise<IApiResponse<IPixPagamentoDTO>> => {
    const payload = { ...pagamento };

    return apiProvider
      .createRequest<IPixCreatePagamentoDTO, IPixPagamentoDTO>({ url: '/pix/enviar-pagamento' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const getPagamentos = async (
    offset: number,
    pageSize: number,
    filter: IPixPagamentoFilterDTO,
  ): Promise<IApiPagedResponse<IPixPagamentoDTO>> => {
    const endToEndId = filter?.endToEndId || null;
    const creditorPrivateIdentification = filter?.creditorPrivateIdentification || null;
    const correlationId = filter?.correlationId || null;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const status = filter?.status || null;

    return apiProvider
      .createRequest<IPagedResult<IPixPagamentoDTO>>({ url: '/pix/pagamentos' })
      .withSearch({
        endToEndId,
        creditorPrivateIdentification,
        correlationId,
        startDate,
        finishDate,
        status,
        offset,
        pageSize,
      })
      .sendAsync();
  }

  const decodeQrCode = async (emv: string): Promise<IApiResponse<IPixDecodeQrCodeDto>> => {
    const payload = { emv };

    return apiProvider
      .createRequest<IPixDecodeQrCodeDto>({ url: '/pix/qrcode/decode' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const getChavePixInfo = async (TaxIdNumber: string): Promise<IApiResponse<IPixGetChavePixInfoDto>> => apiProvider
    .createRequest<IPixGetChavePixInfoDto>({ url: '/pix/consultar-chave' })
    .withSearch({ TaxIdNumber })
    .sendAsync();

  const getTransaction = async (endToEndId: string): Promise<IApiResponse<ITransactionProps>> => apiProvider
    .createRequest<ITransactionProps>({ url: '/pix/payments/transaction' })
    .withSearch({ endToEndId })
    .sendAsync();

  return ({
    createPixCopiaECola,
    getPagedPixCopiaECola,
    createPixPagamento,
    getPagamentos,
    decodeQrCode,
    getChavePixInfo,
    getTransaction,
  })
}

export default pixService;
