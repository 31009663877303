import styled from 'styled-components'
import {
  Row as AntdRow,
  Col as AntdCol,
  Radio as AntdRadio,
} from 'antd';

const AntdRadioGroup = AntdRadio.Group;

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
  lg: { span: 8 },
})``;

export const Radio = styled(AntdRadio)``;

export const RadioGroup = styled(AntdRadioGroup)`
    margin-bottom: 0.8rem;
    text-align: center;
    width: 100%;
`;

export const VerticalLine = styled.hr`
  margin-top: 20px;
  width: 20%;
  height: 100%;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.colors.cyanDark};
  float: center;
  background-color: ${(props) => props.theme.colors.cyanDark};
  `;
