import { Reducer } from 'redux';
import { ConfigActionTypes, IConfigState } from './types';

export const initialState: IConfigState = {
  data: null,
};

const configReducer: Reducer<IConfigState> = (state = initialState, action) => {
  switch (action.type) {
    case ConfigActionTypes.SET_CONFIG:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default configReducer;
