import { AllTransacoesDto } from '../../../../global/dataTransferObjects/transacoesTempoReal';
import { IPhobeusPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  zeroState: boolean;
  allLiveTransactions: IPhobeusPagedResult<AllTransacoesDto>;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_LIVE_TRANSACTIONS = 'GET_ALL_LIVE_TRANSACTIONS',
  SHOW_ZERO_STATE = 'SHOW_ZERO_STATE',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
