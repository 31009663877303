import {Col, Row} from "antd";
import {Button, Container, Form, Label, ResetButton} from "./styles";
import FormInput from '../../../../components/Forms/Input'
import Select from "antd/lib/select";
import React, {useState} from "react";
import {FormProps, IFormProps, IFormValues} from "./types";
import {withFormik} from "formik";

const Filter: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    resetForm,
  } = props;

  const resetUserForm = async () => {
    resetForm();
    await props.onSubmit({} as IFormValues);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={24}>
          <Col>
            <FormInput
              type="text"
              name="identifier"
              label="Nome"
              placeholder="Nome"
            />
          </Col>
          <Col>
            <FormInput
              type="text"
              name="serialNumber"
              label="Número de Série"
              placeholder="Serial Number"
            />
          </Col>
          <Col>
            <FormInput
              type="text"
              name="tid"
              label="Tid"
              placeholder="tid"
            />
          </Col>
          <Col>
            <ResetButton
              onClick={() => resetUserForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default withFormik<IFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    id: 0,
    identifier: '',
    serialNumber: '',
    tid: '',
    status: ''
  }),
  handleSubmit: (values,{props}) => props.onSubmit({...values}),
})(Filter);
