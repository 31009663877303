import {
  CarteiraVirtualDto,
  IFilterCarteiraVirtualProps,
} from '../../global/dataTransferObjects/carteiraVirtual';
import { IPagedResult } from '../../global/generics';
import { ICarteiraVitrualService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { ApiException } from '../../global/exceptions';

const carteiraVirtualService = (apiProvider: ITecpayApi): ICarteiraVitrualService => {
  const getVirtualCheckingAccount = async (
    offset: number,
    pageSize: number,
    businessIdentification?: string,
    filter?: IFilterCarteiraVirtualProps,
  ): Promise<IApiResponse<IPagedResult<CarteiraVirtualDto>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const businessId = businessIdentification || filter?.businessId || null;
    const response = await apiProvider
      .createRequest<IPagedResult<CarteiraVirtualDto>>({ url: 'carteira-virtual' })
      .withSearch({
        offset,
        pageSize,
        businessId,
        startDate,
        finishDate,
      })
      .sendAsync();

    if (response.status !== 200) {
      throw new ApiException(response);
    }

    return response;
  };

  return ({
    getVirtualCheckingAccount,
  });
};

export default carteiraVirtualService;
