import { IExtratoCartaoDto } from '../../global/dataTransferObjects/cartaoAgilitas/extratoCartao';
import {
  IContaDigitalDTO,
  ISaldoCartaoDto,
  IChangeCardPasswordDto,
  IRecoverCardPasswordDto,
} from '../../global/dataTransferObjects/contaDigital';
import {
  IContaDigitalTransacoesDTO,
  IFilterTransacoesValues, IPagedContaDigitalResponseDto,
} from '../../global/dataTransferObjects/contaDigital/transacoes';
import { ICargaCartaoDto, ICargaCartaoResponseDto } from '../../global/dataTransferObjects/pix/pagamento';
import { IPagedResult } from '../../global/generics';
import { IFilterExtratoCartao } from '../../pages/cargaCartao/extrato/components/filterForm/types';
import { IContaDigitalService } from '../@types/services';
import { HttpMethod, IApiResponse, ITecpayApi } from '../providers/types';

const contaDigitalService = (apiProvider: ITecpayApi): IContaDigitalService => {
  const getContaDigital = async (): Promise<IApiResponse<IContaDigitalDTO>> => apiProvider
    .createRequest<IContaDigitalDTO>({ url: '/contas-digitais/minha-conta' })
    .sendAsync();

  const getAllTransacoes = async (
    offset: number,
    pageSize: number,
    filter: IFilterTransacoesValues,
  ): Promise<IApiResponse<IPagedContaDigitalResponseDto<IContaDigitalTransacoesDTO>>> => {
    const url = '/contas-digitais/minha-conta/transacoes';

    const start = filter?.start || null;
    const finish = filter?.finish || null;
    const pagamentoId = filter?.pagamentoId || null;
    const tipoServico = filter?.tipoServico || null;

    return apiProvider
      .createRequest<IPagedContaDigitalResponseDto<IContaDigitalTransacoesDTO>>({ url })
      .withSearch({
        offset,
        pageSize,
        start,
        finish,
        pagamentoId,
        tipoServico,
      })
      .sendAsync();
  }

  const createTransferenciaEntreCartoes = async (valorTransferencia: ICargaCartaoDto):
  Promise<IApiResponse<ICargaCartaoResponseDto>> => {
    const payload = { ...valorTransferencia };

    return apiProvider
      .createRequest<ICargaCartaoDto, ICargaCartaoResponseDto>({ url: '/carga-cartao/transferencia-proxy' })
      .withMethod(HttpMethod.POST)
      .withData({ ...payload })
      .sendAsync();
  }

  const getSaldoCartao = async (
  ): Promise<IApiResponse<ISaldoCartaoDto>> => {
    const url = '/carga-cartao/saldo';
    return apiProvider
      .createRequest<ISaldoCartaoDto>({ url })
      .sendAsync();
  }

  const getTransacoesCartao = async (
    offset: number,
    pageSize: number,
    filter: IFilterExtratoCartao,
  ): Promise<IApiResponse<IPagedResult<IExtratoCartaoDto>>> => {
    const url = '/carga-cartao/extrato';
    const dataInicial = filter?.start || null;
    const dataFinal = filter?.finish || null;
    return apiProvider
      .createRequest<IPagedResult<IExtratoCartaoDto>>({ url })
      .withSearch({
        dataInicial,
        dataFinal,
        offset,
        pageSize,
      })
      .sendAsync();
  }

  const changeCardPassword = async (): Promise<IApiResponse<IChangeCardPasswordDto>> => apiProvider
    .createRequest<IChangeCardPasswordDto>({ url: '/carga-cartao/alterar-senha' })
    .withMethod(HttpMethod.POST)
    .sendAsync()

  const recoverCardPassword = async (): Promise<IApiResponse<IRecoverCardPasswordDto>> => apiProvider
    .createRequest<IRecoverCardPasswordDto>({ url: '/carga-cartao/resgatar-senha' })
    .withMethod(HttpMethod.POST)
    .sendAsync()

  return ({
    getContaDigital,
    getAllTransacoes,
    getSaldoCartao,
    getTransacoesCartao,
    createTransferenciaEntreCartoes,
    changeCardPassword,
    recoverCardPassword,
  });
};

export default contaDigitalService;
