import styled from 'styled-components';
import { Layout } from 'antd';
import { Button as StyledButton } from '../../../styles/buttons'

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const TableContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
    }
`;

export const ResponsiveTable = styled.div`
`;

export const ResponsiveRow = styled.div`
  display: flex;
  margin: 0 12px 12px 0;
  justify-content: space-between;

  text-align: center;
  align-items: center;

  border-radius: 8px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ResponsiveColumn = styled.div`
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  padding: 7px;
`;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  align-self: flex-end;
  border-radius: 20px;
`;

export const ZeroWrapper = styled(Layout)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
`;
