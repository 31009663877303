import React from 'react';
import { useHistory } from 'react-router-dom';
import { RoutesEnum } from '../../routes/types';
import SignInForm from './signForm';
import { IFormValues } from './signForm/types';
import { SignInAdminProps } from './types';

const SignIn: React.FC<SignInAdminProps> = (props: SignInAdminProps) => {
  const { isLoading, match, isAuthenticated } = props;
  const { email } = match.params;
  const history = useHistory();

  const onSignFormSubmit = async (values: IFormValues) => {
    const { password } = values;
    await props.authenticateUser({ email, password, grantType: 'support_access' });
  };

  if (isAuthenticated) {
    history.push(RoutesEnum.Vendas);
    return null;
  }

  return (
    <SignInForm
      loading={isLoading}
      onSignInFormSubmit={onSignFormSubmit}
    />
  );
}

export default SignIn;
