import { IConsulta } from '../@types/services';
import { IConsultaDto, ICreateConsultaDto } from '../../global/dataTransferObjects/consulta';
import {
  HttpMethod,
  IApiResponse,
  ITecpayApi,
} from '../providers/types';
import { IPagedResult } from '../../global/generics';

const consultaService = (apiProvider: ITecpayApi): IConsulta => {
  const createNewConsulta = async (consultaDto: ICreateConsultaDto): Promise<IApiResponse<IConsultaDto>> => {
    const { listaCodigoArranjoPagamento, credenciadora } = consultaDto;
    const arranjoPagamento = listaCodigoArranjoPagamento.map((x: any) => x.value);
    const numeroCredenciadora = credenciadora.map((x: any) => x.value);
    const payload = {
      ...consultaDto,
      listaCodigoArranjoPagamento: arranjoPagamento,
      credenciadora: numeroCredenciadora,
    };
    return apiProvider.createRequest<ICreateConsultaDto, IConsultaDto>({ url: '/cerc/solicitacao-consulta' })
      .withData(payload)
      .withMethod(HttpMethod.POST)
      .sendAsync();
  };

  const getUserCnpj = async (offset = 0, pageSize = 20): Promise<IApiResponse<IPagedResult<IConsultaDto>>> => apiProvider
    .createRequest<IPagedResult<IConsultaDto>>({ url: '/cerc/solicitacao-consulta' })
    .withSearch({ offset, pageSize })
    .sendAsync();

  return ({
    createNewConsulta,
    getUserCnpj,
  });
};

export default consultaService;
