import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import {
  IContaDigitalTransacoesDTO,
  IFilterTransacoesValues,
  IPagedContaDigitalResponseDto,
} from '../../../../global/dataTransferObjects/contaDigital/transacoes';
import createContaDigitalService from '../../../../services/contaDigital';
import { HttpStatus } from '../../../../services/providers/types';
import { ActionTypes } from './types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateTransacoes = (
  transacoes: IPagedContaDigitalResponseDto<IContaDigitalTransacoesDTO>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_TRANSACOES,
  payload: {
    transacoes: { ...transacoes },
  },
});

export const updateTransacoes = (
  offset = 0,
  pageSize = 20,
  filter?: IFilterTransacoesValues,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const contaDigitalService = createContaDigitalService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await contaDigitalService.getAllTransacoes(offset, pageSize, { ...filter });
  if (result.status !== HttpStatus.OK) {
    toast.error(result.message ?? 'Houve um erro ao obter o extrato');
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onUpdateTransacoes({ ...result.response })(dispatch);
  return true;
};
