import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes, IPageState } from './types';
import createVeiculoService from '../../../../services/veiculo';
import { IVeiculoDto } from '../../../../global/dataTransferObjects/veiculo';
import {
  IDebitosDto,
  IDpvatDto,
  IIpvaDto,
  ILicenciamentoDto,
  IMultaDto,
} from '../../../../global/dataTransferObjects/debitosVeiculares';
import { createSecurityProvider } from '../../../../services/providers/factories';
import { TiposDebitosEnum } from '../../../../global/enum';
import createPaymentService from '../../../../services/payment';
import { IPagamentoDebitosFormValues } from '../../../../components/Forms/Pages/PagamentoDebitosVeicularesModal/types';
import { IPagedResult } from '../../../../global/generics';
import { HttpStatus } from '../../../../services/providers/types';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateVeiculos = (veiculos: IPagedResult<IVeiculoDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_VEICULOS,
  payload: {
    veiculos: { ...veiculos },
  },
});

export const onUpdateVehicleDebits = (debits: IDebitosDto, veiculoId: string) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_DEBITS_VEHICLES,
  payload: {
    veiculoId,
    debits: { ...debits },
  },
});

export const onUpdatePaymentModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_PAYMENT_MODAL_STATUS,
  payload: { status },
});

export const onClosePaymentModal = () => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.CLOSE_PAYMENT_MODAL,
});

export const onIpvasSelectionChange = (ipvas: Array<IIpvaDto>) => (dispatch: Dispatch<any>): void => {
  dispatch({
    type: ActionTypes.UPDATE_IPVAS_SELECTION,
    payload: {
      ipvas: [...ipvas],
    },
  });
};

export const onDpvaTsSelectionChange = (dpvats: Array<IDpvatDto>) => (dispatch: Dispatch<any>): void => {
  dispatch({
    type: ActionTypes.UPDATE_DPVATS_SELECTION,
    payload: {
      dpvats: [...dpvats],
    },
  });
};

export const onUpdateMultasSelection = (multas: Array<IMultaDto>) => (dispatch: Dispatch<any>): void => {
  dispatch({
    type: ActionTypes.UPDATE_MULTAS_SELECTION,
    payload: {
      multas: [...multas],
    },
  });
};

export const onUpdateLicenciamentoSelection = (
  licenciamento: ILicenciamentoDto | null = null,
  state: IPageState,
) => (dispatch: Dispatch<any>): void => {
  dispatch({
    type: ActionTypes.UPDATE_LICENCIAMENTO_SELECTION,
    payload: {
      licenciamento: !!licenciamento && { ...licenciamento },
      dpvats: licenciamento
        ? [...state.debits.dpvaTs]
        : [],
      multas: licenciamento
        ? [...state.debits.multas]
        : [],
      ipvas: licenciamento
        ? [...state.debits.ipvas]
        : [],
    },
  });
};

export const onOpenPaymentModal = (
  valorLiquido: number,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_PAYMENT_MODAL,
  payload: {
    valorLiquido,
  },
});

export const onUpdateTotal = (valorTotal: number) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_TOTAL,
  payload: {
    valorTotal,
  },
});

export const sendPayment = (
  state: IPageState,
  values: IPagamentoDebitosFormValues,
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const paymentService = createPaymentService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await paymentService.efetuarPagamento({
    veiculoId: state.vehicleId,
    paymentInfo: {
      installments: values.installments?.value,
      cardInfo: {
        cardBrand: values.brand,
        cardHolder: values.cardholderName,
        cardNumber: values.cardNumber,
        expirationMonth: values.expiry.split('/')[0],
        expirationYear: values.expiry.split('/')[1],
        securityCode: values.cvc,
      },
    },
    dpvaTs: [...state.selectedDebitos.dpvaTs],
    ipvas: [...state.selectedDebitos.ipvas],
    multas: [...state.selectedDebitos.multas],
    licenciamento: state.selectedDebitos.licenciamento.key
      ? { ...state.selectedDebitos.licenciamento }
      : null,
  });

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    return false;
  }

  onUpdateLoadingStatus(false)(dispatch);
  return true;
};

export const openPaymentModal = (state: IPageState) => async (dispatch: Dispatch<any>): Promise<void> => {
  const veiculoService = createVeiculoService();
  const { selectedDebitos } = state;
  onUpdateLoadingStatus(true)(dispatch);

  const valorLiquido = await veiculoService.calcularValorTotalDebitosVeiculares({ ...selectedDebitos });

  if (!valorLiquido) {
    toast.error('Selecione ao menos um débito para realizar o pagamento');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onOpenPaymentModal(valorLiquido)(dispatch);
};

export const getVehicleDebits = (vehicleId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  const vehicleService = createVeiculoService();
  const securityProvider = createSecurityProvider();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await vehicleService.getVehicleDebits(vehicleId);
  if (result.status !== 200) {
    toast.error(result.message ?? 'Erro ao obter débitos do veiculo.');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  const formatDebitos = (debitos: Array<any>, tipoDebito: TiposDebitosEnum) => (debitos.map((debito) => ({
    ...debito,
    key: securityProvider.createUuid(),
    tipoDebito,
  })));

  const licenciamento: ILicenciamentoDto = {
    ...result.response.licenciamento,
    key: securityProvider.createUuid(),
  }
  const dpvaTs = formatDebitos(result.response?.dpvaTs, TiposDebitosEnum.Dpvat);
  const ipvas = formatDebitos(result.response?.ipvas, TiposDebitosEnum.Ipva);
  const multas = formatDebitos(result.response?.multas, TiposDebitosEnum.Multa);

  const debitos = {
    vehicleId,
    licenciamento: { ...licenciamento },
    dpvaTs,
    ipvas,
    multas,
  }

  onUpdateVehicleDebits(debitos, vehicleId)(dispatch);
}

export const updateTotal = (
  installment: number,
  state: IPageState,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const veiculoService = createVeiculoService();
  const { selectedDebitos } = state;
  onUpdateLoadingStatus(true)(dispatch);

  const result = await veiculoService.getDebitosVeicularesTaxas();
  if (result.status !== HttpStatus.OK) {
    toast.error('Erro ao obter a taxa do servico!')
    return;
  }

  const valorTotal = await veiculoService
    .calcularValorTotalDebitosVeicularesComTaxas(installment, { ...selectedDebitos }, result.response);

  if (!valorTotal) {
    toast.error('Não foi possivel obter o valor total');
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }

  onUpdateTotal(valorTotal)(dispatch);
}
