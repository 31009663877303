import React, { useReducer, useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { gerarBoletoCnab } from './actions';
import { Reducer, InitialState } from './actions/reducer';
import { T2 } from '../../../styles/titles';
import * as S from './styles'
import { convertToBase64 } from '../../../utils/convertToBase64';
import { ISelectValue } from '../../../global/types';
import { IGerarBoletoCnabValues } from './types';

const GerarBoletoCnab: React.FC = () => {
  const history = useHistory();

  const [, dispatch] = useReducer(Reducer, InitialState);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [codbanco, setCodbanco] = useState<any>();

  const customRequest = async () => {
    setUploading(true);
    const file = fileList[0];
    const base64 = await convertToBase64(file as RcFile);
    const result = await gerarBoletoCnab({ dataUri: base64, codigoBanco: codbanco?.value } as IGerarBoletoCnabValues)(dispatch);

    if (!result) {
      setUploading(false);
      return;
    }

    toast.success('Informações de arquivo CNAB salvas com sucesso!');
    setUploading(false);
    history.push('/boletos');
  }

  const verificaExtensao = (file: RcFile) => {
    const extPermitidas = ['REM'];
    const extArquivo = file.name.split('.').pop();

    if (typeof extPermitidas.find((ext) => extArquivo === ext) === 'undefined') {
      toast.error(`Extensão "${extArquivo}" não permitida!`);
      return false;
    }
    return true;
  }

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isAllowedType = verificaExtensao(file);
      if (!isAllowedType) {
        setFileList((state) => [...state]);
        return false;
      }
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const options: ISelectValue[] = [
    { label: 'Itaú Unibanco', value: '341' },
  ];

  return (
    <S.Container>
      <T2>Gerar Boleto CNAB</T2>
      <S.Card
        title="Gerar boleto através de um arquivo CNAB"
        bordered={false}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
        }}
        >
          <Select
            name="codigoBanco"
            placeholder="Escolha o banco de origem do arquivo CNAB..."
            label="Instituição Bancária"
            options={options}
            onChange={(value) => setCodbanco(value)}
          />
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Escolher arquivo</Button>
          </Upload>
          <S.SubmitBtn
            htmlType="submit"
            onClick={customRequest}
            disabled={fileList.length === 0 || codbanco === undefined}
            loading={uploading}
            style={{ marginTop: 16, alignSelf: 'flex-end' }}
          >
            {uploading ? 'Enviando...' : 'Enviar arquivo'}
          </S.SubmitBtn>
        </div>
        <S.BottomText>É permitido enviar apenas arquivos CNAB do tipo remessa *.REM</S.BottomText>
      </S.Card>
    </S.Container>
  )
};

export default GerarBoletoCnab;
