import { IVeiculoDto } from '../../../global/dataTransferObjects/veiculo';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean;
  veiculos: IPagedResult<IVeiculoDto>;
  isPaymentModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_VEICULOS = 'UPDATE_VEICULOS',
  OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL',
  UPDATE_PAYMENT_MODAL_STATUS = 'UPDATE_PAYMENT_MODAL_STATUS',
  CREATE_NEW_VEICULO = 'CREATE_NEW_VEICULO',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
