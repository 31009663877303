import React, { useEffect, useMemo, useState } from 'react'
import createContaDigitalService from '../../services/contaDigital';
import { createFormatProvider } from '../../services/providers/factories';
import { Meta } from './styles'

const DigitalBalance: React.FC = () => {
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const [ballance, setBallance] = useState<number>(0);

  const fetchBalance = async () => {
    const contaDigital = await createContaDigitalService().getContaDigital();
    setBallance(contaDigital.response.balance);
  }

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  useEffect(() => {
    fetchBalance();
  }, []);
  return (
    <Meta title="Saldo da conta digital:" description={formattedValue(ballance)} />
  )
}

export default DigitalBalance;
