import { installmentService } from './installmentService';
import { IInstallmentService } from '../@types/services';
import { createApi } from '../providers/factories';

const createInstallmentService = (): IInstallmentService => {
  const apiProvider = createApi();
  return installmentService(apiProvider);
};

export default createInstallmentService;
