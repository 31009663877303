import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AllBoletosDto, IAllBoletos, IBoletosFilterProps } from '../../../../global/dataTransferObjects/boletos';
import { IPagedResult } from '../../../../global/generics';
import createBoletoService from '../../../../services/boletos/listaBoletos';
import { ActionTypes } from './types';

export const onGetAllBoletos = (allBoletos: IPagedResult<IAllBoletos>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_BOLETOS,
  payload: {
    allBoletos: { ...allBoletos },
  },
});

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateBoletoModalStatus = (status: boolean) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_BOLETO_MODAL_STATUS,
  payload: { status },
});

export const onUpdateBoleto = (
  boleto: AllBoletosDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_BOLETO_MODAL,
  payload: {
    boleto: { ...boleto },
  },
});

export const openBoletoViewModal = (
  boleto: AllBoletosDto,
) => (dispatch: Dispatch<any>): void => {
  onUpdateBoleto({ ...boleto })(dispatch);
  onUpdateBoletoModalStatus(true)(dispatch);
}

export const getAllBoletos = (
  offSet: number,
  pageSize: number,
  filter?: IBoletosFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const boletoService = createBoletoService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await boletoService.getAllBoletos(offSet, pageSize, filter);

  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllBoletos(result.response)(dispatch);
}
