import React, {
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { IUpdatePoliticaCobrancaProps } from './types';
import PoliticaCobrancaForm from './components/UpdateForm';
import { IFormValues } from './components/UpdateForm/types';
import { Box, Container } from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { T2 } from '../../../styles/titles';
import { InitialState, Reducer } from './actions/reducer';
import {
  getPoliticaContaDigital,
  updatePoliticaContaDigital,
  getUsersByPoliticaId,
} from './actions';
import { IUpdatePoliticaContaDigitalRequestDTO } from '../../../global/dataTransferObjects/politicaContaDigital';

const PoliticaContaDigitalUpdate: React.FC<IUpdatePoliticaCobrancaProps> = (props: IUpdatePoliticaCobrancaProps) => {
  const { match } = props;
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();

  const handleGetPoliticaCobranca = useCallback(() => {
    const { politicaCobrancaId } = match.params;
    getPoliticaContaDigital(politicaCobrancaId)(dispatch);
    getUsersByPoliticaId(politicaCobrancaId)(dispatch);
  }, []);

  useEffect(() => handleGetPoliticaCobranca(), []);

  const toDecimal = useMemo(() => (value: string) => parseFloat(value.replace('.', '').replace(',', '.')), []);

  const onFormSubmit = async (values: IFormValues) => {
    const formattedValues: IUpdatePoliticaContaDigitalRequestDTO = {
      ...values,
      taxaPixPagamento: toDecimal(values.taxaPixPagamento),
      taxaPixRecebimento: toDecimal(values.taxaPixRecebimento),
      taxaPixConsultaInfo: toDecimal(values.taxaPixConsultaInfo),
      taxaMensalidadeCartao: toDecimal(values.taxaMensalidadeCartao),
      taxaMensalideConta: toDecimal(values.taxaMensalideConta),
      taxaCargaCartao: toDecimal(values.taxaCargaCartao),
      taxaTransferenciaEntreProxies: toDecimal(values.taxaTransferenciaEntreProxies),
      taxaTransferenciaInterna: toDecimal(values.taxaTransferenciaInterna),
      taxaEmissaoBoleto: toDecimal(values.taxaEmissaoBoleto),
      taxaPagamentoBoleto: toDecimal(values.taxaPagamentoBoleto),
      taxaLiquidacaoBoleto: toDecimal(values.taxaLiquidacaoBoleto),
      taxaConsultaInfoBoleto: toDecimal(values.taxaConsultaInfoBoleto),
      tarifaOriginacao: toDecimal(values.tarifaOriginacao),
      tarifaGerenciamento: toDecimal(values.tarifaGerenciamento),
    };

    const result = await updatePoliticaContaDigital(state.politicaContaDigital.id, { ...formattedValues })(dispatch);

    if (!result) {
      return;
    }

    toast.success('Política de cobrança alterada com sucesso!');
    history.push('/contas-digitais/politicas-cobrancas');
  };

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>
        Conta Digital
        <br />
        <small>Alterar política de cobrança</small>
      </T2>
      <Box>
        <PoliticaCobrancaForm
          onSubmit={onFormSubmit}
          politicaContaDigital={{ ...state.politicaContaDigital }}
          foundedSelectedUsers={[...state.selectedUsers]}
        />
      </Box>
    </Container>
  );
}

export default PoliticaContaDigitalUpdate;
