import styled from 'styled-components';

export const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

export const DateView = styled.div`
  margin: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 0.8rem;
  width: 100%;
  max-width: 200px;
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }

    &:focus-visible {

    }
  }
  div.DayPickerInput, input {
   width: 100%;
  }
    .DayPickerInput-OverlayWrapper{
  z-index:2000;
  }
  `;


export const FilterContainer = styled.div`
    display: flex;
    width: 99%;
  @media (max-width: 768px) {
    display: block;
  }

  input {
    height: 34px;
  }

  select {
    height: 34px;

    border-color: lightgray;

    padding-left: 4px;
    padding-right: 4px;
  }

  button {
    height: 34px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 99%;
    justify-content: space-between;
  }
`;
