/* eslint-disable max-len */
/* eslint-disable camelcase */
import { IArranjoDto } from '../../global/dataTransferObjects/arranjos';
import { IPagedResult } from '../../global/generics';
import { IFilterArranjosProps } from '../../pages/arranjos/components/FormFilter/types';
import { IArranjoService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';

const arranjoService = (apiProvider: ITecpayApi): IArranjoService => {
  const getArranjos = async (offset: number, pageSize: number, filter?: IFilterArranjosProps): Promise<IApiResponse<IPagedResult<IArranjoDto>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const CNPJCreddr = filter?.CNPJCreddr || null;
    const NomCreddr = filter?.NomCreddr || null;
    const NULiquid = filter?.NULiquid || null;
    const NomePontoVenda = filter?.NomePontoVenda || null;
    const CNPJ_CPFPontoVenda = filter?.CNPJ_CPFPontoVenda || null;

    const response = await apiProvider
      .createRequest<IPagedResult<IArranjoDto>>({ url: '/cip/arranjo' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
        CNPJCreddr,
        NomCreddr,
        NULiquid,
        NomePontoVenda,
        CNPJ_CPFPontoVenda,
      })
      .sendAsync();

    return response;
  }
  return ({
    getArranjos,
  })
}

export default arranjoService;
