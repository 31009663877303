import { AllBoletosDto } from '../../../../global/dataTransferObjects/boletos';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  allBoletos: IPagedResult<AllBoletosDto>;
  boleto: AllBoletosDto;
  loading: boolean;
  isViewBoletoModalOpen: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ALL_BOLETOS = 'GET_ALL_BOLETOS',
  UPDATE_BOLETO_MODAL_STATUS = 'UPDATE_BOLETO_MODAL_STATUS',
  UPDATE_BOLETO_MODAL = 'UPDATE_BOLETO_MODAL',
}
export interface IAction {
  type: ActionTypes;
  payload?: any;
}
