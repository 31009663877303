import styled from 'styled-components'
import {
  Modal as AntdModal,
} from 'antd';
import { Button as StyledButton } from '../../../../../styles/buttons';

export const Modal = styled(AntdModal)``;

export const Description = styled.text`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

export const Item = styled.text`
  margin-left: 10px;
  font-size: 16px;
  color: black;
`;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 1em;
  align-self: center;
  padding: 0 2em;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #C6C6C6;
`;

export const Container = styled.div`
  padding: 0.5rem 1rem;
  gap: 1rem;
  display: flex;
  justify-content: center;
`;

export const TextGroup = styled.div`
  margin-bottom: 10px;
`;

export const VerticalLine = styled.hr`
  width: 100%;
  height: 100%;
  border: 0.5px solid;
  border-color: #169ead;
  float: center;
  background-color: ${(props) => props.theme.colors.cyanDark};
`;
