import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import {
  ICreateNewUserDto, IFilterUserProps, IUpdateUserDto, UserDto,
} from '../../../global/dataTransferObjects/user';
import { IPagedResult } from '../../../global/generics';
import createUserService from '../../../services/user';
import { HttpStatus } from '../../../services/providers/types';
import createUserAnticipationService from '../../../services/userAnticipation';

export const onLoadingStatusPage = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.LOADING_STATUS_PAGE,
  payload: {
    status,
  },
});

export const onLoadingStatusModal = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.LOADING_STATUS_MODAL,
  payload: {
    status,
  },
});

export const onGetAllUser = (users: IPagedResult<UserDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_USERS,
  payload: {
    users: { ...users },
  },
});

export const onUpdateUserModalStatus = (status: boolean) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_USER_MODAL_STATUS,
  payload: { status },
});

export const onViewUserModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.VIEW_USER_MODAL_STATUS,
  payload: { status },
});

export const onUpdateUser = (
  user: IUpdateUserDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_USER_MODAL,
  payload: {
    user: { ...user },
  },
});

export const openUserViewModal = (
  user: UserDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_USER_MODAL,
  payload: {
    user: { ...user },
  },
});

export const onCreateUserModalOpen = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.OPEN_CREATE_USER_MODAL,
  payload: { status },
});

export const openCreateUserModal = () => (dispatch: Dispatch<any>): void => {
  onCreateUserModalOpen(true)(dispatch);
}

export const openUserEditModal = (
  user: IUpdateUserDto,
) => (dispatch: Dispatch<any>): void => {
  onUpdateUser({ ...user })(dispatch);
  onUpdateUserModalStatus(true)(dispatch);
}

export const onCreateNewUser = (status = false) => (dispatch: Dispatch<any>): any => dispatch({
  type: ActionTypes.CREATE_NEW_USER,
  payload: { status },
});

export const getAllUsers = (
  offSet: number,
  pageSize: number,
  filter?: IFilterUserProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onLoadingStatusPage(true)(dispatch);

  const result = await userService.getUsers(offSet, pageSize, filter);
  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }

  onGetAllUser({ ...result.response })(dispatch);
};

export const deleteUser = (userId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onLoadingStatusPage(true)(dispatch);

  const result = await userService.deleteUser(userId);
  if (result.status !== 200) {
    toast.error(result.message);
  }

  onLoadingStatusPage(false)(dispatch);
  toast.success('Usuário excluído com sucesso!')
};

export const desvincularUsuario = (userId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onLoadingStatusPage(true)(dispatch);

  const result = await userService.desvincularUsuario(userId);
  if (result.status !== 200) {
    toast.error(result.message);
  }

  onLoadingStatusPage(false)(dispatch);
  toast.success('Usuário desvinculado com sucesso!')
};

export const CreateUser = (userDto: ICreateNewUserDto) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userService = createUserService();
  onLoadingStatusModal(true)(dispatch);

  const result = await userService.adminCreateNewUser(userDto);
  if (result.status === undefined || result.status !== HttpStatus.CREATED) {
    onLoadingStatusModal(false)(dispatch);
    toast.error('Erro ao criar usuário');
    if (result.message !== undefined || result.message !== null) {
      toast.error(result.message);
    }

    return;
  }

  onCreateNewUser(false)(dispatch);
  onLoadingStatusPage(false)(dispatch);
  toast.success('Usuário criado com sucesso!');
}

export const UpdateUser = (userId: string, user: IUpdateUserDto) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const userService = createUserService();
  onLoadingStatusModal(true)(dispatch);

  const result = await userService.updateUser(userId, user);
  if (result.status === undefined) {
    toast.error('Erro ao alterar dados do usuário');
    return false;
  }

  onLoadingStatusModal(false)(dispatch);
  toast.success('Usuário alterado com sucesso!')
  return true;
};

export const linkUser = (UserId: string, AnticipationId: string) => async (dispatch: Dispatch<any>): Promise<void> => {
  const userAnticipationService = createUserAnticipationService();
  onLoadingStatusPage(true)(dispatch);

  const result = await userAnticipationService.submitNewLink({ userId: UserId, anticipationId: AnticipationId });
  if (result.status !== HttpStatus.OK) {
    toast.error('Erro ao vincular usuário');
    return;
  }

  onLoadingStatusPage(false)(dispatch);
  toast.success('Solicitação efetivada com sucesso!')
};

export const getUserInformation = (userId: string) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  const userService = createUserService();
  onViewUserModalStatus(true)(dispatch);
  onLoadingStatusPage(true)(dispatch);
  const result = await userService.getUserById(userId);
  if (result.status !== HttpStatus.OK) {
    onLoadingStatusPage(false)(dispatch);
    toast.error('Erro ao obter informações do usuário');
    return false;
  }

  onLoadingStatusPage(false)(dispatch);
  openUserViewModal({ ...result.response })(dispatch);
  return true;
}
