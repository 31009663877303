import { IUserBusinessService } from '../@types/services';
import { createApi } from '../providers/factories';
import userBusinessService from './userBusinessService';

const createUserBusinessService = (): IUserBusinessService => {
  const apiProvider = createApi();
  return userBusinessService(apiProvider);
};

export default createUserBusinessService;
