export const formatterPhoneNumber = (value: string): string => {
  const phoneNumber = value.replace(/[^0-9]/g, '');
  if (phoneNumber.length === 11) {
    const telNumber: RegExpMatchArray | null = phoneNumber.match(/^([0-9]{2})([0-9]{5})([0-9]{4})/);
    if (telNumber !== null) {
      return `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`;
    }
  }
  if (phoneNumber.length === 10) {
    const telNumber: RegExpMatchArray | null = phoneNumber.match(/^([0-9]{2})([0-9]{4})([0-9]{4})/);
    if (telNumber !== null) {
      return `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`;
    }
  }
  return phoneNumber;
}

export const formatterDocumentNumber = (value: string): string => {
  if (value === undefined) {
    return '';
  }
  const documentNumber = value.replace(/[^0-9]/g, '');
  if (documentNumber.length === 11) {
    const cpf: RegExpMatchArray | null = value.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
    if (cpf !== null) {
      return `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
    }
  }
  if (documentNumber.length === 14) {
    const cnpj: any = value.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
    if (cnpj !== null) {
      return `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
    }
  }
  return documentNumber;
}

export const formatterMaskDocumentNumber = (value: string): string => {
  if (value === undefined) {
    return '';
  }
  const documentNumber = value.replace(/[^0-9]/g, '');
  if (documentNumber.length === 11) {
    const cpf: RegExpMatchArray | null = value.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
    if (cpf !== null) {
      return `***.${cpf[2]}.***-**`;
    }
  }
  if (documentNumber.length === 14) {
    const cnpj: any = value.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
    if (cnpj !== null) {
      return `**.${cnpj[2]}.***/****-**`;
    }
  }
  return documentNumber;
}
