export interface IPageState {
  loading: boolean;
  existenceLink: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  CHECK_EXISTENCE = 'CHECK_EXISTENCE',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
