import { IPoliticaCobrancaDTO } from '../../../../global/dataTransferObjects/politicaCobranca';
import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  politicasCobrancas: IPagedResult<IPoliticaCobrancaDTO>;
  isDetailsModalOpen: boolean;
  politicaCobranca: IPoliticaCobrancaDTO;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  UPDATE_POLITICAS_COBRANCAS = 'UPDATE_POLITICAS_COBRANCAS',
  UPDATE_POLITICA_COBRANCA_MODAL = 'UPDATE_POLITICA_COBRANCA_MODAL',
  OPEN_POLITICA_COBRANCA_MODAL = 'OPEN_POLITICA_COBRANCA_MODAL'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
