import styled from 'styled-components';
import ReactSelect from 'react-select';
import { IProps } from '../../../styles/types';

export const SelectField = styled(ReactSelect)`
  & .react-select__control--is-focused {
    border-color: ${(props: IProps) => props.variant} !important;
    box-shadow: none !important;
  }
`;
